export const getAsyncData = () =>{
    return{
        type:"GET_ASYNC_DATA"
    }
}

export const getDoctorDetails= ()=>{
    return{
        type:"GET_DOCTOR_DETAILS",
    }
}

export const getPatientStats= ()=>{
  
    return{
        type:"GET_PATIENT_STATS",
    }
}

export const getEprescription= ()=>{
  
    return{
        type:"GET_EPRESCRIPTION",
    }
}

export const getLabprescription= ()=>{
   
    return{
        type:"GET_LABPRESCRIPTION",
    }
}

export const getNextAppointment= ()=>{
   
    return{
        type:"GET_NEXTAPPOINTMENT",
    }
}

export const getAppointmentDates= ()=>{
    
    return{
        type:"GET_APPOINTMENT_DATES",
    }
}
export const getAppointmentSlot= (date)=>{
    
    return{
        type:"GET_APPOINTMENT_SLOT",
        date
    }
}

