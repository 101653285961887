import Layout from "../../Dashboard/extlayout";
import React, { useState, useEffect, useReducer } from "react";
import "../dashboardstyles/profile.scss";
import {
  makeStyles,
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Dialog, DialogContent, Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Insta from "../../assets/images/insta.svg";
import FB from "../../assets/images/fb.svg";
import FBB from "../../assets/images/doctor.svg";
import LIn from "../../assets/images/linkedin.svg";
import Twitter from "../../assets/images/twitter.svg";
import Play from "../../assets/images/play.svg";
import Star from "../../assets/images/profilestar.svg";
import GreenSlot from "../../assets/images/GreenSlot.svg";
import { getDoctorDetails } from "../../redux/actions/TestAction";
import { getPatientStats } from "../../redux/actions/TestAction";
import { getEprescription } from "../../redux/actions/TestAction";
import { getLabprescription } from "../../redux/actions/TestAction";
import { getNextAppointment } from "../../redux/actions/TestAction";
import { getAppointmentDates } from "../../redux/actions/TestAction";

import ProfileUpdatePage from "./ProfileUpdatePage";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

import { useTheme, useMediaQuery } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import UpdateBankDetails from "../components/UpdateBankDetails";
import {
  getBankDetails,
  getStatistics,
} from "../../redux/actions/ex-appointment-action";
import MobLayout from "../../Dashboard/mobLayout";
import ProfilePage from "../mobileComp/ProfilePage";
import axios from "axios";
import infoIcon from "../../assets/images/info_icon.svg";
import { Helmet } from "react-helmet";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

var pixels = 100;
var screenWidth = window.screen.width;
var percentage = (screenWidth - pixels) / screenWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  dataCards: {
    margin: "10px",
    padding: "20px",
    border: "1px solid #66B5C3",
    borderRadius: "30px",
    background: "#F3FAFC",
  },
  cardHeading: {
    color: "#667085",
    fontSize: "14px",
    lineHeight: "20px",
  },
  cardSubHeading: {
    fontSize: "30px",
    color: "#101828",
    fontWeight: "700",
    lineHeight: "38px",
  },

  incriment: {
    borderRadius: "16px",
    backgroundColor: "#ECFDF3",
    color: "#027A48",
    padding: "2px 8px 2px 10px",
    fontWeight: "600",
  },

  paper: {
    padding: theme.spacing(1),
    Width: "100%",
  },
  paper1: {
    padding: theme.spacing(2),
    maxWidth: "100%",
    maxHeight: "100%",
  },
  paper2: {
    width: "100%",
    backgroundColor: "white",
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    maxWidth: "307px",
    maxHeight: "335px",
    border: "2px solid ",
    borderRadius: "8px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  image: {
    width: percentage,
  },
  calenderContainer: {
    background: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px",
  },

  icon: {
    marginRight: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  iconContainer: {
    width: "100%",
    // height: 160,
  },
  spacer: {
    padding: theme.spacing(1),
  },
  spacer1: {
    padding: theme.spacing(4),
  },
  grid1: {
    width: "100px",
    height: "200px",
    backgroundColor: "green",
  },
  grid2: {
    marginLeft: "5px",
    width: "100px",
    height: "200px",
    backgroundColor: "yellow",
  },
  button: {
    margin: theme.spacing(1),
    whiteSpace: "nowrap",
    backgroundColor: "#08B4D5",
    color: " #FFFFFF",
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "116.1%",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#08B4D5",
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  border: {
    color: "green",
  },
  text9: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "116.1%",
    color: "rgba(1, 60, 68, 0.5)",
    letterSpacing: "-0.3px",
  },
  text8: {
    fontFamily: "VisueltMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "32px",
    color: "#04A9C8",
    letterSpacing: "-0.3px",
    textAlign: "left",
  },
  text2: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "30px",
    lineHeight: "116.1%",
    color: "#013C44",
    letterSpacing: "-0.3px",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  text3: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "30px",
    lineHeight: "116.1%",
    letterSpacing: "-0.3px",
    color: "#013C44",
  },
  text1: {
    fontFamily: "VisueltRegular",
    fontSize: "30px",
    lineHeight: "38px",
    letterSpacing: "-0.3px",
    fontStyle: "normal",
    color: "#66B5C3",
    fontWeight: "normal",
  },
  text4: {
    fontFamily: "VisueltRegular",
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "-0.3px",
    fontStyle: "normal",
    color: "#046673",
    fontWeight: "normal",
  },
  text5: {
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "144.9%",
    color: "rgba(4, 102, 115, 0.7)",
  },
  text6: {
    fontFamily: "VisueltRegular",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "144.9%",
    color: "#013C44",
    padding: theme.spacing(1),
  },
  text7: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "116.1%",
    letterSpacing: "0.7px",
    color: "#00839B",
  },
  iconBorder: {
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 7px 15px -1px rgba(8, 180, 212, 0.2)",
    borderRadius: "50px",
    padding: "2px",
    marginLeft: "4px",
  },
  iconBorder2: {
    background: "rgba(255, 110, 78, 0.3)",
    borderRadius: "10px",
  },
  iconBorder3: {
    background: "#0696B0",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px",
  },
  borderWrapper: {
    background: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "20px",
  },
  borderWrapper2: {
    backgroundColor: "#08B4D4",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px",
  },
  borderWrapper3: {
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px",
  },
  aboutBorder: {
    background: "#F1F9F5",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    bordeRadius: "30px",
  },
  borderContainer: {
    border: "1px solid #66B5C3",
    boxSizing: "border-box",
    boxShadow: "-7px 6px 7px rgba(6, 99, 116, 0.05)",
    borderRadius: "30px",
    padding: "10px",
    backgroundColor: "#F3FAFC",
  },
  doctorName: {
    fontFamily: "VisueltRegular",
    fontSize: "30px",
    lineHeight: "38px",
    letterSpacing: "-0.3px",
    color: "#66B5C3",
    textAlign: "left",
  },
  doctorappoint: {
    fontFamily: "VisueltMedium",
    fontSize: "20px",
    lineHeight: "25.64px",
    letterSpacing: "-0.3px",
    color: "#046673",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  addressword: {
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    color: "#013c44",
  },
}));

export default function DoctorProfilePage(props) {
  const customSM = useMediaQuery("(max-width:800px)");
  const dispatch = useDispatch();
  const history = useHistory();
  const statisticsDetails = useSelector(
    (state) => state.PatientSnapshot.statistics
  );
  const bankState = useSelector((state) => state.PatientSnapshot.bankDetails);
  console.log(bankState, "bankState1");
  const [editBankModel, setEditBankModel] = useState(false);

  const [allCount, setAllCount] = useState();

  const authState = useSelector((state) => state.loginReducer.payload);

  const classes = useStyles();
  const doctorInfo = useSelector((state) => {
    if (
      state.getDoctorDetails != "" &&
      state.getDoctorDetails.payload != undefined &&
      state.getDoctorDetails.payload.length > 0 &&
      state.getDoctorDetails.payload[0] != undefined
    ) {
      console.log(state.getDoctorDetails.payload[0], "789789789");
      return {
        Location:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.address &&
          state.getDoctorDetails.payload[0].profile.address.city,
        Exp:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.practice_started_year,
        languages:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.languages &&
          state.getDoctorDetails.payload[0].profile.languages.toString(),
        firstName:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].firstName,
        full_name:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].full_name,
        profile_pic:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile_pic,
        experience:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile.practice_started_year,
        mobile:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].mobile,
        email:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].email,
        consultation_fee:
          state.getDoctorDetails.payload[0].consultation_fee &&
          state.getDoctorDetails.payload[0].consultation_fee,
        dob:
          state.getDoctorDetails.payload[0].profile.dob &&
          state.getDoctorDetails.payload[0].profile.dob,
        folioNumber: state.getDoctorDetails.payload[0].profile.folioNumber,
        gender:
          state.getDoctorDetails.payload[0].profile.gender &&
          state.getDoctorDetails.payload[0].profile.gender,
        speciality:
          state.getDoctorDetails.payload[0].profile.speciality &&
          state.getDoctorDetails.payload[0].profile.speciality,
        Certification: state.getDoctorDetails.payload[0].Certification,
        // qualification: state.getDoctorDetails.payload[0].profile.professional_detail.qualification,
        qualification:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.professional_detail &&
          state.getDoctorDetails.payload[0].profile.professional_detail
            .qualification,
      };
    }
  });

  let currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var practice_started_year = doctorInfo && doctorInfo.Exp;
  let FullExperience = currentYear - practice_started_year;

  useEffect(() => {
    dispatch(getDoctorDetails());
    dispatch(getBankDetails());
    // dispatch(getStatistics())
    dispatch(getPatientStats());
    dispatch(getEprescription());
    dispatch(getLabprescription());
    dispatch(getAppointmentDates());
    getCount();
  }, []);

  const getCount = () => {
    let endPoint = `/ep/dashboardcount/${authState.uuid}`;

    axios
      .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
        headers: {
          "x-auth-token": authState.token,
        },
      })
      .then((res) => {
        if (res.data) {
          setAllCount(res && res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const MoveToProfileUpdate = () => {
    history.push({
      pathname: "/doctor-profile-update",
    });
  };

  const setFontSize = (text) => {
    if (text) {
      if (text.length < 20) {
        return 18;
      } else {
        if (text.length < 26) {
          return 16;
        } else if (text.length < 29) {
          return 14.5;
        } else if (text.length < 32) {
          return 13.5;
        } else if (text.length < 35) {
          return 12.5;
        } else if (text.length < 38) {
          return 11;
        } else if (text.length < 42) {
          return 9;
        }
        return 18;
      }
    } else {
      return 18;
    }
  };
  // useEffect(()=>{
  //   dispatch(getBankDetails())
  // },[])
  const [heightS, setHeightS] = useState("");

  const checkSafari = () => {
    return (
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      )
    );
  };
  useEffect(() => {
    if (!checkSafari()) {
      setHeightS("185px");
    } else {
      setHeightS("80px");
    }
  }, []);

  return customSM ? (
    <>
      <MobLayout>
        <ProfilePage />
      </MobLayout>
    </>
  ) : (
    <>
      <Layout>
        {doctorInfo != undefined ? (
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.doctorName}
                    style={{ fontFamily: "Greycliff CF" }}
                  >
                    Hi,&nbsp;Dr. {doctorInfo.firstName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="profile-container">
                <div className="content-one">
                  <Grid container>
                    <Grid md={6}>
                      <div className="content-image">
                        <img
                          className="profileimg"
                          src={doctorInfo.profile_pic}
                          alt=" "
                        />
                      </div>
                    </Grid>
                    <Grid md={6} style={{ width: "56%" }}>
                      <div className="detailcontent">
                        <div className="content">
                          <button
                            className="edite_btn"
                            style={{ float: "right" }}
                            onClick={MoveToProfileUpdate}
                          >
                            {" "}
                            Edit
                          </button>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "20rem",
                            }}
                          >
                            <div className="name">
                              Dr.{" "}
                              {doctorInfo &&
                              doctorInfo.full_name &&
                              doctorInfo.full_name
                                ? doctorInfo.full_name
                                : "NA"}
                            </div>
                          </div>
                          <div className="separater" style={{ width: "40%" }}>
                            <div className="value" style={{ marginBottom: 10 }}>
                              <h4>
                                {doctorInfo &&
                                doctorInfo.qualification &&
                                doctorInfo.qualification
                                  ? doctorInfo.qualification
                                  : "NA"}
                              </h4>
                            </div>
                          </div>
                          <div className="special">
                            {doctorInfo &&
                            doctorInfo.speciality &&
                            doctorInfo.speciality
                              ? doctorInfo.speciality
                              : "NA"}
                          </div>
                        </div>
                        <div className="contentone">
                          <div className="separater">
                            <div className="name">Location</div>
                            <div className="value">
                              {doctorInfo &&
                              doctorInfo.Location &&
                              doctorInfo.Location
                                ? doctorInfo.Location
                                : "NA"}
                            </div>
                          </div>
                          <div className="separater" style={{ width: "40%" }}>
                            <div className="name">Date of Birth</div>
                            <div className="value">
                              {doctorInfo &&
                              doctorInfo.dob &&
                              doctorInfo.dob != null
                                ? doctorInfo.dob.substr(0, 10)
                                : ""}
                            </div>
                          </div>
                          <div className="separater" style={{ width: "26%" }}>
                            <div className="name">Gender</div>
                            <div className="value">
                              {doctorInfo &&
                              doctorInfo.gender &&
                              doctorInfo.gender
                                ? doctorInfo.gender
                                : "NA"}
                            </div>
                          </div>
                        </div>
                        <div className="contenttwo">
                          <div
                            className="separater"
                            style={{ paddingRight: "11%" }}
                          >
                            <div className="name">Language Spoken</div>
                            <div className="value">
                              {doctorInfo &&
                              doctorInfo.languages &&
                              doctorInfo.languages
                                ? doctorInfo.languages
                                : "NA"}
                            </div>
                          </div>
                          <div className="separater">
                            <div className="name">Years of experience</div>
                            <div className="value">
                              {FullExperience ? FullExperience : 0} Years
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  {/* <div style={{paddingLeft:"15px",paddingRight:"15px"}}>
                                    <hr style={{border:'1px solid #046673 0.5'}}></hr>
                                    </div> */}

                  {/* <div className="content-image">
                                      <img className="profileimg" src={doctorInfo.profile_pic}
                                            alt=" " crossorigin="anonymous" />
                                    </div>
                                    <div  class="col-md-6" className="detailcontent">
                                        <div className="content">
                                            <button className='edite_btn' style={{ float: "right" }} onClick={MoveToProfileUpdate}> Edit</button>
                                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '20rem' }}>
                                                <div className="name">Dr. {doctorInfo && doctorInfo.full_name && doctorInfo.full_name ? doctorInfo.full_name : "NA"}</div>
                                            </div>
                                            <div className="separater" style={{ width: "40%" }}>
                                                <div className="value" style={{ marginBottom: 10 }}>
                                                    <h4>{doctorInfo && doctorInfo.qualification && doctorInfo.qualification ? doctorInfo.qualification : "NA"}</h4>
                                                </div>
                                            </div>
                                            <div className="special" >{doctorInfo && doctorInfo.speciality && doctorInfo.speciality ? doctorInfo.speciality : "NA"}</div>
                                        </div>
                                        <div className="contentone">
                                            <div className="separater">
                                                <div className="name">Location</div>
                                                <div className="value">{doctorInfo && doctorInfo.Location && doctorInfo.Location ? doctorInfo.Location : "NA"}</div>
                                            </div>
                                            <div className="separater" style={{ width: "40%" }}>
                                                <div className="name">Date of Birth</div>
                                                <div className="value">{doctorInfo && doctorInfo.dob && doctorInfo.dob != null ? doctorInfo.dob.substr(0, 10) : ""}</div>
                                            </div>
                                            <div className="separater" style={{ width: "26%" }}>
                                                <div className="name">Gender</div>
                                                <div className="value">{doctorInfo && doctorInfo.gender && doctorInfo.gender ? doctorInfo.gender : "NA"}</div>
                                            </div>
                                        </div>
                                        <div className="contenttwo">
                                            <div className="separater" style={{ paddingRight: "11%" }}>
                                                <div className="name">Language Spoken</div>
                                                <div className="value">{doctorInfo && doctorInfo.languages && doctorInfo.languages ? doctorInfo.languages : "NA"}</div>
                                            </div>
                                            <div className="separater">
                                                <div className="name">Years of experience</div>
                                                <div className="value">{FullExperience ? FullExperience : 0} Years</div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
                <div className="content-two">
                  <div className="detail-content">
                    <div className="detail">
                      <div className="title">Email:&nbsp;</div>
                      <div
                        className="value"
                        style={{ fontSize: setFontSize(doctorInfo.email) }}
                      >
                        {doctorInfo && doctorInfo.email && doctorInfo.email
                          ? doctorInfo.email
                          : "NA"}
                      </div>
                    </div>
                    <div className="detail">
                      <div className="title">Phone:&nbsp;</div>
                      <div
                        className="value"
                        style={{ fontSize: setFontSize(doctorInfo.mobile) }}
                      >
                        {doctorInfo.mobile}
                      </div>
                    </div>
                    <div
                      className="detail"
                      style={{
                        border: "none",
                        maxHeight: 110,
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        style={{ wordWrap: "break-word" }}
                        className={classes.addressword}
                      ></Typography>
                    </div>
                  </div>
                  <div className="price-content">
                    {/* <div className="price-detail">
                                            <div className="one">
                                                Certification {doctorInfo && doctorInfo.Certification && doctorInfo.Certification}
                                            </div>
                                            <div className="two">
                                                Folio No: <span style={{ fontStyle: "VisueltMedium" }}>{doctorInfo && doctorInfo.folioNumber && doctorInfo.folioNumber ? doctorInfo.folioNumber : "NA"}</span>

                                            </div>
                                        </div> */}
                    <div className="price-description">
                      If you wish to change any information on your profile,
                      please email Doctall Admin at{" "}
                      <a href="mailto:support@doctall.com">
                        support@doctall.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rightcontainer">
                <div className={"calenderContainerProfile"}>
                  {/* <Grid item xs={12}>
                                        <div className='erned2'>
                                            <div className='card_erned'>
                                                <h1 className='erned_txt'>₦ {allCount && allCount.earned && allCount.earned.toFixed(2)}</h1>
                                                <h2 className='erned_txt'>Earned</h2>
                                            </div>
                                        </div>
                                    </Grid> */}
                  <Grid md={12}>
                    <Card className={classes.dataCards}>
                      <Grid item container>
                        <Grid md={10}>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#101828",
                              fontWeight: "600",
                            }}
                          >
                            Earnings
                          </Typography>
                          <Typography className={classes.cardHeading}>
                            Current balance
                          </Typography>
                        </Grid>
                        <Grid md={2} align="right">
                          <img src={infoIcon} />
                        </Grid>
                        <Grid md={10}>
                          <Typography className={classes.cardSubHeading}>
                            ₦ {allCount && allCount.earned && allCount.earned}
                          </Typography>
                        </Grid>
                        <Grid md={2} align="right">
                          <Button className={classes.incriment}>3.4%</Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  {/* <Grid item xs={12}>
                                        <div className='erned1'>
                                            <div className='card_erned'>
                                                <h1 className='Outstanding'>₦ {allCount && allCount.outstanding && allCount.outstanding.toFixed(2)}</h1>
                                                <h2 className='Outstanding'>Outstanding</h2>
                                            </div>
                                        </div>
                                    </Grid> */}
                  <br></br>
                  <Grid md={12}>
                    <Card className={classes.dataCards}>
                      <Grid item container>
                        <Grid md={10}>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#101828",
                              fontWeight: "600",
                            }}
                          >
                            Outstanding Payments
                          </Typography>
                          <Typography className={classes.cardHeading}>
                            Current balance
                          </Typography>
                        </Grid>
                        <Grid md={2} align="right">
                          <img src={infoIcon} />
                        </Grid>
                        <Grid md={10}>
                          <Typography className={classes.cardSubHeading}>
                            ₦{" "}
                            {allCount &&
                              allCount.outstanding &&
                              allCount.outstanding}
                          </Typography>
                        </Grid>
                        <Grid md={2} align="right">
                          <Button className={classes.incriment}>3.4%</Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </div>

                <h1 className="Main_headding">Doctor</h1>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="dashbord_content_container1">
                      <div className="dashbord_content_head">
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <h2 className="dashbord_head_heading">Account</h2>
                          </Grid>
                          <Grid item xs={3}>
                            <button
                              className="edite_btn"
                              onClick={() => setEditBankModel(true)}
                            >
                              Edit
                            </button>
                          </Grid>
                        </Grid>
                      </div>

                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <div className="acount_box">
                            <h2 className="dashbord_content_heading">
                              Bank Name
                            </h2>
                            <p className="dashbord_content_sub_heading">
                              {bankState &&
                              bankState.bank_detail &&
                              bankState.bank_detail.bank_name
                                ? bankState.bank_detail.bank_name
                                : "N/A"}
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="acount_box">
                            <h2 className="dashbord_content_heading">Branch</h2>
                            <p className="dashbord_content_sub_heading">
                              {bankState &&
                              bankState.bank_detail &&
                              bankState.bank_detail.branch
                                ? bankState.bank_detail.branch
                                : "N/A"}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <div className="acount_box">
                            <h2 className="dashbord_content_heading">
                              Account No.
                            </h2>
                            <p className="dashbord_content_sub_heading">
                              {bankState &&
                              bankState.bank_detail &&
                              bankState.bank_detail.acc_number
                                ? bankState.bank_detail.acc_number
                                : "N/A"}
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="acount_box">
                            <h2 className="dashbord_content_heading">
                              Account Name
                            </h2>
                            <p className="dashbord_content_sub_heading">
                              {bankState &&
                              bankState.bank_detail &&
                              bankState.bank_detail.acc_name
                                ? bankState.bank_detail.acc_name
                                : "N/A"}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="dashbord_content_container1">
                  <div className="dashbord_content_head">
                    <Grid container spacing={2}>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={3}>
                        <h2 className="dashbord_content_sub_heading2">
                          This Week
                        </h2>
                      </Grid>
                      <Grid item xs={3}>
                        <h2 className="dashbord_content_sub_heading2">
                          This Month
                        </h2>
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <div className="acount_box">
                        <h2 className="dashbord_content_sub_heading2">
                          Lab Test Advice
                        </h2>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalLabTestCurrentWeek}
                      </h2>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalLabTestThisMonth}
                      </h2>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="acount_box">
                        <h2 className="dashbord_content_sub_heading2">
                          Medicine Prescription
                        </h2>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalMedicieneCurrentWeek}
                      </h2>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalMedicieneThisMonth}
                      </h2>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="acount_box">
                        <h2 className="dashbord_content_sub_heading2">
                          Medicine Ordered
                        </h2>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalMedicieneOrderCurrentWeek}
                      </h2>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalMedicieneOrderThisMonth}
                      </h2>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="acount_box">
                        <h2 className="dashbord_content_sub_heading2">
                          DMP Order
                        </h2>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalLabTestOrderCurrentWeek}
                      </h2>
                    </Grid>
                    <Grid item xs={3}>
                      <h2 className="dashbord_box_cantent">
                        {allCount && allCount.totalLabTestOrderthisMonth}
                      </h2>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* <Grid item xs={6}>
                                <div className='dashbord_content_container1' >
                                    <div className='dashbord_content_head'>
                                        <Grid container spacing={2} >
                                            <Grid item xs={6}>
                                                <h2 className='dashbord_content_sub_heading2' style={{ textAlign: "center" }}>Income This Week</h2>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h2 className='dashbord_content_sub_heading2' style={{ textAlign: "center" }}>Income This Month</h2>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <h1 className='erned_txt' style={{ paddingTop: "55px", paddingBottom: "55px", fontSize: "40px" }}>₦ {allCount && allCount.incomeThisWeek && allCount.incomeThisWeek.toFixed(2)}</h1>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <h1 className='Outstanding' style={{ paddingTop: "55px", paddingBottom: "55px", fontSize: "40px" }}>₦ {allCount && allCount.incomeThisMonth && allCount.incomeThisMonth.toFixed(2)}</h1>
                                        </Grid>
                                    </Grid>
                                </div>

                            </Grid> */}
              <Grid item xs={3}>
                <div className="dashbord_content_container2">
                  <div className="dashbord_content_head1">
                    <h2
                      className="dashbord_content_sub_heading2"
                      style={{ textAlign: "center", color: " #FF6E4E" }}
                    >
                      Income This Week
                    </h2>
                  </div>
                  <h1
                    className="erned_txt"
                    style={{
                      paddingTop: "60px",
                      paddingBottom: "65px",
                      fontSize: "40px",
                    }}
                  >
                    ₦ {allCount && allCount.incomeThisWeek}
                  </h1>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="dashbord_content_container3">
                  <div className="dashbord_content_head2">
                    <h2
                      className="dashbord_content_sub_heading2"
                      style={{ textAlign: "center", color: " #37CC8C" }}
                    >
                      Income This Month
                    </h2>
                  </div>
                  <h1
                    className="Outstanding"
                    style={{
                      paddingTop: "60px",
                      paddingBottom: "65px",
                      fontSize: "40px",
                      color: "#37CC8C",
                    }}
                  >
                    ₦ {allCount && allCount.incomeThisMonth}
                  </h1>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <p>Loading.....</p>
        )}

        <Dialog
          disableBackdropClick
          open={editBankModel}
          onClose={() => setEditBankModel(false)}
          maxWidth="md"
        >
          <DialogContent>
            <UpdateBankDetails close={(event) => setEditBankModel(false)} />
          </DialogContent>
        </Dialog>
      </Layout>
    </>
  );
}
