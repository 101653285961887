import React, { useEffect, useState } from "react";
import style from "./doctorecaseNotes.scss";
import search from "./icons/search.svg";
import opencaratol from "./icons/opencaratol.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createCaseNote,
  getCaseNote,
  SearchICD,
  createCaseNoteRequest,
} from "../../redux/actions/caseNoteAction";
import CreatecaseSuccess from "./CreatecaseSuccess";
import DGUpArrow from "../../assets/images/DGUpArrow.svg";
import { useHistory } from "react-router-dom";
import useOnClickOutside from "../doctorDashboard/OutClickhandler";
import axios from 'axios';

function DoctoreCaseNotes(props) {
  
  let history = useHistory();
  const ref = React.useRef();
  let apidata = React.useRef();
  const tokeen = useSelector(state=>state.loginReducer.payload.token)
  const routedata = history.location.state;
  const [show, setshow] = useState(false);

  const [disease, setDisease] = useState("");
  const [chiefComplaint, setchiefComplaint] = useState("");
  const [patientcomplaint, setpatientcomplaint] = useState("");
  const [diagnosis, setdiagnosis] = useState("");
  const [plan, setplan] = useState("");
  const [others, setothers] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [suggestion, setSuggestion] = useState(false);
  const [viewonly, setViewonly] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errmsg, seterrmsg] = useState("");

  const dispatch = useDispatch();

  const searcharray = useSelector(
    (state) => state.createCaseNotereducer.search
  );
  const searchloading = useSelector(
    (state) => state.createCaseNotereducer.loading
  );
  const createCaseForpopup = useSelector(
    (state) => state.createCaseNotereducer
  );
  const stateData =
    createCaseForpopup != undefined &&
    createCaseForpopup.search != undefined &&
    createCaseForpopup.search.data != undefined
      ? createCaseForpopup.search.data
      : [];

  const dummy = [
    {
      desc: "LOADING..... ",
    },
  ];
  let slicedArray = stateData !== undefined ? stateData.slice(0, 30) : dummy;
  useEffect(() => {
    if (props.data) {
      let {
        disease_name,
        others,
        chief_complaint,
        diagnosis,
        plan_action,
        patient_complaint,
      } = props.data;
      setDisease(disease_name);
      setchiefComplaint(chief_complaint);
      setpatientcomplaint(patient_complaint);
      setdiagnosis(diagnosis);
      setplan(plan_action);
      setothers(others);
      // setViewonly(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (createCaseForpopup && createCaseForpopup.isLoading == false) {
      props.setLoader(false);
    }
    if (createCaseForpopup.isError) {
      props.setLoader(false);
    }
    apidata = createCaseForpopup.search;
  }, [createCaseForpopup]);
  const onsubmitCase = () => {
    if (disease === "") {
      
      seterrmsg("please fill the disease name");
    } else {
      setshow(true);

      const data = {
        disease_name: disease,
        patient_uuid: routedata.patient_uuid,
        doctor_uuid: routedata.doctor_uuid,
        consumer_uuid: routedata.patient.created_by.uuid,
        booking_uuid: routedata.uuid,
        chief_complaint: chiefComplaint,
        patient_complaint: patientcomplaint,
        diagnosis: diagnosis,
        plan_action: plan,
        others: others,
      }

      axios.post(process.env.REACT_APP_API_URL + "/doctor/observation/createNotes" , data, {
        headers: {
            'x-auth-token': tokeen,
        }
    }).then((resp) => {
        if(resp.status == "201")
        setTimeout(function(){
          props.handleSuccessPopup()
         
         }, 0);

        props.handleCloseCasePopup()



       
    })
        .catch(err => {
            console.log("errerr",err)

        })
      // dispatch(
      //   createCaseNoteRequest({
      //     disease_name: disease,
      //     patient_uuid: routedata.patient_uuid,
      //     doctor_uuid: routedata.doctor_uuid,
      //     consumer_uuid: routedata.patient.created_by.uuid,
      //     booking_uuid: routedata.uuid,
      //     chief_complaint: chiefComplaint,
      //     patient_complaint: patientcomplaint,
      //     diagnosis: diagnosis,
      //     plan_action: plan,
      //     others: others,
      //   })
      // );

      setDisease("");
      setchiefComplaint("");
      setpatientcomplaint("");
      setdiagnosis("");
      setplan("");
      setothers("");
      dispatch(getCaseNote(routedata.uuid));
      // props.handleCloseCasePopup()
    }
    if (createCaseForpopup && createCaseForpopup.isLoading == false) {
      props.setLoader(false);
    }
    if (createCaseForpopup.isError) {
      props.setLoader(false);
    }
  };

  const Search = () => {
    if (disease !== "" && !dropdown) {
      dispatch(SearchICD(disease));
    }
    setDropdown(!dropdown);
  };
  useOnClickOutside(ref, () => {
    setDropdown(false);
  });
  const handleChangeRecord = (e) => {
    setDisease(e.target.value);
    dispatch(SearchICD(disease));
    setSuggestion(true);
  };
  const handleChanged = (e) => {
    let changes = e.target.id;
    setDisease(changes);
    const displayChanges = document.querySelector("#arrayData");
    setSuggestion(false);
  };

  return (
    <form>
      <>
      {/* {show === true ?
      <CreatecaseSuccess 
      setLoader={props.setLoader} 
      value="CaseNote" />:null} */}
        {/* {createCaseForpopup &&
        createCaseForpopup.payload != undefined &&
        createCaseForpopup.payload.statusText != undefined &&
        createCaseForpopup.payload.statusText == "Created" &&
        show ? (
          <CreatecaseSuccess setLoader={props.setLoader} value="CaseNote" />
        ) : null} */}
        <div className="doctoreCaseNotes" id="doctoreCaseNotes">
            <div className="docheading" style={{ margin: "30px 50px" }}>
              Doctor Case Notes
            </div>
            <div className="divider"></div>

            <div className="docContainer" style={{ margin: "auto" }}>
              <div style={{ marginBottom: "5px", marginTop: "30px" }}>
                <label className="doclabel">Select Disease</label>
              </div>
              <div ref={ref}>
                <div
                  className="inputContainer"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={search}
                    alt="search"
                    style={{ margin: "0 20px" }}
                    crossorigin="anonymous"
                  />
                  <input
                    type="text"
                    disabled={viewonly}
                    className="docinput"
                    value={disease}
                    name="disease"
                    onChange={handleChangeRecord}
                    placeholder="Search disease by name or ICD 10 code"
                  />
                  <img
                    src={opencaratol}
                    onClick={() => {
                      !viewonly && Search();
                    }}
                    alt="opencaratol"
                    crossorigin="anonymous"
                  />
                </div>

                {slicedArray.length > 0 && suggestion === true ? (
                  <div
                    id="arrayData"
                    className="casedropdown"
                    style={{ display: disease === "" ? "none" : "block" }}
                  >
                    {slicedArray.map((i) => (
                      <p className="value" onClick={handleChanged} id={i.desc}>
                        {i.desc}
                      </p>
                    ))}
                  </div>
                ) : (
                  <>
                    {suggestion === true ? (
                      <div id="arrayData" className="casedropdown">
                        <p className="value"> LOADING..... </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {dropdown && (
                  <div className="casedropdown">
                    {searcharray && searcharray.data ? (
                      searcharray.data.map((e, i) => {
                        return (
                          <div
                            className="value"
                            key={i}
                            onClick={() => {
                              setDisease(e.desc);
                              setDropdown(false);
                            }}
                          >
                            {e.desc}
                          </div>
                        );
                      })
                    ) : searchloading ? (
                      <div className="nodata">Loading ...</div>
                    ) : (
                      <div className="nodata">No data found</div>
                    )}
                  </div>
                )}
                {/* <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}}> 
                      <Typography className={classes.tableData_medicine_font} noWrap>{val.genericname}</Typography>
                    </div> */}
              </div>
              <span style={{ color: "red" }}>{errmsg}</span>
              <div style={{ marginBottom: "5px" }}>
                <label className="doclabel">Chief Complaint</label>
              </div>

              <input
                type="text"
                disabled={viewonly}
                className="docinputField"
                style={{ height: "100px" }}
                value={chiefComplaint}
                name="chiefComplaint"
                onChange={(e) => setchiefComplaint(e.target.value)}
              />

              <div style={{ marginBottom: "5px" }}>
                <label className="doclabel">
                  History of patient complaint:
                </label>
              </div>
              <input
                type="text"
                disabled={viewonly}
                className="docinputField"
                value={patientcomplaint}
                name="patientcomplaint"
                onChange={(e) => setpatientcomplaint(e.target.value)}
              />

              <div style={{ marginBottom: "5px" }}>
                <label className="doclabel">Diagnosis:</label>
              </div>
              <input
                type="text"
                disabled={viewonly}
                className="docinputField"
                value={diagnosis}
                name="patientcomplaint"
                onChange={(e) => setdiagnosis(e.target.value)}
              />

              <div style={{ marginBottom: "5px" }}>
                <label className="doclabel">Plan/Action:</label>
              </div>
              <input
                type="text"
                disabled={viewonly}
                className="docinputField"
                value={plan}
                name="patientcomplaint"
                onChange={(e) => setplan(e.target.value)}
              />

              <div style={{ marginBottom: "5px" }}>
                <label className="doclabel">Other information:</label>
              </div>
              <input
                type="text"
                disabled={viewonly}
                className="docinputField"
                value={others}
                name="patientcomplaint"
                onChange={(e) => setothers(e.target.value)}
              />
              {/* {!viewonly ? ( */}
                <div className="docButton">
                  <div className="buttontxt" onClick={() => onsubmitCase()}>
                    Create Case Notes
                  </div>
                </div>
              {/* ) : ( */}
                {/* <div style={{ marginTop: 50 }}></div> */}
              {/* )} */}
            </div>
          </div>
      </>
    </form>
  );
}

export default DoctoreCaseNotes;
