export const getAppointmentSnapshot = (appointmentType) =>{
    return{
        type:"GET_APPOINTMENT_SNAPSHOT",
        appointmentType
    }
}
export const getAppointmentSnapshotRequest = (appointmentType) =>{
    return{
        type:"GET_APPOINTMENT_SNAPSHOT_REQUEST",
        appointmentType
    }
}
export const getDoctorStatsOne = () =>{
    return{
        type:"GET_DOCTOR_STATS",
    }
}

export const getDoctorStatsTwo = () =>{
    return{
        type:"GET_DOCTOR_STATS_TWO",
    }
}

export const totalPrescription = () =>{
    return{
        type:"GET_DOCTOR_STATS_THREE",
    }
}

export const totalPrescriptionLab = () =>{
    return{
        type:"GET_DOCTOR_STATS_FOUR",
    }
}
export const createSlot= (category,createSlotValues,slotDateFormat,dayName)=>{
    return{
        type:"CREATE_SLOT",
        category,createSlotValues,slotDateFormat,dayName
    }
}