
import React, {useState, useEffect} from 'react';
import backgroundImg from '../../images/BackgroundImage.png';
import { makeStyles, Grid, TextField, Button } from '@material-ui/core'

// components
import Logo from '../../component/Logo/Logo'
import { useStyle } from '../../style/style'
import triangle from '../../images/icons/triangle.png'
import Heading from '../../component/Heading/Heading';
import LabelText from '../../component/LabelText/LabelText';
import Instruction from '../../component/Instruction/Instruction';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import { useHistory } from 'react-router';
import MobLayout from "../../../Dashboard/mobLayout";
import PassResetMob from '../../../doctor-dashboard/mobileComp/PassResetMob';
import { useTheme, useMediaQuery } from "@material-ui/core";


const useStyle2 = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh',
        width: '100vw',
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    gridContainer: {
        height: '100%',
        width: '100%',
    },
    rightGrid: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid #00839B',
        borderRadius: '50px 0px 0px 50px',
        margin: '0 0 0 auto',
        background: '#FFFFFF',
        opacity: '0.95',
        [theme.breakpoints.down("sm")]: {
            borderRadius: '3px',
        },
    },
    container: {
        width: '70%',
        height: 'auto',
        marginTop: '20%'

    }
}))

const initialValues = {
    password: ''
}

const validationSchema = Yup.object().shape({

    password: Yup.string().matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    confirmpwd: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
})





function Screen4() {
    const customSM = useMediaQuery("(max-width:800px)");
    const classes2 = useStyle2();
    const classes = useStyle()
    const history = useHistory()

    const { email } = history.location.state;


    const handleSubmit = (value) => {

        const { password } = value;

        var data = JSON.stringify({ "password": password, "email": email });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/user/auth/change-password',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
             
                history.push({pathname:"/sign_in",state:{email,password}})
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const [heightS, setHeightS] = useState('')

    const checkSafari = () => {
      return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    }
    useEffect(() => {
      if (!checkSafari()){
          setHeightS("70px")
         }
         else {
          setHeightS("170px")
         }
    }, []);
    return customSM ? (
        <>
          <MobLayout>
          <div /* style={{marginTop:heightS}} */>
            <PassResetMob />
            </div>
          </MobLayout>
        </>
      ) : (
        <div className={classes.main}>
            <Grid container className={classes.gridContainer}>
                <Grid item md={6} xs={12} className={classes.rightGrid}>
                    <div className={classes2.container}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {
                                ({ errors, touched }) => (
                                    <Form >
                                        <Logo />
                                        <Heading heading="Forgot password" />
                                        <LabelText label="New Password" marginBottom="3%" />
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            name="password"
                                            type="password"
                                            variant="outlined"
                                            className={classes.primaryInput}
                                            placeholder="xxxxxxxxxxxxxx"
                                            error={touched.password && errors.password}
                                            helperText={touched.password && errors.password}
                                        />
                                        <div style={{ width: 'auto', height: '30px' }}></div>
                                        <LabelText label="Confirm New Password" marginBottom="3%" />
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            name="confirmpwd"
                                            variant="outlined"
                                            type="password"
                                            className={classes.primaryInput}
                                            placeholder="xxxxxxxxxxxxxx"
                                            error={touched.confirmpwd && errors.confirmpwd}
                                            helperText={touched.confirmpwd && errors.confirmpwd}
                                        />

                                        <div style={{ width: 'auto', height: '50px' }}></div>
                                        <Button className={classes.stepperButton} type="submit" style={{ textTransForm: 'capitalize' }} >Confirm Code <img src={triangle} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                                    </Form>
                                )
                            }
                        </Formik>

                        <Instruction />
                        <div style={{ width: 'auto', height: '50px' }}></div>

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Screen4


