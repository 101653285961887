export const getPatients = (pageNumber, searchQuery, pageLimit) => {
    return {
        type: "GET_PATIENTS",
        pageNumber,
        searchQuery,
        pageLimit
    }
}

export const getVisits = (pageNumber, searchQuery) => {
    return {
        type: "GET_VISITS",
        pageNumber,
        searchQuery
    }
}

export const getOrders = (pageNumber, searchQuery,pageLimit) => {
    return {
        type: "GET_ORDERS",
        pageNumber,
        searchQuery,
        pageLimit
    }
}

export const getSaasDoctorProfile = () => {
    return {
        type: "GET_SAAS_DOCTOR_PROFILE",
    }
}

export const getVisitsByPatientId = (id) => {
    return {
        type: "GET_VISITS_BY_PATIENT",
        id
    }
}


export const getBankDetails = () => {
    return {
        type: "GET_BANKS"
    }
}

export const getStatistics = () => {
    return {
        type: "GET_STATISTICS"
    }
}