import React from 'react'
import { Button, Grid, Select, TextField, makeStyles } from '@material-ui/core';
import { useStyle } from '../../style/style';
import MuiPhoneNumber from 'material-ui-phone-number';
import triangleLogo from '../../images/icons/triangle.svg'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axios from 'axios';

const useStyle2 = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '40px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
    }
}))

export const ContactInfo = ({ handleStepperNext, setContactInfo, contactInfo }) => {
    const classes = useStyle();
    const classes2 = useStyle2();
    const [mobile, setMobile] = React.useState('')
    const [country, setCountry] = React.useState("Nigeria");
    const [mobileError, SetMobileError] = React.useState(false);
    const [mobieleErrormsg, setMobileErrormsg] = React.useState('');
    const [mobExist, setmobExist] = React.useState('');

    const handleCountry = (event) => {
        setCountry(event.target.value)
    }

    function handleOnChange(value) {
        var res = value.replace(/\D/g, "");
        setMobile(res);
        SetMobileError(false)
        axios.get(`${process.env.REACT_APP_API_URL}/user/auth/mobileNumExist/${res}`)
            .then(res => setmobExist(res.data.status))
            .catch(err => console.log(err))
    }

    // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const contactInfo_validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email')
            .test('Unique Email', 'Email already in use',
                function (value) {
                    return new Promise((resolve, reject) => {
                        axios.get(process.env.REACT_APP_API_URL + `/user/auth/emailIdExist/${value}`)
                            .then(res => {
                                if (res.data.status === 'failure') {
                                    resolve(false)
                                }
                                resolve(true)
                            })
                    })
                }),
        // mobile: Yup.string().test('Unique Email', 'Email already in use',
        //     function (value) {
        //         return new Promise((resolve, reject) => {
        //             axios.get(process.env.REACT_APP_API_URL + `/user/auth/mobileNumExist/${value}`)
        //                 .then(res => {
        //                     if (res.data.status === 'failure') {
        //                         resolve(false)
        //                     }
        //                     resolve(true)
        //                 })
        //         })
        //     }),
        // address: Yup.string().required('Required'),
        // city: Yup.string().required('Required'),
        // state: Yup.string().required('Required'),
        // postcode: Yup.number().max(6)
    })



    return (
        <div style={{ width: '100%' }} className={classes2.root}>
            <Formik
                initialValues={contactInfo}
                onSubmit={(value) => {
                    const { email, address, city, state, postcode } = value;

                    const contactInfo = {
                        email, mobile, address, city, state, postcode, country
                    }
                    setContactInfo(contactInfo)
                    if (mobile.length == 0) {
                        SetMobileError(true)
                        setMobileErrormsg('Required!')
                    } else if (mobile.length <= 10) {
                        SetMobileError(true)
                        setMobileErrormsg('Invalid mobile number!')
                    } else if (mobExist === 'failure') {
                        SetMobileError(true)
                        setMobileErrormsg('Mobile number already exist!')
                    }
                    else {
                        handleStepperNext()
                    }
                }}
                validationSchema={contactInfo_validationSchema}
            >
                {
                    ({ errors, touched }) => (
                        <Form style={{ width: '70%', margin: 'auto' }} autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Email address*</div>
                                    <Field
                                        fullWidth
                                        name="email"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        required={true}
                                        as={TextField}
                                        error={touched.email && errors.email}
                                        helperText={touched.email && errors.email}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Mobile number*</div>
                                    <Field
                                        as={MuiPhoneNumber}
                                        name="mobile"
                                        defaultCountry={'ng'}
                                        required={true}
                                        onChange={handleOnChange}
                                        fullWidth variant="outlined"
                                        className={classes.primaryInput2}
                                        error={mobileError}
                                        helperText={mobileError ? mobieleErrormsg : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Address</div>
                                    <Field
                                        fullWidth
                                        name="address"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}
                                        placeholder="House name/number"
                                        error={touched.address && errors.address}
                                        helperText={touched.address && errors.address}

                                    />

                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.primaryLabel}>City*</div>
                                    <Field
                                        required
                                        fullWidth
                                        name="city"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}
                                        placeholder="City"
                                        error={touched.city && errors.city}
                                        helperText={touched.city && errors.city}

                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.primaryLabel}>State*</div>
                                    <Field
                                        required
                                        fullWidth
                                        name="state"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}
                                        placeholder="State"
                                        error={touched.state && errors.state}
                                        helperText={touched.state && errors.state}

                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field fullWidth
                                        name="postcode"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}
                                        type="number"
                                        placeholder="Postal code"
                                        error={touched.postcode && errors.postcode}
                                        helperText={touched.postcode && errors.postcode}

                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        name="country"
                                        placeholder={'Country'}
                                        value={country} onChange={handleCountry} variant="outlined" fullWidth className={classes.primaryInput2}>
                                        {/* <option value="None" className={classes.dropDown}>Country</option> */}
                                        <option value="Nigeria" className={classes.dropDown}>Nigeria</option>
                                        <option value="United Kingdom" className={classes.dropDown}>United Kingdom</option>
                                        <option value="United States" className={classes.dropDown}>United States</option>
                                        <option value="India" className={classes.dropDown}>India</option>
                                        <option value="Other" className={classes.dropDown}>Other</option>
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button className={classes.stepperButton} type="submit">Next <img src={triangleLogo} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

ContactInfo.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    handleStepperNext: PropTypes.func.isRequired
};

