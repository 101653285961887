import React, { useEffect, useState, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { getOrders } from '../../redux/actions/ex-appointment-action'

import '../dashboardstyles/dashboardstyle.css'
import { orderColumns } from '../components/TableColumn';
import { customStyles } from '../dashboardstyles/table-style/tableCustomStyle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from "axios";

import Close from "../../assets/images/close.svg";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Dialog, Button, DialogContentText, DialogContent, DialogTitle } from '@material-ui/core';


export default function OrdersTable() {

    let history = useHistory()
    const dispatch = useDispatch();
    const orderState = useSelector((state) => state.PatientSnapshot);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const [orderModel, setOrderModel] = useState(false);
    const [orderDetail, setOrderDetails] = useState();
    const [closepdf, setClosePdf] = useState(false);
    const [openpdf, setOpenPdf] = useState(false);
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState([])
    const [url1, setUrl1] = useState()
    const [orderId, setOrderId] = useState()
    const [addPatientModel, setAddPatientModel] = useState(false);


    const authState = useSelector((state) => state.loginReducer.payload);

    useEffect(() => {
        dispatch(getOrders(pageNumber, searchQuery))
    }, [pageNumber, ''])

    const mergedColumns = orderColumns.map(col => {
        if (!col.editable) {
            return col;
        }
    });

    const createColumns = useCallback(() => {
        return [
            ...mergedColumns,
            {
                name: 'Action',
                allowOverflow: true,
                minWidth: '100px',
                maxHeight: '100px',
                cell: row => {
                    console.log(row.order[0], "45st")
                    setOrderId(row && row.order[0] && row.order[0].uuid)
                    if (row && row.order[0] && row.order[0].status == "completed") {
                        return <RemoveRedEyeIcon style={{ cursor: 'pointer', color: '#046673' }} onClick={() => { handleOrder(row) }}>
                            View</RemoveRedEyeIcon>;
                    }

                },

            },
        ];
    }, [mergedColumns]);
   
    const handleOrder = (item) => {
       
        const OrderId1 = item && item.order && item.order[0] && item.order[0].uuid
        let endPoint = `/order/ordertracking/byOrderId?orderId=${OrderId1}`;

        axios.get(process.env.REACT_APP_API_URL + `${endPoint}`, {
            headers: {
                "x-auth-token": authState.token
            }
        })
            .then((res) => {
                    if (res.data) {
                            setAddPatientModel(true)
                                        setUrl(res && res.data[0] && res.data[0].fullOrderDetails);

                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleOrder1 = (item) => {
        console.log(item.final_action.result[0].url)
        const OrderId1 = item && item.final_action && item.final_action.result[0] && item.final_action && item.final_action.result[0].url

    
        window.open(OrderId1, '_blank');
        setUrl1(OrderId1)
    }

    console.log(orderColumns.map((col) => { }), "5555")
    const handleOrderView = (details) => {
        setOrderDetails(details);
        setOrderModel(true)
    }


    const paginationComponentOptions = {
        rangeSeparatorText: 'of',
        noRowsPerPage: true,
    };


    const handlePageChange = page => {
        setPageNumber(page)
    };

    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value)
        handleSearch()
    }

    const handleSearch = () => {
        dispatch(getOrders(0, searchQuery))
    }
    const MoveToOrder = () => {
        history.push({
            pathname: '/orders',
        });
    }

    return (
        <>
         <div className="snapcontainer2" >
                    <div className='table_header'>
                       <Grid container spacing={2}>
                        {/* <h1 className='table_heading'>Orders</h1> */}
                            <Grid item xs={10}>
                                <h2 className='dashbord_head_heading'>Recent Orders</h2>
                            </Grid>
                            <Grid item xs={2} className='See_all' style={{marginTop:"1%"}} onClick={MoveToOrder}>See all</Grid>
                        </Grid>
                    </div>
                    {orderState && orderState.orderList && orderState.orderList.total > 10 ?
                        <DataTable 
                            columns={createColumns()}
                            data={orderState && orderState.orderList.data && orderState.orderList.data}
                            customStyles={customStyles}
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationTotalRows={orderState && orderState.orderList && orderState.orderList.total}
                            onChangePage={handlePageChange}
                        />
                        :
                        <DataTable
                            columns={createColumns()}
                            data={orderState && orderState.orderList.data && orderState.orderList.data}
                            customStyles={customStyles}
                        />
                    }

                </div>   

                <Dialog
                    disableBackdropClick
                    open={addPatientModel}
                    onClose={() => setAddPatientModel(false)}
                    maxWidth={'l'}
                fullWidth={false}
                // style={{width:"20px"}}
                >
                    <img
                        src={Close}
                        alt="Close"
                        className="Closepopup"
                        onClick={() => setAddPatientModel(false)}
                        crossorigin="anonymous"
                        style={{width:"40px", height:"35px", marginLeft:"auto",cursor:"pointer"}}

                    />
                    {/* <DialogContent> */}
                    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{fontFamily:"Greycliff CF",fontSize:"16px",fontWeight:"bold",padding:"15px 30px"}}>Test Name</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {url && url.map((row) => (
              
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{padding:"30px 30px"}}>
                
                {row.details.standardisedtestname}
              </TableCell>
              <TableCell align="right" style={{padding:"10px 30px"}}>
                    <button className='edite_btn1'  onClick={() => handleOrder1(row)} >View Report</button>
                  {/* <RemoveRedEyeIcon style={{ cursor: 'pointer', color: '#046673' }} onClick={() => { handleOrder1(row) }}>
                            View</RemoveRedEyeIcon> */}
                            {/* </TableCell>  */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

                    {/* </DialogContent> */}
     </Dialog>
        </>
    )
}
