export const createCaseNote = (data) => {
    return {
        type: "POST_CASENOTE_ACTION",
        data
    }
}
export const createCaseNoteRequest = (data) => {
    return {
        type: "POST_CASENOTE_ACTION_REQUEST",
        data
    }
}
export const createRiskNote = (data) => {
    return {
        type: "POST_Risk_NOTE_ACTION",
        data
    }
}

export const createRiskNoteRequest = (data) => {
    return {
        type: "POST_RISK_NOTE_REQUEST_ACTION",
        data
    }
}

export const createPhysacNote = (data) => {
    return {
        type: "POST_CASE_PHYSNOTE_ACTION",
        data
    }
}
export const createPsyRequest = (data) => {
    return {
        type: "POST_CASE_PHYSNOTE_ACTION_REQUEST",
        data
    }
}



export const getCaseNote = (Bookingid) => {
    return {
        type: "GET_CASE_NOTE_ACTION",
        Bookingid
    }
}

export const getphyNote = (Bookingid) => {
    return {
        type: "GET_PHY_NOTE_ACTION",
        Bookingid
    }
}


export const getHistro = (Bookingid,patient,clinic_uuid ) => {
    return {
        type: "GET_HISTRO_ACTION",
        Bookingid,patient,clinic_uuid 
    }
}


export const getRiskarea = () => {
    return {
        type: "GET_RISK_AREA_ACTION"
    }
}

export const getRisknote = (Bookingid) => {
    return {
        type: "GET_RISK_NOTE_ACTION",
        Bookingid
    }
}



export const SearchICD = (search) => {
    return {
        type: "SEARCH_ICD",
        search
    }
}