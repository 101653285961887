import React, { useState, useEffect, useRef } from "react";
import Layout from "../newlayout";
import moment from "moment";
import { usePubNub } from "pubnub-react";
import { useSelector } from "react-redux";
import * as _ from 'lodash'
import "./Chat.css";
import { getChannelMembers, addChannelMetadata, addUserMetadata, fetchMessages } from './services/pubnub-services'
import LoaderComponent from "../../components/Loading/LoaderComponent";
import { Input, Space } from 'antd';
import Button from '@material-ui/core/Button';


const { Search } = Input;

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

export default function ChatPage() {
    const [theme] = useState("light");
    const pubnub = usePubNub(); //usePubNub is only used here to get current user info

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    const currentUser = useSelector((state) => state.loginReducer.payload);
    const myChannel = `support.${currentUser._id}`

    console.log(currentUser)

    // In next two statements we define the state needed for our chat
    const [messageInput, setMessageInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();


    const processPubnub = async (pubnub) => {
        setLoading(true)
        // Set the UUID of our user to their chosen emoji
        const myUUID = `user_${currentUser._id}`
        pubnub.setUUID(myUUID);

        try {
            // setting my uuid and metadata
            await addUserMetadata(pubnub, currentUser.full_name, currentUser.email, myUUID)
            // updating my data to the channel. TODO: we can check and update only if needed
            await addChannelMetadata(pubnub, currentUser.full_name, "Direct chat with " + currentUser.full_name, myChannel)

            const channelMembersResponse = await getChannelMembers(pubnub, myChannel, "")

            var users = _.map(channelMembersResponse.data, item => item.uuid)
            setUsers(users);

            var ids = _.map(channelMembersResponse.data, item => item.uuid.id)
            ids.push(myUUID)
            pubnub.objects.setChannelMembers({
                channel: myChannel,
                uuids: _.uniq(ids)
            })

            // fetching old messages from channel
            const envelopes = await fetchMessages(pubnub, myChannel);



            if (envelopes) {
                var msgs = envelopes.map(envelope => {
                    var user = _.first(_.filter(users, user => user.id === envelope.uuid))
                    return {
                        id: envelope.message.id,
                        author: user ? user.name : 'Unknown',
                        content: envelope.message.text,
                        timetoken: envelope.timetoken,
                        uuid: envelope.uuid
                    }
                })
                setMessages(msgs)
                setLoading(false)
            }
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }


    // First we need to set our PubNub UUID and subscribe to chat channel.
    // We will use `useEffect` hook for that.
    useEffect(() => {
        // We need to make sure that PubNub is defined
        executeScroll()
        try {
            if (pubnub) {
                processPubnub(pubnub)
                // Create a listener that will push new messages to our `messages` variable
                // using the `setMessages` function.
                const listener = {
                    message: envelope => {
                        var user = _.first(_.filter(users, user => user.id === envelope.publisher))
                        console.log('listener callback', envelope);
                        console.log(user)
                        setMessages(msgs => [
                            ...msgs,
                            {
                                id: envelope.message.id,
                                author: user ? user.name : 'Unknown',
                                content: envelope.message.text,
                                timetoken: envelope.timetoken,
                            }
                        ]);
                    }
                };
                // Add the listener to pubnub instance and subscribe to my channel.
                pubnub.addListener(listener);
                pubnub.subscribe({ channels: [myChannel] });
                // We need to return a function that will handle unsubscription on unmount
                return () => {
                    pubnub.removeListener(listener);
                    pubnub.unsubscribeAll();
                };
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }

    }, [pubnub, messages]);


    function handleKeyPress(target) {
        if (target.charCode == 13) {
            handleSubmit()
        }
    }

    // This function handles sending messages.
    const handleSubmit = () => {
        // console.log(messageInput)
        // Clear the input field.
        if (messageInput) {
            const message = {
                text: messageInput,
                type: 'text'
            };
            // Publish our message to the channel `chat`
            pubnub.publish({ channel: myChannel, message });
            setMessageInput("");
            executeScroll()
        }
    };


    const getMessageTime = (messageTime) => {
        return moment(new Date(messageTime)).format('MMM D, YYYY, HH:mmA')
    }


    return (
        <Layout>
            <div style={{ width: '90%' }}>
                <div className={`app-simple ${theme}`}>

                    <div className='chat' >
                        <div style={{ overflowY: 'scroll', marginBottom: '5%' }} >
                            {loading && <LoaderComponent />}
                            {messages &&
                                messages.map((data) => (
                                    <div style={data.author === currentUser.full_name ? { display: 'flex', justifyContent: 'start' } : { display: 'flex', justifyContent: 'flex-end' }} >
                                        <div className="gutter-row" style={{ marginRight: '5%' }}>
                                            <div>
                                                <small>
                                                    {data.author && data.author !== "Unknown" ? data.author + "                " + getMessageTime(data.timetoken / 10000000 * 1000) : 'Doctall Admin' + "                " + getMessageTime(data.timetoken / 10000000 * 1000)}
                                                </small>
                                            </div>
                                            <div>
                                                <p>
                                                    {data.content}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <div ref={myRef}> </div>
                        </div>


                    </div>

                </div >
                <div style={{ marginRight: '8%', marginLeft: '15%', marginTop: '-6%' }}>

                    <div className="searchchat">
                        <input type="text" className="headersearchchat" placeholder={"Type your message here..."} onChange={(event) => { setMessageInput(event.target.value) }} value={messageInput}
                            onKeyPress={handleKeyPress} />
                        <Button className="header-search_chat_button" style={{ backgroundColor: "#046673", color: 'white' }} variant="contained" onClick={handleSubmit}>Send</Button>
                    </div>


                </div>
            </div >

        </Layout>
    )
}
