              
const appointmentSnapshot = (state={isLoading:true,isError:false,payload:[]},action) =>{
    switch(action.type){
        case "SET_TYPE_REQUEST":
            return {
                ...state,
                isLoading:true,
                isError:false,
                payload:[]
            };
            case "SET_TYPE_SUCCESS":
                return {
                ...state,
                    isLoading:false,
                    payload: action.payload ,
                    isError:false
                };
                case "SET_TYPE_ERROR":
                    return {
                ...state,
                        isLoading:false,
                        isError:true,
                         payload:[]

                    };
                    default:
                        return state;
    }

}

const doctorStat = (state="",action) =>{
    switch(action.type){
        case "SET_STAT":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}
const doctorStatTwo = (state="",action) =>{
    switch(action.type){
        case "SET_STAT_TWO":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}

const doctorStatThree = (state="",action) =>{
    switch(action.type){
        case "SET_STAT_THREE":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}
const doctorStatFour = (state="",action) =>{
    switch(action.type){
        case "SET_STAT_FOUR":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}
const doctorCreateSLot = (state="",action) =>{
    switch(action.type){
        case "SET_CREATE_SLOT":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}
export {appointmentSnapshot,doctorStat,doctorStatTwo,doctorStatThree,doctorStatFour,doctorCreateSLot}