import React, { useEffect, useState } from "react";
import dropDown from "../../../assets/images/dropDown.svg";
import plus from "../../../assets/images/plus.svg";
import search from "../../../assets/images/search.svg";
import del from "../../../assets/images/delete.svg";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useSelector, useDispatch } from "react-redux";
import { getMedPrescription } from "../../../redux/actions/cockpitAction";
import CreatecaseSuccess from "../../Popup/CreatecaseSuccess";
import { getHistro } from "../../../redux/actions/caseNoteAction";
import { SettingsRemote } from "@material-ui/icons";
import { Grid } from '@material-ui/core';
import InfoIcon from "../../../assets/images/Info-Icon.svg"

function CreateLabTest({ routedata, showSuccess, medPre, id, message }) {

  console.log(routedata, "routedata")
  const dispatch = useDispatch();
  const [typeTest, setTypeTest] = React.useState("");
  const [result, setResult] = React.useState([]);
  const [option, setOption] = React.useState(false);
  const [optionValue, setOptionValue] = React.useState("");
  const [passValuse, setPassValue] = React.useState([]);
  const [testdis, setTestdis] = React.useState({});
  const [disdropdown, setDisdropdown] = React.useState(false);
  const [addListing, setAddListing] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [addClear, setAddClear] = React.useState(true);
  const [advice, setAdvice] = React.useState("");
  const [addStatus, setAddStatus] = React.useState(false);
  const [number, setnumber] = React.useState();
  const [alredyAddedTest, setAlredyAddedTests] = useState([]);
  const [prescriptionId, setPrescriptionId] = useState();
  const [partners, setPartners] = useState([]);
  const [partnerName, setPartnerName] = useState([])
  const [info, setInfo] = useState(false);
  console.log(partnerName.name, partnerName.uuid,"partnername")
  const tokeen = useSelector((state) => state.loginReducer.payload.token);
  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "white",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };


  useEffect(() => {
    setAlredyAddedTests([])
    fetchAddedTests()
    // if (id !== 'Add') {

    // } else {
    //   console.log("Add Clicked")
    // }
  }, [id])


  const fetchAddedTests = async () => {
    try {
      // patient_visit_uuid:routedata.routedata.data.uuid,
      // let response = await axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/ep-lab/visit/epl/${routedata.visitId}`)
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/ep-lab/visit/${routedata.visitId}`, {
        headers: {
          "x-auth-token": tokeen
        }
      })
      if (response.status === 200) {
        let epId = response.data && response.data[0] && response.data[0].uuid
        setPrescriptionId(epId)
        setAlredyAddedTests(response.data && response.data && response.data[0] && response.data[0].tests)

      }
    } catch (error) {
      console.log(error)
    }
  }


  const handleTestDelete = async (item) => {
    try {
      let visitId = routedata.data && routedata.data.uuid

      let payLoad = {
        "uuid": prescriptionId,
        "patient_visit_uuid": visitId,
        "test_uuid": item.uuid
      }
      // let response = await axios.delete(`${process.env.REACT_APP_API_URL}/externalappointment/remove/test`, { data: payLoad })
      let response = await axios.delete(`${process.env.REACT_APP_API_URL}/ep-lab/remove/labtest`, { data: payLoad })

      if (response.status === 200) {
        fetchAddedTests()
        showSuccess(true)
        message('1')
        routedata.getApi()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = async (value) => {
    setOptionValue(value.target.value);
    if (value.target.value) {
      setOption(true);
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/labtest/epLabTest/labtests/?testtype=${typeTest}&term=${value.target.value}`
        )
        .then((res) => {
          if (typeTest === "pathology") {
            if (res.data.pathology === undefined) {
              setResult(["Not Found"]);
            } else {
              setResult(res.data.pathology.map((v) => v.standardisedtestname));
            }
          } else {
            console.log(res.data);
            if (res.data.radiology === undefined) {
              setResult(["Not Found"]);
            } else {
              setResult(res.data.radiology.map((v) => v.standardisedtestname));
            }
          }
        })
        .catch((error) => console.log(error.response));
    } else {
      setOption(false);
      setResult([]);
    }
  };

  const handleTestDiscipline = async (value) => {
    setOptionValue(value);
    setOption(false);
    setDisdropdown(true);
    if (value) {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/labtest/epLabTest/labtests/?testtype=${typeTest}&term=${value}`
      )
        .then((res) => {
          if (typeTest === "pathology") {
            console.log(value);
            const filter = res.data.pathology.filter(
              (e) => e.standardisedtestname === value
            );

            setPassValue(filter.map((v) => v));
          } else if (typeTest === "radiology") {
            const filter = res.data.radiology.filter(
              (e) => e.standardisedtestname === value
            );
            setPassValue(filter.map((v) => v));
          }
        })
        .catch((error) => console.log(error.response));
    } else {
      setResult([]);
    }
  };

  const handleAdd = () => {
    if (testdis && testdis.active) {
      addListing.push(testdis);
      setTestdis();
      setTypeTest("");
      setOptionValue("");
      setDisdropdown("");
      setAddClear(false);
      setAddStatus(true);
    } else {
      setAddStatus(false);
    }
  };

  const handleDeleteList = (v) => {
    addListing.splice(
      addListing.findIndex((e) => e._id === v._id),
      1
    );
    setUpdate(!update);
  };

  const handleClinical = (value, e) => {
    let index = addListing.findIndex((e) => e._id === value._id);
    addListing[index].clinicaladvice = e.target.value;
    setUpdate(!update);
  };
  const handlePrescribed = (value, e) => {
    if (1 <= e.target.value) {
      let index = addListing.findIndex((e) => e._id === value._id);
      addListing[index].count = e.target.value;
    }
  };
  
  const handleSubmit = async () => {
    if (addListing.length !== 0) {
      var data = {
        patient_uuid: routedata.data.patient_uuid,
        // patient_visit_uuid: routedata.data.uuid,
        patient_visit_uuid: routedata.visitId,
        doctor_uuid: routedata.data.doctor_uuid,
        labtype: "recommended",
        tests: addListing,
        notes: advice,
        homeDelivery: [],
        recomended_partner_name: partnerName.name,
        recomended_partner_uuid: partnerName.uuid

      };

    console.log(data,"data1");
      axios.post(process.env.REACT_APP_API_URL + "/ep-lab/advice/create", data, {
        // axios.post(process.env.REACT_APP_API_URL + "/externalappointment/ep-lab", data, {
        headers: {
          "x-auth-token": tokeen,
          "Content-Type": 'application/json'
        },
      })
        .then((res) => {
          dispatch(
            getHistro(routedata.data && routedata.data.uuid)
          );
          showSuccess(true)
          routedata.getApi()
        })
        .catch((err) => console.log(err));
    } else {
    }
    setTimeout(function () {
      dispatch(getMedPrescription(routedata.data.uuid));
    }, 3000);
  };


  useEffect(() => {
    getPaymentData()
  }, [])
  const getPaymentData = () => {
    axios.get(process.env.REACT_APP_API_URL + `/metadata/partner/getpartner`, {
      // headers: {
      //     "Content-Types": "multipart/form-data",
      //     "x-auth-token": token,
      //   }
    })
      .then((res) => {
        // setCode(res.data.referal_code)
        setPartners(res.data)
        console.log(res.data, "setpeyment")

      })
      .catch((err) => {
        // console.log(err)
      })
  }

  // const options = [];
  // partners.map((item, index) => {
  // options.push(item)

  //   })

//  console.log(options,"options");

  const ChangePartner = (event) =>{
    setPartnerName(event.target.value);
  };


  return (
    <>
      <div className="createLabTest">
        <div className="dropdown">
          {/* <div className="type_test_container"> */}
          
{/* 
          <select id={id} name="cars" 
          onChange={ChangePartner}>
          {partners.map((item, inex) => {

            return<option id={item.uuid} value={{id:item.uuid, name:item.name}}>{item.name}</option>
         })
          }
       
  
    
  </select> */}


  <Grid item container >
          <Grid md={3} style={{minHeight:"auto"}}>
          <div className="type_test_container">
          <Dropdown
              options={partners.map(item=>item.name)}
              placeholder="Partners"
              menuClassName="dropdownMenu"
              controlClassName="myControlClassName"
              arrowClassName="myArrowClassName"
              onChange={(value) => setPartnerName(partners.filter(item=>value.value===item.name)[0])}
              arrowClosed={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
              arrowOpen={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
            />
             </div>
          </Grid>
          <Grid md={6}>
          <span> <img src={InfoIcon} className="info-icon" 
          onMouseOver={(event)=>{setInfo(true)}} 
          onMouseOut={(event)=>{setInfo(false)}} 
           /></span>
          {
            info==true?
            <div className="info-card">
            <div className="info-heading">Important Tip</div>
            <p className="info-para">Select your preferred Diagnostic partner from which you would like your patient to purchase the Test from.</p>
          </div>
          :null
          }
          
          </Grid>
          </Grid>
             {/* </div> */}
            <div className="type_test_container">

           
            <Dropdown
              options={["pathology", "radiology"]}
              onChange={(value) => setTypeTest(value.value)}
              value={typeTest}
              placeholder="Test Type"
              menuClassName="dropdownMenu"
              controlClassName="myControlClassName"
              arrowClassName="myArrowClassName"
              arrowClosed={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
              arrowOpen={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
            />
          </div>
          <div className="test_name_container">
            {/* <Select
            onChange={handleChange}
            options={options}
            onInputChange={(e) => console.log(e)}
            styles={styles}
          /> */}

            <div className="test_name">
              <input
                type="text"
                onChange={handleSearch}
                className="input_text"
                value={optionValue}
                disabled={
                  typeTest == "pathology"
                    ? false
                    : typeTest == "radiology"
                      ? false
                      : true
                }
                placeholder="Standardized Test Name "
              />
              {option ? (
                <div className="option_container">
                  {result.map((v, i) => (
                    <div
                      className="options"
                      key={i}
                      onClick={() => handleTestDiscipline(v)}
                    >
                      {v}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="descipiline_Container">
            <div className="test_descipline">
              {testdis && testdis.testdiscipline && addClear
                ? testdis.testdiscipline
                : "Test Discipline"}
            </div>
            {disdropdown ? (
              <div
                className="descipilineOption"
              >
                {passValuse.map((v, i) => (
                  <div
                    className="option"
                    key={i}
                    onClick={() => {
                      setDisdropdown(false);
                      setAddClear(true);
                      v.clinicaladvice = "";
                      setTestdis(v);
                    }}
                  >
                    {v.testdiscipline}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={"add_btn"} onClick={handleAdd} >
          Add <img src={plus} alt="icon" />
        </div>
        <div className="test_listing_container">
          <table className="listing_container">
            <tr>
              <th style={{ width: "250px", paddingLeft: "30px",paddingTop: "15px", fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px" }}>
                Test
                <br /> Type
              </th>
              <th style={{ width: "300px",fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px" }}>
                Investigation  <br /> Recommended
              </th>
              {/* <th style={{ width: "200px" }}>
                Quantity
                <br /> Prescribed
              </th> */}
              <th style={{ width: "300px" ,fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>
                Clinical <br />
                Advice
              </th>
              {/* <th></th> */}
            </tr>
            <tr>
              <th colSpan="5">
                <hr />
              </th>
            </tr>

            {/* Old Record for Edit */}

            {/* {id != 'Add' && <div> */}
            {alredyAddedTest && alredyAddedTest.length !== 0 ? (
              alredyAddedTest.map((item, i) => (
                <tr key={i}>
                  <td style={{ paddingLeft: "30px" }}>
                    {item.uuid.slice(0, 2) === "LP" ? "Pathology" : "Radiology"}
                  </td>
                  <td>{item.standardisedtestname}</td>
                  <td>
                    {item.count}
                  </td>
                  <td>
                    {item.testdiscipline}
                  </td>
                  <td style={{ paddingRight: "30px" }}>
                    <img
                      src={del}
                      alt="icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleTestDelete(item)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <div className="norecord">{''}</div>
            )}
            {/* </div>} */}


            {/* New Record For Edit */}

            {addListing && addListing.length !== 0 ? (
              addListing.map((list, i) => (
                <tr key={i}>
                  <td style={{ paddingLeft: "30px" }}>
                    {list.uuid.slice(0, 2) === "LP" ? "Pathology" : "Radiology"}
                  </td>
                  <td>{list.standardisedtestname}</td>
                  <td>
                    <input
                      type="text"
                      placeholder=" Please type here "
                      onChange={(e) => handleClinical(list, e)}
                    />
                  </td>
                  <td style={{ paddingRight: "30px" }}>
                    <img
                      src={del}
                      alt="icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteList(list)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <div className="norecord">{''}</div>
            )}
          </table>
        </div>
        <div className="specialAdvice_Container" >
          <div className="heading" style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Special Advice</div>
          <textarea
            className="textarea"
            placeholder=" Please type here"
            onChange={(e) => setAdvice(e.target.value)}
          ></textarea>
          
        </div>
        <div
          disabled={addListing.length !== 0 ? false : true}
          className={addListing.length !== 0 ? "btn" : "btn_notallowed"}
          onClick={handleSubmit}
          style={{fontFamily:"VisueltRegular", backgroundColor: "#00839B", width: 141,
            height: 61,}}
        >
          Submit
        </div>
      </div>
    </>
  );

}

export default CreateLabTest;
