const orders = [
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Pending",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
  {
    orderid: "Doc - 2793E56F23E0",
    name: "Samiat Oba",
    phoneno: "+234817654432",
    type: "DMP",
    amount: "N1,188.00",
    amountearned: "N237.6",
    orderdate: "March 13, 2022",
    referredby: "SELF",
    status: "Completed",
  },
];

export default orders;
