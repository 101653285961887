import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";



const useStyle = makeStyles(theme => ({
    root: {
        width: '200.06px',
        fontSize: '20px',
        fontStyle: 'normal',
        lineHeight: '26px',
        fontWeight: 'normal',
        color: '#013C44',
        cursor: "pointer"
    }
}))

function SecondaryButton() {
    let history = useHistory();
    const classes = useStyle()
    return (
        <>
            <div className={classes.root} onClick={() => { history.push("/forgot_password") }}>
                Forgot password
            </div>

            <div className={classes.root} onClick={() => { history.push("/doctor-registration") }}>
                Register
            </div>
        </>
    )
}

export default SecondaryButton
