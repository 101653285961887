import { Button, CircularProgress, Dialog, Grid, TextField } from '@material-ui/core'
import React from 'react'

import Heading from '../component/Heading/Heading'
import Logo from '../component/Logo/Logo'
import InputAdornment from '@material-ui/core/InputAdornment';
import Instruction from '../component/Instruction/Instruction';
import triangle from '../images/icons/triangle.png'
import { useStyle } from '../style/style'
import passwordicon from '../images/icons/passwordicon.svg'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import PleaseWait from '../component/StepperComponent/PleaseWait';
import { useHistory, useParams } from "react-router-dom";
import ErrorScreen from '../component/StepperComponent/ErrorScreen';

function DoctorCreatePasswordScreen() {
    const classes = useStyle();
    let history = useHistory();


    const { email } = useParams()
    const [password, setpassword] = React.useState(true);
    const [cmfpassword, setCmfpassword] = React.useState(true);
    const [diqlogOpen, setdiqlogOpen] = React.useState(false);
    const [errorScreen, setErrorScreen] = React.useState(false)
    const [error, setError] = React.useState('');

    const initialValue = {
        password: '',
        confirmpassword: ''
    }
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
        confirmpassword: Yup.string().required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    })

    const handleSubmit = (value) => {
        const { password } = value

        var data = JSON.stringify({ "password": password, "email": email });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/user/auth/Createpassword',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        setdiqlogOpen(true)

        axios(config)
            .then(function (response) {
                setdiqlogOpen(false)
                history.push({ pathname: "/sign_in", state: { email, password } })
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    setdiqlogOpen(false)
                    setErrorScreen(true)
                    setError(error.response.data.message);
                }
            });
    }


    const handleError = () => {
        setErrorScreen(false)
    }




    return (
        <Grid container style={{ backgroundColor: '#FFFFFF' }}>
            <Grid item md={4} sm={12} style={{ display: 'block', margin: '0 auto', height: '100vh' }}>
                <div style={{ marginTop: '20%' }}>
                    <Logo />
                    <Heading heading="Please create your password" />
                    <Formik
                        initialValues={initialValue}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            ({ errors, touched }) => (
                                <Form>
                                    <Field
                                        fullWidth
                                        name="password"
                                        variant="outlined"
                                        type={password ? 'password' : 'text'}
                                        as={TextField}
                                        className={classes.primaryInput3}
                                        placeholder="Password"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><img src={passwordicon} alt="lock" style={{ cursor: 'pointer', marginRight: 20 }} onClick={() => setpassword(!password)} crossorigin="anonymous" /></InputAdornment>,
                                        }}
                                        error={touched.password && errors.password}
                                        helperText={touched.password && errors.password}
                                    />
                                    <div style={{ marginBottom: '30px' }}></div>
                                    <Field
                                        fullWidth
                                        name="confirmpassword"
                                        variant="outlined"
                                        type={cmfpassword ? 'password' : 'text'}
                                        as={TextField}
                                        className={classes.primaryInput3}
                                        placeholder="Confirm Password"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><img src={passwordicon} alt="lock" style={{ cursor: 'pointer', marginRight: 20 }} onClick={() => setCmfpassword(!cmfpassword)} crossorigin="anonymous" /></InputAdornment>,
                                        }}
                                        error={touched.confirmpassword && errors.confirmpassword}
                                        helperText={touched.confirmpassword && errors.confirmpassword}
                                    />
                                    <div style={{ marginBottom: '30px' }}></div>
                                    <Button className={classes.stepperButton} type="submit" style={{ textTransform: 'capitalize' }} >Create <img src={triangle} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                                </Form>
                            )
                        }
                    </Formik>
                    <Instruction />
                </div>
            </Grid>
            <Dialog disableBackdropClick open={diqlogOpen}>
                <PleaseWait />
            </Dialog>
            <Dialog disableBackdropClick open={errorScreen} onClose={handleError}>
                <ErrorScreen err={error} />
            </Dialog>
        </Grid>
    )
}

export default DoctorCreatePasswordScreen
