export const getLoginData= (data)=>{
    
    return{
        type:"GET_LOGIN_DATA",
        data
    }
}
export const getLoginLoading= ()=>{
    
    return{
        type:"GET_LOGIN_LOADING"
    }
}
export const Logout= ()=>{
    
    return{
        type:"LOGOUT"
    }
}