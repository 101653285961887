
const initialState = {
    successSnackbarOpen: false,
    successSnackbarMessage: '',
    errorSnackbarOpen: false,
    infoSnackbarOpen: false,
  };

  
export const snackBarReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SNACKBAR_SUCCESS":
        return {
          ...state,
          successSnackbarOpen: true,
          successSnackbarMessage: action.message
        };
      case "SNACKBAR_CLEAR":
        return {
          ...state,
          successSnackbarOpen: false,
          errorSnackbarOpen: false,
          infoSnackbarOpen: false
        };
      default:
        return state;
    }
  };