import { takeLatest, takeEvery, put, all } from "redux-saga/effects";
import axios from "axios";



const getLoginFn = function* ({ data }) {
    let loginData;
    yield axios.post(process.env.REACT_APP_API_URL + '/user/auth/Doctorlogin', data).then((resp) => {
        if (resp) {
            loginData = resp.data
        }
    })
        .catch(err => {

            loginData = err && err.response && err.response.data
        })
    yield put({ type: "SET_LOGIN", payload: loginData })
}




function* getLoginWatcher() {

    yield takeLatest("GET_LOGIN_DATA", getLoginFn);
}

export { getLoginWatcher }