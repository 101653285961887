export const customStyles = {

    rows: {
        style: {
            minHeight: '72px',
            backgroundColor: '#f3fafc',
            color: '#046673',
            fontWeight: '500'
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            paddingTop: '-10px',
            paddingBottom: '-10px',
            fontSize: '15px',
            fontWeight: '500',
            backgroundColor: '#f3fafc',
            color: '#66b5c3',
           
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            
        },
    },
};