import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { useState,useEffect } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import redClose from "../../assets/images/redClose.svg";
import {
  createCaseNote,
  getCaseNote,
  SearchICD,
  createCaseNoteRequest,
} from "../../redux/actions/caseNoteAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useOnClickOutside from "./../../../src/Dashboard/doctorDashboard/OutClickhandler";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import DialogLayout from "./components/DialogLayout";
// import search from "./icons/search.svg";
// import opencaratol from "./icons/opencaratol.svg";


import axios from "axios";
const useStyles = makeStyles((theme) => styles(theme));

const DefaultCaseNotes = ({ closeDNotes, category, props, setTexts, getApi, confirm }) => {

  console.log(props,"props")
  const classes = useStyles();
  const theme = useTheme();
  console.log(props,"oooooooooo/")
  let history = useHistory();
  const ref = React.useRef();
  let apidata = React.useRef();
  const tokeen = useSelector(state => state.loginReducer.payload.token)
  const routedata = history.location.state;
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [disease, setDisease] = useState("");
  const [chiefComplaint, setchiefComplaint] = useState("");
  const [patientcomplaint, setpatientcomplaint] = useState("");
  const [diagnosis, setdiagnosis] = useState("");
  const [plan, setplan] = useState("");
  const [others, setothers] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [suggestion, setSuggestion] = useState(false);
  const [viewonly, setViewonly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [caseSuccess, setCaseSuccess] = useState(false);
 console.log(caseSuccess,"caseSuccess")
  const [errmsg, seterrmsg] = useState("");


  const [present_comp, setpresent_comp] = useState('');
    const [his_of_present_comp, sethis_of_present_comp] = useState('');
    const [past_psy_his, setpast_psy_his] = useState('');
    const [past_medi_his, setpast_medi_his] = useState('');
    const [family_his, setfamily_his] = useState('');
    const [personal_his, setpersonal_his] = useState('');
    const [childhood_his, setchildhood_his] = useState('');
    const [Edu_his, setEdu_his] = useState('');
    const [occupational_his, setoccupational_his] = useState('');
    const [psycosexual_his, setpsycosexual_his] = useState('');
    const [present_liv_con, setpresent_liv_con] = useState('');
    const [substance_use_his, setsubstance_use_his] = useState('');
    const [appearance_nd_behavi, setappearance_nd_behavi] = useState('');
    const [speech, setspeech] = useState('');
    const [mood, setmood] = useState('');
    const [affect, setaffect] = useState('');
    const [stream, setstream] = useState('');
    const [form, setform] = useState('');
    const [content, setcontent] = useState('');
    const [possession, setpossession] = useState('');
    const [perceptual_disturb, setperceptual_disturb] = useState('');
    const [orientation, setorientation] = useState('');
    const [attention_nd_concen, setattention_nd_concen] = useState('');
    const [memory, setmemory] = useState('');
    const [judgement, setjudgement] = useState('');
    const [insight, setinsight] = useState('');
    const [formualtion, setformualtion] = useState('');
    // const [diagnosis, setdiagnosis] = useState('');
    // const [errmsg, seterrmsg] = useState('');


console.log(present_comp,"present_comp")


  const authState = useSelector((state) => state.loginReducer.payload);

console.log(authState.uuid)
  const dispatch = useDispatch();

  const searcharray = useSelector(
    (state) => state.createCaseNotereducer.search
  );
  const searchloading = useSelector(
    (state) => state.createCaseNotereducer.loading
  );
  const createCaseForpopup = useSelector(
    (state) => state.createCaseNotereducer
  );
  const stateData =
    createCaseForpopup != undefined &&
      createCaseForpopup.search != undefined &&
      createCaseForpopup.search.data != undefined
      ? createCaseForpopup.search.data
      : [];

  const dummy = [
    {
      desc: "LOADING..... ",
    },
  ];
  let slicedArray = stateData !== undefined ? stateData.slice(0, 30) : dummy;

  useEffect(() => {
    if (createCaseForpopup && createCaseForpopup.isLoading == false) {
      props.setLoader(false);
    }
    if (createCaseForpopup.isError) {
      props.setLoader(false);
    }
    apidata = createCaseForpopup.search;
  }, [createCaseForpopup]);
  console.log(disease,"sggfd")
  const onsubmitCase = () => {
    if (disease === "") {
      console.log('s')
      seterrmsg("please fill the disease name");
    } else {
      setshow(true);


      const data = {
        patient_uuid: props.patient_uuid,
            doctor_uuid: props.doctor_uuid,
            patient_visit_uuid: props.uuid,
            doctor_uuid : authState.uuid,
            chief_complaint: chiefComplaint,
            patient_complaint: patientcomplaint,
            diagnosis: diagnosis,
            plan_action: plan,
            others: others,
            disease_name: disease,  
      
      }


      // axios.put(process.env.REACT_APP_API_URL + "/externalappointment/addnotes", data, {
      axios.post(process.env.REACT_APP_API_URL + "/externalappointment/createNotes", data, {
        headers: {
          'x-auth-token': tokeen,
        }
      }).then((resp) => {
        setTexts('CaseNotes Created !')
        getApi()
        confirm()
        // confirm.setDefaultNotes()
        // setShowConfirm.setShowConfirm()
      }).catch(err => {
        setTexts('Failed !')
        getApi()
        confirm()
      })
      setDisease("");
      setchiefComplaint("");
      setpatientcomplaint("");
      setdiagnosis("");
      setplan("");
      setothers("");
      console.log(routedata)
      // dispatch(getCaseNote(routedata.uuid ? routedata.uuid : routedata.visitDetails.uuid));
      // props.handleCloseCasePopup()
    }
    if (createCaseForpopup && createCaseForpopup.isLoading == false) {
      props.setLoader(false);
    }
    if (createCaseForpopup.isError) {
      props.setLoader(false);
    }
  };

  const Search = () => {
    if (disease !== "" && !dropdown) {
      dispatch(SearchICD(disease));
    }
    setDropdown(!dropdown);
  };
  useOnClickOutside(ref, () => {
    setDropdown(false);
  });
  const handleChangeRecord = (e) => {
    setDisease(e.target.value);
    dispatch(SearchICD(disease));
    setSuggestion(true);
  };
  const handleChanged = (e) => {
    let changes = e.target.id;
    setDisease(changes);
    const displayChanges = document.querySelector("#arrayData");
    setSuggestion(false);
  };
  const closePopup = () => {
    props.handleCloseCasePopup()
  }

  const handleSuccessPopup = () => {
   setCaseSuccess(false);
    
  };



  onsubmit = () => {
    if (present_comp == "") {
        seterrmsg("please fill the psychiatry case notes");
    }

    else {

        const data = {
            "patient_visit_uuid": props.uuid,
            "patient_uuid": props.patient_uuid,
            "doctor_uuid": props.doctor_uuid,
            "present_comp": present_comp,
            "his_of_present_comp": his_of_present_comp,
            "past_psy_his": past_psy_his,
            "past_medi_his": past_medi_his,
            "family_his": family_his,
            "personal_his": personal_his,
            "childhood_his": childhood_his,
            "Edu_his": Edu_his,
            "occupational_his": occupational_his,
            "psycosexual_his": psycosexual_his,
            "present_liv_con": present_liv_con,
            "substance_use_his": substance_use_his,
            "appearance_nd_behavi": appearance_nd_behavi,
            "speech": speech,
            "mood": mood,
            "affect": affect,
            "stream": stream,
            "form": form,
            "content": content,
            "possession": possession,
            "perceptual_disturb": perceptual_disturb,
            "orientation": orientation,
            "attention_nd_concen": attention_nd_concen,
            "memory": memory,
            "judgement": judgement,
            "insight": insight,
            "formualtion": formualtion,
            "diagnosis": diagnosis,
            "created_by": "DR-352BEDAF"
        }
        // dispatch(

        //             createPsyRequest({
        //                 "booking_uuid":routedata.uuid,
        //                 "patient_uuid":routedata.patient_uuid,
        //                 "doctor_uuid":routedata.doctor_uuid,
        //                 "consumer_uuid":routedata.patient.created_by.uuid,
        // "present_comp": present_comp,
        // "his_of_present_comp": his_of_present_comp,
        // "past_psy_his": past_psy_his,
        // "past_medi_his": past_medi_his,
        // "family_his": family_his,
        // "personal_his": personal_his,
        // "childhood_his": childhood_his,
        // "Edu_his": Edu_his,
        // "occupational_his": occupational_his, 
        // "psycosexual_his": psycosexual_his,
        // "present_liv_con": present_liv_con,
        // "substance_use_his": substance_use_his,
        // "appearance_nd_behavi": appearance_nd_behavi,
        // "speech": speech,
        // "mood": mood,
        // "affect": affect,
        // "stream": stream,
        // "form": form,
        // "content": content,
        // "possession": possession,
        // "perceptual_disturb": perceptual_disturb,
        // "orientation": orientation,
        // "attention_nd_concen": attention_nd_concen,
        // "memory": memory,
        // "judgement": judgement,
        // "insight": insight,
        // "formualtion": formualtion,
        // "diagnosis": diagnosis,
        // "created_by": "DR-352BEDAF"

        //                     })

        // )

        axios.post(process.env.REACT_APP_API_URL + "/externalappointment/psychiatryCaseNotes", data, {
            headers: {
                'x-auth-token': tokeen,
            }
        }).then((resp) => {
            setTexts('Psychiatry CaseNotes Created !')
            getApi()
            confirm()
        })
            .catch(err => {
              setTexts('Failed !')
              getApi()
              confirm()
            })
        setpresent_comp('');
        sethis_of_present_comp('');
        setpast_psy_his('');
        setpast_medi_his('');
        setfamily_his('');
        setpersonal_his('');
        setfamily_his('');
        setchildhood_his('');
        setEdu_his('');
        setoccupational_his('');
        setpsycosexual_his('');
        setsubstance_use_his('');
        setappearance_nd_behavi('');
        setspeech('');
        setmood('');
        setaffect('');
        setstream('');
        setform('');
        setcontent('');
        setpossession('');
        setperceptual_disturb('');
        setorientation('');
        setattention_nd_concen('');
        setmemory('');
        setjudgement('');
        setinsight('');
        setformualtion('');
        setdiagnosis('');
        setpresent_liv_con('');
        setfamily_his('');
        setpersonal_his('');
        setpresent_comp('');
        // dispatch(getphyNote(routedata.uuid))
   
    }     // setTimeout(() => {
        //     dispatch(getphyNote(routedata.uuid))
        // }, 1000);
    // if(createphypopup&&createphypopup.isLoading!=undefined&&createphypopup.isLoading==false){
    //     props.setLoader(false)
    // }
    // if(createphypopup.isError){
    //     props.setLoader(false)
    // }
}



  return (
    <Grid
      container
      direction="column"
      style={{ paddingTop: "16px", padding: "10px" }}
    >
      <Grid container justifyContent="space-between">
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "35px",
            color: "#013C44",
          }}
        >
          Doctor Case Notes
        </Typography>
        <img
          src={dialogclose}
          alt="close button"
          style={{ marginRight: "26px", cursor: "pointer" }}
          onClick={() => closeDNotes()}
        />
      </Grid>
      <hr
        style={{
          height: "0.5px",
          borderWidth: "0px",
          width: "326px",
          marginLeft: "-21px",
          background: "#00839B",
          borderColor: "#00839B",
        }}
      />
      {category === "default" ? (
        <>
        {/* <div>

       
          <Typography className={classes.formLabel} align="left">
            Select Disease<span className={classes.mandatory}>*</span>
          </Typography>
          <input
            className={classes.searchField}
            placeholder="Search disease by name or ICD code"
            style={{ paddingLeft: "10%", width: "286px", fontSize: "12px" }}
          />
          <Typography className={classes.formLabel} align="left">
            Chief Complaint
          </Typography>
          <textarea
            style={{
              width: "286px",
              height: "136px",
              borderRadius: "10px",
              border: "1px solid #66B5C3",
              boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
            }}
          ></textarea>
          <Typography className={classes.formLabel} align="left">
            History of Patient Complaint
          </Typography>
          <input className={classes.inputField} />
          <Typography className={classes.formLabel} align="left">
            Diagnosis{" "}
          </Typography>
          <input className={classes.inputField} />
          <Typography className={classes.formLabel} align="left">
            Plan Action{" "}
          </Typography>
          <input className={classes.inputField} />
          <Typography className={classes.formLabel} align="left">
            Other Information{" "}
          </Typography>
          <input className={classes.inputField} />
          <button
            className={classes.mainButton}
            style={{
              background: "#00839B",
              width: "286px",
              marginTop: "33px",
              marginBottom: "47px",
            }}
            onClick={() => confirm(true)}
          >
            Create Case Note
          </button>
          </div> */}



          <div className="docContainer" 
          style={{ margin: "auto",width:"100%" }}
          >
            <div style={{ marginBottom: "5px", marginTop: "30px" }}>
              <label className="doclabel">Select Disease <span style={{color:"red"}}>*</span></label>
            </div>
            <div ref={ref}>
              <div
                className="inputContainer"
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* <img
                  src={search}
                  alt="search"
                  style={{ margin: "0 20px" }}
                  crossorigin="anonymous"
                /> */}
                <input
                  type="text"
                  disabled={viewonly}
                  className="docinput"
                  
                  value={disease}
                  name="disease"
                  onChange={handleChangeRecord}
                  placeholder="Search disease by name or ICD 10 code"
                />
                {/* <img
                  src={opencaratol}
                  onClick={() => {
                    !viewonly && Search();
                  }}
                  alt="opencaratol"
                  crossorigin="anonymous"
                /> */}
              </div>

              {slicedArray.length > 0 && suggestion === true ? (
                <div
                  id="arrayData"
                  className="casedropdown"
                  style={{ display: disease === "" ? "none" : "block" }}
                >
                  {slicedArray.map((i) => (
                    <p className="value" onClick={handleChanged} id={i.desc}>
                      {i.desc}
                    </p>
                  ))}
                </div>
              ) : (
                <>
                  {suggestion === true ? (
                    <div id="arrayData" className="casedropdown">
                      <p className="value"> LOADING..... </p>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}

              {dropdown && (
                <div className="casedropdown">
                  {searcharray && searcharray.data ? (
                    searcharray.data.map((e, i) => {
                      return (
                        <div
                          className="value"
                          key={i}
                          onClick={() => {
                            setDisease(e.desc);
                            setDropdown(false);
                          }}
                        >
                          {e.desc}
                        </div>
                      );
                    })
                  ) : searchloading ? (
                    <div className="nodata">Loading ...</div>
                  ) : (
                    <div className="nodata">No data found</div>
                  )}
                </div>
              )}
              {/* <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}}> 
                      <Typography className={classes.tableData_medicine_font} noWrap>{val.genericname}</Typography>
                    </div> */}
            </div>
            <span style={{ color: "red" }}>{errmsg}</span>
            <div style={{ marginBottom: "5px" }}>
              <label className="doclabel">Chief Complaint</label>
            </div>

            <input
              type="text"
              disabled={viewonly}
              className="docinputField"
              style={{ height: "100px" }}
              value={chiefComplaint}
              name="chiefComplaint"
              onChange={(e) => setchiefComplaint(e.target.value)}
            />

            <div style={{ marginBottom: "5px" }}>
              <label className="doclabel">
                History of patient complaint:
              </label>
            </div>
            <input
              type="text"
              disabled={viewonly}
              className="docinputField"
              value={patientcomplaint}
              name="patientcomplaint"
              onChange={(e) => setpatientcomplaint(e.target.value)}
            />

            <div style={{ marginBottom: "5px" }}>
              <label className="doclabel">Diagnosis:</label>
            </div>
            <input
              type="text"
              disabled={viewonly}
              className="docinputField"
              value={diagnosis}
              name="patientcomplaint"
              onChange={(e) => setdiagnosis(e.target.value)}
            />

            <div style={{ marginBottom: "5px" }}>
              <label className="doclabel">Plan/Action:</label>
            </div>
            <input
              type="text"
              disabled={viewonly}
              className="docinputField"
              value={plan}
              name="patientcomplaint"
              onChange={(e) => setplan(e.target.value)}
            />

            <div style={{ marginBottom: "5px" }}>
              <label className="doclabel">Other information:</label>
            </div>
            <input
              type="text"
              disabled={viewonly}
              className="docinputField"
              value={others}
              name="patientcomplaint"
              onChange={(e) => setothers(e.target.value)}
            />
            {/* {!viewonly ? ( */}
            <div 
            // className="docButton"
            >
              {/* <div className="buttontxt" onClick={() => onsubmitCase()}>
                Create Case Notes
              </div> */}
              <button
            className={classes.mainButton}
            style={{
              background: "#00839B",
              width: "286px",
              marginTop: "33px",
              marginBottom: "47px",
            }}
            onClick={() => onsubmitCase()}
          >
            Create Case Note
          </button>
            </div>
            {/* ) : ( */}
            {/* <div style={{ marginTop: 50 }}></div> */}
            {/* )} */}
          </div>
          
        </>
      ) : (
        <>
        <div>

      
          <Typography className={classes.formLabel} align="left">
          Psychiatry Case Notes <span style={{color:"red"}}>*</span>
          </Typography>
          <textarea className={classes.notesArea} value={present_comp} name="present_comp" onChange={e => setpresent_comp(e.target.value)}/>
          <span style={{ color: "red" }}>{errmsg}</span>
          <Typography className={classes.formLabel} align="left">
          History of Presenting Complaints:
          </Typography>
          <textarea className={classes.notesArea} value={his_of_present_comp} name="his_of_present_comp" onChange={e => sethis_of_present_comp(e.target.value)} />

          <Typography
            className={classes.formLabel}
            align="left"
            style={{ lineHeight: "18px" }}
          >
            History
          </Typography>
          <Typography
            className={classes.formLabel}
            align="left"
            style={{ fontSize: "12px", marginTop: "0px", lineHeight: "18px" }}
          >
            Past Psychiatric history:
          </Typography>
          <textarea className={classes.notesArea} value={past_psy_his} name="past_psy_his" onChange={e => setpast_psy_his(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Past Medical history::
          </Typography>
          <textarea className={classes.notesArea} value={past_medi_his} name="past_medi_his" onChange={e => setpast_medi_his(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Family history:
          </Typography>
          <textarea className={classes.notesArea} name="family_his" onChange={e => setfamily_his(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Personal history:
          </Typography>
          <textarea className={classes.notesArea}  alue={personal_his} name="personal_his" onChange={e => setpersonal_his(e.target.value)} />

          <Typography className={classes.formLabel} align="left">
          Childhood history:
          </Typography>
          <textarea className={classes.notesArea} value={childhood_his} name="childhood_his" onChange={e => setchildhood_his(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Educational history:
          </Typography>
          <textarea className={classes.notesArea} value={Edu_his} name="Edu_his" onChange={e => setEdu_his(e.target.value)} />

          <Typography className={classes.formLabel} align="left">
          Occupational history:
          </Typography>
          <textarea className={classes.notesArea} value={occupational_his} name="occupational_his" onChange={e => setoccupational_his(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Psychosexual history:
          </Typography>
          <textarea className={classes.notesArea} value={psycosexual_his} name="psycosexual_his" onChange={e => setpsycosexual_his(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Present living conditions:
          </Typography>
          <textarea className={classes.notesArea} value={present_liv_con} name="present_liv_con" onChange={e => setpresent_liv_con(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Substance Use history:
          </Typography>
          <textarea className={classes.notesArea} value={substance_use_his} name="substance_use_his" onChange={e => setsubstance_use_his(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Mental State Examination
          </Typography>
          <Typography className={classes.formLabel} align="left">
          Appearance and behavior:
          </Typography>
         
          <textarea className={classes.notesArea} value={appearance_nd_behavi} name="appearance_nd_behavi" onChange={e => setappearance_nd_behavi(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Speech:
          </Typography>
          <textarea className={classes.notesArea} value={speech} name="speech" onChange={e => setspeech(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Mood:
          </Typography>
          <textarea className={classes.notesArea} value={mood} name="mood" onChange={e => setmood(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Affect:
          </Typography>
          <textarea className={classes.notesArea} value={affect} name="affect" onChange={e => setaffect(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Stream:
          </Typography>
          <textarea className={classes.notesArea} value={stream} name="stream" onChange={e => setstream(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Form:
          </Typography>
          <textarea className={classes.notesArea} value={form} name="form" onChange={e => setform(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Content:
          </Typography>
          <textarea className={classes.notesArea} value={content} name="content" onChange={e => setcontent(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Possession:
          </Typography>
          <textarea className={classes.notesArea} value={possession} name="possession" onChange={e => setpossession(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Perceptual disturbances:
          </Typography>
          <textarea className={classes.notesArea} value={perceptual_disturb} name="perceptual_disturb" onChange={e => setperceptual_disturb(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Orientation:
          </Typography>
          <textarea className={classes.notesArea} value={orientation} name="orientation" onChange={e => setorientation(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Attention and Concentration:
          </Typography>
          <textarea className={classes.notesArea}  value={attention_nd_concen} name="attention_nd_concen" onChange={e => setattention_nd_concen(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Memory:
          </Typography>
          <textarea className={classes.notesArea}  value={memory} name="memory" onChange={e => setmemory(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Judgement:
          </Typography>
          <textarea className={classes.notesArea} value={judgement} name="judgement" onChange={e => setjudgement(e.target.value)} />

          <Typography className={classes.formLabel} align="left">
          Insight:
          </Typography>
          <textarea className={classes.notesArea} value={insight} name="insight" onChange={e => setinsight(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Formulations:
          </Typography>
          <textarea className={classes.notesArea} value={formualtion} name="formualtion" onChange={e => setformualtion(e.target.value)}/>

          <Typography className={classes.formLabel} align="left">
          Diagnosis:
          </Typography>
          <textarea className={classes.notesArea} value={diagnosis} name="diagnosis" onChange={e => setdiagnosis(e.target.value)}/>

          {/* <Typography className={classes.formLabel} align="left">
            History of Presenting Complaints
          </Typography>
          <textarea className={classes.notesArea} /> */}
          <button
            className={classes.mainButton}
            style={{
              background: "#00839B",
              width: "286px",
              marginTop: "33px",
              marginBottom: "47px",
            }}
            onClick={onsubmit}
          >
            Create Case Note
          </button>
          <span style={{ color: "red" }}>{errmsg}</span>

</div>
          

        </>
      )}
      {/* <Dialog
          open={caseSuccess}
          onClose={handleSuccessPopup}
          maxWidth={"sm"}
          fullWidth={true}
          // scroll={"body"}
          // disableBackdropClick
        >
          <div className="ContainerWrapper">
            
            <CancelIcon
              onClick={() => setCaseSuccess(false)}
              style={{
                cursor: "pointer",
                marginRight: "0px",
                float: "right",
                marginLeft: "auto",
                fontSize: "xx-large",
              }}
            />
            
            <DialogContent>
            <p>You Have Successfully Created Case Notes</p>
            </DialogContent>
          </div>
        </Dialog> */}
        {caseSuccess ? (
        <DialogLayout
         open={caseSuccess}
          // onClose={handleSuccessPopup}
          // maxWidth={"xs"}
          style={{maxWidth:"200px!importent"}}
        // TransitionComponent={Transition}
        keepMounted
       
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <div className="ContainerWrapper">
            
            <CancelIcon
              onClick={() => setCaseSuccess(false)}
              style={{
                cursor: "pointer",
                marginRight: "0px",
                float: "right",
                marginLeft: "auto",
                fontSize: "xx-large",
              }}
            />
            
            <DialogTitle>
            <p>You Have Successfully Created Case Notes</p>
            </DialogTitle>
          </div>
     
          {/* <Button onClick={handleSuccessPopup}>Disagree</Button> */}
          
        
      </DialogLayout>
      ) : null}
    </Grid>
  );
};

export default DefaultCaseNotes;
