import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { addVisit, searchPatient, newSearchPatient, addNewPatient } from '../../services/ServiceHelper';
import { useDispatch, useSelector } from "react-redux";
import { useStyle } from '../../../Admin/style/style'
import { useHistory } from 'react-router-dom';
import { getVisits } from '../../../redux/actions/ex-appointment-action';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios"
import MuiPhoneNumber from 'material-ui-phone-number';
import { Form, Formik, Field } from 'formik';
import "./../../components/Addpatient.scss"
import PhoneInput from "react-phone-input-2";
import moment from 'moment';
import 'react-phone-input-2/lib/style.css'
import NumberFormat from 'react-number-format';
import FilledInput from '@mui/material/FilledInput';
import {
    /* the components you used */
    InputAdornment
  } from "@material-ui/core";

// import { AiFillCloseCircle, AiFillEye } from 'react-icons/ai';
export default function AddVisitComponent(props) {
    const classes = useStyle();
    const dispatch = useDispatch();
    let history = useHistory();
    const { id, close, details, toastVisibility, toastText, toastType } = props;
    const authState = useSelector((state) => state.loginReducer.payload);
    const [isPatientFound, setPatientFound] = useState(false);
    const [notFound, setNotFound] = useState(false);

    //Existing details of patient
    const [existingMobile, setExistingMobile] = useState();
    const [existingName, setExistingName] = useState();
    const [existingEmail, setExistingEmail] = useState();
    const [existingGender, setExistingGender] = useState();
    const [existingAge, setExistingAge] = useState();
    const [existingAddress, setExistingAddress] = useState();
    const [existingBloodGroup, setExistingBloodGroup] = useState();
    const [patientUUID, setPatientUUID] = useState();

    //New Patient
    const [mobile, setMobile] = useState();
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [gender, setGender] = useState();
    const [age, setAge] = useState();
    const [age1, setAge1] = useState();
    const [address, setAddress] = useState();
    const [bloodGroup, setBloodGroup] = useState();
    const [mob, setMob] = useState()
    // const [patientUUID, setPatientUUID] = useState();

    const [code, setCode] = useState("");
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [city, setCity] = useState()
    const [area, setArea] = useState()
    const [door, setDoor] = useState()
    const [pin, setPin] = useState()
    const [street, setStreet] = useState()
    const [dob, setDob] = useState()
    const [citytosend, setCitytoSend] = useState()


    const [Symptoms, setSymtoms] = useState();
    const [Complaint, setComplaint] = useState();

    const [visitDetail, setVisitDetails] = useState();
    const [temperature, setTemperature] = useState()
    const [grp, setGrp] = useState('')


    const GrpChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);
        setGrp(value.replace(/[^a-zA-z+-]/ig,''));
    }

    const TempChange = (e) => {
        const { value } = e.target.value;
        console.log('Input value: ', value);

        // const re = /^[A-Za-z]+$/;
        // if (value === "" || re.test(value) === true) {
        //     setSymtoms(value);
        // }
        setTemperature(value && value.replace(/[^cCfF0-9]/ig,''));
    }
    const SymptomsChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        // const re = /^[A-Za-z]+$/;
        // if (value === "" || re.test(value) === true) {
        //     setSymtoms(value);
        // }
        setSymtoms(value && value.replace(/[^a-zA-z]/ig,''))
    }
    const ComplaintsChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        // const re = /^[A-Za-z]+$/;
        // if (value === "" || re.test(value) === true) {
        //     setComplaint(value);
        // }
        setComplaint(value && value.replace(/[^a-zA-z]/ig,''))
    }
    const handleClose = () => {
        props.close(true)
    };
    useEffect(() => {
        setAge1(existingAge)
    })
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/allcities`)
            .then((res) => {
                setCityList(res.data)
                console.log(res.data, "56456456")
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    function fetcharea(cityId) {
        console.log(cityId, "city")
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/getArea/${cityId}`)
            .then(res => {
                setAreaList(res.data)
                // setLoading(false)
            }).catch(err => {
                console.log(err)
                // setLoading(false)
            })
    }
    console.log(age1, "age")
    const handleToast = (visibility, message, type) => {
        toastVisibility(visibility)
        toastText('' + message)
        toastType(type)
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const { phoneNumber, mobile, name, email, age, bloodGroup, gender, symptoms, additional_info, address, Height, Weight, reason } = event.target.elements;
            console.log(event.target.elements, "123456")
            let res = event.target.elements[0].value
            let res1 = res.replace(/\D/g, '');
            let res2 = parseInt(res1)
            let payLoad = {
                name: name.value,
                bloodGroup: bloodGroup.value,
                email: email.value,
                mobile: res2,
                age: age1,
                gender: gender.value,
                symptoms: symptoms.value,
                additional_info: additional_info.value,
                address: address.value,
                doctor_uuid: authState.uuid,
                patient_uuid: patientUUID,
                height: Height.value,
                temperature: temperature,
                weight: Weight.value,
                reason_for_visit: reason.value
            }
            let response = await addVisit(payLoad)
            if (response.status === 200 || response.status === 201) {
                handleVisitView(response.data)
                handleToast(true, "" + "Visit Added Successfully ", "success")
                dispatch(getVisits(0, ""))
                setTimeout(() => {
                    close()
                    handleVisitView(response.data)
                }, 1500);
            }
            
        } catch (error) {
            if (error && error.response) {
                handleToast(true, "" + error.response.data, "error")
                setTimeout(() => {
                    close()
                }, 1500);
            }
        }
    }
    const handleSearch = async (event) => {
        console.log(event.target[1].value)
        // console.log(event.target.elements.phoneNumber.value)
        event.preventDefault()
        setMobile(event && event.target && event.target.elements && event.target.elements.phoneNumber && event.target.elements.phoneNumber.value)
        try {
            let res = event && event.target && event.target.elements && event.target.elements.phoneNumber && event.target.elements.phoneNumber.value
            // let res1 = res.replace(" ",'');
            // let res2 =  res1.replace("-",'');
            let res1 = res.replace(/\D/g, '');
            let payLoad = {
                mobile: parseInt(res1),
                doctor_uuid: authState.uuid
            }
            console.log(parseInt(res1))
            let response = await newSearchPatient(parseInt(res1), authState.token)
            if (response && response.status === 200 || response.status === 201) {
                handleToast(true, "Successfully Fetched Patient Details", "success")
                setPatientFound(true)
                setNotFound(false)
                let mResponse = response.data
                let patientName = mResponse.first_name + "    " + mResponse.last_Name;
                setExistingName(mResponse.full_name)
                setExistingAddress(mResponse.address)
                setExistingAge(mResponse.dob)
                setExistingBloodGroup(mResponse.blood_group)
                setExistingEmail(mResponse.email)
                setExistingMobile(mResponse.mobile)
                setExistingGender(mResponse.gender)
                setPatientUUID(mResponse.uuid)
                window.scrollTo(10000, 1000);
                // setDetails(mResponse)
                // setExistingName(patientName)
                // fetcharea(mResponse.residential_Address[0].city)
            } else {
                handleToast(true, "Patient Not Found With Provided Phone Number", "error")
                setPatientFound(false)
                setNotFound(true)
            }
        } catch (error) {
            setPatientFound(false)
            setNotFound(true)
            if (error && error.response) {
                handleToast(true, "" + error.response.data, "error")
            } else {
                handleToast(true, "Patient Not Found With Provided Phone Number", "error")
            }
        }
    }
    const countryCodeFn = (code) => {
        if (code == "91" || code == "+91") {
            return "in";
        } else if (code == "234" || code == "+234") {
            return "ng";
        } else if (code == "1" || code == "+1") {
            return "us";
        } else if (code == "44" || code == "+44") {
            return "uk";
        } else {
            return "NG";
        }
    };
    // const handleSearch2 = async (event) => {
    //     console.log(event)
    //     try {
    //         let res = event
    //         // let res1 = res.replace(" ",'');
    //         // let res2 =  res1.replace("-",'');
    //         let res1 = res.replace(/\D/g,'');
    //         let payLoad = {
    //             mobile: res1,
    //             doctor_uuid: authState.uuid
    //         }
    //         let response = await searchPatient(payLoad)
    //         if (response && response.status === 200 || response.status === 201) {
    //             handleToast(true, "Successfully Fetched Patient Details", "success")
    //             setPatientFound(true)
    //             setNotFound(false)

    //             let mResponse = response && response.data && response.data.data
    //             let patientName = mResponse.firstName + "    " + mResponse.lastName;

    //             setExistingName(patientName)
    //             setExistingAddress(mResponse.address)
    //             setExistingAge(mResponse.age)
    //             setExistingBloodGroup(mResponse.bloodGroup)
    //             setExistingEmail(mResponse.email)
    //             setExistingMobile(mResponse.mobile)
    //             setExistingGender(mResponse.gender)
    //             setPatientUUID(mResponse.uuid)

    //             let c1code = existingMobile.slice(0, existingMobile.length - 10);
    //             setCode(countryCodeFn(c1code));
    //             console.log(code.toString());
    //         } else {
    //             handleToast(true, "Patient Not Found With Provided Phone Number", "error")
    //             setPatientFound(false)
    //             setNotFound(true)
    //         }

    //     } catch (error) {
    //         setPatientFound(false)
    //         setNotFound(true)
    //         if (error && error.response) {
    //             handleToast(true, "" + error.response.data, "error")

    //         } else {
    //             handleToast(true, "Patient Not Found With Provided Phone Number", "error")
    //         }
    //     }

    // }
    const handleCityChange = (event) => {
        setCity(event && event.target && event.target.value)

        cityList.map((item) => {
            if (item.uuid === event.target.value) {
                console.log(item._id)
                setCitytoSend(item._id)
            }
        })


        console.log(event.target)
        fetcharea(event && event.target && event.target.value)
        console.log(event.target, "555")
        // console.log(event.target.value.id,"555")
    }
    const handleAreaChange = (event) => {
        setArea(event && event.target && event.target.value)
    }
    const handleDoorChange = (event) => {
        setDoor(event && event.target && event.target.value)
    }
    const handlePinChange = (event) => {
        setPin(event && event.target && event.target.value)
    }
    const handleStreetChange = (event) => {
        setStreet(event && event.target && event.target.value)
    }

    const handleSearch2 = async (event) => {
        // try {
        let res = event
        let res1 = res.replace(/\D/g, '');
        let payLoad = {
            mobile: res1,
            doctor_uuid: authState.uuid
        }
        let response = await newSearchPatient(parseInt(res1), authState.token)
        if (response && response.status === 200 || response.status === 201) {
            handleToast(true, "Successfully Fetched Patient Details", "success")
            setPatientFound(true)
            setNotFound(false)

            let mResponse = response.data
            let patientName = mResponse.first_name + "    " + mResponse.last_Name;

            setExistingName(mResponse.full_name)
            setExistingAddress(mResponse.address)
            setExistingAge(mResponse.dob)
            setExistingBloodGroup(mResponse.blood_group)
            setExistingEmail(mResponse.email)
            setExistingMobile(mResponse.mobile)
            setExistingGender(mResponse.gender)
            setPatientUUID(mResponse.uuid)

        } else {
            handleToast(true, "Patient Not Found With Provided Phone Number", "error")
            setPatientFound(false)
            setNotFound(true)
        }

        // } catch (error) {
        //     setPatientFound(false)
        //     setNotFound(true)
        //     if (error && error.response) {
        //         handleToast(true, "" + error.response.data, "error")
        //     } else {
        //         handleToast(true, "Patient Not Found With Provided Phone Number", "error")
        //     }
        // }
    }
    function handleOnChange(value) {
        var res = value.replace(/\D/g, "");
    }
    const handlePatientSubmit = async (event) => {
        event.preventDefault()


        try {
            // const { phoneNumber, mobilenumber, middleName, firstname, lastname, email, bloodgroup, age, gender, address } = event.target.elements
            let res = mobile
            let res1 = res.replace(/\D/g, '');
            let payLoad = {
                "first_name": firstName,
                "last_name": lastName,
                "middle_name": middleName,
                "full_name": firstName + " " + middleName + " " + lastName,
                "blood_group": grp,
                "email": email,
                "mobile": res1,
                "dob": age,
                "gender": gender,
                "address": address,
                "residential_Address": {
                    "city": citytosend,
                    "area": area,
                    "door_no": door,
                    "pincode": pin,
                    "street": street
                },
                "visited_doctor_uuid": authState.uuid
            }
            let response = await addNewPatient(payLoad, authState.token);

            handleToast(true, "Successfully Added New Patient", "success")
            handleSearch2(mobile)
            // props.get112()
            // setTimeout(() => {
            //     close()
            // }, 1500);

        } catch (error) {
            if (error && error.response) {
                // setToast(true)
                handleToast(true, error.response.data, "error")
                // setToastType('error')
            } else {
                // setToast(false)
            }
        }
        // event.preventDefault()
        // try {
        //     // console.log(mobile,"789789")
        //     let res = mobile
        //     // let res1 = res.replace(" ",'');
        //     // let res2 =  res1.replace("-",'');
        //     let res1 = res.replace(/\D/g,'');
        //     let payLoad = {
        //         "firstName": firstName,
        //         "lastName": lastName,
        //         "bloodGroup": bloodGroup,
        //         "email": email,
        //         "mobile": res1,
        //         "age": age,
        //         "gender": gender,
        //         "address": address,
        //         "doctor_uuid": authState.uuid
        //     }
        //     axios.post(`${process.env.REACT_APP_API_URL}/externalappointment`, payLoad)
        //     .then((response) => {
        //         handleToast(true, "New Patient Added Successfuly", "success")
        //         handleSearch2(mobile)
        //     })
        //     .catch((error) => {    
        //         handleToast(true, error && error.response && error.response.data , "error")
        //     console.log(error.response)
        //     })
        // } catch (error) {
        //     handleToast(true, error && error.response && error.response.data , "error")
        //     console.log(error.response.data)
        // }
    }
    const handleAgeChange = (event) => {
        setAge(event.target.value)

    }
    // const varun = (event) => {
    //     s(event.target.value)

    // }

    const handlefirstNameChange = (event) => {
        // setFirstName(event.target.value)
        const { value } = event.target;
            console.log('Input value: ', value);
    
            // const re = /^[A-Za-z]+$/;
            // if (value === "" || re.test(value) === true) {
            //     setFirstName(value);
            // }
            setFirstName(value.replace(/[^a-zA-z]/ig,''));

    }
    const handleMiddleNameChange = (event) => {
        // setMiddleName(event.target.value)
        const { value } = event.target;
            console.log('Input value: ', value);
    
            // const re = /^[A-Za-z]+$/;
            // if (value === "" || re.test(value) === true) {
            //     setMiddleName(value);
            // }
            setMiddleName(value.replace(/[^a-zA-z]/ig,''));


    }
    const handlelastChange = (event) => {
        // setLastName(event.target.value)
        const { value } = event.target;
            console.log('Input value: ', value);
    
            // const re = /^[A-Za-z]+$/;
            // {console.log( re.test(value))}
            // if (value === "" || re.test(value) == true) {
            //     setLastName(value);
            // }

            setLastName(value.replace(/[^a-zA-z]/ig,''));

            

    }
    const handlemobileChange = (event) => {

        setMobile(event)

    }
    const handlegenderChange = (event) => {
        setGender(event.target.value)
    }
    const handleBloodGroupChange = (event) => {
        setBloodGroup(event.target.value)

    }
    const handleemailChange = (event) => {
        setEmail(event.target.value)

    }
    const handleAddressChange = (event) => {
        setAddress(event.target.value)

    }

    const handleVisitView = (item) => {
        console.log(item,"8978979595")
        setVisitDetails(item)
        console.log(visitDetail,"8978979596")
        history.push({
            pathname: "/visit-view",
            state: {
                visitDetails: item,
            },
        });
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <div className='table_heading'>Add Appointment</div>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Close" placement="bottom" arrow>

                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right" }} />

                    </Tooltip>

                </Grid>
            </Grid>

            <hr style={{ borderTop: "0.5px solid #66B5C3", borderBottom: "none", marginBottom: '5%' }} />
            {isPatientFound === false && <small style={{ fontSize: "15px", color: "#013C44" ,fontFamily: "Greycliff CF" ,fontWeight: "bold"}}> <b></b> </small>}
            <div>

                {isPatientFound === false &&
                    <Formik
                        // initialValues={initialValues}
                        onSubmit={handleSearch}
                    >
                        <Form action="" onSubmit={handleSearch}>
                            <Grid container>
                                <Grid item xs={8}>
                                    {/* <div className={classes.primaryLabel}>Phone Number<span className='star-color'>*</span></div> */}

                                    <Field
                                        as={MuiPhoneNumber}
                                        name="phoneNumber"
                                        defaultCountry={'ng'}
                                        required
                                        onChange={(event) => { setNotFound(false); setPatientFound(false); handleOnChange(event); }}
                                        fullWidth variant="outlined"
                                        className={classes.primaryInput2}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <button className='submit_button' type='submit' style={{ marginLeft: '15%', marginTop: "0%", background: "#00839B", height: "60px" }}>Search</button>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                }
            </div>


            {notFound === true && isPatientFound === false && <div style={{ width: '100%', height: '50%' }}>
                <p style={{ color: "red", marginTop: "2%", fontWeight: "500" }}>Patient details not found</p>
                {/* <button className='submit_button' onClick={() => history.push('/patients')}>Add Patient</button> */}
                <h2 style={{fontFamily:"Greycliff CF",fontWeight:"bold"}}>Add Patient :</h2>
                <hr style={{ borderTop: "0.5px solid #66B5C3", borderBottom: "none", marginBottom: '5%' }} />
                <Formik action=""
                    onSubmit={handlePatientSubmit}
                >
                    <Form onSubmit={handlePatientSubmit}>                     <>

                        <Grid container spacing={2}>
                            {/* <Grid item xs={6}>
                                <h3 className="input_lable1">Mobile Number <span className='star-color'>*</span></h3>
                                <Field
                                    as={MuiPhoneNumber}
                                    name="phoneNumber"
                                    defaultCountry={'ng'}
                                    required
                                    onChange={handlemobileChange}
                                    fullWidth variant="outlined"
                                    defaultValue={mobile && mobile}
                                    value={mobile && mobile}
                                    className={classes.primaryInput2}
                                />
                            </Grid> */}
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Mobile Number<span className='star-color'>*</span></h3>
                                    <PhoneInput
                                        className={classes.primaryInput2}
                                        defaultCountry={'ng'}
                                        required
                                        onChange={handlemobileChange}
                                        value={mobile && mobile}
                                    />
                                </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">First Name <span className='star-color'>*</span></h3>
                                <input
                                    className="headersearchtxt"
                                    style={{ width: "100%" }}
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    onChange={handlefirstNameChange}
                                    value={firstName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Middle Name </h3>
                                <input
                                    className="headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    onChange={handleMiddleNameChange}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="middle_name"
                                    value={middleName}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Last Name <span className='star-color'>*</span></h3>
                                <input
                                    className="headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="lastname"
                                    required
                                    onChange={handlelastChange}
                                    value={lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3 className="input_lable1">Email ID<span className='star-color'>*</span></h3>
                                <input
                                    className="headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="email"
                                    required
                                    onChange={handleemailChange}
                                    defaultValue={email}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Blood Group<span className='star-color'>*</span></h3>
                                <select
                                    className="headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="bloodgroup"
                                    required
                                    onChange={GrpChange}
                                    value={grp ? grp : bloodGroup}
                                    defaultValue={bloodGroup}
                                >
                                            <option value="">Select..</option>
                                           <option value="Unknown" key="g">Unknown</option>
                                            <option value="A+" className="headersearchtxt" key="2">A+</option>
                                            <option value="A-" className="headersearchtxt" key="3">A- </option>
                                            <option value="B+" className="headersearchtxt" key="4">B+</option>
                                            <option value="B-" className="headersearchtxt" key="5">B-</option>
                                            <option value="O+" className="headersearchtxt" key="6">O+</option>
                                            <option value="O-" className="headersearchtxt" key="7">O-</option>
                                            <option value="AB+" className="headersearchtxt" key="8">AB+</option>
                                            <option value="AB-" className="headersearchtxt" key="9">AB-</option>
                                            </select>
                            </Grid>
                            <Grid item xs={4}>
                                <h3 className="input_lable1">DOB<span className='star-color'>*</span></h3>
                                <input
                                    className="headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    name="age"
                                    required
                                    onChange={handleAgeChange}
                                    defaultValue={age}
                                    value={age}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Gender<span className='star-color'>*</span></h3>
                                <select id="cars" className="headersearchtxt" name="gender"
                                    onChange={handlegenderChange}
                                    defaultValue={gender}
                                >
                                    <option value="">Select..</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">City</h3>
                                <select id="cars" className="headersearchtxt" name="city"
                                    // required
                                    // defaultValue={PatientDetails && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].city}
                                    // value={city}

                                    onClick={(event) => handleCityChange(event)}
                                >
                                    <option value="">Select..</option>
                                    {
                                        cityList && cityList.map((item) => (<option value={item.uuid}>{item.name}</option>))
                                    }

                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Area</h3>
                                <select id="cars" className="headersearchtxt" name="area"
                                    // required
                                    onChange={(event) => handleAreaChange(event)}
                                    defaultValue={area}
                                    value={area}

                                >
                                    {
                                        areaList && areaList.map((item) => (<option value={item._id}>{item.name}</option>))
                                    }
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Door No</h3>
                                <input
                                    style={{ width: '100%' }}
                                    name="door_no"
                                    variant="outlined"
                                    className="headersearchtxt"
                                    // required
                                    onChange={(event) => handleDoorChange(event)}
                                    defaultValue={door}

                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <h3 className="input_lable1">Pincode</h3>
                                <input
                                    style={{ width: '100%' }}
                                    name="pincode"
                                    variant="outlined"
                                    className="headersearchtxt"
                                    // required
                                    onChange={(event) => handlePinChange(event)}
                                    defaultValue={pin}

                                />
                            </Grid> */}
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Street</h3>
                                <input
                                    style={{ width: '100%' }}
                                    name="street"
                                    variant="outlined"
                                    className="headersearchtxt"
                                    // required
                                    onChange={(event) => handleStreetChange(event)}
                                    defaultValue={street}
                                />
                            </Grid>
                        </Grid>
                        {/* {id === 'Add' && <div className="btn_submit"> */}
                        <div>&nbsp;</div>
                        <button className='submit_button' type='submit' >Submit</button>
                        {/* </div>} */}
                    </>
                    </Form>

                </Formik>

            </div>}

            {isPatientFound === true &&
                <Formik action="" onSubmit={handleSubmit}>
                    <Form onSubmit={handleSubmit}>
                        {/* <> */}
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Mobile</h3>
                                <PhoneInput
                                    // className={"form-control"}
                                    country="ng"
                                    disabled
                                    value={existingMobile}
                                    //   disabled={type == "owner"}
                                    onChange={(event) => handlemobileChange()}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Name</h3>
                                <input
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="text"
                                    disabled
                                    fullWidth
                                    variant="outlined"
                                    name="name"
                                    // required
                                    defaultValue={existingName && existingName}
                                />
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Email</h3></Grid> */}
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Email</h3>
                                <input
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="email"
                                    label="Email ID"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                    name="email"
                                    // required
                                    disabled
                                    defaultValue={existingEmail && existingEmail}
                                />
                            </Grid>

                            {/* <Grid item xs={1}> <h3 className="input_lable1">Age</h3></Grid> */}
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Date of Birth</h3>
                                <input
                                    disabled
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="Age"
                                    label="Age"
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    name="age"
                                    // required
                                    defaultValue={moment(existingAge && existingAge).format("YYYY-MM-DD")}
                                    value={moment(existingAge && existingAge).format("YYYY-MM-DD")}
                                />
                            </Grid>

                            {/* <Grid item xs={1}> <h3 className="input_lable1">Blood Group</h3></Grid> */}
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Blood Group</h3>
                                <select
                                    disabled
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="blood group"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name='bloodGroup'
                                    // required
                                    defaultValue={existingBloodGroup && existingBloodGroup}
                                >
                                          <option value="Unknown" key="g">Unknown</option>
                                            <option value="A+" className="headersearchtxt" key="2">A+</option>
                                            <option value="A-" className="headersearchtxt" key="3">A- </option>
                                            <option value="B+" className="headersearchtxt" key="4">B+</option>
                                            <option value="B-" className="headersearchtxt" key="5">B-</option>
                                            <option value="O+" className="headersearchtxt" key="6">O+</option>
                                            <option value="O-" className="headersearchtxt" key="7">O-</option>
                                            <option value="AB+" className="headersearchtxt" key="8">AB+</option>
                                            <option value="AB-" className="headersearchtxt" key="9">AB-</option>
                           </select>
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Gender</h3></Grid> */}
                            {console.log(existingGender, "789789")}
                            <Grid item xs={4}>
                                <h3 className="input_lable1">Gender</h3>
                                <select id="cars" className=" headersearchtxt" name="gender"
                                    // defaultValue={existingGender && existingGender}
                                    defaultValue={existingGender}
                                    value={existingGender}
                                    disabled
                                >
                                    <option value="">Select..</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Symptoms</h3></Grid> */}
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Symptoms<span className='star-color'>*</span></h3>
                                <input
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="blood group"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name='symptoms'
                                    required
                                    value={Symptoms}
                                    onChange={SymptomsChange}

                                />
                            </Grid>

                            {/* <Grid item xs={1}> <h3 className="input_lable1">Temperature</h3></Grid> */}
                            <Grid item xs={6} s >
                                <h3 className="input_lable1" >Temperature</h3>
                                {/* <NumberFormat
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="Temperature"
                                    // type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="temperature"
                                    // required
                                    value={temperature}
                                    onChange={TempChange}
                                /> */}
                                    <FilledInput
                                    id="filled-adornment-weight"
                                    // value={values.weight}
                                    className="headersearchtxt2"
                                    disableUnderline={true}
                                    type="number"
                                    step = "any"
                                    // style={{background:"red !important"}}
                                    style={{background:"red"}}
                                   
                                    
                                    // onChange={TempChange}
                                    value={temperature}
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">°C</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                    'aria-label': 'weight',
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Address</h3></Grid> */}
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Weight</h3>
                                {/* <NumberFormat
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="Weight"
                                    // type="number"
                                    step="any"
                                    min="0"
                                    fullWidth
                                    variant="outlined"
                                    name="weight"
                                    // required
                                /> */}
                                <FilledInput
                                    // id="filled-adornment-weight"
                                    id="Weight"
                                    // value={values.weight}
                                    className="headersearchtxt2"
                                    disableUnderline={true}
                                    type="number"
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                    'aria-label': 'weight',
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Height</h3></Grid> */}
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Height</h3>
                                {/* <NumberFormat
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="Height"
                                    // type="number"
                                    step="any"
                                    min="0"
                                    fullWidth
                                    variant="outlined"
                                    name="height"
                                    // required
                                /> */}
                                 <FilledInput
                                    // id="filled-adornment-weight"
                                    // value={values.weight}
                                    className="headersearchtxt2"
                                    disableUnderline={true}
                                    id="Height"
                                    type="number"
                                    // type="number"
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">Cm</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                    'aria-label': 'weight',
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Weight</h3></Grid> */}
                           
                            {/* <Grid item xs={4}></Grid> */}

                            {/* <Grid item xs={1}> <h3 className="input_lable1">Chief Complaint</h3></Grid> */}
                            <Grid item xs={12}>
                                <h3 className="input_lable1">Chief Complaint<span className='star-color'>*</span></h3>
                                <input
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="reason"
                                    required
                                    value={Complaint}
                                    onChange={ComplaintsChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3 className="input_lable1">Address</h3>
                                <input
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="Address"
                                    label="Address : "
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name='address'
                                // required
                                />
                            </Grid>
                            {/* <Grid item xs={2}> <h3 className="input_lable1">Additional information</h3></Grid> */}
                            <Grid item xs={12}>
                                <h3 className="input_lable1">Additional information</h3>
                                <textarea
                                    rows="4" cols="100"
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="Address"
                                    label="Address : "
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name='additional_info'
                                // required
                                />
                            </Grid>
                        </Grid>
                        <div className="btn_submit">
                            <button className='submit_button' type='submit' >Submit</button>
                        </div>

                        {/* </> */}
                    </Form>
                </Formik>}
        </>
    );
}
