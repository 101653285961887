import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Typography } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useStyle } from '../../Admin/style/style'
import { editBanks } from "../services/ServiceHelper";
import { getBankDetails } from "../../redux/actions/ex-appointment-action";
import Toast from "../../components/Toast";
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { newSearchPatient, MessagePatient } from '../services/ServiceHelper';



export default function Message(props) {
    let dispatch = useDispatch()
    const authState = useSelector((state) => state.loginReducer.payload);
    console.log(authState)
    const classes = useStyle();

    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);
    const [isPatientFound, setPatientFound] = useState(false);
    const [mob, setMob] = useState('');
    const [msg, setMsg] = useState('');
    const [patientuuid, setPatientuuid] = useState('');



    const handleClose = () => {
        props.close(true)
    };

    const handleChange = async (event) => {
        try {
            let res = event
            console.log(res,"event")
            let res1 = res.replace(/\D/g, '');
            let response = await newSearchPatient(parseInt(res1), authState.token)
            console.log(response)
            if (response.status == 200) {
                setPatientFound(true)
                let mResponse = response && response.data
                setPatientuuid(mResponse.uuid)
                setMob(mResponse.mobile)

            } else {
                setPatientFound(false)
            }

        } catch (error) {
            setPatientFound(false)
        }
    };
    const handleChangemessage = (event) => {
        // console.log(event.target.value)
        setMsg(event.target.value)
    };

    const handlemessage = (event) => {
        setToast(true)
        setToastMessage('' + "Please search patient or patient not found")
        setToastType('error')
    };
    // const handleSearch = async (event) => {
        
    //     // event.preventDefault()
    //     try {
    //         let res = mob
    //         console.log(res,"event")
    //         let res1 = res.replace(/\D/g, '');
    //         let response = await newSearchPatient(parseInt(res1), authState.token)
    //         console.log(response)
    //         if (response.status == 200) {
    //             // setToast(true)
    //             // setToastMessage('' + "Patient Found With Provided Phone Number")
    //             // setToastType('success')
    //             setPatientFound(true)

    //             let mResponse = response && response.data
    //             setPatientuuid(mResponse.uuid)
    //             setMob(mResponse.mobile)

    //         } else {
    //             // setToast(true)
    //             // setToastMessage('' + "Patient Not Found With Provided Phone Number")
    //             // setToastType('error')
    //             // handleToast(true, "Patient Not Found With Provided Phone Number", "error")
    //             setPatientFound(false)
    //         }

    //     } catch (error) {
    //         // setToast(true)
    //         // setToastMessage('' + "Patient Not Found With Provided Phone Number")
    //         // setToastType('error')
    //         // handleToast(true, "Patient Not Found With Provided Phone Number", "error")
    //         setPatientFound(false)
    //     }

    // }

    const message = async (event) => {
        event.preventDefault()
        try{

        var payload = {
            "mobile": mob,
            "message":msg,
            "doctor_uuid": authState.uuid,
            "patient_uuid": patientuuid
        }
        let response = await MessagePatient(payload, authState.token)
        if (response.status == 200 || response.status == 201) {
            setToast(true)
            setToastMessage('' + "Message sent")
            setToastType('success')
            setTimeout(handleClose, 1500)
            props.Messagesget()
            
        } else{
            setToast(true)
            setToastMessage('' + "Failed to send message")
            setToastType('error')
            setTimeout(handleClose, 1500)
        }
        
    }
    catch (error){
        setToast(true)
        setToastMessage('' + "Failed to send message")
        setToastType('error')
        // let close = props.close(true)
        setTimeout(handleClose, 1500)
    }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <div className='table_heading' style={{fontFamily: [""].join(",")}}>Message Patient</div>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Close" placement="bottom" arrow>

                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right" }} classNm/>
                    </Tooltip>
                </Grid>
            </Grid>


            <hr style={{ borderTop: "2px solid #00839B", borderBottom: "none", marginBottom: '5%' }} />
            <Formik>
            <form action="" onSubmit={message}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                                    <h3 className="input_lable_message">Mobile Number<span className='star-color'>*</span></h3>
                                    <Field
                                    as={MuiPhoneNumber}
                                    name="phoneNumber"
                                    defaultCountry={'ng'}
                                    required
                                    style={{ innerHeight: "50px" }}
                                    fullWidth variant="outlined"
                                    onChange={(event) => handleChange(event)}
                                    onKeyDown={(event) => handleChange(event)}
                                    className={classes.primaryInput2}
                                />
                    </Grid>
                    {/* <button className='submit_button' onClick={handleSearch} style={{ marginLeft: '15%', marginTop: "0%", background: "#00839B", height: "60px" }}>Search</button> */}
                    
                    {/* {isPatientFound && <> */}
                    <Grid item md={12} style={{ textAlign: "left" }}>
                    <h3 className="input_lable_message">Message</h3>
                    </Grid>
                    <Grid item md={12} >
                    <TextField
                        style={{border: "1px solid #66B5C3",borderRadius:"10px"}}
                        fullWidth variant="outlined"
                        multiline
                        rows={6}
                        onChange={(event) => handleChangemessage(event)}
                        // className={classes.multilineInputField}
                    />
                    </Grid> 
                    <div className="btn_submit">
                    <button className='submit_button' type='submit' disabled={isPatientFound == false}>Send Message</button>
                   </div>

                   {/* </> */}
{/* }                    */}
                 </Grid> 

            </form>
            </Formik>
            <Toast
                text={toastMessage}
                type={toastType}
                open={showToast}
                setClose={() => {
                    setToast(false);
                }}
            />

        </>
    )
}

