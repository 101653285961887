import React from 'react';
import Close from '../../assets/images/close.svg';
import { Dialog } from '@material-ui/core';
import axios from 'axios';
import { useHistory,useLocation, useParams } from "react-router-dom";
import success from '../../assets/images/success.svg'
import './ConfirmAvailability.scss';
import Icon from '../../assets/images/Icon.svg'

function ConfirmAvailability() {

    let doctorid = window.location.href.slice(-15);
    let bookingid = window.location.href.slice(-27, -16)
    const [open, setOpen] = React.useState(true)
    const [succ, setSucc] = React.useState(false)
    const [err, setErr] = React.useState(false)

    const handleClose = () => {
        setOpen(false)
        window.location.href = '/'
    }
    

    function clickHandler() {
        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/booking/doctoravailabilitystatus/${bookingid}/${doctorid}`,
            headers: { }
          };
        axios(config).then((i)=>{
            setSucc(true)
                setTimeout(() => {
                    window.location.href = '/'
                }, 3000);
        }).catch(err=>{setErr(true)})
    
    }

    return (
        <Dialog
            maxWidth={"lg"}
            open={open}
            scroll={"body"}
            onClose={handleClose}
        >
            <div className="ContainerWrapper">
                <img src={Close} alt="Close" className="Closepopup" onClick={handleClose} crossorigin="anonymous" />
                {err ?
                    <div className="SuccessMsg">
                        <div className="ErrorMsg">
                            <img src={Icon} alt="icon" />
                            Internal Server Error!
                        </div>
                    </div> : succ ? <div className="SuccessMsg">
                        <div className="MsgContainer">
                            <img src={success} alt="icon"  crossorigin="anonymous"/>
                            Updated Successfully!
                        </div>
                    </div> :
                        <div className="ConfirmAvailability">
                            <div className="heading">Please Confirm your Availability</div>
                            <div className="buttonContaoner">
                                <div className="btn" onClick={clickHandler}>Yes</div><div className="btn" onClick={handleClose}>No</div>
                            </div>
                        </div>

                }
            </div>

        </Dialog>
    )
}

export default ConfirmAvailability
