import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
} from "@material-ui/core";
import styles from "../style";
import successIc from "../../../assets/images/successIc.svg";
const useStyles = makeStyles((theme) => styles(theme));

const RegConfirm = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid container justifyContent="center" className={classes.confirm}>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        style={{ marginTop: "279px" }}
      >
        <img
          src={successIc}
          alt="success"
          style={{ height: "56px", width: "56px" }}
        />
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "20px",
            color: "#344054",
            marginTop: "27px",
          }}
        >
          Congratulations!
        </Typography>
        <Typography
          align="center"
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#344054",
            marginTop: "16px",
            width: "247px",
          }}
        >
          You have successfully completed your registration process.
        </Typography>
        <button
          className={classes.regButton}
          style={{ marginTop: "247px" }}
          // onClick={() => regComplete()}
        >
          Continue
        </button>
      </Grid>
    </Grid>
  );
};

export default RegConfirm;
