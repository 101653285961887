import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import redClose from "../../assets/images/redClose.svg";
import uploadplus from "../../assets/images/uploadplus.svg";
import medicalupload from "../../assets/images/medicalupload.svg";
import saveIcon from "../../assets/images/saveIcon.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import LoaderComponent from '../../components/Loading/LoaderComponent';
import MuiPhoneNumber from 'material-ui-phone-number';
import Toast from '../../components/Toast';
import { editBanks } from "../services/ServiceHelper";
import { getBankDetails } from "../../redux/actions/ex-appointment-action";
import { useDispatch } from "react-redux";
import { getSaasDoctorProfile } from '../../redux/actions/ex-appointment-action';



const useStyles = makeStyles((theme) => styles(theme));

const UpdateProfile = ({ closeEditProfile, category, bankDetails, drdata }) => {

  let dispatch = useDispatch()
  console.log(drdata, "drdata")
  const authState = useSelector((state) => state.loginReducer.payload);



  useEffect(() => {
    dispatch(getSaasDoctorProfile())
  }, [])

  const saasProfile = useSelector((state) => state.PatientSnapshot.doctorProfiles);
  console.log(saasProfile, "saasProfile")
  // console.log(doctorInfo, "doctorInfo12")
  console.log(authState, "authState")
  const useId = authState.uuid
  const userToken = authState.token

  const [firstName, setFirstName] = useState("sdf")
  const [middleName, setMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dob, setDob] = useState("2008-10-11")
  console.log(dob, "fdassdasd")
  const [mobile, setMobile] = useState("")
  const [email, setEmail] = useState("")
  const [gender, setGender] = useState("")
  const [location, setLocation] = useState("")
  const [yearofExp, setYearofExp] = useState("")
  const [practicestartYear, setpracticeStartYearExp] = useState()
  const [qualification, setQualification] = useState("")
  // const [spaciality, setSpaciality]=useState([])
  const [languageSpoken, setLanguageSpoken] = useState()
  console.log(languageSpoken, "languageSpoken")
  const [professional, setprofessional] = useState("")
  const [specialityData, setSpecialityData] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  console.log(speciality, "speciality")
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [signImage, setSignImage] = useState();
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [regCertificate, setRegCertificate] = useState();
  const [certificateUploaded, setCertificateUploaded] = useState(false);

  const [address1, setAddress] = React.useState();

  const UpdateLocation = (event) => {
    setAddress(event && event.target && event.target.value)
}

  const [showToast, setToast] = useState(true);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const [accountName, setAccountName] = useState("")
  const [bankName, setBankName] = useState("")
  const [branchName, setBranchName] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountType, setAccountType] = useState("")
  const [bankIban, setBankIban] = useState("")
  const [bankSwiftIcd, setBankSwiftIcd] = useState("")
  const [age, setAge] = useState('')

  // let age ;
  const YearOFexp = (event) => {
    setpracticeStartYearExp(event.target.value)
    let currentDate = new Date()
    var currentYear = currentDate.getFullYear();
    // var birthYear = authState.profile.practice_started_year
    setAge(currentYear - event.target.value);
  }

  useEffect(() => {
    setAccountName(bankDetails.bank_detail.acc_name)
    setBankName(bankDetails.bank_detail.bank_name)
    setBranchName(bankDetails.bank_detail.branch)
    setAccountNumber(bankDetails.bank_detail.acc_number)
    setAccountType(bankDetails.bank_detail.acc_type)
    setBankIban(bankDetails.bank_detail.bankIban)
    setBankSwiftIcd(bankDetails.bank_detail.bankSwiftIcd)
  }, [])





  const [file, setFile] = useState();
  console.log(profileImage, signImage, "signImage")


  console.log(speciality, "speciality")
  const handleSpeciality = (event) => {
    setSpeciality(event.target.value)
  }



  useEffect(() => {
    const getData = () => {
      axios.get(process.env.REACT_APP_API_URL + '/metadata/specialities')
        .then(data => setSpecialityData(data.data))
    }

    getData()
  }, [])


  useEffect(() => {
    setFirstName(saasProfile.firstName)
    setMiddleName(saasProfile.middleName)
    setLastName(saasProfile.lastName)
    setDob(moment(saasProfile.profile.dob).format("ddd, DD MMM, YYYY"))
    setMobile(saasProfile.mobile)
    setEmail(saasProfile.email)
    setGender(saasProfile.profile.gender)
    setAddress(saasProfile && saasProfile.profile && saasProfile.profile.address && saasProfile.profile.address.city)
    setSpeciality(saasProfile.profile.speciality[0])
    // setYearofExp("")
    setpracticeStartYearExp(saasProfile.profile.practice_started_year)
    setQualification(saasProfile && saasProfile.profile && saasProfile.profile.professional_detail && saasProfile.profile.professional_detail.qualification)
    // setSpaciality(authState && authState.profile && authState.profile.professional_detail && authState.profile.professional_detail.professional)
    setLanguageSpoken(drdata && drdata.languages)
    setprofessional(saasProfile && saasProfile.profile && saasProfile.profile.professional_detail && saasProfile.profile.professional_detail.professional)
  
    let currentDate = new Date()
  var currentYear = currentDate.getFullYear();
  var birthYear = saasProfile.profile.practice_started_year
  setAge(currentYear - birthYear);

  }, [])

  console.log(firstName, "firstName")

  const doctorInfo = useSelector((state) => {
    if (
      state.getDoctorDetails != "" &&
      state.getDoctorDetails.payload != undefined &&
      state.getDoctorDetails.payload.length > 0 &&
      state.getDoctorDetails.payload[0] != undefined
    ) {
      console.log(state.getDoctorDetails.payload[0], "hasdads");


      return {
        Location:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.address &&
          state.getDoctorDetails.payload[0].profile.address.city,
        Exp:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.practice_started_year,
        languages:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.languages &&
          state.getDoctorDetails.payload[0].profile.languages.toString(),
        firstName:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].firstName,
        full_name:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].full_name,
        profile_pic:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile_pic,
        experience:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile.practice_started_year,
        mobile:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].mobile,
        email:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].email,
        consultation_fee:
          state.getDoctorDetails.payload[0].consultation_fee &&
          state.getDoctorDetails.payload[0].consultation_fee,
        dob:
          state.getDoctorDetails.payload[0].profile.dob &&
          state.getDoctorDetails.payload[0].profile.dob,
        folioNumber: state.getDoctorDetails.payload[0].profile.folioNumber,
        gender:
          state.getDoctorDetails.payload[0].profile.gender &&
          state.getDoctorDetails.payload[0].profile.gender,
        speciality:
          state.getDoctorDetails.payload[0].profile.speciality &&
          state.getDoctorDetails.payload[0].profile.speciality,
        Certification: state.getDoctorDetails.payload[0].Certification,
        // qualification: state.getDoctorDetails.payload[0].profile.professional_detail.qualification,
        qualification:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.professional_detail &&
          state.getDoctorDetails.payload[0].profile.professional_detail
            .qualification,
      };
    }
  });

  console.log(doctorInfo, "doctorInfo12")
  const classes = useStyles();
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault()
    const payLoad = {
      'practice_started_year': practicestartYear,
      'gender': gender,
      'bio': 'I am doctor',
      // 'folioNumber': profile && profile.folioNumber,
      'dob': dob,
      "address": {
        'city': address1,
      },
      'languages': [languageSpoken],
      'speciality': [speciality],

      "professional_detail": {
        "professional": professional,
        "qualification": qualification,
      },
      'sign_pic': signImage ? signImage : saasProfile && saasProfile.profile && saasProfile.profile.sign_pic,
      'profile_pic': profileImage ? profileImage : saasProfile.profile_pic,
      'full_name': firstName + middleName + lastName,
      "firstName": firstName,
      "lastName": lastName,
      "middleName": middleName,
      'profile': {
        'languages': languageSpoken,
        'city': address1,
        'speciality': speciality,
      },


    };
    axios.put(process.env.REACT_APP_API_URL + `/user/updateDetailsByAdmin/${useId}`, payLoad, {
      headers: {
        'x-auth-token': userToken,
      }
    })

      .then((res) => {
        console.log(res, "dsadgddsd")
        if (res.status == 200 || res.status == 201) {
          setToastType("success")
          setToastMessage("Successfully updated profile")
          setToast(true)
          dispatch(getSaasDoctorProfile())
          setTimeout(() => {
            closeEditProfile()
          }, 1500)
        }

      })
      .catch((err) => {
        console.log(err, "dsfsfsd");
        setToastType("error")
        setToastMessage("somthing went wrong")
        setToast(true)
      })
  }









  const handleProfilePic = async ({ target: { files } }) => {
    const uploadedImage = files[0];
    setLoading(true)
    await uploadMedia(uploadedImage, "PIC")

  }
  const uploadMedia = async (document, docType) => {
    try {
      const formData = new FormData()
      formData.append('media', document)
      // There is no auth header available it's hadcoded in old registration as well
      // const token1 = userdata.token;
      let hardCodedHeader = {
        'Content-Types': 'multipart/form-data',
        'x-auth-token': userToken
      }

      const config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/upload/avatar',
        headers: hardCodedHeader,
        data: formData,
      }

      let response = await axios(config)
      if (response.status === 200 || response.status === 201) {
        if (docType === "PIC") {
          setProfileImage(response.data.url)
          setProfileUploaded(true)
        } else {
          setRegCertificate(response.data.url)
          setCertificateUploaded(true)
        }
        setLoading(false)
      }

    } catch (error) {
      if (error && error.response) {
        // handleToast(true, "" + error.response.data, 'error')
      }
      setLoading(false)
      if (docType === "PIC") {
        setProfileUploaded(false)
      } else {
        setCertificateUploaded(false)
      }
    }
  }


  const handleSignPic = async ({ target: { files } }) => {
    const uploadedImage = files[0];
    setLoading(true)
    await uploadMediaSign(uploadedImage, "PIC1")

  }
  const uploadMediaSign = async (document, docType) => {
    try {
      const formData = new FormData()
      formData.append('media', document)
      // There is no auth header available it's hadcoded in old registration as well
      // const token1 = userdata.token;
      let hardCodedHeader = {
        'Content-Types': 'multipart/form-data',
        'x-auth-token': userToken
      }

      const config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/upload/avatar',
        headers: hardCodedHeader,
        data: formData,
      }

      let response = await axios(config)
      if (response.status === 200 || response.status === 201) {
        if (docType === "PIC") {
          setSignImage(response.data.url)
          setProfileUploaded(true)
        } else {
          setRegCertificate(response.data.url)
          setCertificateUploaded(true)
        }
        setLoading(false)
      }

    } catch (error) {
      if (error && error.response) {
        // handleToast(true, "" + error.response.data, 'error')
      }
      setLoading(false)
      if (docType === "PIC") {
        setProfileUploaded(false)
      } else {
        setCertificateUploaded(false)
      }
    }
  }


  const handleChangePhone = (e) => {
    console.log(e, "e")
    const value = e
    const noSpecialCharacters = value.replace(/[^a-zA-Z0-9()]/g, '');
    console.log(noSpecialCharacters, "noSpecialCharacters");
    setMobile(noSpecialCharacters);

  }


  const handleSubmitBankDetails = async (event) => {
    event.preventDefault()

    try {


      let payLoad = {
        "acc_name": accountName,
        "bank_name": bankName,
        "branch": branchName,
        "acc_number": accountNumber,
        "acc_type": accountType,
        "bankSwiftIcd": bankSwiftIcd,
        "bankIban": bankIban
      }

      let response = await editBanks(payLoad, authState.uuid)
      dispatch(getBankDetails())
      // props.close(false)

      if (response.status == 200 || response.status == 201) {
        setToastType("success")
        setToastMessage("Successfully updated Bank Details")
        setToast(true)
        dispatch(getSaasDoctorProfile())
        setTimeout(() => {
          closeEditProfile()
        }, 1500)
      }
      console.log(response, "sdfsdfxvcvxs")
    } catch (error) {
      if (error && error.response) {
        setToast(true)
        setToastMessage('' + error.response.data)
        setToastType('error')
      } else {
        setToast(false)
      }
    }

  }

  // let currentDate = new Date()
  // var currentYear = currentDate.getFullYear();
  // var birthYear = authState.profile.practice_started_year
  // let age = currentYear - birthYear;


  return (
    <>
      <Grid container direction="column" style={{ paddingTop: "16px" }}>
        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "35px",
              color: "#013C44",
              marginLeft: "21px",
            }}
          >
            {category === "profile" ? "Update Profile" : "Update Bank Details"}
          </Typography>
          <img
            src={dialogclose}
            alt="close button"
            style={{ marginRight: "16px", cursor: "pointer" }}
            onClick={() => closeEditProfile()}
            crossorigin="anonymous"
          />
        </Grid>
        <hr
          style={{
            height: "0.5px",
            borderWidth: "0px",
            width: "326px",

            background: "#00839B",
            borderColor: "#00839B",
          }}
        />
        {category === "profile" ? (
          <>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ padding: "24px 22px 64px 20px " }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.uploadCont}
                >
                  {profileImage ?
                    <img src={profileImage} alt="upload" style={{width:"100px",height:"100px"}} crossOrigin="anonymous"/>
                    :
                    <>
                      <img src={uploadplus} alt="upload" />
                      <Typography
                        align="center"
                        style={{
                          width: "121px",
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "18px",
                          color: "#3C3E40",
                          marginTop: "15px",
                        }}
                      >
                        Upload Profile Picture
                      </Typography>
                    </>
                  }




                  <Typography
                    align="center"
                    style={{
                      width: "213px",
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "17px",
                      color: "#262626",
                      marginTop: "15px",
                    }}
                  >
                    Profile picture should be in the standard formart PNG, JPEG &
                    no more than 2MB
                  </Typography>
                  <label
                    for="file-upload"
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <img src={medicalupload} alt="uplaod" />
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                    onChange={handleProfilePic}
                    as={TextField}
                    accept="image/*"
                    name="profilePic"
                  />
                  {/* <input
                                                                style={{ height: '20px', width: '20px' }}
                                                                type="file"
                                                                name="profilePic"
                                                                as={TextField}
                                                                className={classes.uploadButton}
                                                                id="upload-photo"
                                                                onChange={handleProfilePic}
                                                                accept="image/*"
                                                            /> */}
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.uploadCont}
                  style={{ marginTop: "45px" }}
                >
                  {signImage ?
                     <img src={signImage} alt="upload" style={{width:"100px",height:"100px"}} crossOrigin="anonymous"/>
                    :
                    <>
                      <img src={saveIcon} alt="upload" />
                      <Typography
                        align="center"
                        style={{
                          width: "121px",
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "18px",
                          color: "#3C3E40",
                          marginTop: "15px",
                        }}
                      >
                        Upload Signature
                      </Typography>
                      <Typography
                        align="center"
                        style={{
                          width: "213px",
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "17px",
                          color: "#262626",
                          marginTop: "15px",
                        }}
                      >
                        Profile picture should be in the standard formart PNG, JPEG &
                        no more than 2MB
                      </Typography>
                    </>
                  }

                  <label
                    for="file-upload1"
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <img src={medicalupload} alt="uplaod" />
                  </label>
                  <input
                    type="file"
                    id="file-upload1"
                    style={{ display: "none" }}
                    // type="file"
                    name="certificate"
                    as={TextField}
                    // className={classes.uploadButton}
                    // id="upload-photo2"
                    onChange={handleSignPic}
                    accept="image/*"
                  />
                </Grid>
                <Grid item container direction="column">
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                    style={{ marginTop: "51px" }}
                  >
                    First Name<span className={classes.mandatory}>*</span>
                  </Typography>
                  <input className={classes.inputField} value={firstName} onChange={e => setFirstName(e.target.value)} />
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Middle Name
                  </Typography>
                  <input className={classes.inputField} value={middleName} onChange={e => setMiddleName(e.target.value)} />
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Last Name<span className={classes.mandatory}>*</span>
                  </Typography>
                  <input className={classes.inputField} value={lastName} onChange={e => setLastName(e.target.value)} />
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Date of Birth<span className={classes.mandatory}>*</span>
                  </Typography>
                  <input className={classes.inputField}
                    // initialValue={dob} 
                    type="date"

                    value={moment(dob).format("YYYY-MM-DD")}
                    defaultValue={moment(dob).format("YYYY-MM-DD")}
                    onChange={e => setDob(e.target.value)} />
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Mobile Number<span className={classes.mandatory}>*</span>
                  </Typography>
                  {/* <input className={classes.inputField}  value={mobile}   */}
                  {/* // onChange={e=>setMobile(e.target.value)} */}

                  {/* /> */}
                  <MuiPhoneNumber
                    defaultCountry={"in"}
                    // onChange={handleChangePhone}
                    InputProps={{ disableUnderline: true }}
                    className={classes.regInput}
                    placeholder="Enter Mobile number"
                    value={mobile}
                    // defaultValue={mobile}
                    disabled
                    style={{ width: "286px" }}
                  />

                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Email <span className={classes.mandatory}>*</span>
                  </Typography>
                  <input className={classes.inputField} value={email}
                    onChange={e => setEmail(e.target.value)}
                    disabled />
                  <Grid item container>
                    <Grid item style={{ width: "135px" }}>
                      <Typography
                        className={classes.formLabel}
                        alignSelf="flex-start"
                      >
                        Gender <span className={classes.mandatory}>*</span>
                      </Typography>
                      {/* <input
                      className={classes.inputField}
                      style={{ width: "135px" }}
                      value={gender}  onChange={e=>setGender(e.target.value)}
                    /> */}
                      <select name="cars" id="cars"
                        className={classes.inputField}
                        style={{ width: "135px" }}
                        value={gender} onChange={e => setGender(e.target.value)}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                        {/* <option value="audi">Audi</option> */}
                      </select>
                    </Grid>
                    <Grid item style={{ width: "135px", marginLeft: "6px" }}>
                      <Typography
                        className={classes.formLabel}
                        alignSelf="flex-start"
                      >
                        Location
                      </Typography>
                      <input
                        className={classes.inputField}
                        style={{ width: "135px" }}
                        value={address1} onChange={(event) => UpdateLocation(event)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item style={{ width: "135px" }}>
                      <Typography
                        className={classes.formLabel}
                        alignSelf="flex-start"
                      >
                        Year of Experience
                      </Typography>
                      <input
                        className={classes.inputField}
                        style={{ width: "135px" }}
                        value={age}
                        // onChange={e=>setYearofExp(e.target.value)}
                        disabled
                      />
                    </Grid>
                    <Grid item style={{ width: "135px", marginLeft: "6px" }}>
                      <Typography
                        className={classes.formLabel}
                        alignSelf="flex-start"
                        style={{ fontSize: "13px" }}
                      >
                        Practice Started Year
                      </Typography>
                      <input
                        className={classes.inputField}
                        style={{ width: "135px" }}
                        type="number"
                        value={practicestartYear} 
                        // defaultValue={practicestartYear}
                        onChange={(e) => YearOFexp(e)}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Qualification
                  </Typography>
                  <input className={classes.inputField}
                    value={qualification} onChange={e => setQualification(e.target.value)}
                  />
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Speciality
                  </Typography>
                  <select name="speciality" value={speciality} onChange={handleSpeciality} variant="outlined" required={true} fullWidth className="headersearchtxt">
                    {/* <option aria-label="None" value="" disabled selected hidden>Speciality</option> */}
                    {
                      specialityData.map(data => (
                        <option value={data.name} key={data._id} className={classes.dropDown}>{data.name}</option>
                      ))
                    }
                  </select>

                  {/* <input className={classes.inputField} 
                  value={spaciality}  onChange={e=>setSpaciality(e.target.value)}
                /> */}

                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Language Spoken
                  </Typography>
                  <input className={classes.inputField} value={languageSpoken} onChange={e => setLanguageSpoken(e.target.value)} />
                </Grid>
                <Grid item container style={{ marginTop: "47px" }}>
                  <button
                    className={classes.mainButton}
                    style={{
                      width: "135px",
                      borderRadius: "6px",
                      background: "#00839B",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    style={{
                      width: "135px",
                      marginLeft: "12px",
                      height: "50px",

                      borderRadius: "6px",
                      border: "1px solid #ACB2BC",
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#ACB2BC",
                    }}
                    onClick={closeEditProfile}
                  >
                    Cancel
                  </button>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmitBankDetails}>


              <Grid
                item
                container
                style={{
                  padding: "13px 20px 42px 22px",
                }}
              >
                <Typography className={classes.formLabel} alignSelf="flex-start">
                  Account Name <span className={classes.mandatory}>*</span>
                </Typography>
                <input className={classes.inputField} value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                <Grid item container>
                  <Grid item>
                    <Typography
                      className={classes.formLabel}
                      alignSelf="flex-start"
                    >
                      Bank Name <span className={classes.mandatory}>*</span>
                    </Typography>
                    <input
                      className={classes.inputField}
                      style={{ width: "135px" }}
                      value={bankName} onChange={(e) => setBankName(e.target.value)}
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: "16px" }}>
                    <Typography
                      className={classes.formLabel}
                      alignSelf="flex-start"
                    >
                      Branch Name <span className={classes.mandatory}>*</span>
                    </Typography>
                    <input
                      className={classes.inputField}
                      style={{ width: "135px" }}
                      value={branchName} onChange={(e) => setBranchName(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item>
                    <Typography
                      className={classes.formLabel}
                      alignSelf="flex-start"
                    >
                      Account Number <span className={classes.mandatory}>*</span>
                    </Typography>
                    <input
                      className={classes.inputField}
                      style={{ width: "135px" }}
                      type="number"
                      value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: "16px" }}>
                    <Typography
                      className={classes.formLabel}
                      alignSelf="flex-start"
                    >
                      Account Type <span className={classes.mandatory}>*</span>
                    </Typography>
                    <input
                      className={classes.inputField}
                      style={{ width: "135px" }}
                      value={accountType} onChange={(e) => setAccountType(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <button
                  className={classes.mainButton}
                  style={{ background: "#00839B", marginTop: "26px" }}
                  type="submit"
                >
                  Submit
                </button>
              </Grid>
              {loading && <LoaderComponent />}
            </form>
          </>
        )}
      </Grid>

      <Toast
        style={{ marginTop: "100px" }}
        text={toastMessage}
        type={toastType}
        open={showToast}
        setClose={() => {
          setToast(false);
        }}
      />
    </>
  );
};

export default UpdateProfile;
