import React from 'react';
import tick from './icons/tick.svg';

function FailurePdf({text,subtext}) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '287px', backgroundColor: '#F0F5F5', boxShadow: '0px 0px 24px 5px rgba(218, 218, 218, 0.3)', borderRadius: '30px', border: '1px solid #A0DFC4' }}>
            <div style={{ height: '176' }}>
               
                <div style={{ color: 'red', textAlign: 'center', fontSize: '20px', lineHeight: '24px', fontWeight: 'bold', fontFamily: 'GreycliffCFBold',marginLeft:"60px",marginRight:"60px" }}>{text}</div>
                <br/>
                <div style={{ color: 'black', textAlign: 'center', fontSize: '20px', lineHeight: '24px', fontWeight: 'bold', fontFamily: 'GreycliffCFBold',marginLeft:"60px",marginRight:"60px" }}>{subtext}</div>
            </div>
        </div>
    )
}

export default FailurePdf