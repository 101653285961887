import { takeLatest, select, put, all } from "redux-saga/effects";
import axios from "axios";
import {
    getAreaForPrescription, getSearchLabnameWatcher, getSearchLabPathnameWatcher, getCityForPrescription, getCityLabWatcher, searchGenericNameForPrescription,
    searchProductNameForPrescription, getHomeserviceWatcher, medAttrSaga, getQueryHistorySaga, getRequestSaga, getAllPrescriptionSagas
} from "./cockpitSaga";
import { getAreaLabWatcher, getMedPrescriptionSaga } from "./cockpitSaga";
import { getSearchLabWatcher } from "./cockpitSaga";
import { extentAppointmentWatcher } from "./cockpitSaga";
import { changeStatusWatcher } from "./cockpitSaga";
import { getDocWatcher, getSearchRaLabWatcher } from "./cockpitSaga";
import { getPdfCaseNoteSaga } from "./cockpitSaga";
import { getAppointmentSnapshotWatcher } from "./dashboardSaga";
import { getDoctorStatOneWatcher } from "./dashboardSaga";
import { getDoctorStatTwoWatcher } from "./dashboardSaga";
import { getDoctorStatThreeWatcher } from "./dashboardSaga";
import { getDoctorStatFourWatcher } from "./dashboardSaga";
import { createSlotWatcher } from "./dashboardSaga";
import { getLoginWatcher } from "./loginSaga";
import { getOrderWatcher, getDetailsWatcher, getVisitsByPatientWatcher, getPatientsWatcher, getVisitsWatcher, getBanksWatcher, getStatisticsWatcher } from './ex-appointment-saga'

import { getFollowupWatcher, getNotification, getNotifyPopupScreenSagas } from "./cockpitSaga";
import { createCaseNoteWatcher, createPhyCaseNoteWatcher, GETCaseNoteWatcher, GETPhyNoteWatcher, GETHistroNoteWatcher, SearchICDDATA, GETRiskNoteNoteWatcher, createRiskNoteWatcher, GETRiskareaeWatcher, } from "./createCasenote";
export const getUser = state => state.loginReducer.payload;


const runouraction = function* () {
    let remoteData;
    yield axios.get("http://63.33.216.212/api/v1/user/DoctorDetails/DR-5FFE775A").then((resp) => {
        remoteData = resp.data;
    })
    yield put({ type: "SET_DATA", payload: remoteData })
}
const getDoctorDetails = function* () {
    const user = yield select(getUser);

    let doctorData;
    yield axios.get(process.env.REACT_APP_API_URL + "/user/DoctorDetails/" + user.uuid).then((resp) => {
        if (resp.data.data) {
            doctorData = resp.data.data;
        }
    })
    yield put({ type: "SET_DOCTOR", payload: doctorData })
}
const getPatientDetails = function* () {
    const user = yield select(getUser);

    let patientData;
    yield axios.get(process.env.REACT_APP_API_URL + "/booking/totalPatients/" + user.uuid,
        {
            headers: {
                'x-auth-token': user.token,
            }
        }
    ).then((resp) => {


        if (resp) {
            patientData = resp.data;
        }
    })
    yield put({ type: "SET_PATIENT", payload: patientData })
}
const getEprescription = function* () {
    const user = yield select(getUser);

    let epres;
    yield axios.get(process.env.REACT_APP_API_URL + "/ep/totalPrescription/" + user.uuid,
        {
            headers: {
                'x-auth-token': user.token,
            }
        }
    ).then((resp) => {
        if (resp) {
            epres = resp.data;
        }
    })
    yield put({ type: "SET_EPRES", payload: epres })
}
const getLabprescription = function* () {
    const user = yield select(getUser);

    let labpres;
    yield axios.get(process.env.REACT_APP_API_URL + "/ep-lab/totalPrescriptionLab/" + user.uuid,
        {
            headers: {
                'x-auth-token': user.token,
                'Content-Type': 'application/json'
            }
        }
    ).then((resp) => {

        if (resp) {
            labpres = resp.data;
        }
    })
    yield put({ type: "SET_LABPRES", payload: labpres })
}
const getNextAppointment = function* () {
    const user = yield select(getUser);
    let getNextAppointment;
    yield axios.get(process.env.REACT_APP_API_URL + "/booking/nextApointment/" + user.uuid)
        .then((resp) => {
            if (resp) {
                getNextAppointment = resp.data;
            }
        })
    yield put({ type: "SET_APPOINTMENT", payload: getNextAppointment })
}
const getAppointmentDates = function* () {
    const user = yield select(getUser);
    let getAppointmentDate;
    yield axios.get(process.env.REACT_APP_API_URL + "/booking/getdoctorAppointments/" + user.uuid)
        .then((resp) => {

            if (resp) {
                getAppointmentDate = resp.data;
            }
        })
    yield put({ type: "SET_APPOINTMENT_DATE", payload: getAppointmentDate })
}

const getAppointmentSlots = function* ({ date }) {
    const user = yield select(getUser);

    let getAppointmentSlot;
    yield axios.get(process.env.REACT_APP_API_URL + `/booking/getParticularDate/?booking_date=${date}&doctor_uuid=${user.uuid}`)
        .then((resp) => {
            if (resp) {
                getAppointmentSlot = resp.data;
            }
        })
    yield put({ type: "SET_APPOINTMENT_SLOT", payload: getAppointmentSlot })
}

function* getAsyncDataWatcher() {
    yield takeLatest("GET_ASYNC_DATA", runouraction);
}
function* getDoctorDetailsWatcher() {
    yield takeLatest("GET_DOCTOR_DETAILS", getDoctorDetails);
}
function* getPatientDetailsWatcher() {
    yield takeLatest("GET_PATIENT_STATS", getPatientDetails);
}
function* getEprescriptionDetailsWatcher() {
    yield takeLatest("GET_EPRESCRIPTION", getEprescription);
}
function* getLabprescriptionWatcher() {
    yield takeLatest("GET_LABPRESCRIPTION", getLabprescription);
}
function* getNextAppointmentWatcher() {
    yield takeLatest("GET_NEXTAPPOINTMENT", getNextAppointment);
}

function* getAppointmentDatesWatcher() {
    yield takeLatest("GET_APPOINTMENT_DATES", getAppointmentDates);
}
function* getAppointmentSlotWatcher() {
    yield takeLatest("GET_APPOINTMENT_SLOT", getAppointmentSlots);
}



export default function* rootSaga() {
    yield all([getOrderWatcher(), getDetailsWatcher(), getVisitsByPatientWatcher(), getPatientsWatcher(), getVisitsWatcher(), getBanksWatcher(), getStatisticsWatcher(), getDoctorDetailsWatcher(), getPatientDetailsWatcher(), getEprescriptionDetailsWatcher(),
    getLabprescriptionWatcher(), getCityLabWatcher(), getAreaLabWatcher(),
    getSearchLabWatcher(), getNextAppointmentWatcher(),
    getAppointmentSnapshotWatcher(), getDoctorStatOneWatcher(),
    getDoctorStatTwoWatcher(), getDoctorStatThreeWatcher(), getDoctorStatFourWatcher(), getAppointmentDatesWatcher(), getAppointmentSlotWatcher(),
    createCaseNoteWatcher(), createPhyCaseNoteWatcher(), GETCaseNoteWatcher(), GETPhyNoteWatcher(), getLoginWatcher(), createSlotWatcher(), getDocWatcher(),
    getSearchRaLabWatcher(), extentAppointmentWatcher(), changeStatusWatcher(), GETHistroNoteWatcher(), getFollowupWatcher(), SearchICDDATA(), getCityForPrescription(),
    getAreaForPrescription(), searchGenericNameForPrescription(), searchProductNameForPrescription(), GETRiskareaeWatcher(),
    GETRiskNoteNoteWatcher(), createRiskNoteWatcher(), getHomeserviceWatcher(), getSearchLabnameWatcher(), getSearchLabPathnameWatcher(), medAttrSaga(), getMedPrescriptionSaga(), getNotifyPopupScreenSagas(),
    getQueryHistorySaga(), getNotification(), getRequestSaga(), getPdfCaseNoteSaga(), getAllPrescriptionSagas()
    ]);
}
