import { takeLatest, select, put } from "redux-saga/effects";
import axios from "axios";
export const getUser = state => state.loginReducer.payload;



const getPatients = function* ({  searchQuery, pageNumber, pageLimit }) {
    try {
        
        const user = yield select(getUser);
        // let response = yield axios.get(`${process.env.REACT_APP_API_URL}/externalappointment?limit=${10}&page=${pageNumber}&search=${searchQuery}&doctor_uuid=${user.uuid}`, {
        let response = yield axios.get(`${process.env.REACT_APP_API_URL}/patient/list/from/doctall/saas?doctor_uuid=${user.uuid}&search=${searchQuery}&limit=${pageLimit ? pageLimit : 10}&page=${pageNumber}`, {
        headers: {
                'x-auth-token': user.token,
            }
        })
        if (response.data) {
            yield put({ type: "GET_PATIENT_SUCCESS", payload: response.data })
        }
        else {
            yield put({ type: "GET_PATIENT_FAILED" })
        }
    }
    catch {
        yield put({ type: "SET_TYPE_ERROR" })
    }
}

const getVisits = function* ({ pageNumber, searchQuery }) {
    try {
        const user = yield select(getUser);
        console.log(user.uuid,"gshdhfdg")
        let response = yield axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/visit/list?limit=${10}&page=${pageNumber}&search=${searchQuery}&doctor_uuid=${user.uuid}`, {
            headers: {
                'x-auth-token': user.token,
            }
        })

        if (response.data) {
            yield put({ type: "GET_VISIT_SUCCESS", visitList: response.data })
        }
        else {
            yield put({ type: "GET_VISIT_FAILED" })
        }
    }
    catch {
        yield put({ type: "SET_TYPE_ERROR" })
    }
}


const getOrder = function* ({ pageNumber, searchQuery,pageLimit }) {
    try {
        const user = yield select(getUser);
        let response = yield axios.get(`${process.env.REACT_APP_API_URL}/ep/doctor/order/list?limit=${pageLimit ? pageLimit : 10}&page=${pageNumber}&search=${searchQuery}&doctor_uuid=${user.uuid}`, {
            headers: {
                'x-auth-token': user.token,
            }
        })

        if (response.data) {
            yield put({ type: "GET_ORDERS_SUCCESS", orderList: response.data })
        }
        else {
            yield put({ type: "GET_ORDERS_FAILED" })
        }
    }
    catch {
        yield put({ type: "SET_TYPE_ERROR" })
    }
}


const getVisitsByPatient = function* ({ id }) {
    try {
        const user = yield select(getUser);
    // let response = yield axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/patientvisit/list/${id}`, {
            let response = yield axios.get(`${process.env.REACT_APP_API_URL}/patient/patientvisit/allist?patient_uuid=${id}&limit=10&page=0`, {
        headers: {
                'x-auth-token': user.token,
            }
        })

        if (response.data) {
            if (response.data && response.data.length > 0) {
                if (response.data[0].extpatientvisit && response.data[0].extpatientvisit.length > 0) {
                    yield put({ type: "GET_VISITS_BY_PATIENT_SUCCESS", visitByPatients: response.data[0].extpatientvisit })
                }
            }
        }
        else {
            yield put({ type: "GET_VISITS_BY_PATIENT_FAILED" })
        }
    }
    catch {
        yield put({ type: "SET_TYPE_ERROR" })
    }
}




const getBanksDetails = function* ({ appointmentType }) {
    try {
        const user = yield select(getUser);
        let response = yield axios.get(`${process.env.REACT_APP_API_URL}/user/profile/doctor/bank/details/${user.uuid}`, {
            headers: {
                'x-auth-token': user.token,
            }
        })

        if (response.data) {
            yield put({ type: "GET_BANKS_SUCCESS", bankDetails: response.data.profile })
        }
        else {
            yield put({ type: "GET_BANK_FAILED" })
        }
    }
    catch {
        yield put({ type: "SET_TYPE_ERROR" })
    }
}

const getSaasDoctorDetails = function* ({ appointmentType }) {
    try {
        const user = yield select(getUser);
        let response = yield axios.get(`${process.env.REACT_APP_API_URL}/user/profile/details/doctor/${user.uuid}`, {
            headers: {
                'x-auth-token': user.token,
            }
        })

        if (response.data) {
            yield put({ type: "GET_SAAS_DOCTOR_PROFILE_SUCCESS", doctorProfiles: response.data })
        }
        else {
            yield put({ type: "GET_SAAS_DOCTOR_PROFILE_FAILED" })
        }
    }
    catch {
        yield put({ type: "SET_TYPE_ERROR" })
    }
}


const getStatisticsDetails = function* ({ appointmentType }) {
    try {
        const user = yield select(getUser);
        let response = yield axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/dashboardcount/${user.uuid}`, {
            headers: {
                'x-auth-token': user.token,
            }
        })

        if (response.data) {
            yield put({ type: "GET_STATISTICS_SUCCESS", statistics: response.data })
        }
        else {
            yield put({ type: "GET_STATISTICS_FAILED" })
        }
    }
    catch {
        yield put({ type: "SET_TYPE_ERROR" })
    }
}



function* getPatientsWatcher() {
    yield takeLatest("GET_PATIENTS", getPatients);
}

function* getVisitsWatcher() {
    yield takeLatest("GET_VISITS", getVisits);
}

function* getVisitsByPatientWatcher() {
    yield takeLatest("GET_VISITS_BY_PATIENT", getVisitsByPatient);
}

function* getBanksWatcher() {
    yield takeLatest("GET_BANKS", getBanksDetails);
}

function* getStatisticsWatcher() {
    yield takeLatest("GET_STATISTICS", getStatisticsDetails);
}

function* getDetailsWatcher() {
    yield takeLatest("GET_SAAS_DOCTOR_PROFILE", getSaasDoctorDetails);
}

function* getOrderWatcher() {
    yield takeLatest("GET_ORDERS", getOrder);
}






export { getOrderWatcher, getDetailsWatcher, getPatientsWatcher, getVisitsWatcher, getBanksWatcher, getStatisticsWatcher, getVisitsByPatientWatcher }
