import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { Form, Formik, Field } from 'formik';
import PhoneInput from "react-phone-input-2";
import { useState, useEffect } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import redClose from "../../assets/images/redClose.svg";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { fn } from "moment/moment";
import { newSearchPatient, newUpdatePatient, addNewPatient, newUpdatePatient1 } from '../services/ServiceHelper';
import Toast from '../../components/Toast/index';
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from 'moment'
const useStyles = makeStyles((theme) => styles(theme));

const AddPatientMob = ({ closePat }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [pExist, setPExist] = useState(null);
  const [gender, setGender] = React.useState("");
  const userdata = useSelector((state) => state.loginReducer.payload);


  const [isPatientFound, setPatientFound] = useState(false);
  const [ExistingPatientData, setExistingPatientData] = useState()
  const [isFoundPationt, setisFoundPationt] = useState("")
  console.log(isFoundPationt, "issFoundPationt")
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  console.log(cityList,areaList,"areaList")

  const [SelecctedCityData, setSelecctedCityData] = useState()
  console.log(SelecctedCityData,"SelecctedCityData")
  const [CityId, setCityId] = useState("")
  const [fname, setFName] = useState("")
  const [mname, setMName] = useState("")
  const [lname, setLName] = useState("")
  const [email, setEmail] = useState("")
  const [dob, setDob] = useState("")
  const [bloodGroup, setBloodGroup] = useState("")
  const [city, setCity] = useState("Select")
  const [cityname, setCityName] = useState("Select")

  const [area, setArea] = useState("Select")
  console.log(area,"dzzczxc")
  const [editFlag, setEditFlag] = useState(false);

  const [phoneNewNumber, setPhoneNewNumber] = useState('')
  const authState = useSelector((state) => state.loginReducer.payload);

  console.log(ExistingPatientData && ExistingPatientData.created_by_doctor_uuid,authState.uuid,"ExistingPatientData")

  console.log(phoneNewNumber, "phoneNewNumber")

  const [doorNo, setDoorNo] = useState("")
  const [street, setStreet] = useState("")

  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const [showToast, setToast] = useState(false);

  console.log(cityList, areaList, city, "padsad");

  const handleChange = (event) => {
    setGender(event.target.value);
  };


  const [SearchMobile, setSearchMobile] = useState("")
  console.log(SearchMobile, "SearchMobile");





  const handleSearchPatient = (event) => {
    event.preventDefault()
    let res = event && event.target && event.target.elements && event.target.elements.phoneNumber && event.target.elements.phoneNumber.value
    console.log(res, "res123")
    let res1 = res.replace(/\D/g, '');
    setSearchMobile(res1)
    axios.get(`${process.env.REACT_APP_API_URL}/patient/search/${res1}`, {
      headers: {
        "x-auth-token": userdata.token
      }
    })
      .then((res) => {
        console.log(res,"dfsfsdfs")
        if(res.status == 200) {
          handleToast(true, "Successfully Fetched Patient Details", "success")
          setEditFlag(false)
          setPatientFound(true)


        }
        else {
          handleToast(true, "Patient Not Found With Provided Phone Number", "error")
          setEditFlag(true)
          setPatientFound(false)

        }
        setisFoundPationt(res.data == "" ? "no data found" : "data found",)
        setExistingPatientData(res.data)
        setFName(res.data == "" ? "" : res.data.first_name)
        setMName(res.data == "" ? "" : res.data.middle_name)
        setLName(res.data == "" ? "" : res.data.last_name)
        setEmail(res.data == "" ? "" : res.data.email)
        setGender(res.data == "" ? "" : res.data.gender)
        setDob(res && res.data && res.data.dob)
        // setSearchMobile(res && res.data == "" || undefined ? SearchMobile : res.data.mobile)
        setBloodGroup(res.data == "" ? "" : res.data.blood_group)
        setDoorNo(res.data == "" ? "" : res.data.residential_Address[0].door_no)
        setStreet(res.data == "" ? "" : res.data.residential_Address[0].street)
        setCityName(res.data == "" ? "" : res.data.residential_Address[0].city)
        setArea(res.data == "" ? "" : res.data.residential_Address[0].area)
        cityList.map((item) => {
          if (item._id == cityname) {
            setCity(item.uuid)
            fetcharea(item.uuid)
            console.log(item.uuid, "itedfm")
          }
        })
        
      })
      .catch((err) => {
        console.log(err, "err")
        setPatientFound(false)
      })
  }
  
  console.log(dob, "shbjsb")
  const PatientSubmit = async (e) => {
    e.preventDefault()
    if(ExistingPatientData && ExistingPatientData.created_by_doctor_uuid === authState.uuid){
      let payLoad = {
        "first_name": fname,
        "last_name": lname,
        "middle_name": mname,
        "blood_group": bloodGroup,
        "email": email,
        "full_name": fname + lname,
        "mobile": SearchMobile,
        "dob": dob,
        "gender": gender,
        "residential_Address": {
            "city": CityId,
            "area": area,
            "door_no": doorNo,
            "street": street
        },
        "created_by_doctor_uuid" : authState.uuid
    }
    try {
      let response = await newUpdatePatient1(payLoad, ExistingPatientData.uuid, authState.token);
      if (response.status === 200 || response.status === 201) {
        handleToast(true, '' + "New Patient Added Successfully", "success")
        setTimeout(() => {
          closePat()
        }, 1500);
      }
  }
  catch (error) {
      if (error && error.response) {
        handleToast(true, error ? error.response.data : null, "error")
      }
  }
    }
    else{
      let payLoad = {
        visited_doctor_uuid: authState.uuid,
    }
    try {
        let response = await newUpdatePatient(payLoad, ExistingPatientData.uuid, authState.token);
        if (response.status === 200 || response.status === 201) {
          handleToast(true, '' + "New Patient Added Successfully", "success")
          setTimeout(() => {
            closePat()
          }, 1500);
        }
    }
    catch (error) {
        if (error && error.response) {
          handleToast(true, error ? error.response.data : null, "error")

        } 
    }

    }
  }
  const handleSbmit = (e) => {
    e.preventDefault()
    
    let payload = {
      "first_name": fname,
      "last_name": lname,
      "middle_name": mname,
      "blood_group": bloodGroup,
      "created_by_doctor_uuid": userdata.uuid,
      "dob": dob,
      "email": email,
      "full_name": fname + lname,
      "gender": gender,
      "mobile": SearchMobile,
      "residential_Address": {
        "city": CityId,
        "area": area,
        "door_no": doorNo,
        "street": street
      },
      "visited_doctor_uuid": userdata.uuid
    }
    axios.post(`${process.env.REACT_APP_API_URL}/patient/create/from/doctall/saas`, payload, {
      headers: {
        "x-auth-token": userdata.token
      }
    })
      .then((res) => {
        handleToast(true, '' + "New Patient Added Successfully", "success")
        setTimeout(() => {
          closePat()
        }, 1500);
      })
      .catch((error) => {
        console.log(error, "erzvxcr")
        handleToast(true, error ? error.response.data : null, "error")
      })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/allcities`)
      .then((res) => {
        console.log(res, "hfgsdgd");
        setCityList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  // useEffect(() => {
  //   fetcharea()
  // }, [city])
  function fetcharea(uuid) {
    axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/getArea/${uuid}`)
      .then(res => {
        setAreaList(res.data)
        console.log(area, "area")
      }).catch(err => {
        console.log(err)
      })
  }


  useEffect(()=>{
    let CityId =cityname
    cityList.map((item) => {
          if (item._id == CityId) {
        setCity(item.uuid)
        fetcharea(item.uuid)
            console.log(item.uuid, "itedfm")
          }
        })
    
  },[cityname])

  // http://test-api.doctall.com/api/v1/patient/create/from/doctall/saas
  


  const handleToast = (visibility, messages, type) => {
    setToast(visibility)
    setToastMessage('' + messages)
    setToastType(type)
  }


  const handleCityChange = (event) => {
    console.log(event,"eventevent")
    setSelecctedCityData(event && event.target && event.target.value)
    setCityName(event && event.target && event.target.value)
    

  }
  useEffect(() => {
    setCity(SelecctedCityData && SelecctedCityData.uuid)
    setCityId(SelecctedCityData && SelecctedCityData._id)
    // setCityName(SelecctedCityData && SelecctedCityData._id)
  }, [SelecctedCityData])


  const editEventHandler = event => {
    console.log(event,"evesdfsdfdsnt")
    setCityName(event._id)
    setCity(event.uuid)
    setCityId(event._id)
  
  };





  const handleChangePhone = (value) => {
    // console.log(e,"e")
    // const  value = e
    const noSpecialCharacters = value && value.replace(/[^a-zA-Z0-9()]/g, '');
    console.log(noSpecialCharacters, "noSpecialCharacters");

    // const re = /[^a-zA-Z0-9 ]/;
    // if (value === "" || re.test(value) === true) {
    setSearchMobile(noSpecialCharacters);
    // }
    // setFolio(value.replace(/\D/g,''));

  }


  

 
  
  return (
    <Grid
      container
      direction="column"
      style={{ paddingTop: "17px", paddingLeft: "21px" }}
    >
      <Grid item container direction="column">
        <Grid item container justifyContent="space-between">
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "35px",
              letterSpacing: "0.015em",
              color: "#013C44",
            }}
          >
            Search existing patient appointment
          </Typography>
          <img
            src={dialogclose}
            alt="close button"
            style={{ marginRight: "26px" }}
            onClick={() => closePat()}
          />
        </Grid>
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "12px",
            letterSpacing: "0.015em",
            color: "#00839B",
            marginTop: "6px",
            marginBottom: "6px",
          }}
        >
          Mobile Number <span style={{ color: "red" }}>*</span>
        </Typography>
        {/* <input
        type="number"
          className={classes.inputField}
          style={{
            borderColor:
              pExist === true
                ? "#37CC8C"
                : pExist === false
                ? "#FF6E4E"
                : undefined,
          }}
          onChange={ e => setSearchMobile(e.target.value)}
        /> */}
        {/* <MuiPhoneNumber
          defaultCountry={'ng'}
          onChange={handleChangePhone}
          InputProps={{ disableUnderline: true }}
          className={classes.inputField}
          placeholder="Enter Folio number"
        // value={phoneNewNumber}
        /> */}
        <Formik
          // initialValues={initialValues}
          onSubmit={handleSearchPatient}
        >
          <Form action=""
            onSubmit={handleSearchPatient}
          >
            <Grid container>
              <Grid item xs={11}>
                {/* <div className={classes.primaryLabel}>Phone Number<span className='star-color'>*</span></div> */}

                <Field
                  as={MuiPhoneNumber}
                  name="phoneNumber"
                  defaultCountry={'ng'}
                  required
                  fullWidth variant="outlined"
                  className={classes.primaryInput2}
                />
              </Grid>
              <br />
              {/* <Grid item xs={12}> */}
              <button
                type="submit"
                className={classes.searchButton}

              >Search</button>
              {/* </Grid> */}
            </Grid>
          </Form>
        </Formik>

        {pExist === true ? (
          <Grid container style={{ marginTop: "6px", marginBottom: "26px" }}>
            <img src={checkIcon} alt="check" style={{ marginRight: "8px" }} />
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#08A05F",
              }}
            >
              Patient exist
            </Typography>
          </Grid>
        ) : pExist === false ? (
          <Grid container style={{ marginTop: "6px", marginBottom: "26px" }}>
            <img src={redClose} alt="check" style={{ marginRight: "8px" }} />
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#FF6E4E",
              }}
            >
              Patient Not exist
            </Typography>
          </Grid>
        ) : (
          <Grid
            style={{ height: "16px", marginTop: "6px", marginBottom: "26px" }}
          ></Grid>
        )}
        {/* <button
          onClick={handleSearchPatient}
          className={classes.searchButton}>Search</button> */}
      </Grid>
      {/* {isPatientFound === true ? */}
        <> 
          {isFoundPationt === "no data found" ?
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "35px",
                color: "#013C44",
                marginTop: "25px",
              }}
            >
              Add New Patient
            </Typography>
            :
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "35px",
                color: "#013C44",
                marginTop: "25px",
              }}
            >
              View Patient
            </Typography>
          }

          <hr
            style={{
              height: "0.5px",
              borderWidth: "0px",
              width: "326px",
              marginLeft: "-21px",
              background: "#00839B",
              borderColor: "#00839B",
            }}
          />
          <form onSubmit={editFlag == true ? handleSbmit : PatientSubmit}>
            <Typography className={classes.formLabel} align="left">
              Mobile Number
            </Typography>
            <PhoneInput
              // className={classes.inputField}
              style={{ color: "#013C44", fontWeight: "500", width:"286px"}}
              country="ng"
              value={SearchMobile}
              disabled={isPatientFound === true}
            />
            <Typography className={classes.formLabel} align="left">
              First Name<span className={classes.mandatory}>*</span>
            </Typography>
            <input
              value={fname}
              className={classes.inputField}
              style={{ background: "transparent" }}
              onChange={e => setFName(e.target.value)}
              disabled={isPatientFound === true}
            />
            <Typography className={classes.formLabel} align="left">
              Middle Name
            </Typography>
            <input
              value={mname}
              className={classes.inputField}
              style={{ background: "transparent" }}
              onChange={e => setMName(e.target.value)}
              disabled={isPatientFound === true}
            />
            <Typography className={classes.formLabel} align="left">
              Last Name<span className={classes.mandatory}>*</span>
            </Typography>
            <input
              value={lname}
              className={classes.inputField}
              style={{ background: "transparent" }}
              onChange={e => setLName(e.target.value)}
              disabled={isPatientFound === true}
            />
            <Typography className={classes.formLabel} align="left">
              Email<span className={classes.mandatory}>*</span>
            </Typography>
            <input
              value={email}
              className={classes.inputField}
              style={{ background: "transparent" }}
              onChange={e => setEmail(e.target.value)}
              disabled={isPatientFound === true}
            />
            <Grid container>
              <Grid item container direction="column" style={{ width: "88px" }}>
                <Typography className={classes.formLabel} align="left">
                  Gender<span className={classes.mandatory}>*</span>
                </Typography>

                <Select
                  value={gender}
                  onChange={handleChange}
                  className={classes.gender}
                  disableUnderline={true}
                  disabled={isPatientFound === true}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="others">Others</MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ marginLeft: "6px", width: "103px" }}
              >
                <Typography className={classes.formLabel} align="left">
                  DOB<span className={classes.mandatory}>*</span>
                </Typography>
                <input
                  type="date"
                  value={moment(dob).format('YYYY-MM-DD')}
                  // value="2018-07-22"
                  // defaultValue={moment(ExistingPatientData && ExistingPatientData.dob).format('DD/MM/YYYY')}
                  className={classes.gender}
                  style={{ background: "transparent", width: "103px" }}
                  onChange={e => setDob(e.target.value)}
                  disabled={isPatientFound === true}
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ marginLeft: "6px", width: "83px" }}
              >
                <Typography className={classes.formLabel} align="left">
                  BloodGroup<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  value={bloodGroup}
                  onChange={e => setBloodGroup(e.target.value)}
                  className={classes.gender}
                  disableUnderline={true}
                  style={{ width: "83px" }}
                  disabled={isPatientFound === true}
                >
                  <MenuItem value="">Select..</MenuItem>
                  <MenuItem value="Unknown">Unknown</MenuItem>
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="O+">O+</MenuItem>
                  <MenuItem value="O-">O-</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="female">AB-</MenuItem>


                </Select>
              </Grid>
              <Grid
                item
                container
                direction="column"
              style={{ marginLeft: "0px", width: "140px" }}
              >
                <Typography className={classes.formLabel} align="left">
                  City
                </Typography>
                <Select
                  value={cityname}
                  // defaultValue={cityname}
                  // onChange={ e => setCity(e.target.value)}
                  // onClick={(event) => handleCityChange(event)}
                  className={classes.gender}
                  disableUnderline={true}
                  disabled={isPatientFound === true}
                style={{ width: "140px" }}
                >
                  <MenuItem value="Select">Select..</MenuItem>
                  {cityList.map((city) => {
                    console.log(city,"sdfsdf")
                    return <MenuItem value={city._id} onClick={() => editEventHandler(city)}>{city.name}</MenuItem>
                  })}
                </Select>
              </Grid>


         

              <Grid
                item
                container
                direction="column"
              style={{ marginLeft: "6px", width: "140px" }}
              >
                <Typography className={classes.formLabel} align="left">
                  Area
                </Typography>
                <Select
                  value={area}
                  onChange={e => setArea(e.target.value)}
                  className={classes.gender}
                  disableUnderline={true}
                  disabled={isPatientFound === true}
                style={{ width: "140px" }}
                >
                  <MenuItem value="Select">Select..</MenuItem>
                  {areaList.map((area) => {
                    return <MenuItem value={area._id}>{area.name}</MenuItem>
                  })}

                </Select>
              </Grid>





            </Grid>

            <Grid container>
              {/* <Grid item container style={{ width: "135px" }}>
          <Typography className={classes.formLabel} align="left">
            City
          </Typography>
          <input
          value={city}
           onChange={ e => setCity(e.target.value)}
            className={classes.inputField}
            style={{ background: "transparent", width: "135px" }}
            
          />
        </Grid> */}
              {/* <Grid item container style={{ width: "135px", marginLeft: "6px" }}>
          <Typography className={classes.formLabel} align="left">
            Area
          </Typography>
          <input
          value={area}
           onChange={ e => setArea(e.target.value)}
            className={classes.inputField}
            style={{ background: "transparent", width: "135px" }}
          />
        </Grid> */}
            </Grid>
            <Grid container>
              <Grid item container style={{ width: "76px" }}>
                <Typography className={classes.formLabel} align="left">
                  Door No
                </Typography>
                <input
                  value={doorNo}
                  onChange={e => setDoorNo(e.target.value)}
                  className={classes.inputField}
                  style={{ background: "transparent", width: "76px" }}
                  disabled={isPatientFound === true}
                />
              </Grid>
              <Grid item container style={{ width: "204px", marginLeft: "6px" }}>
                <Typography className={classes.formLabel} align="left">
                  Street
                </Typography>
                <input
                  value={street}
                  onChange={e => setStreet(e.target.value)}
                  className={classes.inputField}
                  style={{ background: "transparent", width: "204px" }}
                  disabled={isPatientFound === true}
                />
              </Grid>
            </Grid>
            <button
              className={classes.searchButton}
              style={{ marginTop: "38px", marginBottom: "29px" }}
              type="submit"
            >
              Submit
            </button>
          </form>
        </>
      {/* //   : null
      // } */}

      <Toast
        text={toastMessage}
        type={toastType}
        open={showToast}
        setClose={() => {
          setToast(false);
        }}
      />


    </Grid>
  );
};

export default AddPatientMob;