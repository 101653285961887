import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import { DashboardpatientColumns } from '../components/TableColumn'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../dashboardstyles/dashboardcontent.css'
import { Dialog } from '@material-ui/core';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import OpenWithIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import AddPatientComponent from './AddPatientComponent'
import { getVisitsByPatientId, getPatients } from "../../redux/actions/ex-appointment-action";
import { customStyles } from '../dashboardstyles/table-style/tableCustomStyle';
import AddPatientComponent1 from "./visits/AddVisitFromPatient"
import ViewPatientComponent from "./ViewPatientComponent"

export default function DashbordPatients() {
    let history = useHistory();
    const dispatch = useDispatch();
    const patientsState = useSelector((state) => state.PatientSnapshot);
    const [addPatientModel, setAddPatientModel] = useState(false);
    const [patientDetail, setPatientDetails] = useState();
    const [diffId, setDiffId] = useState("ADD");
    const [patientId, setPatientId] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [addPatientModel1, setAddPatientModel1] = useState(false);
    const [maxWidth, setMaxwidth] = React.useState('sm')

    const [viewPatientModel, setViewPatientModel] = useState(false);



    useEffect(() => {
        try {
            let id = patientsState && patientsState.payload && patientsState.payload.data && patientsState.payload.data[0] && patientsState.payload.data[0].uuid;
            console.log(patientsState && patientsState.payload && patientsState.payload.data && patientsState.payload.data[0] && patientsState.payload.data[0].uuid, "89898989")
            let ptId = patientId && patientId ? patientId : id
            dispatch(getVisitsByPatientId(ptId))
        } catch (error) {
            console.log(error)
        }

    }, [patientId])


    useEffect(() => {
        try {
            dispatch(getPatients(pageNumber, "", 10))
        } catch (error) {
            console.log(error)
        }

    }, [])
    //new data

    const handlePatientsView1 = (patientDetails) => {
        setPatientDetails(patientDetails)
        setDiffId("EDIT")
        setViewPatientModel(true)
    }

    const mergedColumns = DashboardpatientColumns.map(col => {
        if (!col.editable) {
            return col;
        }
    });

    const createColumns = useCallback(() => {
        return [
            ...mergedColumns,
            {
                name: 'Action',
                allowOverflow: true,
                minWidth: '100px',
                cell: row => {
                    return (
<>
<Grid item container>
<Grid md={2} xs={2} sm={2} lg={2} xl={2} >
<OpenWithIcon style={{ cursor: 'pointer' }} onClick={() => handlePatientsView1(row)} > </OpenWithIcon>
</Grid>
<Grid md={10} sm={10} lg={10} xl={10}>
<button className='edite_btn1' onClick={() => handlePatientsAdd(row)} >Add Visit</button>
</Grid>

</Grid>
{/* <div class="row">
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-6">
                           <OpenWithIcon style={{ cursor: 'pointer' }} onClick={() => handlePatientsView1(row)} > </OpenWithIcon>
                         
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-6 ">
                        <button className='edite_btn1' onClick={() => handlePatientsAdd(row)} >Add Visit</button>
                        </div>
           
                        </div> */}
</>
                    )
                   
                },

            },

        ];
    }, [mergedColumns]);


    const paginationComponentOptions = {
        rangeSeparatorText: 'of',
        noRowsPerPage: true,
    };


    const handlePageChange = page => {
        setPageNumber(page - 1)
    };

    const handlePatientsView = (patientDetails) => {
        console.log(patientDetails.uuid, "enter")
        setPatientId(patientDetails.uuid)
    }
    const handlePatientsAdd = (patientDetails) => {
        setPatientDetails(patientDetails)
        setAddPatientModel1(true)
    }
    const MoveToPatient = () => {
        history.push({
            pathname: '/patients',
        });
    }
    return (
        <>
            <Grid item xs={12}>
                <div className="dashbord_content_container121">
                    <div className='dashbord_content_head'>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <h2 className='dashbord_head_heading'>Recent Patients</h2>
                            </Grid>
                            {/* <Grid item xs={5}>
                                <button className='edite_btn' onClick={() => {
                                    setAddPatientModel(true);
                                    setPatientDetails({});
                                    setDiffId('Add')

                                }} >Add New Patient +</button>
                            </Grid> */}
                            <Grid item xs={2} className='See_all' style={{marginTop:"2%"}} onClick={MoveToPatient}>See all</Grid>
                        </Grid>
                    </div>

                    {/* <table class="table border shadow" > */}
                    <DataTable
                        className="snapcontainer2"
                        columns={createColumns()}
                        customStyles={customStyles}
                        data={patientsState && patientsState.payload && patientsState.payload.data}
                    //   pagination 
                    // paginationServer
                    // paginationComponentOptions={paginationComponentOptions}
                    // paginationTotalRows={patientsState && patientsState.payload && patientsState.payload.total}
                    // onChangePage={handlePageChange}
                    />

                    {/* </table> */}

                </div>
                <Dialog
                    disableBackdropClick
                    open={addPatientModel}
                    onClose={() => setAddPatientModel(false)}
                >

                    <DialogContent>
                        <AddPatientComponent
                            id={diffId}
                            close={(event) => setAddPatientModel(false)}
                            details={patientDetail}
                            getPnt={() => getPatients(0, "",10)}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    disableBackdropClick
                    open={addPatientModel1}
                    onClose={() => setAddPatientModel1(false)}
                    maxWidth={maxWidth}
                >
                    <DialogContent>
                        <AddPatientComponent1
                            id={diffId}
                            close={() => setAddPatientModel1(false)}
                            details={patientDetail}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    disableBackdropClick
                    open={viewPatientModel}
                    onClose={() => setViewPatientModel(false)}
                    // fullScreen={fullScreen}
                    maxWidth={maxWidth}
                // style={{width:"20px"}}
                >

                    <DialogContent>
                        <ViewPatientComponent
                            id={diffId}
                            close={(event) => setViewPatientModel(false)}
                            details={patientDetail}
                        />
                    </DialogContent>
                </Dialog>
            </Grid>
        </>
    );
};



