const patients = [
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },

  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },

  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
  {
    date: "March 15, 2022",
    name: "Samiat Oba",
    dob: "10/9/1990",
    email: "Poo@doctgmail.com",
    mobile: "+234 905 764 9234",
    gender: "male",
    bloodgroup: "O+ve",
    city: "Lagos",
    area: "Victoria Island",
    doorno: "No 13",
  },
];

export default patients;
