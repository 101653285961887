import React from 'react';
import Layout from '../newlayout';
import DoctorProfileContent from './doctorProfileContent';

export const Doctor = () => {
    return (
        <Layout>
            <DoctorProfileContent />
        </Layout>
    )
}