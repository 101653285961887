import React, { useEffect } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  Fab,
} from "@material-ui/core";
import ibutton from "../../assets/images/ibutton.svg";
import icon11 from "../../assets/images/Icon11.svg";
import downArrow from "../../assets/images/downArrow.svg";
import floatOpen from "../../assets/images/floatOpen.svg";
import floatClose from "../../assets/images/floatClose.svg";
import addpatientmenu from "../../assets/images/addPatientmenu.svg";
import prescription from "../../assets/images/prescription.svg";
import diagOrder from "../../assets/images/diagOrder.svg";
import messagept from "../../assets/images/messagept.svg";

import style from "./style";
import { useState } from "react";
import DialogLayout from "./components/DialogLayout";
import AddPatientMob from "./AddPatientMob";
import MessagePatient from "./MessagePatient";
import DiagnosticPres from "./DiagnosticPres";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FloatingIcon from "../mobileComp/FloatingIcons/FloatingIcon"



const useStyles = makeStyles((theme) => style(theme));

const DoctorDataMob = (props) => {
  const [floatMenu, setFloatMenu] = useState(false);
  const [addPatient, setAddPatient] = useState(false);
  const [messagePatient, setMessagePatient] = useState(false);
  const [diagnostic, setDiagnostic] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  const patientsState = useSelector((state) => state.PatientSnapshot);
  const orderState = useSelector((state) => state.PatientSnapshot);
  const visitState = useSelector((state) => state.PatientSnapshot);
  var totalData = props && props.count_earned;
  var totalAppointments =
    visitState && visitState.visitList && visitState.visitList.total;
  var totalOrder =
    orderState && orderState.orderList && orderState.orderList.total;
  var totalPatients =
    patientsState && patientsState.payload && patientsState.payload.total;
  const authState = useSelector((state) => state.loginReducer.payload);
  console.log(authState, "authState");
  const [allCount, setAllCount] = useState();

  useEffect(() => {
    getCount();
  }, []);

  const getCount = () => {
    let endPoint = `/ep/dashboardcount/${authState.uuid}`;

    axios
      .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
        headers: {
          "x-auth-token": authState.token,
        },
      })
      .then((res) => {
        if (res.data) {
          setAllCount(res && res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid
      container
      direction="column"
      className={classes.dashboardContainer}
      style={{ paddingBottom: "79px" }}
    >
      <Typography
        style={{
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "18px",
          color: "#66B5C3",
          marginTop: "17px",
          marginBottom: "18px",
          lineHeight: "23px",
          fontFamily: "Visuelt",
        }}
      >
        Hi, Dr. {authState.firstName}
      </Typography>
      <Grid container>
        <Grid item className={classes.dataCard} style={{ marginRight: "16px" }}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ marginBottom: "8px" }}
          >
            <Typography
              style={{
                width: "86px",
                height: "40px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#667085",
                lineHeight: "16px",
                fontFamily: "Visuelt Pro",
              }}
            >
              No. of Appointments
            </Typography>
            <img src={ibutton} alt="ibutton" />
          </Grid>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "30px",
              color: "#101828",
              lineHeight: "38px",
              fontFamily: "Visuelt Pro",
            }}
          >
            {totalAppointments}
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              background: "#ECFDF3",
              color: "#027A48",
              width: "60px",
              height: "24px",
              borderRadius: "16px",
              padding: "2px 8px 2px 10px",
              marginTop: "10px",
            }}
          >
            <img src={icon11} alt="rise" />
            <Typography
              style={{
                marginLeft: "4.5px",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",

                lineHeight: "20px",
                fontFamily: "Visuelt Pro",
              }}
            >
              10%
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.dataCard}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ marginBottom: "8px" }}
          >
            <Typography
              style={{
                width: "86px",
                height: "40px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#667085",
                lineHeight: "16px",
                fontFamily: "Visuelt Pro",
              }}
            >
              No. of Orders
            </Typography>
            <img src={ibutton} alt="ibutton" />
          </Grid>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "30px",
              color: "#101828",
              lineHeight: "38px",
              fontFamily: "Visuelt Pro",
            }}
          >
            {totalOrder}
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              background: "#ECFDF3",
              color: "#027A48",
              width: "60px",
              height: "24px",
              borderRadius: "16px",
              padding: "2px 8px 2px 10px",
              marginTop: "10px",
            }}
          >
            <img src={icon11} alt="rise" />
            <Typography
              style={{
                marginLeft: "4.5px",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",

                lineHeight: "20px",
                fontFamily: "Visuelt Pro",
              }}
            >
              12%
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "16px" }}>
        <Grid item className={classes.dataCard} style={{ marginRight: "16px" }}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ marginBottom: "8px" }}
          >
            <Typography
              style={{
                width: "86px",
                height: "40px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#667085",
                lineHeight: "16px",
                fontFamily: "Visuelt Pro",
              }}
            >
              No. of Patients
            </Typography>
            <img src={ibutton} alt="ibutton" />
          </Grid>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "30px",
              color: "#101828",
              lineHeight: "38px",
              fontFamily: "Visuelt Pro",
            }}
          >
            {totalPatients}
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              background: "#ECFDF3",
              color: "#027A48",
              width: "60px",
              height: "24px",
              borderRadius: "16px",
              padding: "2px 8px 2px 10px",
              marginTop: "10px",
            }}
          >
            <img src={icon11} alt="rise" />
            <Typography
              style={{
                marginLeft: "4.5px",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",

                lineHeight: "20px",
                fontFamily: "Visuelt Pro",
              }}
            >
              12%
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.dataCard}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ marginBottom: "8px" }}
          >
            <Typography
              style={{
                width: "86px",
                height: "40px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#667085",
                lineHeight: "16px",
                fontFamily: "Visuelt Pro",
              }}
            >
              No. of Lab Test Generated
            </Typography>
            <img src={ibutton} alt="ibutton" />
          </Grid>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "30px",
              color: "#101828",
              lineHeight: "38px",
              fontFamily: "Visuelt Pro",
            }}
          >
            {allCount && allCount.totalLabTest}
            {/* {totalData && totalData.totalLabTest} */}
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              background: "#FEF3F2",
              color: "#B42318",
              width: "60px",
              height: "24px",
              borderRadius: "16px",
              padding: "2px 8px 2px 10px",
              marginTop: "10px",
            }}
          >
            <img src={downArrow} alt="rise" />
            <Typography
              style={{
                marginLeft: "4.5px",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "20px",
                fontFamily: "Visuelt Pro",
              }}
            >
              2%
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "16px", width: "328px" }}>
        <Carousel
          showArrows={true}
          showIndicators={false}
          showStatus={false}
          swipeable={true}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
        >
          <Grid
            item
            container
            direction="column"
            className={classes.earningsCard}
            style={{ marginRight: "24px" }}
          >
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
              style={{ marginBottom: "11px" }}
            >
              <Typography
                style={{
                  width: "86px",
                  height: "24px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#101828",
                  lineHeight: "24px",
                  fontFamily: "Visuelt Pro",
                }}
              >
                Earnings
              </Typography>
              <img
                src={ibutton}
                alt="ibutton"
                style={{ width: "16px", height: "16px" }}
              />
            </Grid>
            <Typography
              style={{
                height: "24px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#667085",
                lineHeight: "16px",
                fontFamily: "Visuelt Pro",
              }}
            >
              Current Balance
            </Typography>
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "30px",
                color: "#101828",
                lineHeight: "38px",
                fontFamily: "Visuelt Pro",
              }}
            >
              ₦{allCount && allCount.earned}
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                background: "#ECFDF3",
                color: "#027A48",
                width: "60px",
                height: "24px",
                borderRadius: "16px",
                padding: "2px 8px 2px 10px",
                marginTop: "10px",
              }}
            >
              <img
                src={icon11}
                alt="rise"
                style={{ width: "7px", height: "7px" }}
              />
              <Typography
                style={{
                  marginLeft: "4.5px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",

                  lineHeight: "20px",
                  fontFamily: "Visuelt Pro",
                }}
              >
                12%
              </Typography>
            </Grid>{" "}
          </Grid>
      
          <Grid
            item
            container
            direction="column"
            className={classes.earningsCard}
            style={{ marginRight: "24px" }}
          >
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
              style={{ marginBottom: "11px" }}
            >
              <Typography
                style={{
                  width: "86px",
                  height: "24px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#101828",
                  lineHeight: "24px",
                  fontFamily: "Visuelt Pro",
                }}
              >
                Outstanding Payments
              </Typography>
              <img
                src={ibutton}
                alt="ibutton"
                style={{ width: "16px", height: "16px" }}
              />
            </Grid>
            <Typography
              style={{
                height: "24px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#667085",
                lineHeight: "16px",
                fontFamily: "Visuelt Pro",
                paddingTop: "10px",
              }}
            >
              Current Balance
            </Typography>
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "30px",
                color: "#101828",
                lineHeight: "38px",
                fontFamily: "Visuelt Pro",
              }}
            >
              ₦{allCount && allCount.outstanding}
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                background: "#ECFDF3",
                color: "#027A48",
                width: "60px",
                height: "24px",
                borderRadius: "16px",
                padding: "2px 8px 2px 10px",
                marginTop: "10px",
              }}
            >
              <img
                src={icon11}
                alt="rise"
                style={{ width: "7px", height: "7px" }}
              />
              <Typography
                style={{
                  marginLeft: "4.5px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",

                  lineHeight: "20px",
                  fontFamily: "Visuelt Pro",
                }}
              >
                12%
              </Typography>
            </Grid>{" "}
          </Grid>
        </Carousel>
      </Grid>
      <Grid className={classes.sideHeading}>
        <Typography
          style={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "Visuelt Pro",
            color: "#00839B",
          }}
        >
          This Week
        </Typography>
      </Grid>
      <Grid container className={classes.statisticsCard}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderBottom: "0.5px solid #00839B",
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          <Typography>Lab Test Advice</Typography>
          <Typography>
            {allCount && allCount.totalLabTestCurrentWeek}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderBottom: "0.5px solid #00839B",
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          {" "}
          <Typography>Medicine Prescription</Typography>
          <Typography>
            {allCount && allCount.totalMedicieneCurrentWeek}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderBottom: "0.5px solid #00839B",
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          <Typography>Medicine Ordered</Typography>
          <Typography>
            {allCount && allCount.totalMedicieneOrderCurrentWeek}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          <Typography>DMP Order</Typography>
          <Typography>
            {allCount && allCount.totalLabTestOrderCurrentWeek}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.sideHeading} style={{ width: "86px" }}>
        <Typography
          style={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "Visuelt Pro",
            color: "#00839B",
          }}
        >
          This Month
        </Typography>
      </Grid>
      <Grid container className={classes.statisticsCard}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderBottom: "0.5px solid #00839B",
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          <Typography>Lab Test Advice</Typography>
          <Typography>{allCount && allCount.totalLabTestThisMonth}</Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderBottom: "0.5px solid #00839B",
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          {" "}
          <Typography>Medicine Prescription</Typography>
          <Typography>
            {" "}
            {allCount && allCount.totalMedicieneThisMonth}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderBottom: "0.5px solid #00839B",
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          <Typography>Medicine Ordered</Typography>
          <Typography>
            {allCount && allCount.totalLabTestOrderCurrentWeek}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            paddingLeft: "16px",
            paddingRight: "26px",
          }}
        >
          <Typography>DMP Order</Typography>
          <Typography>
            {allCount && allCount.totalLabTestOrderthisMonth}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "35px" }}>
        <Grid
          item
          container
          direction="column"
          className={classes.weekIncomeCard}
          alignItems="center"
        >
          <Typography
            style={{
              color: "#FF6E4E",
            }}
            className={classes.incomeHeading}
          >
            Income This Week
          </Typography>
          <hr
            style={{
              borderTop: "0.3px solid #FFBEB2",
              height: "0px",
              width: "100%",
            }}
          />
          <Typography className={classes.incomeMain}>
            {" "}
            ₦ {allCount && allCount.incomeThisWeek}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          className={classes.monthIncomeCard}
          alignItems="center"
        >
          <Typography
            style={{
              color: "#37CC8C",
            }}
            className={classes.incomeHeading}
          >
            Income This Month
          </Typography>
          <hr
            style={{
              borderTop: "0.3px solid #37CC8C",
              height: "0px",
              width: "100%",
            }}
          />
          <Typography
            className={classes.incomeMain}
            style={{ color: "#37CC8C" }}
          >
            ₦ {allCount && allCount.incomeThisMonth}
          </Typography>
        </Grid>
      </Grid>




      {/* floating icons start */}
      {/* <Grid
        style={{
          position: "fixed",
          top: floatMenu ? "400px" : "600px",
          right: "23px",
        }}
      >
        {floatMenu ? (
          <Grid container direction="column" alignItems="flex-end">
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setAddPatient(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Find /Add a Patient
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#00839B" }}
                // onClick={() => setAddPatient(true)}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#66B5C3" }}
                >
                  <img src={addpatientmenu} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setDiagnostic(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Create a Prescription
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#37CC8C" }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#76DCB0" }}
                  // onClick={() => setDiagnostic(true)}
                >
                  <img src={prescription} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setDiagnostic(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Create a Diagnostic Order
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#013C44" }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#3D636A" }}
                  // onClick={() => setDiagnostic(true)}
                >
                  <img src={diagOrder} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setMessagePatient(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Message Patient
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#FF5C3C" }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#FF8D77" }}
                  // onClick={() => setMessagePatient(true)}
                >
                  <img src={messagept} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img
                src={floatClose}
                alt="close"
                onClick={() => setFloatMenu(false)}
              />
            </Grid>
          </Grid>
        ) : (
          <img
            src={floatOpen}
            alt="floatOpen"
            onClick={() => setFloatMenu(true)}
          />
        )}
      </Grid> */}
      <FloatingIcon/>




      {addPatient ? (
        <DialogLayout open={addPatient}>
          <AddPatientMob closePat={() => setAddPatient(false)} />
        </DialogLayout>
      ) : null}
      {messagePatient ? (
        <DialogLayout open={messagePatient} height="556px">
          <MessagePatient closeMessage={() => setMessagePatient(false)} />
        </DialogLayout>
      ) : null}
      {diagnostic ? (
        <DialogLayout open={diagnostic}>
          <DiagnosticPres closeDiag={() => setDiagnostic(false)} />
        </DialogLayout>
      ) : null}
    </Grid>
  );
};

export default DoctorDataMob;
