import React, { useEffect, useState } from "react";
import regLogo from "../../../assets/images/regLogo.svg";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  Checkbox,
} from "@material-ui/core";
import styles from "../style";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import lock from "../../../Admin/images/icons/lock.svg";

import SignUpForm from "./SignUpFormMob";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginData,
  getLoginLoading,
  Clear,
} from "../../../redux/actions/loginAction";
import Toast from "../../../components/Toast";
const useStyles = makeStyles((theme) => styles(theme));

const Login = () => {
  // const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const customSM = useMediaQuery("(max-width:800px)");
  const userdata = useSelector((state) => state.loginReducer.payload);
  const userss = useSelector((state) => state.loginReducer);

  let history = useHistory();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  // const classes2 = useStyle2();
  const [showPassword, setShowPassword] = React.useState(true);
  const [loginError, setLoginError] = React.useState(false);
  const [errormsg, setErrorMSg] = React.useState("");
  const [showToast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.elements.email.value;
    const password = e.target.password.value;
    const data = {
      username: username,
      password: password,
    };
    dispatch(getLoginData(data));
    console.log(data);
  };
  useEffect(() => {
    dispatch(getLoginLoading());
  }, []);

  useEffect(() => {
    if (userdata) {
      if (userdata.token) {
        let doctorType =
          userdata.profile &&
          userdata.profile.doctor_type &&
          userdata.profile.doctor_type
            ? userdata.profile.doctor_type
            : "N/A";
        if (doctorType === "advice") {
          history.push("/doctor-dashboard");
        } else {
          history.push("/");
        }
        setLoginError(false);
      } else {
        if (userdata === "Invalid username or password!") {
          handleToast(true, "" + "Invalid username or password!", "error");
          setErrorMSg(userdata);
        } else if (userdata === "Your account have been suspended!") {
          setActive(true);
        } else {
          setErrorMSg(userdata.message);
        }
        setLoginError(true);
      }
    } else {
      setLoginError(true);
    }
  }, [userdata]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToast = (visibility, messages, type) => {
    console.log(visibility, messages, type, "typesfdfdsf");
    setToast(visibility);
    setToastMessage("" + messages);
    setToastType(type);
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingTop: "48px" }}
    >
      <img src={regLogo} alt="logo" />
      <Typography className={classes.regTitle} style={{ marginTop: "28px" }}>
        Welcome
      </Typography>
      <Typography className={classes.regSubTitle} style={{ marginTop: "8px" }}>
        Welcome to Doctall Patient Portal
      </Typography>
      <Grid
        item
        container
        alignItems="center"
        style={{ width: "328px", marginTop: "28px" }}
        direction="column"
      >
        <Grid align="center">
          <p style={{ color: "red" }}>{errormsg}</p>
        </Grid>

        <form action="" onSubmit={handleSubmit}>
          <Typography className={classes.regLabel} align="left">
            Email Address
          </Typography>
          <input
            type="text"
            className={classes.regInput}
            placeholder="Enter Email Address"
            // fullWidth
            name="email"
            // variant="outlined"
            // className={classes.primaryInput}
            // placeholder="E-mail"
          />
          <Typography className={classes.regLabel} align="left">
            Passcode
          </Typography>
          {/* <input
         name="password"
         type={showPassword ? "password" : "text"}
          className={classes.regInput}
          placeholder="Passcode"
         
        /> */}

          <TextField
            fullWidth
            name="password"
            // variant="outlined"
            // InputProps={{ disableUnderline: true }}
            type={showPassword ? "password" : "text"}
            className={classes.regInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={lock}
                    alt="lock"
                    style={{ cursor: "pointer", marginRight: 22 }}
                    onClick={handleClickShowPassword}
                    crossorigin="anonymous"
                  />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            placeholder="Password"
          />
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ marginTop: "16px" }}
          >
            <Grid
              item
              container
              alignItems="center"
              style={{ marginLeft: "-12px" }}
            >
              <Checkbox
                size="small"
                // color="primary"
                sx={{
                  color: "#B4331A",
                  padding: "0px",
                  "& .Mui-checked": {
                    color: "blue",
                  },
                }}
              />
              <Typography
                style={{
                  fontFamily: "Visuelt",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                }}
              >
                Remember for 30 days
              </Typography>
              <Typography
                className={classes.uploadInfo}
                align="right"
                style={{
                  color: "#B4331A",
                  cursor: "pointer",
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginTop: "0px",
                  marginLeft: "39px",
                }}
                onClick={() => {
                  history.push("/forgot_password");
                }}
              >
                Forgot Password
              </Typography>
            </Grid>
          </Grid>
          <button
            className={classes.regButton}
            style={{ marginTop: "20px" }}
            type="submit"
            //onClick={() => setScreen("certificate")}
          >
            Sign In
          </button>
        </form>
        <Typography
          className={classes.uploadInfo}
          align="center"
          style={{ fontWeight: "500", marginTop: "32px" }}
        >
          Don’t have an account?{" "}
          <span
            style={{ color: "#B4331A", cursor: "pointer" }}
            onClick={() => {
              history.push("/doctor-registration");
            }}
          >
            Sign up
          </span>
        </Typography>
      </Grid>
      <Toast
        style={{ marginTop: "100px" }}
        text={toastMessage}
        type={toastType}
        open={showToast}
        setClose={() => {
          setToast(false);
        }}
      />
    </Grid>
  );
};

export default Login;
