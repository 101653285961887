import React from 'react';
import tick from './icons/tick.svg';

function CreateSlotSuccess() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '200px', backgroundColor: '#F0F5F5', boxShadow: '0px 0px 24px 5px rgba(218, 218, 218, 0.3)', borderRadius: '30px', border: '1px solid #A0DFC4' }}>
            <div style={{  height: '176' }}>
                <div style={{ color: '#013C44', textAlign: 'center', fontSize: '20px', lineHeight: '24px', fontWeight: 'bold', fontFamily: 'GreycliffCFBold', }}>Slot with same day and time already exists!</div>
            </div>
        </div>
    )
}

export default CreateSlotSuccess
