import React, { useEffect, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getOrders } from "../../redux/actions/ex-appointment-action";
import {
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import "../dashboardstyles/dashboardstyle.css";
import { orderColumns } from "../components/TableColumn";
import { customStyles } from "../dashboardstyles/table-style/tableCustomStyle";
import Toast from "../../components/Toast";
import Layout from "../../Dashboard/extlayout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import ViewOrderModel from "../components/ViewOrderModel";
import Search from "./../../assets/images/search.svg";
import moment from "moment";
import axios from "axios";
import Close from "../../assets/images/close.svg";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import MobLayout from "../../Dashboard/mobLayout";
import OrdersComp from "../mobileComp/OrdersComp";

export default function DoctorOrders() {
  const theme = useTheme();
  const customSM = useMediaQuery("(max-width:800px)");
  let history = useHistory();
  const dispatch = useDispatch();
  const orderState = useSelector((state) => state.PatientSnapshot);
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const [showToast, setToast] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [orderModel, setOrderModel] = useState(false);
  const [orderDetail, setOrderDetails] = useState();
  const [closepdf, setClosePdf] = useState(false);
  const [openpdf, setOpenPdf] = useState(false);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState([]);
  const [url1, setUrl1] = useState();
  const [orderId, setOrderId] = useState();
  const [addPatientModel, setAddPatientModel] = useState(false);

  const authState = useSelector((state) => state.loginReducer.payload);
  console.log(orderState, "orderState");
  const failurePdfOpen = () => {
    setClosePdf(true);
  };

  const failurePdfClose = () => {
    setClosePdf(false);
  };

  const handlePdfOpen = () => {
    setOpenPdf(true);
  };

  const handlePdfClose = () => {
    setOpenPdf(false);
  };

  useEffect(() => {
    dispatch(getOrders(pageNumber, searchQuery));
  }, [pageNumber, "", 10]);

  const mergedColumns = orderColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
  });

  const createColumns = useCallback(() => {
    return [
      ...mergedColumns,
      {
        name: "Action",
        allowOverflow: true,
        minWidth: "100px",
        cell: (row) => {
          console.log(row.order[0], "45st");
          setOrderId(row && row.order[0] && row.order[0].uuid);
          if (row && row.order[0] && row.order[0].status == "completed") {
            return (
              <RemoveRedEyeIcon
                style={{ cursor: "pointer", color: "#046673" }}
                onClick={() => {
                  handleOrder(row);
                }}
              >
                View
              </RemoveRedEyeIcon>
            );
          }
        },
      },
    ];
  }, [mergedColumns]);
  // order/ordertracking/byOrderId?orderId=DOC-7FBB16B19649
  const handleOrder = (item) => {
    // console.log()
    const OrderId1 = item && item.order && item.order[0] && item.order[0].uuid;
    let endPoint = `/order/ordertracking/byOrderId?orderId=${OrderId1}`;

    axios
      .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
        headers: {
          "x-auth-token": authState.token,
        },
      })
      .then((res) => {
        // console.log(res && res.data[0] && res.data[0].fullOrderDetails[0].final_action.result[0].url)
        // console.log(res && res.data[0] && res.data[0].fullOrderDetails[0] && res.data[0].fullOrderDetails[0].final_action && res.data[0].fullOrderDetails[0].final_action.result[0] && res.data[0].fullOrderDetails[0].final_action.result[0].url, "order-res")
        if (res.data) {
          // handlePdfOpen();
          setAddPatientModel(true);
          // setUrl(res && res.data[0] && res.data[0].fullOrderDetails[0] && res.data[0].fullOrderDetails[0].final_action && res.data[0].fullOrderDetails[0].final_action.result[0] && res.data[0].fullOrderDetails[0].final_action.result[0].url);
          setUrl(res && res.data[0] && res.data[0].fullOrderDetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOrder1 = (item) => {
    console.log(item.final_action.result[0].url);
    const OrderId1 =
      item &&
      item.final_action &&
      item.final_action.result[0] &&
      item.final_action &&
      item.final_action.result[0].url;

    // handlePdfOpen();
    // setOpen(true)
    window.open(OrderId1, "_blank");
    setUrl1(OrderId1);
  };

  console.log(
    orderColumns.map((col) => {}),
    "5555"
  );
  const handleOrderView = (details) => {
    setOrderDetails(details);
    setOrderModel(true);
  };

  const paginationComponentOptions = {
    rangeSeparatorText: "of",
    noRowsPerPage: true,
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
    handleSearch();
  };

  const handleSearch = () => {
    dispatch(getOrders(0, searchQuery));
  };

  const [heightS, setHeightS] = useState("");

  const checkSafari = () => {
    return (
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      )
    );
  };
  useEffect(() => {
    if (!checkSafari()) {
      setHeightS("185px");
    } else {
      setHeightS("80px");
    }
  }, []);
  return customSM ? (
    <>
      <MobLayout>
        <div /* style={{marginTop:heightS}} */>
          <OrdersComp />
        </div>
      </MobLayout>
    </>
  ) : (
    <>
      {/* <Dialog
                style={{ borderRadius: "0px" }}
                maxWidth={'l'}
                fullWidth={false}
                open={openpdf}
                scroll={"body"}
                onClose={handlePdfClose}
                disableBackdropClick
            >
                <div className="ContainerWrapper">
                    <img
                        src={Close}
                        alt="Close"
                        className="Closepopup"
                        onClick={() => handlePdfClose()}
                        crossorigin="anonymous"
                    />
                    <DialogContent>
                        <iframe src={url1} className="pdfiframe" id="pdf" target="_blank"></iframe>
                    </DialogContent>
                </div>
            </Dialog> */}
      <Dialog
        disableBackdropClick
        open={addPatientModel}
        onClose={() => setAddPatientModel(false)}
        maxWidth={"l"}
        fullWidth={false}
        // style={{width:"20px"}}
      >
        <img
          src={Close}
          alt="Close"
          className="Closepopup"
          onClick={() => setAddPatientModel(false)}
          crossorigin="anonymous"
          style={{
            width: "40px",
            height: "35px",
            marginLeft: "auto",
            cursor: "pointer",
          }}
        />
        {/* <DialogContent> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: "Greycliff CF",
                    fontSize: "16px",
                    fontWeight: "bold",
                    padding: "15px 30px",
                  }}
                >
                  Test Name
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {url &&
                url.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ padding: "30px 30px" }}
                    >
                      {row.details.standardisedtestname}
                    </TableCell>
                    <TableCell align="right" style={{ padding: "10px 30px" }}>
                      <button
                        className="edite_btn1"
                        onClick={() => handleOrder1(row)}
                      >
                        View Report
                      </button>
                      {/* <RemoveRedEyeIcon style={{ cursor: 'pointer', color: '#046673' }} onClick={() => { handleOrder1(row) }}>
                            View</RemoveRedEyeIcon> */}
                      {/* </TableCell>  */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* </DialogContent> */}
      </Dialog>
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            {/* <input
                            className="text_field"
                            margin="dense"
                            id="mobile"
                            label="Mobile no :"
                            type="text"
                            fullWidth
                            placeholder="search..."
                            variant="outlined"
                            onChange={(event) => handleSearchQuery(event)}
                        /> */}
            <div className="search">
              <input
                className="headersearch"
                margin="dense"
                type="text"
                fullWidth
                placeholder="Search"
                variant="outlined"
                onChange={(event) => handleSearchQuery(event)}
              />
              <img
                className="header-search"
                src={Search}
                crossorigin="anonymous"
                onClick={(event) => handleSearchQuery(event)}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <SearchOffIcon
              style={{ cursor: "pointer" }}
              onClick={handleSearch}
            />
          </Grid>
        </Grid>

        <div className="snapcontainer2">
          <div className="table_header">
            <h1 className="table_heading">Orders</h1>
          </div>
          {orderState &&
          orderState.orderList &&
          orderState.orderList.total > 10 ? (
            <DataTable
              columns={createColumns()}
              data={
                orderState &&
                orderState.orderList.data &&
                orderState.orderList.data
              }
              customStyles={customStyles}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              paginationTotalRows={
                orderState && orderState.orderList && orderState.orderList.total
              }
              onChangePage={handlePageChange}
            />
          ) : (
            <DataTable
              columns={createColumns()}
              data={
                orderState &&
                orderState.orderList.data &&
                orderState.orderList.data
              }
              customStyles={customStyles}
            />
          )}
        </div>

        <Dialog
          disableBackdropClick
          open={orderModel}
          onClose={() => setOrderModel(false)}
        >
          <DialogContent>
            <ViewOrderModel
              id={1}
              close={(event) => setOrderModel(false)}
              details={orderDetail}
            />
          </DialogContent>
        </Dialog>

        <Toast
          text={toastMessage}
          type={toastType}
          open={showToast}
          setClose={() => {
            setToast(false);
          }}
        />
      </Layout>
    </>
  );
}
