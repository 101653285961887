import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import patients from "./components/Patients";
import dialogclose from "../../assets/images/DialogClose.svg";
import DialogLayout from "./components/DialogLayout";
import DiagnosticPres from "./DiagnosticPres";
import moment from "moment";
import styles from "./style";
import axios from "axios";

const useStyles = makeStyles((theme) => styles(theme));

const PatientDetail = ({ closePatDetail, patient }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [diagnostic, setDiagnostic] = useState(false);
  console.log(patient, "patient");
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  console.log(cityList, "sdfsdf");
  const [city, setCity] = useState("Select");
  const [cityID, setCityID] = useState("Select");
  const [area, setArea] = useState("Select");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/uploadDoc/allcities`)
      .then((res) => {
        console.log(res, "hfgsdgd");
        setCityList(res.data);
        let CityId =
          patient &&
          patient.residential_Address &&
          patient.residential_Address[0] &&
          patient.residential_Address[0].city;
        res.data.map((item) => {
          if (item._id == CityId) {
            // console.log(item, "item")
            setCity(item.name);
            // setCityID(item.uuid)
            fetcharea(item.uuid);
          }
          // console.log(item, "item")
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   fetcharea()
  // }, [city])
  function fetcharea(CityUUID) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/uploadDoc/getArea/${CityUUID}`)
      .then((res) => {
        setAreaList(res.data);
        let areaId =
          patient &&
          patient.residential_Address &&
          patient.residential_Address[0] &&
          patient.residential_Address[0].area;
        res.data.map((item) => {
          if (item._id == areaId) {
            console.log(item, "item");
            setArea(item.name);
          }
          console.log(item, "item");
        });
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false)
      });
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        padding: "28px 20px 38px 16px",
      }}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{ marginBottom: "28px" }}
      >
        <img src={dialogclose} alt="close" onClick={() => closePatDetail()} />
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Name</Typography>
          <Typography className={classes.value}>{patient.full_name}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Date</Typography>
          <Typography className={classes.value}>
            {patient && patient.createdAt
              ? `${moment(patient.createdAt).format("DD/MM/YYYY")}`
              : "NA"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Gender</Typography>
          <Typography className={classes.value}>{patient.gender}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Date of Birth</Typography>
          <Typography className={classes.value}>
            {patient && patient.dob
              ? `${moment(patient.dob).format("DD/MM/YYYY")}`
              : "NA"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Email</Typography>
          <Typography className={classes.value}>{patient.email}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Mobile No</Typography>
          <Typography className={classes.value}>{patient.mobile}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Blood Group</Typography>
          <Typography className={classes.value}>
            {patient.blood_group}
          </Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>City</Typography>
          <Typography className={classes.value}>{city}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Area</Typography>
          <Typography className={classes.value}>{area}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Door No</Typography>
          <Typography className={classes.value}>
            {patient &&
              patient.residential_Address &&
              patient.residential_Address[0] &&
              patient.residential_Address[0].door_no}
          </Typography>
        </Grid>
      </Grid>
      <button
        className={classes.addVisit}
        style={{ width: "300px", marginTop: "35px" }}
        onClick={() => {
          setDiagnostic(true);
        }}
      >
        Add Visit
      </button>
      {diagnostic ? (
        <DialogLayout open={diagnostic}>
          <DiagnosticPres
            closeDiag={() => setDiagnostic(false)}
            patient={patient}
            Vtype="direct"
          />
        </DialogLayout>
      ) : null}
    </Grid>
  );
};

export default PatientDetail;
