import React, { useEffect, useState } from "react";
import Layout from "../Dashboard/extlayout";
import MobLayout from "../Dashboard/mobLayout";
import DashbordVisits from "./components/DashbordVisits";
import UpdateBankDetails from "./components/UpdateBankDetails";
import DashbordPatients from "./components/DashbordPatients";
import "./dashboardstyles/dashboardcontent.css";
import Grid from "@mui/material/Grid";
import {
  getBankDetails,
  getOrders,
  getPatients,
  getStatistics,
  getVisits,
} from "../redux/actions/ex-appointment-action";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import DoctorData from "./pages/DoctorData";
import DoctorDataMob from "./mobileComp/DoctorDataMob";
import Messages from "./pages/Messages";
import OrdersTable from "./pages/OrderTable";
import styles from "./style";
const useStyles = makeStyles((theme) => styles(theme));

export default function DoctorDashBoardPage() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("800px"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const customSM = useMediaQuery("(max-width:800px)");
  const dispatch = useDispatch();
  const bankState = useSelector((state) => state.PatientSnapshot.bankDetails);
  const statisticsDetails = useSelector(
    (state) => state.PatientSnapshot.statistics
  );
  const [editBankModel, setEditBankModel] = useState(false);
  const [allCount, setAllCount] = useState();
  const [patientCount, setPatientCount] = useState();
  const [msgData, setMsgData] = useState([]);
  const [totals, setTotal] = useState("");
  const [page, setPage] = useState(0);

  const authState = useSelector((state) => state.loginReducer.payload);
  const userdata = useSelector((state) => state.loginReducer.payload);

  let Dr_FirstName = authState && authState.firstName;

  useEffect(() => {
    dispatch(getPatients(0, "", 5));
    dispatch(getBankDetails());
    dispatch(getStatistics());
    dispatch(getOrders(0, "", 3));
    dispatch(getVisits(0, ""));
    getCount();
  }, []);

  const getCount = () => {
    let endPoint = `/ep/dashboardcount/${authState.uuid}`;

    axios
      .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
        headers: {
          "x-auth-token": authState.token,
        },
      })
      .then((res) => {
        if (res.data) {
          setAllCount(res && res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Messagesget();
  }, []);
  let arrayForHoldingPosts = [];
  const Messagesget = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/sms/saas?doctor_uuid=${userdata.uuid}&pagenum=${page}&limit=5`,
        {
          headers: {
            "x-auth-token": userdata.token,
          },
        }
      )
      .then((res) => {
        const slicedPosts = res.data.data;
        setTotal(res.data.total);
        setMsgData(slicedPosts);
        console.log(res, "arrayForHoldingPosts");
      })
      .catch((err) => console.log(err));
  };
  const handleScroll = (event) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/sms/saas?doctor_uuid=${
          userdata.uuid
        }&pagenum=${page + 1}&limit=4`,
        {
          headers: {
            "x-auth-token": userdata.token,
          },
        }
      )
      .then((res) => {
        const slicedPosts = res.data.data;
        // arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setMsgData((arrayForHoldingPosts) => [
          ...arrayForHoldingPosts,
          ...slicedPosts,
        ]);
        console.log(res, "arrayForHoldingPosts");
      })
      .catch((err) => console.log(err));

    setPage((prev) => prev + 1);
  };
  const [heightS, setHeightS] = useState("");

  const checkSafari = () => {
    return (
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      )
    );
  };
  useEffect(() => {
    if (!checkSafari()) {
      setHeightS("185px");
    } else {
      setHeightS("80px");
    }
  }, []);
  return customSM ? (
    <>
      <MobLayout>
        <div /* style={{marginTop:heightS}} */>
          <DoctorDataMob />
        </div>
      </MobLayout>
    </>
  ) : (
    <>
      <Layout>
        <h1 className="Main_headding">Hi, Dr. {Dr_FirstName}</h1>
        <DoctorData count_earned={allCount} Messagesget={Messagesget} />
        {/* <Grid container spacing={2} >
                      <Grid item xs={6}>
                          <div className='dashbord_content_container1'>
                              <div className='dashbord_content_head'>
                                  <Grid container spacing={2} >
                                      <Grid item xs={9}>
                                          <h2 className='dashbord_head_heading'>Account</h2>
                                      </Grid>
                                      <Grid item xs={3}>
                                          <button className='edite_btn' onClick={() => setEditBankModel(true)}>Edit</button>
                                      </Grid>
                                  </Grid>
                              </div>
  
                              <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                      <div className='acount_box'>
                                          <h2 className='dashbord_content_heading'>Bank Name</h2>
                                          <p className='dashbord_content_sub_heading'>{bankState && bankState.bank_detail && bankState.bank_detail.bank_name ? bankState.bank_detail.bank_name : "N/A"}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <div className='acount_box'>
                                          <h2 className='dashbord_content_heading'>Branch</h2>
                                          <p className='dashbord_content_sub_heading'>{bankState && bankState.bank_detail && bankState.bank_detail.branch ? bankState.bank_detail.branch : "N/A"}</p>
                                      </div>
                                  </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                      <div className='acount_box'>
                                          <h2 className='dashbord_content_heading'>Account No.</h2>
                                          <p className='dashbord_content_sub_heading'>{bankState && bankState.bank_detail && bankState.bank_detail.acc_number ? bankState.bank_detail.acc_number : "N/A"}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <div className='acount_box'>
                                          <h2 className='dashbord_content_heading'>Account Name</h2>
                                          <p className='dashbord_content_sub_heading'>{bankState && bankState.bank_detail && bankState.bank_detail.acc_name ? bankState.bank_detail.acc_name : "N/A"}</p>
                                      </div>
                                  </Grid>
                              </Grid>
                          </div>
                      </Grid>
  
                      <Grid item xs={3}>
                          <div className='erned'>
                              <div className='card_erned'>
                                  <h1 className='erned_txt'>₦ {allCount && allCount.earned && allCount.earned.toFixed(2)}</h1>
                                  <h2 className='erned_txt'>Earned</h2>
                              </div>
                          </div>
                      </Grid>
                      <Grid item xs={3}>
                          <div className='erned1'>
                              <div className='card_erned'>
                                  <h1 className='Outstanding'>₦ {allCount && allCount.outstanding && allCount.outstanding.toFixed(2)}</h1>
                                  <h2 className='Outstanding'>Outstanding</h2>
                              </div>
                          </div>
                      </Grid>
                  </Grid> */}

        <Grid container spacing={2}>
          <Grid item xs={7}>
            <DashbordPatients />
          </Grid>
          <Grid item xs={5}>
            {/* <DashbordVisits /> */}
            <Messages
              totals={totals}
              msgData={msgData}
              handleScroll={handleScroll}
            />
          </Grid>
        </Grid>
        {/* Order table */}
        <OrdersTable />

        {/* Statistics starts*/}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="dashbord_content_container1">
              <div className="dashbord_content_head">
                <Grid container spacing={2}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={3}>
                    <h2 className="dashbord_content_sub_heading2">This Week</h2>
                  </Grid>
                  <Grid item xs={3}>
                    <h2 className="dashbord_content_sub_heading2">
                      This Month
                    </h2>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="acount_box">
                    <h2 className="dashbord_content_sub_heading3">
                      Lab Test Advice
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalLabTestCurrentWeek}
                  </h2>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalLabTestThisMonth}
                  </h2>
                </Grid>

                <Grid item xs={6}>
                  <div className="acount_box">
                    <h2 className="dashbord_content_sub_heading3">
                      Medicine Prescription
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalMedicieneCurrentWeek}
                  </h2>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalMedicieneThisMonth}
                  </h2>
                </Grid>
                <Grid item xs={6}>
                  <div className="acount_box">
                    <h2 className="dashbord_content_sub_heading3">
                      Medicine Ordered
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalMedicieneOrderCurrentWeek}
                  </h2>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalMedicieneOrderThisMonth}
                  </h2>
                </Grid>
                <Grid item xs={6}>
                  <div className="acount_box">
                    <h2 className="dashbord_content_sub_heading3">DMP Order</h2>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalLabTestOrderCurrentWeek}
                  </h2>
                </Grid>
                <Grid item xs={3}>
                  <h2 className="dashbord_box_cantent">
                    {allCount && allCount.totalLabTestOrderthisMonth}
                  </h2>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className="dashbord_content_container2">
              <div className="dashbord_content_head1">
                <h2
                  className="dashbord_content_sub_heading2"
                  style={{ textAlign: "center", color: " #FF6E4E" }}
                >
                  Income This Week
                </h2>
              </div>
              <h1
                className="erned_txt"
                style={{
                  paddingTop: "60px",
                  paddingBottom: "65px",
                  fontSize: "40px",
                }}
              >
                ₦ {allCount && allCount.incomeThisWeek}
              </h1>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="dashbord_content_container3">
              <div className="dashbord_content_head2">
                <h2
                  className="dashbord_content_sub_heading2"
                  style={{ textAlign: "center", color: " #37CC8C" }}
                >
                  Income This Month
                </h2>
              </div>
              <h1
                className="Outstanding"
                style={{
                  paddingTop: "60px",
                  paddingBottom: "65px",
                  fontSize: "40px",
                  color: "#37CC8C",
                }}
              >
                ₦ {allCount && allCount.incomeThisMonth}
              </h1>
            </div>
          </Grid>

          {/* <div className='dashbord_content_container1' >
                              <div className='dashbord_content_head'>
                                  <Grid container spacing={2} >
                                      <Grid item xs={6}>
                                          <h2 className='dashbord_content_sub_heading2' style={{ textAlign: "center" }}>Income This Week</h2>
                                      </Grid>
                                      <Grid item xs={6}>
                                          <h2 className='dashbord_content_sub_heading2' style={{ textAlign: "center" }}>Income This Month</h2>
                                      </Grid>
                                  </Grid>
                              </div>
  
  
  
                              <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                      <h1 className='erned_txt' style={{ paddingTop: "60px", paddingBottom: "65px", fontSize: "40px" }}>₦ {allCount && allCount.incomeThisWeek}</h1>
                                  </Grid>
  
  
                                  <Grid item xs={6}>
                                      <h1 className='Outstanding' style={{ paddingTop: "60px", paddingBottom: "65px", fontSize: "40px" }}>₦ {allCount && allCount.incomeThisMonth}</h1>
                                  </Grid>
  
  
                              </Grid>
  
                          </div> */}
        </Grid>

        <Dialog
          disableBackdropClick
          open={editBankModel}
          onClose={() => setEditBankModel(false)}
          maxWidth="md"
        >
          <DialogContent>
            <UpdateBankDetails close={(event) => setEditBankModel(false)} />
          </DialogContent>
        </Dialog>
      </Layout>
    </>
  );
}
