import React, { useEffect, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useMediaQuery, makeStyles, useTheme } from "@material-ui/core";
import { getVisits } from "../../redux/actions/ex-appointment-action";
import {
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import "../dashboardstyles/dashboardstyle.css";
import { visitColumns } from "./TableColumn";
import { customStyles } from "../dashboardstyles/table-style/tableCustomStyle";
import AddVisitComponent from "./visits/AddVisitComponent";
import Toast from "../../components/Toast";
import Layout from "../../Dashboard/extlayout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Search from "./../../assets/images/search.svg";
import MobLayout from "../../Dashboard/mobLayout";
import VisitMob from "../mobileComp/VisitMob";

export default function VisitComponent() {
  let history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const customSM = useMediaQuery("(max-width:800px)");
  const visitState = useSelector((state) => state.PatientSnapshot);
  const [addVisitModel, setAddVisitModel] = useState(false);
  const [visitDetail, setVisitDetails] = useState();
  const [diffId, setDiffId] = useState("ADD");
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const [showToast, setToast] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    dispatch(getVisits(pageNumber, searchQuery));
  }, [pageNumber, ""]);
  const mergedColumns = visitColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
  });
  const createColumns = useCallback(() => {
    return [
      ...mergedColumns,
      {
        name: "Action",
        allowOverflow: true,
        minWidth: "100px",
        cell: (row) => {
          return (
            <RemoveRedEyeIcon
              style={{ cursor: "pointer", color: "#046673" }}
              onClick={() => {
                handleVisitView(row);
              }}
            >
              View
            </RemoveRedEyeIcon>
          );
        },
      },
    ];
  }, [mergedColumns]);
  const handleVisitView = (details) => {
    setVisitDetails(details);
    history.push({
      pathname: "/visit-view",
      state: {
        visitDetails: details,
      },
    });
  };
  const paginationComponentOptions = {
    rangeSeparatorText: "of",
    noRowsPerPage: true,
  };
  const handlePageChange = (page) => {
    setPageNumber(page - 1);
  };
  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
    handleSearch();
  };
  const handleSearch = () => {
    dispatch(getVisits(0, searchQuery));
  };
  const [heightS, setHeightS] = useState("");

  const checkSafari = () => {
    return (
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      )
    );
  };
  useEffect(() => {
    if (!checkSafari()) {
      setHeightS("185px");
    } else {
      setHeightS("80px");
    }
  }, []);

  return customSM ? (
    <>
      <MobLayout>
        <div /* style={{marginTop:heightS}} */>
          <VisitMob />
        </div>
      </MobLayout>
    </>
  ) : (
    <>
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <div className="search">
              <input
                className="headersearch"
                margin="dense"
                type="text"
                fullWidth
                placeholder="Search"
                variant="outlined"
                onChange={(event) => handleSearchQuery(event)}
              />
              <img
                className="header-search"
                src={Search}
                crossorigin="anonymous"
                onClick={(event) => handleSearchQuery(event)}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <SearchOffIcon
              style={{ cursor: "pointer" }}
              onClick={handleSearch}
            />
          </Grid>
          <Grid item xs={2}>
            <div style={{ marginLeft: "10px" }}>
              <button
                className="add_visit_button"
                onClick={() => {
                  setAddVisitModel(true);
                  setVisitDetails({});
                  setDiffId("Add");
                }}
              >
                Add Appointment
              </button>
            </div>
          </Grid>
        </Grid>
        {console.log(
          visitState &&
            visitState.visitList &&
            visitState.visitList.data &&
            visitState.visitList.data,
          "asfg"
        )}
        <div className="snapcontainer2">
          <div className="table_header">
            <h1 className="table_heading">Appointments</h1>
          </div>
          {visitState &&
          visitState.visitList &&
          visitState.visitList.total > 10 ? (
            <DataTable
              columns={createColumns()}
              data={
                visitState &&
                visitState.visitList &&
                visitState.visitList.data &&
                visitState.visitList.data
              }
              customStyles={customStyles}
              pagination
              paginationServer
              paginationComponentOptions={paginationComponentOptions}
              paginationTotalRows={
                visitState && visitState.visitList && visitState.visitList.total
              }
              onChangePage={handlePageChange}
            />
          ) : (
            <DataTable
              columns={createColumns()}
              data={
                visitState &&
                visitState.visitList &&
                visitState.visitList.data &&
                visitState.visitList.data
              }
              customStyles={customStyles}
            />
          )}
        </div>
        <Dialog
          disableBackdropClick
          open={addVisitModel}
          onClose={() => setAddVisitModel(false)}
          maxWidth="sm"
        >
          <DialogContent>
            <AddVisitComponent
              id={diffId}
              close={(event) => setAddVisitModel(false)}
              details={visitDetail}
              toastVisibility={(event) => setToast(event)}
              toastText={(event) => setToastMessage(event)}
              toastType={(event) => setToastType(event)}
            />
          </DialogContent>
        </Dialog>

        <Toast
          text={toastMessage}
          type={toastType}
          open={showToast}
          setClose={() => {
            setToast(false);
          }}
        />
      </Layout>
    </>
  );
}
