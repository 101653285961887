import { takeLatest, takeEvery, put, select } from "redux-saga/effects";
import axios from "axios";

export const getUser = state => state.loginReducer.payload;

const createCaseNote = function* ({ data }) {
    try{
        const user = yield select(getUser);
     let response=      yield axios.post(process.env.REACT_APP_API_URL + `/doctor/observation/createNotes`, data, {
        headers: {
            'x-auth-token': user.token,
            'Content-Type': 'application/json'
        }
    })
        if(response){
            yield put({ type: "SET_CASE_NOTE_SUCCESS", payload: response })
        }
    }
    catch{
        yield put({ type: "SET_CASE_NOTE_ERROR" })
    }
}
const searchICD = function* ({ search }) {
    const user = yield select(getUser);

    let searchicd;
    yield put({ type: "SET_SEARCH_LOADING", payload: searchicd })

    yield axios.get(process.env.REACT_APP_API_URL + `/metadata/icdcodes/search?search=${search}`, {
        headers: {
            'x-auth-token': user.token,
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if (resp) {
            searchicd = resp.data
        }

    })
        .catch(err => {
            searchicd = err
        })
    yield put({ type: "SET_SEARCH_ICD", payload: searchicd })
}
const createPhyNote = function* ({ data }) {
    try{
        const user = yield select(getUser);
     let response=     yield axios.post(process.env.REACT_APP_API_URL + `/doctor/observation/addPsychCaseNotes`, data, {
        headers: {
            'x-auth-token': user.token,
            'Content-Type': 'application/json'
        }
    })
        if(response){
            yield put({ type: "SET_PSHY_SUCCESS", payload: response })
        }
    }
    catch{
        yield put({ type: "SET_PSHY_ERROR" })
    }
}

const createRiskNote = function* ({ data }) {
    try{
        const user = yield select(getUser);
     let response=   yield axios.post(process.env.REACT_APP_API_URL + `/doctor/observation/createriskNotes`, data, {
            headers: {
                'x-auth-token': user.token,
                'Content-Type': 'application/json'
            }
        })
        if(response){
            yield put({ type: "SET_PATIENT_RISK_NOTE_SUCCESS", payload: response })
        }
    }
    catch{
        yield put({ type: "SET_PATIENT_RISK_NOTE_ERROR" })
    }
}






const getCaseNoteSaga = function* ({ Bookingid }) {
    const user = yield select(getUser);

    let caseNotedata;
    yield axios.get(process.env.REACT_APP_API_URL + `/doctor/observation/getallcase/${Bookingid}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp.data) {
            caseNotedata = resp.data
        }
    })
        .catch(err => {
            caseNotedata = err
        })
    yield put({ type: "GET_CASE_NOTE_REDUCER", payload: caseNotedata })
}




const getPhyNoteSaga = function* ({ Bookingid }) {
    const user = yield select(getUser);

    let casePhyNotedata;
    yield axios.get(process.env.REACT_APP_API_URL + `/doctor/observation/getallpsychiatrycase/${Bookingid}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp.data) {
            casePhyNotedata = resp.data
        }
    })
        .catch(err => {
            casePhyNotedata = err
        })
    yield put({ type: "GET_PHY_CASE_NOTE_REDUCER", payload: casePhyNotedata })
}


const gethisNoteSaga = function* ({ Bookingid,patient,clinic_uuid }) {
    const user = yield select(getUser);

    let gethisNote;
    // console.warn("refer",patient)
    // http://test-api.doctall.com/api/v1/ep/healthDetails/PT-938749DF
    // yield axios.get(process.env.REACT_APP_API_URL + `/booking/apt_history/${Bookingid}?doctor_uuid=${user.uuid}&patient_uuid=${patient}&clinic_uuid=${clinic_uuid}`, 
    yield axios.get(process.env.REACT_APP_API_URL + `/ep/healthDetails/${patient}`, 
    {   headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp.data) {
            gethisNote = resp.data
        }
    })
        .catch(err => {
            gethisNote = err
        })
    yield put({ type: "GET_HIS_REDUCER", payload: gethisNote })
}



const getRisknotSaga = function* ({ Bookingid }) {
    const user = yield select(getUser);

    let getRisknote;
    yield axios.get(process.env.REACT_APP_API_URL + `/doctor/observation/getallrisknote/${Bookingid}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp.data) {
            // console.log(resp,'second')
            getRisknote = resp.data
        }
    })
        .catch(err => {
            getRisknote = err
        })
    yield put({ type: "GET_RISK_NOTE_REDUCER", payload: getRisknote })
}

const geRiskareaSaga = function* () {
    const user = yield select(getUser);

    let getRiskarea;
    yield axios.get(process.env.REACT_APP_API_URL + `/doctor/observation/riskarea`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp.data) {
            getRiskarea = resp.data
        }
    })
        .catch(err => {
            getRiskarea = err
        })
    yield put({ type: "GET_RISKAREA_REDUCER", payload: getRiskarea })
}

function* createCaseNoteWatcher() {

    yield takeLatest("POST_CASENOTE_ACTION_REQUEST", createCaseNote);

}

function* createPhyCaseNoteWatcher() {

    yield takeLatest("POST_CASE_PHYSNOTE_ACTION_REQUEST", createPhyNote);

}

function* GETCaseNoteWatcher() {

    yield takeLatest("GET_CASE_NOTE_ACTION", getCaseNoteSaga);

}

function* GETPhyNoteWatcher() {

    yield takeLatest("GET_PHY_NOTE_ACTION", getPhyNoteSaga);

}

function* GETHistroNoteWatcher() {

    yield takeLatest("GET_HISTRO_ACTION", gethisNoteSaga);

}


function* createRiskNoteWatcher() {

    yield takeLatest("POST_RISK_NOTE_REQUEST_ACTION", createRiskNote);

}




function* GETRiskNoteNoteWatcher() {

    yield takeLatest("GET_RISK_NOTE_ACTION", getRisknotSaga);

}


function* GETRiskareaeWatcher() {

    yield takeLatest("GET_RISK_AREA_ACTION", geRiskareaSaga);

}

function* SearchICDDATA() {

    yield takeLatest("SEARCH_ICD", searchICD);

}

export { createCaseNoteWatcher, createPhyCaseNoteWatcher, GETCaseNoteWatcher, GETPhyNoteWatcher, GETHistroNoteWatcher, SearchICDDATA, createRiskNoteWatcher, GETRiskareaeWatcher, GETRiskNoteNoteWatcher }
