import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Typography,
    useMediaQuery,
    Box,
    Card,
} from "@material-ui/core";
import "../pages/message.scss";
import doctall_msg_icon from "../../assets/images/doctall_msg_icon.svg";
import attachment_icon from "../../assets/images/attachment_icon.svg"
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

function Messages({totals, msgData, handleScroll}) {
    const userdata = useSelector(state => state.loginReducer.payload)
    // const [msgData, setMsgData] = useState([])
    // const [page, setPage] = useState(0)
    // const [totals, setTotal] = useState('')


    console.log(msgData)

    // const handleScroll = (event) => {

    //     axios.get(`${process.env.REACT_APP_API_URL}/sms/saas?doctor_uuid=${userdata.uuid}&pagenum=${page + 1}&limit=4`, {
    //         headers: {
    //             'x-auth-token': userdata.token
    //         }
    //     })
    //         .then((res) => {
    //             const slicedPosts = res.data.data
    //             // arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    //             setMsgData((arrayForHoldingPosts) => [...arrayForHoldingPosts, ...slicedPosts]);
    //             console.log(res, "arrayForHoldingPosts")
    //         })
    //         .catch(err => console.log(err))

    //     setPage(prev => prev + 1);
    // };

    // useEffect(() => {
    //     Messagesget()
    // }, [])
    // let arrayForHoldingPosts = [];
    // const Messagesget = () => {
    //     axios.get(`${process.env.REACT_APP_API_URL}/sms/saas?doctor_uuid=${userdata.uuid}&pagenum=${page}&limit=5`, {
    //         headers: {
    //             'x-auth-token': userdata.token
    //         }
    //     })
    //         .then((res) => {
    //             const slicedPosts = res.data.data
    //             setTotal(res.data.total)
    //             setMsgData(slicedPosts);
    //             console.log(res, "arrayForHoldingPosts")
    //         })
    //         .catch(err => console.log(err))
    // }

    return (
        <div>
            <Grid item xs={12}>
                <div className="dashboard_message_container"  >
                    <Grid container  >
                        <Grid item md={12} style={{ height: "528px" }}>
                            <h2 className='dashbord_message_heading'>Messages</h2>
                            {msgData.map((item) => {

                                return <Card className="messageCard">
                                    <Grid item container >
                                        <Grid md={2}>
                                            <img src={doctall_msg_icon} />

                                        </Grid>
                                        <Grid md={8}>
                                            <Typography className='massege_sender_name' style={{color:"#002931",fontWeight:"600"}}>{item && item.patient && item.patient[0] && item.patient[0].gender === "male" ? "Mr." : "Ms."} {item && item.patient && item.patient[0] && item.patient[0].full_name}</Typography>
                                            <p className='message_text'>{item && item.message}</p>


                                        </Grid>
                                        <Grid md={2}>
                                            {console.log(moment(new Date()).format('hh'))}
                                            <p className='message_text'>{Math.abs(moment(new Date()).format('hh') - moment(item.createdAt).format('hh'))}hours ago</p>
                                            <div>
                                            </div>
                                            {/* <img src={attachment_icon} /> <span className='message_number'>24</span> */}

                                        </Grid>
                                    </Grid>

                                </Card>
                            })}
                            {console.log(totals, msgData.length)}
                            <button onClick={handleScroll} disabled={totals <= msgData.length || totals < 5} className='edite_btn1' style={{textAlign:"right", float:"right", marginLeft:"auto", marginRight:"2%"}}>Load More</button>
                            {/* {totals < msgData.length || totals < 5 ?
                            <button onClick={handleScroll} disabled className='edite_btn123' style={{textAlign:"right", float:"right", marginLeft:"auto", marginRight:"2%"}}>Load More</button>
                            :
                            <button onClick={handleScroll} className='edite_btn1' style={{textAlign:"right", float:"right", marginLeft:"auto", marginRight:"2%"}}>Load More</button>
                            }     */}
                            {/* <Card className="messageCard">
                                <Grid item container >
                                    <Grid md={2}>
                                        <img src={doctall_msg_icon} />

                                    </Grid>
                                    <Grid md={8}>
                                        <Typography className='massege_sender_name'>Mrs. Amanda Parker </Typography>
                                        <p className='message_text'>Your balance of 2 Million naira has been credited into your bank account...</p>


                                    </Grid>
                                    <Grid md={2}>
                                        <p className='message_text'>2 hours ago</p>
                                        <div>
                                        </div>
                                        <img src={attachment_icon} /> <span className='message_number'>24</span>

                                    </Grid>
                                </Grid>

                            </Card>
                            
                            <Card className="messageCard">
                                <Grid item container >
                                    <Grid md={2}>
                                        <img src={doctall_msg_icon} />

                                    </Grid>
                                    <Grid md={8}>
                                        <Typography className='massege_sender_name'>Mrs. Amanda Parker </Typography>
                                        <p className='message_text'>Your balance of 2 Million naira has been credited into your bank account...</p>


                                    </Grid>
                                    <Grid md={2}>
                                        <p className='message_text'>2 hours ago</p>
                                        <div>
                                        </div>
                                        <img src={attachment_icon} /> <span className='message_number'>24</span>

                                    </Grid>
                                </Grid>

                            </Card>
                            
                            <Card className="messageCard">
                                <Grid item container >
                                    <Grid md={2}>
                                        <img src={doctall_msg_icon} />

                                    </Grid>
                                    <Grid md={8}>
                                        <Typography className='massege_sender_name'>Mrs. Amanda Parker </Typography>
                                        <p className='message_text'>Your balance of 2 Million naira has been credited into your bank account...</p>


                                    </Grid>
                                    <Grid md={2}>
                                        <p className='message_text'>2 hours ago</p>
                                        <div>
                                        </div>
                                        <img src={attachment_icon} /> <span className='message_number'>24</span>

                                    </Grid>
                                </Grid>

                            </Card>
                            
                             <Card className="messageCard">
                                <Grid item container >
                                    <Grid md={2}>
                                        <img src={doctall_msg_icon} />

                                    </Grid>
                                    <Grid md={8}>
                                        <Typography className='massege_sender_name'>Mrs. Amanda Parker </Typography>
                                        <p className='message_text'>Your balance of 2 Million naira has been credited into your bank account...</p>


                                    </Grid>
                                    <Grid md={2}>
                                        <p className='message_text'>2 hours ago</p>
                                        <div>
                                        </div>
                                        <img src={attachment_icon} /> <span className='message_number'>24</span>

                                    </Grid>
                                </Grid>

                            </Card> */}

                        </Grid>
                    </Grid>
                    {/* </div> */}

                </div>
            </Grid>
        </div>
    )
}

export default Messages