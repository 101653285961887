import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import SwipeableViews from 'react-swipeable-views';
import { getAreaLab, getCityLab, getSearchLab } from "../../../redux/actions/cockpitAction";
import CreatecaseSuccess from '../../Popup/CreatecaseSuccess';
import LabChaperoneContentPathology from "./LabChaperoneContentPathology";
import LabChaperoneContentRadiology from "./LabChaperoneRadiology";
import LabTestHelp from "../../doctorCockpit/PrescriptionLab/labTestHelp";
import Closebutton from "../icons/close.svg";
function LabPanelChaperone(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="TabPanelChaperone"
      hidden={value !== index}
      id={`simple-TabPanelChaperone-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

LabPanelChaperone.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11ysProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-TabPanelChaperone-${index}`,
  };
}

function TabPanelChaperone(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="TabPanelChaperone"
      hidden={value !== index}
      id={`full-width-TabPanelChaperone-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelChaperone.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-TabPanelChaperone-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  container: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "20px",
    padding: "30px"
  },
  container2: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "41px",
    padding: "30px"
  },
  closebtn: {
    position: "absolute",
    right: "-50px",
    top: 30,
    backgroundColor: "white",
    width: "30px",
    height: "30px",

  }

}));

const AntTabsLabChap = withStyles({
  root: {
    borderBottom: '2px solid rgba(0, 131, 155, 0.5)',
    flexGrow: 1,
    marginTop: 16
  },
  indicator: {
    backgroundColor: '#A0DFC4',
    height: 7
  },
  flexContainer: {
    justifyContent: 'space-evenly'
  }
})(Tabs);

const AntTabLabChap = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: "20%",
    fontWeight: "bold",
    marginRight: theme.spacing(4),
    fontFamily: [
      'GreycliffCFBold'
    ].join(','),
    '&:hover': {
      color: '#046673',
      opacity: 1,
    },
    '&$selected': {
      color: '#046673',
    },
    '&:focus': {
      color: '#046673',
    },
    color: '#046673',
    maxWidth: 'none'
  },
  selected: {},
  wrapper: {
    fontSize: 30
  }
}))((props) => <Tab disableRipple {...props} />);

const LabTestSubTabs = withStyles({
  root: {
    borderBottom: '2px solid #A0DFC4',
    flexGrow: 1,
    marginLeft: -24,
    marginRight: -24,
    marginTop: -10
  },
  indicator: {
    backgroundColor: '#A0DFC4',
    height: 6
  },
  flexContainer: {
    justifyContent: 'space-evenly'
  },
  scroller: {
    marginBottom: -2
  }
})(Tabs);

const LabTestSubTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: "20%",
    fontWeight: "bold",
    marginRight: theme.spacing(4),
    fontFamily: [
      'GreycliffCFBold'
    ].join(','),
    '&:hover': {
      color: '#046673',
      opacity: 1,
    },
    '&$selected': {
      color: '#046673',
    },
    '&:focus': {
      color: '#046673',
    },
    color: '#046673',
  },
  wrapper: {
    fontSize: 25
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function LabChaperone(props) {
  const initialPathologyData = {
    city: "",
    area: "",
    testDiscipline: "",
    searchTest: undefined,
    search: "",
    homeService: ""
  };
  const initialRadiologyData = {
    city: "",
    area: "",
    testDiscipline: "",
    searchTest: undefined,
    search: "",
    homeService: ""
  };
  const classes = useStyles();
  const theme = useTheme();
  const [labHeader, setLabHeader] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [pathologyData, setPathologyData] = React.useState(initialPathologyData);
  const [pathologyList, setPathologyList] = React.useState([]);
  const [radiologyList, setRadiologyList] = React.useState([]);
  const [radiologyData, setRadiologyData] = React.useState(initialRadiologyData);
  const [pathologyNote, setPathologyNote] = React.useState("");
  const [radiologyNote, setRadiologyNote] = React.useState("");


  const dispatch = useDispatch();



  const handleChangeSubHeader = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  const handleChange = (event, newValue) => {
    setLabHeader(newValue);
  };


  useEffect(() => {
    dispatch(getCityLab())

  }, [])
  return (
    <>
      {
        show
          ?
          <CreatecaseSuccess value="Lab Test Advice" width='auto' />
          : <div>
            <Grid container xs={12} style={{ paddingBottom: 25, backgroundColor: "#FFFFFF", border: "1px solid rgba(102, 181, 195, 0.5)", borderRadius: "30px" }}>
              <Grid item xs={12}>
                <div className={classes.root}>
                  <AntTabsLabChap
                    value={labHeader}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="green"
                    variant="standard"
                  >
                    <AntTabLabChap label="Create lab test advice"  {...a11ysProps(0)} />
                    <AntTabLabChap label="Help"  {...a11ysProps(1)} />
                  </AntTabsLabChap>

                  <LabPanelChaperone value={labHeader} index={0}>
                    {/* <div className={classes.root1}> */}
                    <LabTestSubTabs
                      value={value}
                      onChange={handleChangeSubHeader}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="standard"
                    >
                      <LabTestSubTab label="Pathology" {...a11yProps(0)} />
                      <LabTestSubTab label="Radiology" {...a11yProps(1)} />
                    </LabTestSubTabs>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanelChaperone value={value} index={0} dir={theme.direction}>
                        <LabChaperoneContentPathology {...props} />
                      </TabPanelChaperone>
                      <TabPanelChaperone value={value} index={1} dir={theme.direction} >
                        <LabChaperoneContentRadiology {...props} />
                      </TabPanelChaperone>
                    </SwipeableViews>
                    {/* </div> */}

                  </LabPanelChaperone>
                  <LabPanelChaperone value={labHeader} index={1}>
                    <LabTestHelp />
                  </LabPanelChaperone>
                </div>
              </Grid>
              {/* <img src={Closebutton} alt="close-button" className={classes.closebtn} /> */}
            </Grid>


          </div>
      }

    </>
  );
}
