import React, { useState } from "react";
import { Grid, Stepper } from "@material-ui/core";
import { useStyle } from "../../Admin/style/style";
import { useHistory } from "react-router-dom";
import SignUpForm from "./components/SignUpForm";
import Toast from "../../components/Toast";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import MobLayout from "../../Dashboard/mobLayout";
import Registration from "../mobileComp/authPages/Registration";

export default function RegistrationPage() {
  const classes = useStyle();
  let history = useHistory();
  const customSM = useMediaQuery("(max-width:800px)");
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const [showToast, setToast] = useState(false);

  return customSM ? (
    <>
      <Registration />
    </>
  ) : (
    <div className={classes.main}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} md={6} className={classes.rightGrid}>
          <div style={{ width: "90%", marginTop: "40px" }}>
            <div
              onClick={() => history.push("/login")}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 10px",
                marginBottom: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "88px",
                  height: "39px",
                  background: "rgba(102, 181, 195, 0.15)",
                  boxShadow: "-7px 6px 7px rgba(6, 99, 116, 0.05)",
                  borderRadius: "7px",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    display: "block",
                    margin: "auto",
                    fontFamily: "GreycliffCFBold",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    letterSpacing: "1.71px",
                    color: "#013C44",
                  }}
                >
                  Sign in
                </p>
              </div>
              <div
                style={{
                  width: "78px",
                  height: "12px",
                  fontFamily: "GreycliffCFBold",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "16px",
                  lineHeight: "19px",
                  textAlign: "right",
                  letterSpacing: "1.71px",
                  color: "white",
                  marginRight: "20px",
                }}
              >
                Register
              </div>
            </div>
            <SignUpForm />
          </div>
        </Grid>
      </Grid>
      <Toast
        text={toastMessage}
        type={toastType}
        open={showToast}
        setClose={() => {
          setToast(false);
        }}
      />
    </div>
  );
}
