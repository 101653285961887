//To fetch channel Members
export const getChannelMembers = async (pubnub, channelName, nextPage) => {
    const channelMembersResponse = await pubnub.objects.getChannelMembers({
        channel: channelName,
        include: {
            UUIDFields: true,
            customUUIDFields: true,
            totalCount: true,
        },
        limit: 100,
        page: {
            next: nextPage,
        },
    });
    if (channelMembersResponse.status === 200) {
        return channelMembersResponse;
    }
    throw new Error("Failed to get PubNub channel memebers");
}

// To add channel metadata to a application keyset
export async function addChannelMetadata(pubnub, channelName, channelDescription, channel) {
    const addChannelMetadataResponse = await pubnub.objects.setChannelMetadata({
        channel,
        data: {
            name: channelName,
            description: channelDescription,
        },
    });
    if (addChannelMetadataResponse.status === 200) {
        return addChannelMetadataResponse;
    }
    throw new Error("Failed to add channel metadata");
}

// To add user metadata to a application keyset
export async function addUserMetadata(pubnub, userName, email, uuid, profileUrl) {
    if (!email) email = null;
    if (!profileUrl) profileUrl = null;
    const addUserMetadataResponse = await pubnub.objects.setUUIDMetadata({
        uuid,
        data: {
            name: userName,
            email,
            profileUrl,
        },
    });
    if (addUserMetadataResponse.status === 200) {
        return addUserMetadataResponse;
    }
    throw new Error(addUserMetadataResponse.message);
}

//To fetch Messages
export async function fetchMessages(pubnub, channelName) {
    console.log('channelName', channelName);
    const msgResponse = await pubnub.fetchMessages({
        channels: [channelName],
        includeMeta: true,
        includeMessageActions: true,
        count: 100,
    });
    if (msgResponse) {
        console.log('msgResponse', msgResponse);
        return msgResponse.channels[channelName];
    }
    throw new Error("Failed to get messages");
}