import React from 'react'

function WeightPopUP({ weightData }) {
  
    return (
        <div className="BP_CONTAINER">
            <div className="BP_HEADING1">Weight</div>
            {weightData.map((data, index) => (
                <div className="BP_SUB_DIV" key={index}>
                    <div className="BP_SUB_DIV_TXT">{data.value}&nbsp;&nbsp;&nbsp;{data.unit}</div>
                    <div className="PHP_OB_DIV"></div>
                    <div className="BP_SUB_DIV_TXT">{data.date.slice(0, 10)}</div>
                    <div className="PHP_OB_DIV"></div>
                    <div className="BP_SUB_DIV_TXT">{data.bmi}&nbsp;&nbsp;{data.unit}</div>
                </div>
            ))}
        </div>
    )
}

export default WeightPopUP
