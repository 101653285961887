import React, { useEffect } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { useState } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import redClose from "../../assets/images/redClose.svg";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getHistro } from "../../redux/actions/caseNoteAction";
import {
  getAreaForPrescription,
  getCityForPrescription,
  searchGenericNameForPrescription,
  searchProductNameForPrescription,
  getMedAttr,
  getMedPrescription,
  getallprescription,
} from "../../redux/actions/cockpitAction";

const useStyles = makeStyles((theme) => styles(theme));

const CreatePrescription = ({ closePres,data, sucessPop, setTexts, getApi }) => {
  console.log(data,"datafaa")

  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [showForm, setShowForm] = useState(false);
const [GenericName, setGenericName]=useState("")

const [alredyAddedTest, setAlredyAddedTests] = useState([]);
const [bookingId, setBookingId] = useState('');
const [visitId, setVisitId] = useState('');
const [uuid, setUuid] = useState('')

useEffect(() => {
  setAlredyAddedTests([])
  fetchAddedTests()
  // fetchAddedTests()
}, [data && data.uuid])

const fetchAddedTests = async () => {
  try {
    // let response = await axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/prescription/visit/ep/${props.visitInfo.uuid}`)
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/ep/visit/${data && data.uuid}`)
    if (response.status === 200) {
      setAlredyAddedTests(response.data && response.data && response.data[0].medication)
      let bookingID = response.data.data && response.data.data.booking_uuid
      let uuid2 = response.data && response.data && response.data[0].uuid
      let Visit_Id = response.data && response.data && response.data[0].patient_visit_uuid
      setVisitId(Visit_Id)
      setUuid(uuid2)
      setBookingId(bookingID)
    }
  } catch (error) {
    console.log(error)
  }
}

console.log(alredyAddedTest,"alredyAddedTest")
const handleTestDelete = async (item) => {
  try {
    let payLoad = {
      "uuid": uuid,
      "patient_visit_uuid": visitId,
      "medic_uuid": item.uuid

    }
    // let response = await axios.delete(`${process.env.REACT_APP_API_URL}/externalappointment/prescription/remove/medication`, { data: payLoad })
    let response = await axios.delete(`${process.env.REACT_APP_API_URL}/ep/remove/prescription`, { data: payLoad })

    if (response.status === 200) {
      fetchAddedTests()
      setTexts('prescription Removed !')
      sucessPop()
      getApi()
      // closePres()
    }
  } catch (error) {
    console.log(error)
    setTexts('Failed !')
      sucessPop()
  }
}



const [prescrip, setPrescrip] = useState([]);
console.log(prescrip,"prescrip")
const [prescrip1, setPrescrip1] = useState([]);
const addPrescrip = () => {
  setPrescrip((i) => [
    ...i,
    {
      GenName: " ",
      DrugName: " ",
      Attr: "",
      Strength: "",
      duration: "",
      Days: " ",

    },
  ]);
};
const handlePrescripChange = (e) => {
  const data = [...prescrip];
  data[e.target.dataset.id][e.target.name] = e.target.value;
  setPrescrip(data);
};
const handlePrescripDelete = (index) => {
  const currentPrescriptionList = [...prescrip];
  currentPrescriptionList.splice(index, 1);
  setPrescrip(currentPrescriptionList);
};

const userdata = useSelector((state) => state.loginReducer.payload);


const randId = (size) => {
  const nums = Array.from({ length: 10 }, (_, i) =>
    String.fromCharCode("0".charCodeAt(0) + i)
  );
  const alphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("a".charCodeAt(0) + i)
  );
  const chars = [...nums, ...alphabets];
  const rand = (length) => Math.floor(Math.random() * length);
  return Array.from({ length: size }, () => chars[rand(chars.length)]).join(
    ""
  );
};

const handleSubmit = (e) => {
  e.preventDefault()
  // const validation = prescrip.every(item => item.Strength && item.Attr && item.duration && item.GenName && item.DrugName && item.Days);



  // if (validation === true) {
    const fullData = prescrip.map((v) =>
      Object.assign(v, {
        productupc: "",
        uuid: "HLP-" + randId(8).toUpperCase(),
        therapeuticclassification: "",
        genericname: "",
        packsize: "",
        attr: "",
        rrp: "",
        discountofferedforretail: "",
        doctallsexclusivediscountedprice: "",
        location_uuid: "",
        cities: "",
        areas: "",
      })
    );

    console.log(data, "wdfhag")
  const payload = {
        "patient_uuid": data && data.patient_uuid,
        "patient_visit_uuid": data && data.uuid,
        "doctor_uuid": data && data.doctor_uuid,
        "medication": fullData,
        "homeDelivery": [
          {
            "active": true,
            "therapeuticclassification": "OTHER SERVICES",
            // "uuid": "HLP-" + randId(8).toUpperCase(),
            "productname": "HOME SERVICE CHARGE",
            "genericname": "Home Service Charge",
            "rrp": "1500",
            "10discountedoffrrp": "1500",
            "discountofferedforretail": "0",
            "doctallsexclusivediscountedprice": "1500",
            "location_uuid": "",
            "cities": "",
            "areas": "",
          },
        ],
        "ignorerecommendation": false,
        // "notes": note,
      };
  
  axios.post(`${process.env.REACT_APP_API_URL}/ep/advice/create`, payload, {
    headers: {
      'Content-Type': 'application/json',
      "x-auth-token": userdata.token
    },
  })
  .then(function (response) {

    dispatch(
      getHistro(data.uuid)
    );
    setTexts('prescription Created')
    sucessPop()
    getApi()
    closePres()
  })
  .catch(function (error) { 
    setTexts('Failed !')
    sucessPop()
    getApi()
    closePres()
  });
setPrescrip([])

setTimeout(function () {
  dispatch(getMedPrescription(data.uuid));
}, 3000);
  // }
  // else {
  //   console.log('error')
  // }
}

  return (
    <Grid container direction="column" style={{ paddingTop: "16px" }}>
      <Grid container justifyContent="space-between">
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "35px",
            color: "#013C44",
            marginLeft: "21px",
          }}
        >
          Create Prescription Medicine
        </Typography>
        <img
          src={dialogclose}
          alt="close button"
          style={{ marginRight: "16px", cursor: "pointer" }}
          onClick={() => closePres()}
        />
      </Grid>
      <hr
        style={{
          height: "0.5px",
          borderWidth: "0px",
          width: "326px",

          background: "#00839B",
          borderColor: "#00839B",
        }}
      />
      <Grid
        conatiner
        direction="column"
        style={{ width: "326px", paddingLeft: "10px", paddingTop: "26px" }}
      >
        {alredyAddedTest && alredyAddedTest.length !== 0 && 
        alredyAddedTest && alredyAddedTest.map((i, index) => {
          return (
            <Grid
            item
            container
            direction="column"
            //alignItems="center"
            style={{
              width: "307px",
              height: "496px",
              background: "#F0F5FE",
              border: "0.5px solid #66B5C3",
              borderRadius: "10px",
              paddingLeft: "12px",
              marginTop:"20px",
              marginBottom:"20px"

            }}
           >
          
            <Typography className={classes.formLabel} alignSelf="flex-start">
              Generic Name<span className={classes.mandatory}>*</span>
            </Typography>
            <input className={classes.inputField} 
              disabled
              data-id={index}
              value={i.GenName}
              name="GenName"

            />
            <Typography className={classes.formLabel} alignSelf="flex-start">
              Drug Name<span className={classes.mandatory}>*</span>
            </Typography>
            <input 
            className={classes.inputField} 
            disabled
            onChange = {handlePrescripChange}
            data-id={index}
            value={i.DrugName}
            name="DrugName"
            />
            <Grid item container>
              <Grid
                item
                container
                direction="column"
                style={{ width: "135px" }}
              >
                <Typography
                  className={classes.formLabel}
                  alignSelf="flex-start"
                >
                  Attribute<span className={classes.mandatory}>*</span>
                </Typography>
                <select className={classes.customSelect} 
                disabled
                data-id={index}
                value={i.Attr}
                name="Attr"
                onChange={handlePrescripChange}
                >
                    <option value="">Select Attribute</option>
                    <option value="Tabs" className="headersearchtxt">Tabs</option>
                    <option value="Capsules" className="headersearchtxt">Capsules</option>
                    <option value="Ampoule" className="headersearchtxt">Ampoule</option>
                    <option value="Powder" className="headersearchtxt">Powder</option>
                    <option value="Cream" className="headersearchtxt">Cream</option>
                </select>
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: "135px" }}
              >
                <Typography
                  className={classes.formLabel}
                  alignSelf="flex-start"
                >
                  Strength<span className={classes.mandatory}>*</span>
                </Typography>
                <input
                  className={classes.inputField}
                  disabled
                  style={{ width: "135px" }}
                  onChange={handlePrescripChange}
                  // placeholder="Please type here"
                  data-id={index}
                  value={i.Strength}
                  name="Strength"
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                item
                container
                direction="column"
                style={{ width: "135px" }}
              >
                <Typography
                  className={classes.formLabel}
                  alignSelf="flex-start"
                >
                  Duration<span className={classes.mandatory}>*</span>
                </Typography>
                <select className={classes.customSelect}
                disabled
                 data-id={index}
                 value={i.duration}
                 name="duration"
                 onChange={handlePrescripChange}
                 >
                    <option value="">Select Duration</option>
                    <option value="Once a day- M" className="headersearchtxt">Once a day- M</option>
                    <option value="Once a day- AF" className="headersearchtxt">Once a day- AF</option>
                    <option value="Once a day- N" className="headersearchtxt">Once a day- N</option>
                    <option value="Twice a day - M,N" className="headersearchtxt">Twice a day - M,N</option>
                    <option value="Thrice a day" className="headersearchtxt">Thrice a day</option>
                    <option value="Four times a " className="headersearchtxt">Four times a day </option>
                </select>
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: "135px" }}
              >
                <Typography
                  className={classes.formLabel}
                  alignSelf="flex-start"
                >
                  Days<span className={classes.mandatory}>*</span>
                </Typography>
                <input
                  className={classes.inputField}
                  disabled
                  style={{ width: "135px" }}
                  onChange = {handlePrescripChange}
                  data-id={index}
                  value={i.Days}
                  name="Days"
                />
              </Grid>
            </Grid>
            <button
              className={classes.mainButton}
              style={{
                background: "#00839B",
                width: "286px",
                marginTop: "37px",
              }}
              onClick={() => { handleTestDelete(i) }}
            >
              Delete
            </button> 

          </Grid>

          )
        })}
        {showForm ? (
          <>
         { prescrip && prescrip.length !== 0 ? (
          prescrip.map((i, index) => {
            console.log(i, "yfghj")
            return (
              <Grid
              item
              container
              direction="column"
              //alignItems="center"
              style={{
                width: "307px",
                height: "496px",
                background: "#F0F5FE",
                border: "0.5px solid #66B5C3",
                borderRadius: "10px",
                paddingLeft: "12px",
                marginTop:"20px"
              }}
             >
            
              <Typography className={classes.formLabel} alignSelf="flex-start">
                Generic Name<span className={classes.mandatory}>*</span>
              </Typography>
              <input className={classes.inputField} 
                // onChange={(e)=>setGenericName(e.target.value)}
                onChange = {handlePrescripChange}
                data-id={index}
                value={i.GenName}
                name="GenName"

              />
              <Typography className={classes.formLabel} alignSelf="flex-start">
                Drug Name<span className={classes.mandatory}>*</span>
              </Typography>
              <input className={classes.inputField} 
              onChange = {handlePrescripChange}
              data-id={index}
              value={i.DrugName}
              name="DrugName"
              />
              <Grid item container>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "135px" }}
                >
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Attribute<span className={classes.mandatory}>*</span>
                  </Typography>
                  <select className={classes.customSelect} 
                  data-id={index}
                  value={i.Attr}
                  name="Attr"
                  onChange={handlePrescripChange}
                  >
                      <option value="">Select Attribute</option>
                      <option value="Tabs" className="headersearchtxt">Tabs</option>
                      <option value="Capsules" className="headersearchtxt">Capsules</option>
                      <option value="Ampoule" className="headersearchtxt">Ampoule</option>
                      <option value="Powder" className="headersearchtxt">Powder</option>
                      <option value="Cream" className="headersearchtxt">Cream</option>
                  </select>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "135px" }}
                >
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Strength<span className={classes.mandatory}>*</span>
                  </Typography>
                  <input
                    className={classes.inputField}
                    style={{ width: "135px" }}
                    onChange={handlePrescripChange}
                    // placeholder="Please type here"
                    data-id={index}
                    value={i.Strength}
                    name="Strength"
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "135px" }}
                >
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Duration<span className={classes.mandatory}>*</span>
                  </Typography>
                  <select className={classes.customSelect}
                   data-id={index}
                   value={i.duration}
                   name="duration"
                   onChange={handlePrescripChange}
                   >
                      <option value="">Select Duration</option>
                      <option value="Once a day- M" className="headersearchtxt">Once a day- M</option>
                      <option value="Once a day- AF" className="headersearchtxt">Once a day- AF</option>
                      <option value="Once a day- N" className="headersearchtxt">Once a day- N</option>
                      <option value="Twice a day - M,N" className="headersearchtxt">Twice a day - M,N</option>
                      <option value="Thrice a day" className="headersearchtxt">Thrice a day</option>
                      <option value="Four times a " className="headersearchtxt">Four times a day </option>
                  </select>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "135px" }}
                >
                  <Typography
                    className={classes.formLabel}
                    alignSelf="flex-start"
                  >
                    Days<span className={classes.mandatory}>*</span>
                  </Typography>
                  <input
                    className={classes.inputField}
                    style={{ width: "135px" }}
                    onChange = {handlePrescripChange}
                    data-id={index}
                    value={i.Days}
                    name="Days"
                  />
                </Grid>
              </Grid>
              {/* <button
                className={classes.mainButton}
                style={{
                  background: "#00839B",
                  width: "286px",
                  marginTop: "37px",
                }}
                // onClick={() => setShowForm(false)}
                onClick={() => {
                  addPrescrip();
                  // setShowForm(true);
                }}
              >
                Add +
              </button> */}
              <button
                className={classes.mainButton}
                style={{
                  background: "#00839B",
                  width: "286px",
                  marginTop: "37px",
                }}
                onClick={handlePrescripDelete.bind(this, index)}
              >
                Delete
              </button> 

            </Grid>

            )})) : ''}
                          <button
                className={classes.mainButton}
                style={{
                  background: "#00839B",
                  width: "286px",
                  marginTop:"20px"
                }}
                onClick={() => {
                  addPrescrip();
                  setShowForm(true);
                }}
              >
                Add +
              </button>
          </>
        ) : (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: "307px",
                height: "94px",
                background: "#F0F5FE",
                border: "0.5px solid #66B5C3",
                borderRadius: "10px",
              }}
            >
              <button
                className={classes.mainButton}
                style={{
                  background: "#00839B",
                  width: "286px",
                }}
                // onClick={() => setShowForm(true)}
                onClick={() => {
                  addPrescrip();
                  setShowForm(true);
                }}
              >
                Add +
              </button>
            </Grid>
          </>
        )}
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          style={{
            width: "307px",
            height: "401px",
            background: "#F0F5FE",
            border: "0.5px solid #66B5C3",
            borderRadius: "10px",
            marginTop: "28px",
            marginBottom: "43px",
            paddingTop: "10px",
          }}
        >
          <Typography
            className={classes.formLabel}
            style={{ alignSelf: "flex-start", marginLeft: "12px" }}
          >
            Special Advise
          </Typography>
          <textarea
            className={classes.notesArea}
            style={{
              width: "283px",
              height: "255px",
              border: "0.5px solid #A0DFC4",
            }}
          />
          <button
            className={classes.mainButton}
            style={{ background: "#00839B", width: "286px", marginTop: "26px" }}
            onClick={handleSubmit}
          >
            Send Message
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreatePrescription;
