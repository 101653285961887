import React from 'react';
import { DashboardContent } from "./dashboardContent"

const Dashboard = () => {

    return (
        <>
            <DashboardContent />
        </>
    )
}

export default Dashboard;