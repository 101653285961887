import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#EFF1F7",
        border: "1px solid rgba(4, 102, 115, 0.3)",
        boxSizing: "border-box",
        borderRadius: "30px",
        marginTop: "20px"
    },
    typography: {
        fontFamily: [
            'GreycliffCFBold'
        ].join(','),
        fontWeight: "bold",
        fontSize: 25,
        color: '#013C44'
    },
    padding: {
        padding: 15
    },
    list: {
        '&:before': {
            content: "'• '",
            color: '#00839B',
            width: 20,
            height: 20,
            fontSize: 35,
            textAlign: 'end',
            position: 'relative',
            top: 4
        },
        fontFamily: [
            'VisueltRegular'
        ].join(','),
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '49.64px'
    },
    ul: {
        listStyle: 'none',
        paddingLeft: 20
    }
}));

const labHelpText = `
To search for any medicine, please use the filters in the order in which they have been presented - from left to right.

After searching for a particular medicine of your choosing, you will be presented a search result table containing multiple versions of the same medicine with slight variations, you can then use the radio button to select the particular medicine you want to prescribe and add it to the prescription document.

In the event that you need to reach the patient for further clarification before making a selection, you can move to the lower right section of your cockpit, select the chat functionality. You can either send a chat message to the patient which gets delivered in-app or send an SMS to the patient’s local mobile number by using the SMS button just underneath the chat window.

For further assistance, please reach out to us on support@doctall.com or call 09010996000`
const MedicalPrescriptionHelp = () => {
    const classes = useStyles();

    return (
        <Grid container xs={12} className={classes.container}>
            <Grid xs={12} className={classes.padding}>
                <Typography className={classes.typography}>Help</Typography>
            </Grid>
            <Grid xs={12}>
                <Divider />
            </Grid>
            <Grid xs={12} style={{ marginTop: 20 }}>
                <ul className={classes.ul}>
                    {
                        labHelpText.split('\n').filter(d => d)
                            .map(helpText => (<li className={classes.list}>{helpText}</li>))
                    }
                </ul>
            </Grid>
        </Grid>
    );
}

export default MedicalPrescriptionHelp;