import React, { useState } from 'react';
import { Grid, TextField, Input, Number , InputSuffix} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useStyle } from '../../../Admin/style/style'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import Toast from '../../../components/Toast';
import MuiPhoneNumber from 'material-ui-phone-number';
import { addPatient, editPatient } from '../../services/ServiceHelper';
import { getPatients } from '../../../redux/actions/ex-appointment-action';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import FilledInput from '@mui/material/FilledInput';
import moment from 'moment';
import "./../Addpatient.scss"
import { getVisits } from '../../../redux/actions/ex-appointment-action';
import { addVisit, searchPatient, newSearchPatient, addNewPatient } from '../../services/ServiceHelper';
import {
    /* the components you used */
    InputAdornment
  } from "@material-ui/core";

export default function AddPatientComponent(props) {
    const authState = useSelector((state) => state.loginReducer.payload);
    let history = useHistory()
    console.log(props)
    const dispatch = useDispatch();
    const { id, close, details } = props;
    console.log(details.uuid)
    const classes = useStyle();
    const [editFlag, setEditFlag] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);
    const [visitData, setVisitData] = useState()
    console.log(visitData)
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [city, setCity] = useState()
    const [area, setArea] = useState()
    const [door, setDoor] = useState()
    const [pin, setPin] = useState()
    const [street, setStreet] = useState()
    const [dob, setDob] = useState()
    const [citytosend, setCitytoSend] = useState()
    const [defaultCityId, setDefaultcityid] = useState()
    const [visitDetail, setVisitDetails] = useState();


        //New Patient
        const [mobile, setMobile] = useState();
        const [firstName, setFirstName] = useState();
        const [middleName, setMiddleName] = useState();
        const [lastName, setLastName] = useState();
        const [email, setEmail] = useState();
        const [gender, setGender] = useState();
        const [age, setAge] = useState();
        const [age1, setAge1] = useState();
        const [address, setAddress] = useState();
        const [bloodGroup, setBloodGroup] = useState();
        const [Symptoms, setSymtoms] = useState();
        const [Complaint, setComplaint] = useState();
        const [temperature, setTemperature] = useState();
        const patientUUID = details && details.uuid

        const SymptomsChange = (e) => {
            const { value } = e.target;
            console.log('Input value: ', value);
    
            // const re = /^[A-Za-z]+$/;
            // if (value === "" || re.test(value) === true) {
            //     setSymtoms(value);
            // }
            setSymtoms(value.replace(/[^a-zA-z]/ig,''));
        }
        const TempChange = (e) => {
            const { value } = e.target.value;
            console.log('Input value: ', e.target.value);
    
            // const re = /^[0-9\b]+$/;
            // if (value == "" || re.test(e.target.value) == true) {
            //     console.log(value, "/^[0-9\b]+$/")
            //     setTemperature(value);
            // }
            setTemperature(value);
        }
        const ComplaintsChange = (e) => {
            const { value } = e.target;
            console.log('Input value: ', value);
    
            // const re = /^[A-Za-z]+$/;
            // if (value === "" || re.test(value) === true) {
            //     setComplaint(value);
            // }
            setComplaint(value && value.replace(/[^a-zA-z]/ig,''));

        }

    const handleClose = () => {
        // alert("enter")
        close()
    };

    useEffect(() => {
        setAge1(details && details.dob)
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/allcities`)
            .then((res) => {
                setCityList(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])
    let addressDetails = details.residential_Address[0]
    useEffect(() => {
        cityList.map((item) => {
            if (item._id == addressDetails.city) {
                setDefaultcityid(item.uuid)
                fetcharea(item.uuid)
            }
        })
    }, [cityList])
    function fetcharea(cityId) {
        console.log(cityId, "city")
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/getArea/${cityId}`)
            .then(res => {
                setAreaList(res.data)

                // setLoading(false)
            }).catch(err => {
                console.log(err)
                // setLoading(false)
            })
    }


    const handleCityChange = (event) => {
        setCity(event && event.target && event.target.value)
        setDefaultcityid(event && event.target && event.target.value)
        cityList.map((item) => {
            if (item.uuid === event.target.value) {
                console.log(item._id)
                setCitytoSend(item._id)
            }
        })
        console.log(event.target)
        fetcharea(event && event.target && event.target.value)

        // console.log(event.target.value.id,"555")
    }
    const handleCityChange1 = (event) => {
        console.log(event.target.value)
        setCity(event && event.target && event.target.value)
        cityList.map((item) => {
            if (item.uuid === event.target.value) {
                console.log(item._id)
                setCitytoSend(item._id)
            }
        })


        console.log(event.target)
        fetcharea(event && event.target && event.target.value)
        console.log(event.target, "555")
        // console.log(event.target.value.id,"555")
    }
    const handleAreaChange = (event) => {
        setArea(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "555")
    }
    const handleDoorChange = (event) => {
        setDoor(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "door")
    }
    const handlePinChange = (event) => {
        setPin(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "555")
    }
    const handleStreetChange = (event) => {
        setStreet(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "555")
    }

    const handleDobChange = (event) => {
        setDob(event && event.target && event.target.value)
        console.log(event.target.value, "555")
    }




    useEffect(() => {
        if (id == 'Add') {
            setEditFlag(true)
        } else {
            setEditFlag(false)
        }
    }, [])
    const handleVisitView = (item) => {
        setVisitDetails(item)
        history.push({
            pathname: "/visit-view",
            state: {
                visitDetails: item,
            },
        });
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const { phoneNumber, mobile, fname, full_name, email, age, bloodgroup, gender, symptoms, additional_info, address, Height, Weight, reason } = event.target.elements;
            console.log(event.target.elements, "123456")
            let res = event.target.elements.phoneNumber.value
            
            let res1 = res.replace(/\D/g, '');
            let res2 = parseInt(res1)
            console.log(temperature)
            console.log(Complaint)

            let payLoad = {
                name: full_name.value,
                bloodGroup: bloodgroup.value,
                email: email.value,
                mobile: res2,
                age: age1,
                gender: gender.value,
                symptoms: Symptoms,
                additional_info: additional_info.value,
                address: address.value,
                doctor_uuid: authState.uuid,
                patient_uuid: patientUUID,
                height: Height.value,
                temperature: temperature,
                weight: Weight.value,
                reason_for_visit: Complaint
            }
            setVisitData(payLoad)
            console.log(visitData)
            let response = await addVisit(payLoad)
            if (response.status === 200 || response.status === 201) {
                setToast(true)
                setToastMessage('' + "Add Visit Successfully")
                setToastType('success')
                dispatch(getVisits(0, ""))
                setTimeout(() => {
                    handleClose()
                }, 1500);
                handleVisitView(response.data)
                console.log(details,"56565655")
            }
            handleVisitView(response.data)
        } catch (error) {
            if (error && error.response) {
                setToast(true)
                setToastMessage('' + "error.response.data")
                setToastType('error')
                setTimeout(() => {
                    handleClose()
                }, 1500);
            }
        }
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <div className='table_heading'>Add Appointments</div>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title="Close" placement="bottom" arrow>
                        <CloseIcon onClick={()=>handleClose()} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>

                </Grid>
            </Grid>
            <hr style={{ borderTop: "2px solid #00839B", borderBottom: "none", marginBottom: '5%' }} />
            <Formik action="" onSubmit={handleSubmit}>
                <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Mobile Number<span className='star-color'>*</span></h3>
                            <Field
                                as={MuiPhoneNumber}
                                name="phoneNumber"
                                defaultCountry={'ng'}
                                required
                                style={{ innerHeight: "50px" }}
                                fullWidth variant="outlined"
                                defaultValue={details && details.mobile}
                                value={details && details.mobile}
                                className={classes.primaryInput2}
                            />

                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Full Name<span className='star-color'>*</span></h3>
                            <input
                                name="full_name"
                                variant="outlined"
                                className="headersearchtxt"
                                style={{ width: "100%" }}
                                required
                                defaultValue={details && details.full_name}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Email ID<span className='star-color'>*</span></h3>
                            <input
                                style={{ width: '100%' }}
                                name="email"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                defaultValue={details && details.email}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Blood Group<span className='star-color'>*</span></h3>
                            <select
                                style={{ width: '100%' }}
                                name="bloodgroup"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                defaultValue={details && details.blood_group}
                                disabled={editFlag === false}
                            >
                                            <option value="Unknown" key="g">Unknown</option>
                                            <option value="A+" className="headersearchtxt" key="2">A+</option>
                                            <option value="A-" className="headersearchtxt" key="3">A- </option>
                                            <option value="B+" className="headersearchtxt" key="4">B+</option>
                                            <option value="B-" className="headersearchtxt" key="5">B-</option>
                                            <option value="O+" className="headersearchtxt" key="6">O+</option>
                                            <option value="O-" className="headersearchtxt" key="7">O-</option>
                                            <option value="AB+" className="headersearchtxt" key="8">AB+</option>
                                            <option value="AB-" className="headersearchtxt" key="9">AB-</option>
                                    </select>


                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Dob<span className='star-color'>*</span></h3>
                            <input
                                type='date'
                                name="dob"
                                variant="outlined"
                                className="headersearchtxt"
                                style={{ width: "100%" }}
                                onChange={(event) => handleDobChange(event)}
                                required
                                // defaultValue={details && Number(details.age)}dob
                                defaultValue={moment(details && details.dob).format("YYYY-MM-DD")}
                                value={moment(details && details.dob ? details.dob : dob).format("YYYY-MM-DD")}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Gender<span className='star-color'>*</span></h3>
                            <select  id="cars" className="headersearchtxt" name="gender"
                                required
                                defaultValue={details && details.gender}
                                disabled={editFlag === false}
                            >
                                <option value="">Select..</option>
                                <option value="male" className="headersearchtxt">Male</option>
                                <option value="female" className="headersearchtxt">Female</option>
                                <option value="other" className="headersearchtxt">Other</option>

                            </select>
                        </Grid>
                        <Grid item xs={6}>
                                <h3 className="input_lable1">Symptoms<span className='star-color'>*</span></h3>
                                <input
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="blood group"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name='symptoms'
                                    value={Symptoms}
                                    onChange={SymptomsChange}
                                    required

                                />
                            </Grid>

                            {/* <Grid item xs={1}> <h3 className="input_lable1">Temperature</h3></Grid> */}
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Temperature</h3>
                                {/* <NumberFormat
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="Temperature"
                                    // type="number"
                                    fullWidth
                                    variant="outlined"
                                    name="temperature"
                                    // required
                                    value={temperature}
                                    onChange={TempChange}
                                /> */}
                                 <FilledInput
                                    id="filled-adornment-weight"
                                    // value={values.weight}
                                    className=" headersearchtxt2"
                                    disableUnderline={true}
                                    type="number"
                                    step = "any"
                                    
                                    onChange={TempChange}
                                    // value={temperature}
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">°C</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                    'aria-label': 'weight',
                                    }}
                                />
                                
            
                            </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Weight</h3>
                                {/* <NumberFormat
                                    className="headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="Weight"
                                    // type="number"
                                    fullWidth
                                    variant="outlined"
                                    name="weight"
                                    // required
                                /> */}
                                 <FilledInput
                                    // id="filled-adornment-weight"
                                    id="Weight"
                                    // value={values.weight}
                                    className=" headersearchtxt2"
                                    disableUnderline={true}
                                    type="number"
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">KG</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                    'aria-label': 'weight',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <h3 className="input_lable1">Height</h3>
                                {/* <NumberFormat
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="Height"
                                    // type="number"
                                    fullWidth
                                    variant="outlined"
                                    name="height"
                                    step="any"
                                    // required
                                /> */}
                                <FilledInput
                                    // id="filled-adornment-weight"
                                    // value={values.weight}
                                    className=" headersearchtxt2"
                                    disableUnderline={true}
                                    id="Height"
                                    type="number"
                                    // type="number"
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">CM</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                    'aria-label': 'weight',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3 className="input_lable1">Chief Complaint<span className='star-color'>*</span></h3>
                                <input
                                    className=" headersearchtxt"
                                    autoFocus
                                    margin="dense"
                                    id="fname"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="reason"
                                    value={Complaint}
                                    onChange={ComplaintsChange}
                                    required
                                />
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Address</h3></Grid> */}
                            <Grid item xs={12}>
                                <h3 className="input_lable1">Address</h3>
                                <input
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="Address"
                                    label="Address : "
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name='address'
                                // required
                                />
                            </Grid>
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Height</h3></Grid> */}
                            {/* <Grid item xs={1}> <h3 className="input_lable1">Weight</h3></Grid> */}
                            
                            {/* <Grid item xs={4}></Grid> */}

                            {/* <Grid item xs={1}> <h3 className="input_lable1">Chief Complaint</h3></Grid> */}
                            {/* <Grid item xs={2}> <h3 className="input_lable1">Additional information</h3></Grid> */}
                            <Grid item xs={12}>
                                <h3 className="input_lable1">Additional information</h3>
                                <textarea
                                    rows="4" cols="100"
                                    className=" headersearchtxt"
                                    margin="dense"
                                    id="Address"
                                    label="Address : "
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name='additional_info'
                                // required
                                />
                            </Grid>

                    </Grid>
                   {/* <div className="btn_submit"> */}
                        <button className='submit_button' type='submit' >Submit</button>
                    {/* </div> */}
                </Form>
            </Formik>

            <Toast
                text={toastMessage}
                type={toastType}
                open={showToast}
                setClose={() => {
                    setToast(false);
                }}
            />

        </>
    );
}