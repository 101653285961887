import {
     customAsyncData, getDoctorDetails, getPatientDetails, getEpresDetails,
     getLabpresDetails, getAppointmentDate, getAppointmentSlot, nextAppointmentDetails
} from "./TestReducer";
import {
     getCity, homeserviceSearch, radiologySearchLabname, pathologySearchLabname,
     radiologySearch, getCityForPrescription, getAreaForPrescription,
     searchGenericNameForPrescription, searchProductNameForPrescription,
     extentReducer, changeStatusReducer, pathologySearch,
     pathologyArea, followupReducer, medAttribute, getMedPrescription, queryHistoryReducer, notifyDrReducer, getNotifyPopupScreen, allPrescriptionDetail
} from "./cockpitReducers";
import { appointmentSnapshot, doctorStat, doctorStatTwo, doctorStatThree, doctorStatFour, doctorCreateSLot } from "./dashboardReducer";
import { loginReducer } from "./loginReducer";
import { PatientSnapshot } from "./ex-appointment-reducer";

import { createCaseNotereducer, createPhyCaseNotereducer, getCaseNoteredu, getPhyNoteredus, getHIsreducer, createRiskreducer, getRISKarearedus, getRiskNoteredus } from './createcasenote'
import { snackBarReducer } from "./snackBarReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
     customAsyncData, getDoctorDetails, getPatientDetails, getEpresDetails,
     getLabpresDetails, getCity, pathologyArea, pathologySearch, nextAppointmentDetails,
     appointmentSnapshot, doctorStat,
     doctorStatTwo, doctorStatThree, doctorStatFour, getAppointmentDate, getAppointmentSlot,
     createCaseNotereducer, createPhyCaseNotereducer, getCaseNoteredu, getPhyNoteredus, loginReducer, doctorCreateSLot, extentReducer, changeStatusReducer,
     radiologySearch, getHIsreducer, followupReducer, getCityForPrescription, getAreaForPrescription, searchGenericNameForPrescription, searchProductNameForPrescription,
     notifications: snackBarReducer, getRISKarearedus,
     getRiskNoteredus, createRiskreducer, homeserviceSearch,
     pathologySearchLabname, radiologySearchLabname, medAttribute, getMedPrescription, queryHistoryReducer, notifyDrReducer, getNotifyPopupScreen, allPrescriptionDetail
     , PatientSnapshot,
});
export default rootReducer;