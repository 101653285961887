import React, { useState } from "react";
import regLogo from "../../../assets/images/regLogo.svg";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
} from "@material-ui/core";
import styles from "../style";
import SignUpForm from "./SignUpFormMob";
import RegConfirm from "./RegConfirm";

const useStyles = makeStyles((theme) => styles(theme));

const Registration = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [reg, setReg] = useState(true);
  return (
    <Grid container>
      {reg ? <SignUpForm regComplete={() => setReg(false)} /> : <RegConfirm />}
    </Grid>
  );
};

export default Registration;
