import React from "react";
import "./Referral.scss";
import drop from "../../assets/images/drop.svg";
import axios from "axios";
import { useSelector } from "react-redux";

function ReferralGet({ uniqReferral }) {
  const [reffDetails, setReffDetails] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/epquery/fetchIndiviRef/" +
          uniqReferral
      )
      .then((res) => setReffDetails(res.data.data));
  }, []);
  return (
    <div className="Referral">
      <div className="heading">Create Referral</div>
      <div className="dropdown" style={{ cursor: "not-allowed" }}>
        {reffDetails.referred_speciality}{" "}
        <img src={drop} alt="icon" style={{ cursor: "not-allowed" }} />
      </div>

      <div className="secondary_heading">Referral Note:</div>
      <textarea
        placeholder="Please type heare"
        className="input_referral"
        disabled
        defaultValue={reffDetails.referral_notes}
      ></textarea>
      <div className="reff_btn" style={{ cursor: "not-allowed" }}>
        Create Referral
      </div>
    </div>
  );
}

export default ReferralGet;
