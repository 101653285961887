import axios from 'axios';

export const addPatient = async (payLoad) => {
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/externalappointment`, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Add Patient");
}
export const addNewPatient = async (payLoad, token) => {
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/patient/create/from/doctall/saas`, payLoad, {
        headers: {
            'x-auth-token': token,
        }
    })
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Add Patient");
}
export const newUpdatePatient = async (payLoad, uuid, token) => {
    let response = await axios.put(`${process.env.REACT_APP_API_URL}/patient/update/from/doctall/saas/${uuid}`, payLoad, {
        headers: {
            'x-auth-token': token,
        }
    })
    if (response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Add Patient");
}
export const newUpdatePatient1 = async (payLoad, uuid, token) => {
    let response = await axios.put(`${process.env.REACT_APP_API_URL}/patient/doctor/update/from/doctall/saas/${uuid}`, payLoad, {
        headers: {
            'x-auth-token': token,
        }
    })
    if (response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Add Patient");
}
export const editPatient = async (payLoad, uuid, token) => {
    // let response = await axios.put(`${process.env.REACT_APP_API_URL}/externalappointment/patient/update/${uuid}`, payLoad)
    let response = await axios.put(`${process.env.REACT_APP_API_URL}/externalappointment/patient/update/${uuid}`, payLoad, {
        headers: {
            'x-auth-token': token,
        }
    })
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Edit Patient Details");
}


export const editDoctorProfile = async (payLoad, uuid, authToken) => {

    let response = await axios.put(`${process.env.REACT_APP_API_URL}/user/updateDetailsByAdmin/${uuid}`, payLoad, {
        headers: {
            'x-auth-token': authToken,
        }
    })
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Edit Doctor Details");
}


export const editBanks = async (payLoad, doctor_id) => {
    let response = await axios.put(`${process.env.REACT_APP_API_URL}/user/profile/doctor/bank/update/${doctor_id}`, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Edit Patient Details");
}



export const addVisit = async (payLoad) => {
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/externalappointment/addvisit`, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Add Patient");
}


export const searchPatient = async (payLoad, token) => {
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/externalappointment/search`, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to Add Patient");
}
export const newSearchPatient = async (payLoad, token) => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/patient/search/${payLoad}`, {
        headers: {
            'x-auth-token': token,
        }
    })
    console.log(response.status, response.statusText)
    if (response.status == 200) {
        return response;
    }
    throw new Error("Failed to Add Patient");
}

export const MessagePatient = async (payLoad, token) => {
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/sms/saas/send`, payLoad, {
        headers: {
            'x-auth-token': token,
        }
    })
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to message Patient");
}