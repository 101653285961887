import React, { useEffect, useState } from 'react';
import ICON1 from "../assets/images/icon1.svg"
import ICON2 from "../assets/images/icon2.svg"
import ICON3 from "../assets/images/icon3.svg"
import ICON4 from "../assets/images/icon4.svg"
import ICON5 from "../assets/images/icon5.svg"
import ICON6 from "../assets/images/icon6.svg"
import ICON7 from "../assets/images/icon7.svg"
import ICON8 from "../assets/images/icon8.svg"
import ICON9 from "../assets/images/icon9.svg"
import ICON10 from "../assets/images/icon10.svg"
import Logo from "../assets/images/logo.svg"
import Down from "../assets/images/DArrow.svg"
import Profile from "../assets/images/profile.svg"
import Notify from "../assets/images/notify.svg"
import Chap from "../assets/images/ChapImage.svg"
import Chat from "../assets/images/chat.svg"
import Search from "../assets/images/search.svg"
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useOnClickOutside from "./doctorDashboard/OutClickhandler";
import { Logout } from "../redux/actions/loginAction"
import moment from 'moment'
import Ellipse from '../assets/images/Ellipse.svg';
import Ellipse2 from '../assets/images/Ellipse2.svg'
import doc1 from '../assets/images/doc1.svg'
import { getNotifyDR } from '../redux/actions/cockpitAction';
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import { getNotifyPopupScreen, getMedPrescription } from '../redux/actions/cockpitAction';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { Tooltip, Button, message } from 'antd';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@material-ui/core';
import close from "../assets/images/cross_icon.svg";


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Dot from "../../src/assets/images/notification_dote.svg";
import { getAsyncData } from '../redux/actions/TestAction';


const drawerWidth = 500;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));



const Layout = (props) => {
    const ref = React.useRef();
    const dispatch = useDispatch();
    const [drop, setDrop] = useState(false);
    const [notifylist, setnotifylist] = useState([]);
    const [notifyPopupScreen, setNotifyPopupScreen] = useState(false);
    const [notificationPopup, setNotificationPopup] = useState(false)
    const userdata = useSelector(state => state.loginReducer.payload)
    const nofityDr = useSelector(state => state.notifyDrReducer)
    const GetNotify = useSelector(state => state.getNotifyPopupScreen != "" && state.getNotifyPopupScreen.payload != undefined && state.getNotifyPopupScreen.payload.length > 0 ?
        state.getNotifyPopupScreen.payload : []
    )
    const [notificationStatus, setNotificationStatus] = useState(true)
    const [GetNotifyFilter, setGetNotifyFilter] = useState([])

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [tempdata, setTempdata] = useState([]);

    let newdata = [];
    let getData = (title, createdAt, message) => {
        newdata = [title, createdAt, message];

        setTempdata(newdata);
        console.log("new data", tempdata);

    };



    const [popupOpen, setPopupOpen] = React.useState(false);

    const handleClickPopupOpen = () => {
        setPopupOpen(true);
    };

    const handleClickPopupClose = () => {
        setPopupOpen(false);
    };


    const checkChaproene = () => {
        if (props.data && props.data.local_doctor_uuid) {
            if (props.data.local_doctor_uuid == userdata.uuid) {
                return "local"
            }
            else {
                return "international"
            }
        }
        else {
            return false;
        }
    }
    useOnClickOutside(ref, () => {
        setNotificationPopup(false);
        setNotifyPopupScreen(false)
    });



    useEffect(() => {
        setInterval(function () {
            axios.get(process.env.REACT_APP_API_URL + `/notification/list/${userdata.uuid}`, {
                headers: {
                    'x-auth-token': userdata.token
                }
            }).then((res) => {
                setnotifylist(res.data)
            })
                .catch((err) => {
                    setnotifylist([])
                })
        }, 30000);
    }, [])


    useEffect(() => {
        if (props.data) {
            dispatch(getNotifyDR())
        }

    }, [props.data])
    useEffect(() => {
        if (props.loading) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [props.loading])
    const handleclose = () => {
        setNotificationPopup(false)
        setNotifyPopupScreen(false)
    }

    const handleCountData = () => {
        let filterData = []
        for (let x of notifylist) {
            filterData.push(x._id);
        }

        const data = JSON.stringify({ "id": filterData, "status": "read" });

        var config = {
            method: 'put',
            url: process.env.REACT_APP_API_URL + '/notification/status/all',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                // console.log(error);
            });

    }

    var filterCount = notifylist.filter(item => { if (item.status == "unread") return item });
    console.log("sdfsf", notifylist);

    return (
        <>

            <div className="container-doctall">

                <div className="Sidemenu">
                    <div className="navbar">
                        <a href="/"><img alt="" className="navimageselection" src={ICON1} crossorigin="anonymous" /></a>
                        <img alt="" className="navimage" src={ICON2} crossorigin="anonymous" />
                        <img alt="" className="navimage" src={ICON3} crossorigin="anonymous" />
                        <img alt="" className="navimage" src={ICON4} crossorigin="anonymous" />
                        <img alt="" className="navimage" src={ICON5} crossorigin="anonymous" />
                        <img alt="" className="navimage" src={ICON6} crossorigin="anonymous" />
                        <img alt="" className="navimage" src={ICON7} crossorigin="anonymous" />
                        <img alt="" className="navimage" src={ICON8} crossorigin="anonymous" />
                        <a href="/support"><Tooltip placement="topLeft" title="Chat With Admin"><img alt="" className="navimage" src={ICON9} crossorigin="anonymous" /></Tooltip></a>
                        <img alt="" className="navimage" src={ICON10} crossorigin="anonymous" />
                    </div>
                </div>
                <div className="content-holder">
                    {checkChaproene() ?
                        <div className="header-layout">
                            <div className="header-content">
                                <div className="headerleftChap">
                                    <img className="header-logoC" src={Logo} crossorigin="anonymous" />

                                    <div className="contentCB">Appointment ID: <div className="valueC">{props.data.uuid}</div></div>
                                    <div className="contentC">Appointment Type: <div className="valueC">{props.data.type}</div></div>

                                    <div className="contentCB">Date Created: <div className="valueC">{moment(props.data.createdAt).format("ddd, DD MMM YYYY")}</div></div>


                                </div>
                                <div className="headerrightChap">
                                    <div className="ChapDrop" onClick={() => { setDrop(!drop) }}>
                                        <img src={Chap} crossorigin="anonymous" />
                                        <div>
                                            <div>{checkChaproene() == "local" ? `Consulting Doctor: Dr ` : `Doctor Chaperone: Dr `}</div>
                                            <div>{checkChaproene() == "local" ? props.data.doctors[0] && props.data.doctors[0].full_name : props.data.local_doctors[0] && props.data.local_doctors[0].full_name}</div>

                                        </div>
                                        <img src={Down} className="imgarrow" crossorigin="anonymous" />


                                    </div>
                                    {drop && <div className="ChapDown">
                                        <div className="contentCDB">Speciality: <span className="valueCD">{checkChaproene() == "local" ? `${props.data.doctors[0].profile.speciality.join(",")}` : `${props.data.local_doctors[0].profile.speciality.join(",")}`}</span></div>
                                        <div className="contentCDB">Location: <span className="valueCD">{checkChaproene() == "local" ? `${props.data.doctors[0].profile.address ? props.data.doctors[0].profile.address.city : ""}` : `${props.data.local_doctors[0].profile.address ? props.data.local_doctors[0].profile.address.country : ""}`}</span></div>
                                        <div className="contentCD">Nationality: <span className="valueCD">{checkChaproene() == "local" ? `${props.data.doctors[0].profile.address ? props.data.doctors[0].profile.address.country : ""}` : `${props.data.local_doctors[0].profile.address ? props.data.local_doctors[0].profile.address.country : ""}`}</span></div>

                                    </div>}
                                    <div style={{ display: "flex", position: "relative" }}>
                                        <img className="header-notify" style={{ marginLeft: 20, cursor: 'pointer', top: '10px', left: '10px' }} src={Notify}
                                            onClick={() => {
                                                dispatch(getMedPrescription(props.data.uuid))
                                                setNotifyPopupScreen(!notifyPopupScreen)
                                                setNotificationPopup(false)
                                                handleCountData()
                                                setNotificationStatus(false)
                                            }}
                                            alt="icon"
                                            crossorigin="anonymous"
                                        />

                                        {filterCount.length === 0 ? "" : <div className="NOTIFY_POPUP_COUNT" style={{ top: '10px', left: '10px' }}>
                                            {filterCount.length}
                                        </div>}
                                        {notifyPopupScreen ? <div className="notificationPopup NotifyPopupScreen" style={{ right: '0px', top: '58px', zIndex: '1000' }}>

                                            {
                                                notifylist.map((value, index) =>
                                                    <div className="notificationList">
                                                        <div style={{ display: 'flex' }} key={index}>
                                                            <div className="NOTIFY_POPUP_GREEN_DESIGN">
                                                            </div>
                                                            <div style={{ width: '100%', height: 'auto' }}>

                                                                <div className="NOTIFY_POPUP_PRIMARY_TEXT">{value.message}</div>
                                                                <div className="NOTIFY_POPUP_DATE">{moment(value.createdAt).format("ddd, DD MMM YYYY")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }



                                        </div> : ""}

                                    </div>
                                </div>
                            </div>
                        </div> : <div className="header-layout">
                            <div className="header-content">
                                <div className="headerleft">
                                    <img className="header-logo" src={Logo} crossorigin="anonymous" />
                                    <div className="search">
                                        <input type="text" placeholder="Search" className="headersearch" />
                                        <img className="header-search" src={Search} crossorigin="anonymous" />

                                    </div>
                                </div>

                                <div className="headerright">
                                    <div className="image-holder" ref={ref}>

                                        {/* <div style={{ position: "relative" }}> */}
                                        <div className="HEADER_IMG_CONTAINER" >
                                            <img className="header-notify" src={Notify}
                                                onClick={handleDrawerOpen}
                                                //  onClick={() => {

                                                //     setNotifyPopupScreen(!notifyPopupScreen)
                                                //     setNotificationPopup(false)
                                                //     handleCountData()
                                                //     dispatch(getMedPrescription(props.data.uuid))
                                                //     setNotificationStatus(false)
                                                // }} 

                                                style={{ cursor: 'pointer' }} alt="icon" crossorigin="anonymous" />
                                            {filterCount.length === 0 ? "" : <div className="NOTIFY_POPUP_COUNT">
                                                {filterCount.length}
                                            </div>}

                                        </div>
                                        {notifyPopupScreen ? <div className="notificationPopup NotifyPopupScreen">

                                            {
                                                notifylist.map((value, index) =>
                                                    <div className="notificationList">
                                                        <div style={{ display: 'flex' }} key={index}>
                                                            <div className="NOTIFY_POPUP_GREEN_DESIGN">
                                                            </div>
                                                            <div style={{ width: '100%', height: 'auto' }}>

                                                                <div className="NOTIFY_POPUP_PRIMARY_TEXT">{value.message}</div>
                                                                <div className="NOTIFY_POPUP_DATE">{moment(value.createdAt).format("ddd, DD MMM YYYY")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }



                                        </div> : ""}

                                        <img className="header-chat" src={Chat} onClick={() => {
                                            setNotificationPopup(!notificationPopup)
                                            setNotifyPopupScreen(false)
                                        }} style={{ cursor: 'pointer' }} crossorigin="anonymous" />
                                        {notificationPopup ? <div className="notificationPopup NotifyDoc">
                                            <div className="notificationList">
                                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    <img src={Ellipse} alt="Ellipse" className="header_popup_dot" crossorigin="anonymous" />
                                                    <div style={{ position: 'relative' }}>
                                                        <img src={doc1} alt="profile" className="header_popup_profile" crossorigin="anonymous" />
                                                        <img src={Ellipse2} alt="Ellipse" className="header_popup_dot2" crossorigin="anonymous" />
                                                    </div>

                                                    <div className="header_popup_drName">
                                                        Dr. Adamo has sent a message
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notificationList">
                                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    <img src={Ellipse} alt="Ellipse" className="header_popup_dot" crossorigin="anonymous" />
                                                    <div style={{ position: 'relative' }}>
                                                        <img src={doc1} alt="profile" className="header_popup_profile" crossorigin="anonymous" />
                                                        <img src={Ellipse2} alt="Ellipse" className="header_popup_dot2" crossorigin="anonymous" />
                                                    </div>

                                                    <div className="header_popup_drName">
                                                        Dr. Adamo has sent a message
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notificationList">
                                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    <img src={Ellipse} alt="Ellipse" className="header_popup_dot" crossorigin="anonymous" />
                                                    <div style={{ position: 'relative' }}>
                                                        <img src={doc1} alt="profile" className="header_popup_profile" crossorigin="anonymous" />
                                                        <img src={Ellipse2} alt="Ellipse" className="header_popup_dot2" crossorigin="anonymous" />
                                                    </div>

                                                    <div className="header_popup_drName">
                                                        Dr. Adamo has sent a message
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notificationList">
                                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    <img src={Ellipse} alt="Ellipse" className="header_popup_dot" crossorigin="anonymous" />
                                                    <div style={{ position: 'relative' }}>
                                                        <img src={doc1} alt="profile" className="header_popup_profile" crossorigin="anonymous" />
                                                        <img src={Ellipse2} alt="Ellipse" className="header_popup_dot2" crossorigin="anonymous" />
                                                    </div>

                                                    <div className="header_popup_drName">
                                                        Dr. Adamo has sent a message
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notificationList">
                                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    <img src={Ellipse} alt="Ellipse" className="header_popup_dot" crossorigin="anonymous" />
                                                    <div style={{ position: 'relative' }}>
                                                        <img src={doc1} alt="profile" className="header_popup_profile" crossorigin="anonymous" />
                                                        <img src={Ellipse2} alt="Ellipse" className="header_popup_dot2" crossorigin="anonymous" />
                                                    </div>

                                                    <div className="header_popup_drName">
                                                        Dr. Adamo has sent a message
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}

                                        {/* </div> */}

                                    </div>

                                    {userdata && <div className="profile-holder">
                                        <div className="profile-name">
                                            {`${userdata.firstName} ${userdata.middleName && userdata.middleName != "" && userdata.middleName} ${userdata.lastName}`}
                                        </div>
                                        <LayoutPopup userdata={userdata} />
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    <div className="children">
                        {props.children}
                    </div>

                </div>
                {props.loading ? <div className="loadingcontainer">
                    <CircularProgress style={{ color: "#00839b" }} />
                </div> : null}





            </div>


            <Box sx={{ display: 'flex' }}>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <h1 className="notification_heading" >Notification <spam className="new_notification"> {filterCount.length} new</spam></h1>
                    {
                        notifylist.map((value, index) =>
                            <div key={index}>

                                <div style={{ padding: "20px 20px 0px 20px" }}>

                                    <Divider />
                                    <h2 className='notification_sub_heading'
                                        //  onClick={handleClickPopupOpen} 
                                        onClick={() => {
                                            getData(value.title, value.createdAt, value.message)
                                            handleClickPopupOpen()

                                        }}>

                                        <img src={Dot} alt="dot" /> &nbsp;
                                        {value.title}
                                        {/* Pharmacy Bills Paid 🎉 */}
                                        <span style={{ float: "right", color: "#5B7B83", fontSize: "16px", fontWeight: "400" }}>{moment(value.createdAt).format("ddd, DD MMM YYYY")}</span>
                                    </h2>
                                    <p className='notification_para'>
                                        {value.message}
                                    </p>
                                </div>
                            </div>

                        )
                    }
                </Drawer>
            </Box>


            {/* notification popup open */}
            <div>
                <Dialog
                    open={popupOpen}
                    onClose={handleClickPopupClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogContent >
                        <Grid item container>
                            <Grid md={11}>


                                <Typography style={{ fontSize: "35px", color: "#1A97AF" }}>

                                    {tempdata[0]}

                                </Typography>
                            </Grid>
                            <Grid md={1} align="right"><img src={close}
                                alt="close icon" style={{ width: "20px", marginTop: "10px" }}
                                onClick={handleClickPopupClose} /></Grid>
                        </Grid>
                        <hr /><br />
                        <Grid >
                            <Typography style={{ color: "#5B7B83", fontSize: "20px" }}> <br /><br />
                                {tempdata[2]}
                            </Typography>
                        </Grid>
                    </DialogContent>
                </Dialog>

            </div>
            {/* notification popup close*/}

        </>
    );
};

export default Layout;
const LayoutPopup = (props) => {
    const { userdata } = props;
    let history = useHistory();
    const ref = React.useRef();
    const [timepop, setTimePop] = React.useState(false);
    useOnClickOutside(ref, () => {
        setTimePop(false);
    });
    const doctorProfile = useSelector(state => state && state.getDoctorDetails && state.getDoctorDetails.payload && state.getDoctorDetails.payload[0])

    const [doctorData, setDoctorData] = useState('')
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/user/DoctorDetails/" + userdata.uuid).then((resp) => {
            setDoctorData(resp.data.data[0].profile_pic)
        })
    })
    console.log(doctorData, "fghj")
    return (
        <div className="layoutsession" ref={ref} onClick={() => { setTimePop(!timepop) }}>
            {/* {doctorProfile && doctorProfile.profile_pic ? <img className="header-profile" src={doctorProfile && doctorProfile.profile_pic} crossorigin="anonymous" /> : */}
            <img className="header-profile" src={doctorData} 
            // crossorigin="anonymous" 
            />
            {/* } */}
            {timepop ? <div className="popUp">
                <div className="contentpopup" onClick={() => { history.location.pathname.indexOf("/profile") > -1 ? history.push("/") : history.push("/profile") }}>{`${history.location.pathname.indexOf("/profile") > -1 ? "Dashboard" : "Profile"}`}</div>
                <div className="contentpopup" onClick={() => { Logout(); history.push("/login"); }}>Logout</div>
            </div> : null}
        </div>
    )
}