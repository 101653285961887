import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Dashboard/extlayout";
import DialogContent from "@material-ui/core/DialogContent";
import DoctoreCaseNotes from "./../Popup/DoctoreCaseNotes";
import { Dialog } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LabPres from "./PrescriptionLab/labTestDialog";
import moment from "moment";
import axios from "axios";
import MedicalPrescription from "./MedicalPrescription/medicalPrescriptionDialog";
import Close from "../../assets/images/close.svg";
import Plus from "../../assets/images/plus.svg";
import CloseIcon from "@mui/icons-material/Close";
import PsychiatryCaseNotes from "./../Popup/PsychiatryCaseNotes";
import SendIcon from "@mui/icons-material/Send";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import CreatecaseSuccess from "../../doctor-dashboard/Popup/CreatecaseSuccess";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import MobLayout from "../../Dashboard/mobLayout";
import ViewVisit from "../mobileComp/ViewVisit";
import styles from "../style";
const useStyles = makeStyles((theme) => styles(theme));
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #66B5C3",
    padding: "0px 5px",
    flexGrow: 1,
  },
  indicator: {
    backgroundColor: "#04A9C8",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: "20%",
    fontWeight: "bold",
    fontSize: 18,
    marginRight: theme.spacing(4),
    fontFamily: ["GreycliffCFBold"].join(","),
    "&:hover": {
      color: "#013C44",
      opacity: 1,
    },
    "&$selected": {
      color: "#013C44",
    },
    "&:focus": {
      color: "#013C44",
    },
    color: "#013C44",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const casedata = [
  "A00-B99. Certain infectious and parasitic diseases.",
  "C00-D49. Neoplasms.",
  "disorders involving the immune mechanism.",
];
const AntTab2 = withStyles((theme) => ({
  root: {
    textTransform: "none",
    width: "50%",
    fontWeight: "bold",
    fontSize: 18,
    marginRight: theme.spacing(4),
    fontFamily: ["GreycliffCFBold"].join(","),
    "&:hover": {
      color: "#013C44",
      opacity: 1,
    },
    "&$selected": {
      color: "#013C44",
    },
    "&:focus": {
      color: "#013C44",
    },
    color: "#013C44",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const monthNames = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function ViewVisitPage() {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  console.log(location, "locatiosfdsn");
  const customSM = useMediaQuery("(max-width:800px)");
  let info =
    history.location.state && history.location.state.visitDetails
      ? history.location.state.visitDetails
      : {};
  let visitInfo = info;
  console.log(history, "dddd");
  const user = useSelector((state) => state.loginReducer.payload);
  const [phpData, setphpData] = useState(false);
  const [stData, setStData] = React.useState([]);
  const [visitList, setVisitList] = useState([]);
  const [issue, setIssue] = useState([]);
  const [note, setNote] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [addCasePopup, setCaseAddPopup] = useState(false);
  const [docCase, setdocCase] = useState(false);
  const [caseSuccess, setCaseSuccess] = useState(false);
  const [medicineReportId, setMedicineReportId] = useState("");
  const [medicalPrescriptionPopup, setMedicalPrescriptionPopup] =
    useState(false);
  const [labPopup, setLabpopup] = useState(false);
  const [labAdviceId, setLabAdviceId] = useState("");
  const [caseNotesList, setCaseNoteList] = useState([]);
  const [caseNotesone, setCaseNotesone] = useState();
  const [loader, setLoader] = useState(false);
  const [closepdf, setClosePdf] = useState(false);
  const [openpdf, setOpenPdf] = useState(false);
  const [visitId, setVisitId] = useState();
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [prescriptionDate, setPrescriptionDate] = useState();
  const [prescriptionId, setPrescriptionId] = useState();
  const [prescription, setPrescription] = useState([]);
  const [prescriptionlabs, setPrescriptionlabs] = useState();
  const [prescriptionCheck, setPrescriptionCheck] = useState([]);
  const [labCheck, setLabCheck] = useState([]);
  const [eprescriptionId, setEprescriptionid] = useState();
  const [labTestId, setLabtestId] = useState();
  const [labtest, setLabtes] = useState();
  const [psyciatryNote, setpsyciatryNote] = useState(false);
  const [addPsychiatry, setAddPshychiatry] = useState(false);
  const [phySuccess, setPhySuccess] = useState(false);
  const [epsychiatrycasenotesList, setepsychiatrycasenotesList] = useState([]);
  const [epsychiatrycaseNotes, setepsychiatrycasenotes] = useState();
  const [tab2, setTab2] = React.useState(0);
  {
    console.log(prescription, "asd");
  }
  const handleTabChange2 = (event, newValue) => {
    setTab2(newValue);
  };
  const handleaddPsychiatry = () => {
    setAddPshychiatry(true);
  };
  const handleCloseaddPsychiatry = () => {
    setAddPshychiatry(false);
  };
  const handlePhySuccessPopup = () => {
    {
      phySuccess == false ? setPhySuccess(true) : setPhySuccess(false);
    }
  };
  const handlepsyciatryNote = () => {
    setpsyciatryNote(true);
  };
  const handlepsyciatryNote1 = () => {
    setpsyciatryNote(false);
  };
  const handleCasePopup = () => {
    setCaseAddPopup(true);
  };

  const handleCloseCasePopup = () => {
    setCaseAddPopup(false);
  };

  const handleSuccessPopup = () => {
    {
      caseSuccess == false ? setCaseSuccess(true) : setCaseSuccess(false);
    }
  };

  const handleMedicalPrescriptionOpen = (id) => {
    if (id) {
      setMedicineReportId(id);
      setMedicalPrescriptionPopup(true);
    } else {
      setMedicineReportId("Add");
      setMedicalPrescriptionPopup(true);
    }
  };

  const handleMedicalPrescriptionClose = () => {
    setMedicalPrescriptionPopup(false);
  };

  const handleLabOpen = (id) => {
    if (id) {
      setLabpopup(true);
      setLabAdviceId(id);
    } else {
      setLabpopup(true);
      setLabAdviceId("Add");
    }
  };

  const handleLabClose = () => {
    setLabpopup(false);
  };

  const handlePdf = (item, type) => {
    let endPoint = "";
    if (type === "PRESCRIPTION") {
      endPoint = `/ep/prescription/preview/${eprescriptionId}`;
      // endPoint = `/externalappointment/prescription/preview/${item.uuid}`
    } else if (type === "LABTEST") {
      endPoint = `/ep-lab/labetst/preview/${labTestId}`;
      // endPoint = `/externalappointment/ep-lab/preview/${item.uuid}`
    } else if (type === "Psychiatry") {
      endPoint = `/externalappointment/psychiatryNotePreview/${item.uuid}`;
    } else {
      endPoint = `/externalappointment/caseNotePreview/${item.uuid}`;
    }

    setLoader(true);
    let dt;
    let url;
    async function getpdf(item) {
      try {
        await axios
          .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
            headers: {
              "x-auth-token": authState.token,
            },
          })
          .then((res) => {
            if (res.data) {
              dt = res.data;
              setLoader(false);
            }
          });

        handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
      } catch (error) {
        failurePdfOpen();
      }
    }

    getpdf(item);
  };

  const failurePdfOpen = () => {
    setClosePdf(true);
  };

  const failurePdfClose = () => {
    setClosePdf(false);
  };

  const handlePdfOpen = () => {
    setOpenPdf(true);
  };

  const handlePdfClose = () => {
    setOpenPdf(false);
  };
  const authState = useSelector((state) => state.loginReducer.payload);
  console.log(visitInfo, "visit");
  useEffect(() => {
    // console.log(authState.uuid, "454656465")
    //Patient details from visit
    // const api = process.env.REACT_APP_API_URL + `/externalappointment/patient/edit/${visitInfo.patient_uuid}`;
    const api =
      process.env.REACT_APP_API_URL +
      `/patient/patientDetail/${
        visitInfo.patient_uuid ? visitInfo.patient_uuid : visitInfo.uuid
      }`;
    const token = user.token; /*take only token and save in token variable*/
    axios
      .get(api, { headers: { "x-auth-token": `${token}` } })
      .then((response) => {
        setphpData(response.data);
        setStData(response.data);
      });
  }, []);

  useEffect(() => {
    //Prescription list
    const api =
      process.env.REACT_APP_API_URL +
      `/externalappointment/prescription/visit/ep/${
        visitId ? visitId : visitInfo.uuid
      }`;
    const token = user.token; /*take only token and save in token variable*/
    axios
      .get(api, { headers: { "x-auth-token": `${token}` } })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.length &&
          response.data.length > 0
        ) {
          if (
            response.data[0] &&
            response.data[0].medication &&
            response.data[0].medication.length > 0
          ) {
            try {
              let xList = response.data[0].medication;
              let xdate =
                response.data[0].updatedAt && response.data[0].updatedAt
                  ? response.data[0].updatedAt
                  : "NA";
              let id =
                response.data[0].uuid && response.data[0].uuid
                  ? response.data[0].uuid
                  : "NA";
              setPrescriptionList(xList);
              setPrescriptionDate(xdate);
              setPrescriptionId(id);
            } catch (error) {
              console.log(error);
            }
          }
        }
      });

    // fetchAddedTests(visitInfo.uuid)
  }, [medicalPrescriptionPopup, labPopup]);

  // const fetchAddedTests = async (visitId) => {
  //   try {
  //     let response = await axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/ep-lab/visit/epl/${visitId}`)
  //     if (response.status === 200) {
  //       let epId = response.data && response.data[0] && response.data[0].uuid
  //       setPrescriptionId(epId)
  //       setAlredyAddedTests(response.data && response.data && response.data[0] && response.data[0].tests)

  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    //Patients visit
    // const VisitList = process.env.REACT_APP_API_URL + `/externalappointment/patientvisit/list/${visitInfo.patient_uuid}`;
    const VisitList =
      process.env.REACT_APP_API_URL +
      `/patient/patientvisit/allist?patient_uuid=${
        visitInfo.patient_uuid ? visitInfo.patient_uuid : visitInfo.uuid
      }&limit=10&page=0`;
    const token = user.token;
    axios
      .get(VisitList, { headers: { "x-auth-token": `${token}` } })
      .then((response) => {
        setVisitList(response.data[0]);
      });
  }, []);

  useEffect(() => {
    setVisitId(visitInfo.uuid);
  }, [visitInfo]);

  const DynamicListByVisitId = () => {
    // Case notes list dynamic visit id
    try {
      setCaseNoteList([]);
      // const VisitList = `${process.env.REACT_APP_API_URL}/externalappointment/patientvisit/${visitId ? visitId : visitInfo && visitInfo.uuid}`;
      const VisitList = `${
        process.env.REACT_APP_API_URL
      }/externalappointment/patientvisit/details/${
        visitId ? visitId : visitInfo && visitInfo.uuid
      }`;
      const token = user.token;
      axios.get(VisitList).then((response) => {
        let length =
          response &&
          response.data &&
          response.data.extdoctorcasenotes &&
          response.data.extdoctorcasenotes.length - 1;
        setIssue(response.data && response.data.issues);
        //caseNotes
        setNote(
          response &&
            response.data &&
            response.data.notes &&
            response.data.notes[0]
        );
        setCaseNoteList(
          response && response.data && response.data.extdoctorcasenotes
        );
        setCaseNotesone(
          response &&
            response.data &&
            response.data.extdoctorcasenotes &&
            response.data.extdoctorcasenotes[length]
        );
        //psypsychiatrycasenotes
        let psylength =
          response &&
          response.data &&
          response.data.extpsychiatrycasenotes &&
          response.data.extpsychiatrycasenotes.length - 1;
        setepsychiatrycasenotesList(
          response &&
            response.data &&
            response &&
            response.data.extpsychiatrycasenotes
        );
        setepsychiatrycasenotes(
          response &&
            response.data &&
            response &&
            response.data.extpsychiatrycasenotes &&
            response.data.extpsychiatrycasenotes[psylength]
        );
        //Prescription Labs
        setPrescriptionlabs(
          response &&
            response.data &&
            response.data.ext_prescriptionlabs &&
            response.data.ext_prescriptionlabs[0] &&
            response.data.ext_prescriptionlabs[0].createdAt
        );
        setPrescription(
          response &&
            response.data &&
            response.data.exteprescriptions &&
            response.data.exteprescriptions[0]
        );
        // setPrescription(response && response.data && response.data.exteprescriptions[0] && response.data.exteprescriptions[0].createdAt)
        //check prescription and lb pdf avialable or not
        setPrescriptionCheck(
          response &&
            response.data &&
            response.data.exteprescriptions &&
            response.data.exteprescriptions[0] &&
            response.data.exteprescriptions[0].medication
        );
        setLabCheck(
          response &&
            response.data &&
            response.data.ext_prescriptionlabs &&
            response.data.ext_prescriptionlabs[0] &&
            response.data.ext_prescriptionlabs[0].tests
        );
        setLabtestId(
          response &&
            response.data &&
            response.data.ext_prescriptionlabs &&
            response.data.ext_prescriptionlabs &&
            response.data.ext_prescriptionlabs[0] &&
            response.data.ext_prescriptionlabs[0].uuid
        );
        // setLabtestId(response && response.data && response.data.ext_prescriptionlabs)
        //id of prescription and labtest and psychiatry
        setLabtes(
          response &&
            response.data &&
            response.data.ext_prescriptionlabs &&
            response.data.ext_prescriptionlabs[0] &&
            response.data.ext_prescriptionlabs[0].uuid
        );
        setEprescriptionid(
          response &&
            response.data &&
            response.data.exteprescriptions &&
            response.data.exteprescriptions[0] &&
            response.data.exteprescriptions[0].uuid
        );
        // setEprescriptionid(response && response.data && response.data.exteprescriptions.uuid)
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    DynamicListByVisitId();
  }, [refresh, visitId]);

  const [heightS, setHeightS] = useState("");

  const checkSafari = () => {
    return (
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      )
    );
  };
  useEffect(() => {
    if (!checkSafari()) {
      setHeightS("auto");
    } else {
      setHeightS("80px");
    }
  }, []);

  return customSM ? (
    <>
      <MobLayout>
        <div  style={{marginTop:heightS}} >
          <ViewVisit data={location.state} />
        </div>
      </MobLayout>
    </>
  ) : (
    <div style={{ overflowX: "hidden" }}>
      <Layout>
        <Dialog
          open={phySuccess}
          onClose={handlePhySuccessPopup}
          maxWidth={"lg"}
          fullWidth={true}
          scroll={"body"}
          disableBackdropClick
        >
          <div className="ContainerWrapper">
            {/* <img
              src={Close}
              alt="Close"
              className="Closepopup"
              onClick={() => setPhySuccess(false)}
              crossorigin="anonymous"
            /> */}
            <CancelIcon
              onClick={() => setPhySuccess(false)}
              style={{
                cursor: "pointer",
                marginRight: "0px",
                float: "right",
                marginLeft: "auto",
                fontSize: "xx-large",
              }}
            />
            <CreatecaseSuccess
              value={" You Have Successfully Created phsyciatry Case Notes"}
              otherMessage={true}
            />
          </div>
        </Dialog>
        <Dialog
          open={caseSuccess}
          onClose={handleSuccessPopup}
          maxWidth={"lg"}
          fullWidth={true}
          scroll={"body"}
          disableBackdropClick
        >
          <div className="ContainerWrapper">
            {/* <img
              src={Close}
              alt="Close"
              className="Closepopup"
              onClick={() => setCaseSuccess(false)}
              crossorigin="anonymous"
            /> */}
            <CancelIcon
              onClick={() => setCaseSuccess(false)}
              style={{
                cursor: "pointer",
                marginRight: "0px",
                float: "right",
                marginLeft: "auto",
                fontSize: "xx-large",
              }}
            />
            <CreatecaseSuccess
              value={" You Have Successfully Created Case Notes"}
              otherMessage={true}
            />
          </div>
        </Dialog>
        <Dialog
          open={labPopup}
          onClose={handleLabClose}
          maxWidth={"lg"}
          fullWidth={true}
          scroll={"body"}
          disableBackdropClick
        >
          <div className="ContainerWrapper">
            {/* <img
              src={Close}
              alt="Close"
              className="Closepopup"
              onClick={() => setLabpopup(false)}
              crossorigin="anonymous"
            /> */}
            <CancelIcon
              onClick={() => setLabpopup(false)}
              style={{
                cursor: "pointer",
                marginRight: "0px",
                float: "right",
                marginLeft: "auto",
                fontSize: "xx-large",
              }}
            />

            <LabPres
              visitId={visitId}
              data={visitInfo}
              id={labAdviceId}
              getApi={() => DynamicListByVisitId()}
            />
          </div>
        </Dialog>
        <Dialog
          disableBackdropClick
          maxWidth={"xl"}
          open={addCasePopup}
          scroll={"body"}
          onClose={handleCloseCasePopup}
        >
          <div className="ContainerWrapper">
            {/* <img
              src={Close}
              alt="Close"
              className="Closepopup"
              onClick={() => setCaseAddPopup(false)}
              crossorigin="anonymous"
            /> */}
            <DoctoreCaseNotes
              getApi={() => DynamicListByVisitId()}
              data={visitInfo}
              handleCloseCasePopup={handleCloseCasePopup}
              handleSuccessPopup={() => handleSuccessPopup()}
              refreshScreen={() => setRefresh(Math.floor(Math.random() * 10))}
            />
          </div>
        </Dialog>

        <Dialog
          open={medicalPrescriptionPopup}
          onClose={handleMedicalPrescriptionClose}
          maxWidth={"lg"}
          fullWidth={true}
          scroll={"body"}
          disableBackdropClick
        >
          <div className="ContainerWrapper">
            {/* <img
              src={Close}
              alt="Close"
              className="Closepopup"
              onClick={() => setMedicalPrescriptionPopup(false)}
              crossorigin="anonymous"
            /> */}
            <CancelIcon
              onClick={() => setMedicalPrescriptionPopup(false)}
              style={{
                cursor: "pointer",
                marginRight: "0px",
                float: "right",
                marginLeft: "auto",
                fontSize: "xx-large",
              }}
            />

            <MedicalPrescription
              getApi={() => DynamicListByVisitId()}
              data={phpData}
              visitId={visitId}
              visitData={visitInfo}
              closefn={setMedicalPrescriptionPopup}
              // medlist={chapMedPre}
              id={medicineReportId}
            />
          </div>
        </Dialog>

        <div className="DCL_Container DCL_C_Row">
          {/* <div className="DCLC_Left DCL_C_Column"> */}

          <div className="DCLC_Left">
            <div className="DCLCL_Top1 DCL_C_Row1">
              <div className="DCLCLT_Container DCL_C_Column">
                <div className="DCLCLTC_Header">Patient Details</div>
                <div className="DCLCLTC_ContentWraper">
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Name: </span>
                    <span className="DCLCLTC_Value">{phpData.full_name}</span>
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Phone: </span>
                    <span className="DCLCLTC_Value">
                      {phpData.mobile}{" "}
                      <span
                        style={{ marginLeft: "1%", marginTop: "100px" }}
                      ></span>
                    </span>
                    {/* <SendIcon
                      className="DCLCLTC_SMS"
                      onClick={() => setSendSms(true)}
                    /> */}
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Gender: </span>
                    <span className="DCLCLTC_Value">{phpData.gender}</span>
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Date: </span>
                    <span className="DCLCLTC_Value">
                      {moment(phpData.createdAt).format("ddd, DD MMM YYYY")}
                    </span>
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Time: </span>
                    <span className="DCLCLTC_Value">
                      {/* {moment(phpData && phpData.start_time && phpData.start_time).format("hh:mm A")} */}
                      {moment(phpData.createdAt).format("hh:mm A")}
                    </span>
                  </div>
                  <div className="DCLCLTC_Content_L">
                    <span className="DCLCLTC_Name">Date of Birth: </span>
                    <span className="DCLCLTC_Value">
                      {/* {getAge(
                        moment(phpData.gender && phpData.dob).format("YYYY/MM/DD")
                      )} */}
                      {moment(phpData.dob).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="DCLCL_Bottom DCL_C_Column">
                <div className="DCLCLB_Text">Current issues</div>
                <div>
                  <div className="DCLCLB_Iptitle">
                    Chief complaint :{" "}
                    {visitInfo &&
                    visitInfo.reason_for_visit &&
                    visitInfo.reason_for_visit
                      ? visitInfo.reason_for_visit
                      : visitInfo.name}
                  </div>
                  <div className="DCLCLB_Iptitle">
                    Symptoms :{" "}
                    {visitInfo && visitInfo.symptoms && visitInfo.symptoms
                      ? visitInfo.symptoms
                      : "NA"}
                  </div>
                  <div className="DCLCLB_Iptitle">
                    Temperature :{" "}
                    {visitInfo && visitInfo.temperature && visitInfo.temperature
                      ? visitInfo.temperature
                      : "NA"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <div>&nbsp;</div>
      <div className="CD_Container">
        <div className="CD_Subcontainer">
          <div className="CD_SCont_1">
            <div className="HA_Title">Visit History</div>
            {/* <div className="HA_Content DCL_C_Row">
                <div class="HA_Left">
                  <div class="HAL_Line"></div>
                </div>
                <div className="HA_Right">
                  <div className="HAR_Space"></div>
                  <div className="HAs_Container_Wraper">
                    <div clssName="HAs_History">
                      {visitList && visitList.extpatientvisit && visitList.extpatientvisit.map((item, index) => (
                        <div>
                          <div className="DCLCLB_Iptitle1">
                            {"Visit" + " "}{index + 1}
                          </div>
                          <div class="HASC_Dot1 history"></div>
                          <div className="DCLCLB_Iptitle2">
                            {moment(item.createdAt).format("ddd, DD MMM YYYY")} <button className="HA_Title_Content_button1" onClick={() => setVisitId(item.uuid)}>Select</button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="HA_Content DCL_C_Row">
              <div class="HA_Left">
                <div class="HAL_Line"></div>
              </div>
              <div className="HA_Right">
                <div className="HAR_Space"></div>
                <div className="HAs_Container_Wraper">
                  <div clssName="HAs_History">
                    {visitList &&
                      visitList.extpatientvisit &&
                      visitList.extpatientvisit.map((item, index) => (
                        <div>
                          <div className="DCLCLB_Iptitle1">
                            {"Visit" + " "}
                            {index + 1}
                          </div>
                          <div class="HASC_Dot1 history"></div>
                          <div className="DCLCLB_Iptitle2">
                            {moment(item.createdAt).format("ddd, DD MMM YYYY")}{" "}
                            <button
                              className="HA_Title_Content_button1"
                              onClick={() => setVisitId(item.uuid)}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="HAR_Space"></div>
              </div>
            </div>
          </div>
          <div className="CD2_SCont_1121">
            <>
              <div className="CD_Container1">
                <div class="HASC_Dot history"></div>
                {/* <div className="HA_Title112"> */}
                <div className="HA_Tab_Container_1">
                  <AntTabs
                    value={tab2}
                    onChange={handleTabChange2}
                    aria-label="ant example"
                  >
                    <AntTab2 label="Create Case Notes" />
                    <AntTab2 label="Psychiatry Case Notes" />
                  </AntTabs>
                </div>
                {/* </div> */}
                <div
                  style={{
                    overflowY: "auto",
                    padding: "15px",
                    height: "505px",
                  }}
                >
                  {/* case notes New Ui */}
                  {tab2 == 0 && (
                    <>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Chief complaint:{" "}
                          <span className="PHP_Value">
                            {" "}
                            &nbsp;&nbsp;
                            {caseNotesone && caseNotesone.chief_complaint}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          History of patient complaint:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;
                            {caseNotesone && caseNotesone.patient_complaint}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Diagnosis:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;{caseNotesone && caseNotesone.diagnosis}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Plan/Action:{" "}
                          <span className="PHP_Value">
                            {" "}
                            &nbsp;&nbsp;
                            {caseNotesone && caseNotesone.plan_action}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Other information:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp; {caseNotesone && caseNotesone.others}
                          </span>
                        </div>
                      </div>

                      {/* over */}
                      <button
                        className="HA_Title_Content_button"
                        style={{
                          height: "auto",
                          width: "180px",
                          marginLeft: "65%",
                          cursor: "pointer",
                          backgroundColor: "#046673",
                        }}
                        onClick={() => handleCasePopup()}
                      >
                        Create Case Notes
                      </button>

                      {caseNotesList && caseNotesList.length > 0 ? (
                        caseNotesList.map((i) => (
                          <>
                            <div className="value">
                              <div className="name">
                                <div className="DCLCLB_Iptitle2">
                                  {moment(i.createdAt).format(
                                    "ddd, DD MMM YYYY"
                                  )}{" "}
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "60%",
                                    }}
                                    className="HA_Title_Content_button1"
                                    id={i.uuid}
                                    onClick={() => handlePdf(i, "CASE")}
                                  >
                                    View
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className="value">
                          <div className="name">
                            {/* <div className="text">No Past data found</div> */}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {tab2 == 1 && (
                    <>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Presenting complaints:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;
                            {epsychiatrycaseNotes &&
                              epsychiatrycaseNotes.present_comp}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          History:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;
                            {epsychiatrycaseNotes &&
                              epsychiatrycaseNotes.his_of_present_comp}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Past Psychiatry history:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;{" "}
                            {epsychiatrycaseNotes &&
                              epsychiatrycaseNotes.past_medi_his}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Past Medical history:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;
                            {epsychiatrycaseNotes &&
                              epsychiatrycaseNotes.past_medi_his}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Family history:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;
                            {epsychiatrycaseNotes &&
                              epsychiatrycaseNotes.family_his}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Personal history:{" "}
                          <span className="PHP_Value">
                            &nbsp;&nbsp;
                            {epsychiatrycaseNotes &&
                              epsychiatrycaseNotes.personal_his}{" "}
                          </span>
                        </div>
                      </div>

                      {/* over */}
                      <button
                        className="HA_Title_Content_button"
                        style={{
                          height: "auto",
                          width: "180px",
                          marginLeft: "65%",
                          cursor: "pointer",
                          backgroundColor: "#046673",
                        }}
                        onClick={() => handleaddPsychiatry()}
                      >
                        Create Case Notes
                      </button>

                      {epsychiatrycasenotesList &&
                      epsychiatrycasenotesList.length > 0 ? (
                        epsychiatrycasenotesList.map((i) => (
                          <>
                            <div className="value">
                              <div className="name">
                                <div className="DCLCLB_Iptitle2">
                                  {moment(i.createdAt).format(
                                    "ddd, DD MMM YYYY"
                                  )}{" "}
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "60%",
                                    }}
                                    className="HA_Title_Content_button1"
                                    id={i.uuid}
                                    onClick={() => handlePdf(i, "Psychiatry")}
                                  >
                                    View
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className="value">
                          <div className="name"></div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
            <div>
              {/* <button className="HA_Title_Content_button"
                  onClick={() => handleCasePopup()}
                >Create Case Notes</button> */}
            </div>
          </div>
          <div className="CD2_SCont_1">
            <div className="HA_Title">Prescription/Lab Test Advice</div>
            <div className="HA_Cont_style1">
              {prescriptionCheck && prescriptionCheck.length >= 1 ? (
                <button
                  className="DCLCLB_Iptitle1"
                  onClick={() => handleMedicalPrescriptionOpen()}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#00839b",
                    opacity: "0.5",
                  }}
                  disabled
                >
                  Create Medicine Prescription{" "}
                  <img
                    src={Plus}
                    crossorigin="anonymous"
                    style={{ marginLeft: "7%" }}
                  />
                  {/* <div className="CCNS_LI"> */}
                  {/* </div> */}
                </button>
              ) : (
                <button
                  className="DCLCLB_Iptitle1"
                  onClick={() => handleMedicalPrescriptionOpen()}
                  style={{ cursor: "pointer" }}
                >
                  Create Medicine Prescription{" "}
                  <img
                    src={Plus}
                    crossorigin="anonymous"
                    style={{ marginLeft: "7%" }}
                  />
                </button>
              )}
              {labCheck && labCheck.length >= 1 ? (
                <button
                  className="DCLCLB_Iptitle1"
                  onClick={() => handleLabOpen()}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#00839b",
                    opacity: "0.5",
                  }}
                  disabled
                >
                  {/* {routedata && routedata.reasons && routedata.reasons.join(",")} */}
                  Create Lab Test Advice{" "}
                  <img
                    src={Plus}
                    style={{ marginLeft: "25%" }}
                    crossorigin="anonymous"
                  />
                </button>
              ) : (
                <button
                  className="DCLCLB_Iptitle1"
                  onClick={() => handleLabOpen()}
                  style={{ cursor: "pointer" }}
                >
                  {/* {routedata && routedata.reasons && routedata.reasons.join(",")} */}
                  Create Lab Test Advice{" "}
                  <img
                    src={Plus}
                    style={{ marginLeft: "25%" }}
                    crossorigin="anonymous"
                  />
                </button>
              )}

              <div>&nbsp;</div>
              <div className="DCLCLB_Iptitle11">Medicine Report</div>
              {prescriptionCheck && prescriptionCheck.length > 0 ? (
                <>
                  {/* {prescriptionCheck.map((item) => (  */}
                  <div className="DCLCLB_Iptitle2">
                    <>
                      {/* {moment(prescription[0] && prescription[0].createdAt).format('LL')} <button className="HA_Title_Content_button1" style={{ cursor: 'pointer',marginLeft:"40%" }} onClick={() => handlePdf('item', "PRESCRIPTION")}>View</button><button className="HA_Title_Content_button1" style={{ cursor: 'pointer',marginLeft:"2%" }} onClick={() =>handleMedicalPrescriptionOpen()}>Edit</button> */}
                      {moment(prescription && prescription.updatedAt).format(
                        "LL"
                      )}{" "}
                      <button
                        className="HA_Title_Content_button1"
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePdf("item", "PRESCRIPTION")}
                      >
                        View
                      </button>
                      <button
                        className="HA_Title_Content_button1"
                        style={{ cursor: "pointer", marginLeft: "2%" }}
                        onClick={() => handleMedicalPrescriptionOpen()}
                      >
                        Edit
                      </button>
                    </>
                  </div>
                  {/* ))} */}
                </>
              ) : (
                <div className="DCLCLB_Iptitle2">No Past Data Found</div>
              )}

              <div className="DCLCLB_Iptitle11">Lab Report</div>
              {labCheck && labCheck.length > 0 ? (
                <>
                  {/* {labCheck.map((item) => ( */}
                  <div className="DCLCLB_Iptitle2">
                    <>
                      {moment(labtest && labtest.updatedAt).format("LL")}{" "}
                      <button
                        className="HA_Title_Content_button1"
                        style={{ cursor: "pointer", marginLeft: "40%" }}
                        onClick={() => handlePdf("item", "LABTEST")}
                      >
                        View
                      </button>{" "}
                      <button
                        className="HA_Title_Content_button1"
                        style={{ cursor: "pointer", marginLeft: "2%" }}
                        onClick={() => handleLabOpen()}
                      >
                        Edit
                      </button>
                    </>
                  </div>
                  {/* ))} */}
                </>
              ) : (
                <div className="DCLCLB_Iptitle2">No Past Data Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"lg"}
        fullWidth={true}
        open={openpdf}
        scroll={"body"}
        onClose={handlePdfClose}
      >
        <div className="ContainerWrapper">
          {/* <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => handlePdfClose()}
            crossorigin="anonymous"
          /> */}
          <CancelIcon
            onClick={() => handlePdfClose(false)}
            style={{
              cursor: "pointer",
              marginRight: "0px",
              float: "right",
              marginLeft: "auto",
            }}
          />
          <DialogContent>
            <iframe src="" className="pdfiframe" id="pdf"></iframe>
          </DialogContent>
        </div>
      </Dialog>
      {/* PsychiatryCaseNotes */}
      <Dialog
        maxWidth={"lg"}
        open={addPsychiatry}
        scroll={"body"}
        onClose={handleCloseaddPsychiatry}
      >
        <div className="ContainerWrapper">
          {/* <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setAddPshychiatry(false)}
            crossorigin="anonymous"
          /> */}
          {/* <CancelIcon onClick={() => setAddPshychiatry(false)} style={{cursor:"pointer", marginRight:"0px", float:"right",marginLeft:"auto"}}/> */}
          <PsychiatryCaseNotes
            // setLoader={setLoader}
            getApi={() => DynamicListByVisitId()}
            handleCloseaddPsychiatry={handleCloseaddPsychiatry}
            handlePhySuccessPopup={handlePhySuccessPopup}
            data={visitInfo}
            // handleCloseCasePopup={handleCloseCasePopup}
            setLoader={setLoader}
            // handleSuccessPopup={handleSuccessPopup}
            refreshScreen={() => setRefresh(Math.floor(Math.random() * 10))}
          />
        </div>
      </Dialog>
    </div>
  );
}
