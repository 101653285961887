import React, { useEffect, useState } from "react";
import regLogo from "../../../assets/images/regLogo.svg";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  Checkbox,
} from "@material-ui/core";
import styles from "../style";
import SignUpForm from "./SignUpFormMob";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginData,
  getLoginLoading,
  Clear,
} from "../../../redux/actions/loginAction";
import axios from "axios";
import * as Yup from "yup";
import Toast from "../../../components/Toast";
import { Field, Form, Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme) => styles(theme));

const ForgotePassword = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const customSM = useMediaQuery("(max-width:800px)");

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [isValid, setIsvalid] = useState(false);
  const [isValidOTP, setIsvalidOTP] = useState(false);

  const [showToast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();

  const [ConfirmPassword, setConfirmPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  console.log(otp, "fsdfssfdsfdf");

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      email: email,
      mobile: mobile,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/auth/reset-password`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res, "res1");
        if (res.status === 200) {
          console.log(res, "res2");
          setToastType("success");
          setToastMessage(res.data.message);
          setToast(true);
          setIsvalid(true);
        }
      })
      .catch((err) => {
        console.log(err, "res3");
        setToastType("error");
        setToastMessage("No user exists with the provided details");
        setToast(true);
        // setIsvalid(true)
      });
  };

  const handleSubmitOTP = (e) => {
    e.preventDefault();
    const payload = {
      otp: otp,
      mobile: mobile,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/sms/register/verify-otp`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // if(res.data.message==="OTP verified successfully."){
        setToastType("success");
        setToastMessage(
          res && res.data && res.data.message !== undefined
            ? res.data.message
            : res.data
        );
        setToast(true);
        setIsvalidOTP(true);
        // }
      })
      .catch((err) => {
        console.log(err, "res1231231747547");
        setToastType("error");
        setToastMessage("Invalid OTP. Please try again.");
        setToast(true);
      });
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();
    if (ConfirmPassword === newPassword) {
      const payload = {
        email: email,
        password: ConfirmPassword,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/auth/change-password`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setToastType("success");
            setToastMessage("Password Changed Successfully!");
            setToast(true);
            console.log(res, "res123123");
            // setIsvalidOTP(true)

            setTimeout(() => {
              console.log("Hello, World!");
              history.push("/");
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err, "res1231231");
        });
    } else {
      setToastType("error");
      setToastMessage("New Password and Confirm password shoud be same");
      setToast(true);
    }
  };

  const handleChangePhone = (e) => {
    console.log(e, "e");
    const value = e;
    const noSpecialCharacters = value.replace(/[^a-zA-Z0-9()]/g, "");
    console.log(noSpecialCharacters, "noSpecialCharacters");
    setMobile(noSpecialCharacters);
  };

  //test-api.doctall.com/api/v1/user/auth/change-password
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingTop: "48px" }}
    >
      <Link to="/login">
        <img src={regLogo} alt="logo" />
      </Link>

      <Typography className={classes.regTitle} style={{ marginTop: "28px" }}>
        Forgot Password
      </Typography>
      <Typography className={classes.regSubTitle} style={{ marginTop: "8px" }}>
        Welcome to Doctall Patient Portal
      </Typography>
      <Grid
        item
        container
        style={{ width: "328px", marginTop: "28px" }}
        direction="column"
      >
        {/* {
            
            
       
            
                                ({ errors, touched }) => ( */}

        {isValidOTP == true ? (
          <form action="" onSubmit={handleSubmitNewPassword}>
            <Typography className={classes.regLabel}>New Password</Typography>
            <input
              name="NewPassword"
              type="text"
              className={classes.regInput}
              placeholder="Enter Password"
              required={true}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Typography className={classes.regLabel}>
              Confirm New Password
            </Typography>
            <input
              name="ConfirmNewPassword"
              placeholder="Enter Password"
              className={classes.regInput}
              required={true}
              type="text"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Grid
              item
              container
              justifyContent="space-between"
              style={{ marginTop: "16px" }}
            ></Grid>
            <button
              className={classes.regButton}
              style={{ marginTop: "20px" }}
              type="submit"
              //onClick={() => setScreen("certificate")}
            >
              Next
            </button>
          </form>
        ) : (
          <div>
            {isValid ? (
              <form action="" onSubmit={handleSubmitOTP}>
                <Typography className={classes.regLabel}>Otp</Typography>
                <input
                  name="otp"
                  type="number"
                  className={classes.regInput}
                  placeholder="Enter 6 Digit OTP"
                  required={true}
                  onChange={(e) => setOtp(e.target.value)}
                />

                <button
                  className={classes.regButton}
                  style={{ marginTop: "20px" }}
                  type="submit"
                  //onClick={() => setScreen("certificate")}
                >
                  Submit
                </button>
              </form>
            ) : (
              <form action="" onSubmit={handleSubmit}>
                <Typography className={classes.regLabel}>
                  Email Address <span style={{ color: "red" }}>*</span>
                </Typography>
                <input
                  name="email"
                  className={classes.regInput}
                  placeholder="E-mail"
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Typography className={classes.regLabel}>
                  Mobile Number
                </Typography>
                {/* <input
                    name="mobile"
                      type="number"
                    className={classes.regInput}
                    required={true}

                    onChange={(e) => setMobile(e.target.value)}
                  /> */}

                <MuiPhoneNumber
                  defaultCountry={"ng"}
                  onChange={handleChangePhone}
                  InputProps={{ disableUnderline: true }}
                  className={classes.regInput}
                  placeholder="Enter Folio number"
                  // value={mobile}
                />

                {/* <Form>  
                  <Field
                                            as={MuiPhoneNumber}
                                            name="mobile"
                                            defaultCountry={'ng'}
                                            // onChange={handleOnChange}
                                            fullWidth variant="outlined"
                                            required={true}
                                            className={classes.primaryInput}
                                            // error={errorMobile}
                                            // helperText={errorMobile ? "Required!" : ""}

                                        />
                                        </Form> */}
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  style={{ marginTop: "16px" }}
                ></Grid>
                <button
                  className={classes.regButton}
                  style={{ marginTop: "20px" }}
                  type="submit"
                  //onClick={() => setScreen("certificate")}
                >
                  Next
                </button>
              </form>
            )}
          </div>
        )}
      </Grid>
      <Toast
        style={{ marginTop: "100px" }}
        text={toastMessage}
        type={toastType}
        open={showToast}
        setClose={() => {
          setToast(false);
        }}
      />
    </Grid>
  );
};

export default ForgotePassword;
