import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyle } from '../../Admin/style/style'
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import Toast from '../../components/Toast';
import { addPatient, editPatient } from '../services/ServiceHelper';
import { getPatients } from '../../redux/actions/ex-appointment-action';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';


export default function ViewOrderModel(props) {
    const authState = useSelector((state) => state.loginReducer.payload);

    const dispatch = useDispatch();
    const { id, close, details } = props;
    console.log(props)

    const classes = useStyle();
    const [editFlag, setEditFlag] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);
    const handleClose = () => {
        props.close(true)
    };


    useEffect(() => {
        if (id == 'Add') {
            setEditFlag(true)
        } else {
            setEditFlag(false)
        }
    }, [])



    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const { mobilenumber, middleName, firstname, lastname, email, bloodgroup, age, gender, address } = event.target.elements
            let payLoad = {
                firstName: firstname.value,
                lastName: lastname.value,
                middleName: middleName.value,
                bloodGroup: bloodgroup.value,
                email: email.value,
                mobile: mobilenumber.value,
                age: age.value,
                gender: gender.value,
                address: address.value,
                doctor_uuid: authState.uuid
            }

            if (id !== 'Add') {
                let response = await editPatient(payLoad, props.details.uuid);
                dispatch(getPatients())
                props.close()
            } else {
                let response = await addPatient(payLoad);
                dispatch(getPatients())
                props.close()
            }

        } catch (error) {
            if (error && error.response) {
                setToast(true)
                setToastMessage('' + error.response.data)
                setToastType('error')
            } else {
                setToast(false)
            }

        }

    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <div className='table_heading'>{"ORDER DETAILS"}</div>
                </Grid>

                <Grid item xs={1}>

                    <Tooltip title="Close" placement="bottom" arrow>
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>

                </Grid>
            </Grid>


            <hr style={{ borderTop: "2px solid #00839B", borderBottom: "none", marginBottom: '5%' }} />
            <form action="" onSubmit={handleSubmit}>

                <Grid container spacing={2}>
                    <Grid item xs={3}> <h3 className="input_lable">Order ID </h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            type='number'
                            style={{ width: '100%' }}
                            name="mobilenumber"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={details && Number(details.mobile)}
                            disabled={editFlag === false}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Patient Name </h3></Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="firstname"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            placeholder='First Name'
                            required
                            defaultValue={details && details.firstName}
                            disabled={editFlag === false}
                        />
                    </Grid>
                    <Grid item xs={3}>

                        <TextField
                            name="middleName"
                            variant="outlined"
                            defaultValue={details && details.middleName && details.middleName ? details.middleName : "NA"}
                            className={classes.primaryInputAddPatient}
                            placeholder='Middle Name'
                            disabled={editFlag === false}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="lastname"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            placeholder='Last Name'
                            required
                            defaultValue={details && details.lastName}
                            disabled={editFlag === false}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Email ID</h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            style={{ width: '100%' }}
                            name="email"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={details && details.email}
                            disabled={editFlag === false}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Blood Group</h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            style={{ width: '100%' }}
                            name="bloodgroup"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={details && details.bloodGroup}
                            disabled={editFlag === false}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Age</h3></Grid>
                    <Grid item xs={3}>
                        <TextField
                            type='number'
                            name="age"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={details && Number(details.age)}
                            disabled={editFlag === false}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Order Status</h3></Grid>
                    <Grid item xs={3}>
                        <TextField
                            type='text'
                            name="status"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={details && Number(details.age)}
                            disabled={editFlag === false}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Amount Earned</h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            name="amount"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            style={{ width: '100%' }}
                            required
                            defaultValue={details && details.address}
                            disabled={editFlag === false}
                        />
                    </Grid>
                </Grid>

            </form>

            <Toast
                text={toastMessage}
                type={toastType}
                open={showToast}
                setClose={() => {
                    setToast(false);
                }}
            />

        </>
    );
}


