import React, { useState, useEffect, useReducer } from 'react';
import "./doctor.scss"
import { makeStyles, createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Chip from '@material-ui/core/Chip';
import AlarmIcon from '@material-ui/icons/Alarm';
import Insta from "../../assets/images/insta.svg"
import FB from "../../assets/images/fb.svg"
import LIn from "../../assets/images/linkedin.svg"
import Twitter from "../../assets/images/twitter.svg"
import Play from "../../assets/images/play.svg"
import Btn1 from "../../assets/images/profilebtn1.svg"
import Btn2 from "../../assets/images/profilebtn2.svg"
import Btn3 from "../../assets/images/profilebtn3.svg"
import Btn4 from "../../assets/images/profilebtn4.svg"
import Star from "../../assets/images/profilestar.svg"
import Up from "../../assets/images/up.svg"
import Down from "../../assets/images/down.svg"
import Income from "../../assets/images/income.svg"
import GreenSlot from "../../assets/images/GreenSlot.svg"
import OrangeSlot from "../../assets/images/OrangeSlot.svg"
import { getDoctorDetails } from "../../redux/actions/TestAction";
import { getPatientStats } from "../../redux/actions/TestAction";
import { getEprescription } from "../../redux/actions/TestAction";
import { getLabprescription } from "../../redux/actions/TestAction";
import { getNextAppointment } from "../../redux/actions/TestAction";
import { getAppointmentDates } from "../../redux/actions/TestAction";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from 'react-redux';
import Calendar from '../calender/customCalender';
import moment from "moment";
import { Helmet } from 'react-helmet';



let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

var pixels = 100;
var screenWidth = window.screen.width;
var percentage = (screenWidth - pixels) / screenWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
  },
  paper: {
    padding: theme.spacing(1),
    Width: '100%'

  },
  paper1: {
    padding: theme.spacing(2),
    maxWidth: '100%',
    maxHeight: '100%',
  },
  paper2: {
    width: '100%',
    backgroundColor: "white"
  },
  img: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    maxWidth: '307px',
    maxHeight: '335px',
    border: "2px solid ",
    borderRadius: "8px",
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  image: {
    width: percentage,
  },
  calenderContainer: {

    background: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px",

  },

  icon: {
    marginRight: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  iconContainer: {
    width: '100%',
    // height: 160,
  },
  spacer: {
    padding: theme.spacing(1),
  },
  spacer1: {
    padding: theme.spacing(4),
  },
  grid1: {
    width: "100px",
    height: "200px",
    backgroundColor: "green"
  },
  grid2: {
    marginLeft: '5px',
    width: '100px',
    height: '200px',
    backgroundColor: "yellow"
  },
  button: {
    margin: theme.spacing(1),
    whiteSpace: "nowrap",
    backgroundColor: "#08B4D5",
    color: " #FFFFFF",
    fontFamily: 'VisueltRegular',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "116.1%",
    '&:hover': {
      backgroundColor: '#fff',
      color: '#08B4D5',
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  border: {
    color: "green"
  },
  text9: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "116.1%",
    color: "rgba(1, 60, 68, 0.5)",
    letterSpacing: "-0.3px",
  },
  text8: {
    fontFamily: "VisueltMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "32px",
    color: "#04A9C8",
    letterSpacing: "-0.3px",
    textAlign: "left",
  },
  text2: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "30px",
    lineHeight: "116.1%",
    color: "#013C44",
    letterSpacing: "-0.3px",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  text3: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "30px",
    lineHeight: "116.1%",
    letterSpacing: "-0.3px",
    color: "#013C44",
  },
  text1: {
    fontFamily: "VisueltRegular",
    fontSize: "30px",
    lineHeight: "38px",
    letterSpacing: "-0.3px",
    fontStyle: "normal",
    color: "#66B5C3",
    fontWeight: "normal",
  },
  text4: {
    fontFamily: "VisueltRegular",
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "-0.3px",
    fontStyle: "normal",
    color: "#046673",
    fontWeight: "normal",
  },
  text5: {
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "144.9%",
    color: "rgba(4, 102, 115, 0.7)",
  },
  text6: {
    fontFamily: "VisueltRegular",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "144.9%",
    color: "#013C44",
    padding: theme.spacing(1),
  },
  text7: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "116.1%",
    letterSpacing: "0.7px",
    color: "#00839B"
  },
  iconBorder: {
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 7px 15px -1px rgba(8, 180, 212, 0.2)",
    borderRadius: "50px",
    padding: "2px",
    marginLeft: "4px"
  },
  iconBorder2: {
    background: "rgba(255, 110, 78, 0.3)",
    borderRadius: "10px",
  },
  iconBorder3: {
    background: "#0696B0",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px"
  },
  borderWrapper: {
    background: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "20px",
  },
  borderWrapper2: {
    backgroundColor: "#08B4D4",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px",
  },
  borderWrapper3: {
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    borderRadius: "30px"
  },
  aboutBorder: {
    background: "#F1F9F5",
    border: "1px solid rgba(102, 195, 189, 0.5)",
    boxSizing: "border-box",
    boxShadow: "-1px 19px 32px rgba(8, 180, 212, 0.15)",
    bordeRadius: "30px"
  },
  borderContainer: {
    border: "1px solid #66B5C3",
    boxSizing: "border-box",
    boxShadow: "-7px 6px 7px rgba(6, 99, 116, 0.05)",
    borderRadius: "30px",
    padding: "10px",
    backgroundColor: "#F3FAFC"
  },
  doctorName: {
    fontFamily: "VisueltRegular",
    fontSize: "30px",
    lineHeight: "38px",
    letterSpacing: "-0.3px",
    color: "#66B5C3",
    textAlign: "left"
  },
  doctorappoint: {
    fontFamily: "VisueltMedium",
    fontSize: "20px",
    lineHeight: "25.64px",
    letterSpacing: "-0.3px",
    color: "#046673",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  addressword: {
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    color: "#013c44"
  }
}));



export default function ComplexGrid() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const doctorInfo = useSelector(state => {
    if (state.getDoctorDetails != "" && state.getDoctorDetails.payload != undefined && state.getDoctorDetails.payload.length > 0 && state.getDoctorDetails.payload[0] != undefined) {
      return ({
        firstName: state.getDoctorDetails.payload[0].firstName,
        full_name: state.getDoctorDetails.payload[0].full_name,
        profile_pic: state.getDoctorDetails.payload[0].profile_pic,
        experience: state.getDoctorDetails.payload[0].profile.practice_started_year,
        mobile: state.getDoctorDetails.payload[0].mobile,
        consultation_fee: state.getDoctorDetails.payload[0].consultation_fee,
        email: state.getDoctorDetails.payload[0].email,
        address: state.getDoctorDetails.payload[0].profile.address,
        dob: state.getDoctorDetails.payload[0].profile.dob,
        folioNumber: state.getDoctorDetails.payload[0].profile.folioNumber,
        gender: state.getDoctorDetails.payload[0].profile.gender,
        speciality: state.getDoctorDetails.payload[0].profile.speciality,
        bio: state.getDoctorDetails.payload[0].profile.bio,
        rating: state.getDoctorDetails.payload[0].profile.rating,
        languages: state.getDoctorDetails.payload[0].profile.languages,
        city: state.getDoctorDetails.payload[0].profile.address.city,
        country: state.getDoctorDetails.payload[0].profile.address.country,
        city: state.getDoctorDetails.payload[0].profile.address.city,
        houseNumber: state.getDoctorDetails.payload[0].profile.address.houseNumber,
        postalcode: state.getDoctorDetails.payload[0].profile.address.postalcode,
        state: state.getDoctorDetails.payload[0].profile.address.state,
        fb: state.getDoctorDetails.payload[0].profile.social_media.fb,
        insta: state.getDoctorDetails.payload[0].profile.social_media.insta,
        linkedin: state.getDoctorDetails.payload[0].profile.social_media.linkedin,
        tw: state.getDoctorDetails.payload[0].profile.social_media.tw,
        Certification: state.getDoctorDetails.payload[0].Certification,
        videoUrl: state.getDoctorDetails.payload[0].profile.profileVideoUrl[0],
        qualification: state.getDoctorDetails.payload[0].profile.professional_detail.qualification,
      })
    }
  }
  );


  const patientDetails = useSelector(state => {
    if (state.getPatientDetails != "" && state.getPatientDetails.payload != undefined) {
      return ({
        patientThisMonth: state.getPatientDetails.payload.totalcurrentMonthPatients,
        patientLastMonth: state.getPatientDetails.payload.totalPreviousMonthPatients,
        completedThisMonth: state.getPatientDetails.payload.totalcurrentcompletedAppointment,
        completedLastMonth: state.getPatientDetails.payload.totalPreviousMonthcompletedAppointment,
        incomeMonth: state.getPatientDetails.payload.TotalThisMontIncome,
        incomeYear: state.getPatientDetails.payload.TotalThisYearSalary,
      })
    }
  }
  );

  const EpresDetails = useSelector(state => {
    if (state.getEpresDetails != "" && state.getEpresDetails.payload != undefined) {
      return ({
        epresThisMonth: state.getEpresDetails.payload.totalcurrentPrescription,
        epresLastMonth: state.getEpresDetails.payload.totalPreviousMonthPrescription,
      })
    }
  }
  );
  const LabpresDetails = useSelector(state => {
    if (state.getLabpresDetails != "" && state.getLabpresDetails.payload != undefined) {
      return ({
        labThisMonth: state.getLabpresDetails.payload.totalcurrentMonthPrescriptionLab,
        labLastMonth: state.getLabpresDetails.payload.totalPreviousMonthPrescriptionLAb,
      })
    }
  }
  );
  const nextAppointmentDetails = useSelector(state => {
    if (state.nextAppointmentDetails.payload != undefined && state.nextAppointmentDetails.payload.length >= 1) {
      return ({
        appointmentData: state.nextAppointmentDetails.payload,
        appointmentDate: state.nextAppointmentDetails.payload[0].StartTime,
        PatientName: state.nextAppointmentDetails.payload[0].PatientName[0],
      })
    } else {
      return ({
        noBooking: "NO APPOINTMENT FOR YOU"
      })
    }
  }
  );
  useEffect(() => {
    dispatch(getNextAppointment())
    dispatch(getDoctorDetails())
    dispatch(getPatientStats())
    dispatch(getEprescription())
    dispatch(getLabprescription())
    dispatch(getAppointmentDates())

  }, [])
  useEffect(() => {
    console.log(LabpresDetails, 'LabpresDetails')
  }, [LabpresDetails])
  const monthConverterfn = () => {
    if (nextAppointmentDetails.appointmentDate != undefined) {
      return moment(nextAppointmentDetails.appointmentDate.substr(0, 10)).format('MMMM')
    }
  }

  const numberWithCommas = (x) => {
    if (x) {
      x = parseFloat(x).toFixed(0)
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
      return x;
    }
    return "0";
  }
  const setFontSize = (text) => {

    if (text) {
      if (text.length < 20) {
        return 18
      }
      else {
        if (text.length < 26) {
          return 16
        }
        else if (text.length < 29) {
          return 14.5
        }
        else if (text.length < 32) {
          return 13.5
        }
        else if (text.length < 35) {
          return 12.5
        }
        else if (text.length < 38) {
          return 11
        }
        else if (text.length < 42) {
          return 9
        }
        return 18
      }
    }
    else {
      return 18
    }

  }

  return (
    <>
    <Helmet>
      <title>doctor.doctall.com | profile 
</title>
<meta 
            name='description' 
            content='View your doctors profile on the doctors portal,  see number of appointments, create appointment slots and patient case notes. Attend to patients, write and deliver prescription notes 
            '
            />
    </Helmet>
      {doctorInfo != undefined && patientDetails != undefined && EpresDetails != undefined && LabpresDetails != undefined && LabpresDetails != undefined && nextAppointmentDetails != undefined ?
        <div className={classes.root}>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} >

                <Typography className={classes.doctorName}>Hi,&nbsp;{doctorInfo.firstName}</Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                {

                  nextAppointmentDetails.PatientName != undefined && nextAppointmentDetails.appointmentDate != undefined ?
                    <>
                      <Typography className={classes.doctorappoint} >Your next appointment with <span style={{ color: "#FF6E4E", }}>
                        {nextAppointmentDetails.PatientName},&nbsp;{nextAppointmentDetails.appointmentDate != undefined ? nextAppointmentDetails.appointmentDate.substr(8, 2) + " " + monthConverterfn() + " " + nextAppointmentDetails.appointmentDate.substr(0, 4) : ""} </span>
                        <span style={{ fontFamily: "GreycliffCFBold" }}>starts at</span>
                      </Typography>
                      <div style={{ width: 136, height: 37, background: "#046673", borderRadius: 10, position: "relative", marginLeft: 20 }}>
                        <img src={GreenSlot} crossorigin="anonymous" />
                        <span className="slottime">{moment(nextAppointmentDetails.appointmentDate).format("hh:mm a")}</span>
                      </div>
                    </>
                    :
                    ""
                }

              </Grid>

            </Grid>
          </Grid>
          <Grid container  >

            <div className="profile-container">
              <div className="content-one">
                <div className="content-image">
                  <img className="profileimg" src={doctorInfo.profile_pic}
                    alt="doctor-image" 
                    // crossorigin="anonymous"
                     />
                </div>
                <div className="detailcontent">
                  <div className="content">
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '20rem' }}>
                      <div className="name">Dr. {doctorInfo.full_name}</div>
                    </div>
                    <div className="separater" style={{ width: "40%" }}>
                      <div className="value" style={{ marginBottom: 10 }}>
                        <h4>{doctorInfo && doctorInfo.qualification ? doctorInfo.qualification : ""}</h4>
                      </div>
                    </div>
                    <div className="special" >{doctorInfo.speciality}</div>
                    <div className="star">
                      {doctorInfo.rating}<img src={Star} crossorigin="anonymous" />
                    </div>
                  </div>
                  <div className="contentone">
                    <div className="separater">
                      <div className="name">Location</div>
                      <div className="value">{doctorInfo.country}</div>
                    </div>
                    <div className="separater" style={{ width: "40%" }}>
                      <div className="name">Date of Birth</div>
                      <div className="value">{doctorInfo.dob != null ? doctorInfo.dob.substr(0, 10) : ""}</div>
                    </div>
                    <div className="separater" style={{ width: "26%" }}>
                      <div className="name">Gender</div>
                      <div className="value">{doctorInfo.gender}</div>
                    </div>
                  </div>
                  <div className="contenttwo">
                    <div className="separater" style={{ paddingRight: "11%" }}>
                      <div className="name">Language Spoken</div>
                      <div className="value">{doctorInfo.languages}</div>
                    </div>
                    <div className="separater">
                      <div className="name">Years of experience</div>
                      <div className="value">{moment().format("YYYY") - doctorInfo.experience} Years</div>
                    </div>
                  </div>
                  <div className="contentthree">
                    <div className="images">
                      <div className={doctorInfo.insta != undefined && doctorInfo.insta != "" ? "image" : "disabled_image"} onClick={(e) => {
                        e.preventDefault();
                        window.open(doctorInfo.insta, "_blank");
                      }}><img src={Insta} crossorigin="anonymous" /></div>
                      <div className={doctorInfo.tw != undefined && doctorInfo.tw != "" ? "image" : "disabled_image"} src={Twitter} onClick={(e) => {
                        e.preventDefault();
                        window.open(doctorInfo.tw, "_blank");
                      }} ><img src={Twitter} crossorigin="anonymous" /></div>
                      <div className={doctorInfo.fb != undefined && doctorInfo.fb != "" ? "image" : "disabled_image"} src={FB} onClick={(e) => {
                        e.preventDefault();
                        window.open(doctorInfo.fb, "_blank");
                      }} ><img src={FB} crossorigin="anonymous" /></div>
                      <div className={doctorInfo.linkedin != undefined && doctorInfo.linkedin != "" ? "image" : "disabled_image"} src={LIn} onClick={(e) => {
                        e.preventDefault();
                        window.open(doctorInfo.linkedin, "_blank");
                      }} ><img src={LIn} crossorigin="anonymous" /></div>
                    </div>
                    <div className={doctorInfo.videoUrl != undefined && doctorInfo.videoUrl != "" ? "btn" : "disabled_btn"} onClick={(e) => {
                      e.preventDefault();
                      window.open(doctorInfo.videoUrl, "_blank");
                    }
                    }>
                      <img className="btnimg" src={Play} crossorigin="anonymous" />
                      <span className="btntxt">Play your profile video</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-two">
                <div className="detail-content">
                  <div className="detail">
                    <div className="title">Email:&nbsp;</div>
                    <div className="value" style={{ fontSize: setFontSize(doctorInfo.email) }}>{doctorInfo.email}</div>
                  </div>
                  <div className="detail">
                    <div className="title">Phone:&nbsp;</div>
                    <div className="value" style={{ fontSize: setFontSize(doctorInfo.mobile) }}>{doctorInfo.mobile}</div>
                  </div>
                  <div className="detail" style={{ border: "none", maxHeight: 110, alignItems: "flex-start" }}>
                    <Typography style={{ wordWrap: 'break-word' }} className={classes.addressword} >
                      {`Address: ${doctorInfo.houseNumber},`}<br />{`${doctorInfo.city} ${doctorInfo.postalcode},`}<br />{`${doctorInfo.state} ${doctorInfo.country}`}
                    </Typography>
                  </div>
                </div>
                <div className="price-content">
                  <div className="price">
                    <div className="title">Consultation Fee:</div>
                    <div className="value">₦{numberWithCommas(doctorInfo.consultation_fee)}</div>
                  </div>
                  <div className="price-detail">
                    <div className="one">
                      Certification {doctorInfo.Certification}
                    </div>
                    <div className="two">
                      Folio No: <span style={{ fontStyle: "VisueltMedium" }}>{doctorInfo.folioNumber}</span>

                    </div>
                  </div>
                  <div className="price-description">
                    If you wish to change any information on your profile, please email Doctall Admin at <a href="mailto:support@doctall.com"  >support@doctall.com</a>
                  </div>
                </div>
              </div>
            </div>




            <div className="rightcontainer">
              <div className={"calenderContainerProfile"}>
                <Calendar />
              </div>



              <div className="btncollection">
                <div className="holder">
                  <div className="btn marginright">
                    <div className="imageholder" style={{ background: "rgba(255, 110, 78, 0.3)" }}>
                      <img src={Btn1} crossorigin="anonymous" /></div>
                    <div className="textholder">
                      <div className="title">
                        Total Patients this month
                      </div>
                      <div className="value">
                        <span className="one">{patientDetails.patientThisMonth}</span>
                        <span className="two green">
                          <img src={Up} style={{ marginRight: 5 }} crossorigin="anonymous" />
                          {patientDetails.patientLastMonth}%</span>
                      </div>
                    </div>
                  </div>
                  <div className="btn">
                    <div className="imageholder" style={{ background: "rgba(255, 110, 78, 0.3)" }}>
                      <img src={Btn2} crossorigin="anonymous" /></div>
                    <div className="textholder">
                      <div className="title">
                        Total Completed Appointment this month                    </div>
                      <div className="value">
                        <span className="one">{patientDetails.completedThisMonth}</span>
                        <span className="two red">
                          <img src={Down} style={{ marginRight: 5 }} crossorigin="anonymous" />

                          {patientDetails.completedLastMonth}%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="holder">
                  <div className="btn marginright">
                    <div className="imageholder" style={{ background: "rgba(255, 110, 78, 0.3)" }}>
                      <img src={Btn3} crossorigin="anonymous" /></div>
                    <div className="textholder">
                      <div className="title">
                        Total Medicine Prescription this month                    </div>
                      <div className="value">
                        <span className="one">{EpresDetails.epresThisMonth}</span>
                        <span className="two green">
                          <img src={Up} style={{ marginRight: 5 }} crossorigin="anonymous" />
                          {EpresDetails.epresLastMonth}%</span>
                      </div>
                    </div>
                  </div>
                  <div className="btn">
                    <div className="imageholder" style={{ background: "rgba(255, 110, 78, 0.3)" }}>
                      <img src={Btn4} crossorigin="anonymous" /></div>
                    <div className="textholder">
                      <div className="title">
                        Total Lab Test Advice this month                    </div>
                      <div className="value">
                        <span className="one">{LabpresDetails.labThisMonth}</span>
                        <span className="two green">
                          <img src={Up} style={{ marginRight: 5 }} crossorigin="anonymous" />
                          {LabpresDetails.labLastMonth}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <Grid container direction="row"  >
              <div className={"about"}>
                <div className={"title"}>About</div>
                <div className={"value"}>
                  {doctorInfo.bio}
                </div>
              </div>

              <div className="income">
                <div className="image">
                  <img src={Income} crossorigin="anonymous" />
                </div>
                <div className="content">
                  <div className="separate">
                    <div className="title">Income this Month</div>
                    <div className="value">₦{numberWithCommas(patientDetails.incomeMonth)}</div>
                  </div>
                  <div className="separate">
                    <div className="title">Income this Year</div>
                    <div className="value">₦{numberWithCommas(patientDetails.incomeYear)}</div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        :
        <p>Loading.....</p>}
    </>
  );
}
