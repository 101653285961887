import React from 'react'
import { Snackbar } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
function Toast(props) {

    return (
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={props.open}
            autoHideDuration={1500} onClose={() => props.setClose(false)}>
            <Alert severity={props.type}>
                {props.text}
            </Alert>
        </Snackbar>
    )
}

export default Toast