import React, { useEffect, useState } from "react";
import { format, subMonths, addMonths, startOfWeek, addDays, startOfMonth, endOfMonth, endOfWeek, isSameMonth, isSameDay, parseISO,isPast,isBefore,isFuture } from "date-fns/esm"
import "./Calender.css";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { shallowEqual, useSelector } from 'react-redux';
import moment from "moment";
import PopClose from "../../assets/images/PopClose.svg";
import {getAppointmentSlot} from "../../redux/actions/TestAction";
import { useDispatch } from "react-redux"; 
import { isYesterday } from "date-fns";

const Calendar = (props) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState([format(new Date(), "yyyy-MM-dd")]);
  const [openCalender, setOpenCalender] = React.useState(false);
  const [seDate, setSeDate] = React.useState("");
 
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.selectedDate && props.selectedDate.length > 0)
      setSelectedDate(props.selectedDate)
  }, [props.selectedDate])

 

  const [bookings, setBooking] = useState([]);

  const dateState = useSelector(state => state.getAppointmentDate.payload != undefined && state.getAppointmentDate != "" ? state.getAppointmentDate.payload : "")
  const slotDate = useSelector(state => state.getAppointmentSlot.payload != undefined && state.getAppointmentSlot != "" ? state.getAppointmentSlot.payload : "")

  useEffect(() => {
    fetchAppointment()

  }, [dateState])

  const fetchAppointment = () => {
    let data = [];
    if (dateState != undefined && dateState != "") {
      dateState.map((val, ind) => {
        data.push(moment(val.start_time).format("YYYY-MM-DD"))
      })
    }
    setBooking(data);
  }

  const handleClickOpen = () => {
    setOpenCalender(true);
  };

  const handleClose = () => {
    setOpenCalender(false);
  };

  const header = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <>
        <div className="header">
          <div className="month-header">
            <span>{format(currentDate, dateFormat)}</span>
          </div>
          <div className="month-nav">
            <div className="icon" onClick={prevMonth}>
              <ArrowBackIosIcon className="icon-button-left" style={{ color: "#013C44" }} />
            </div>
            <div className="icon" onClick={nextMonth}>
              <ArrowForwardIosIcon className="icon-button" style={{ color: "#013C44" }} />
            </div>
          </div>
        </div>
        <div className="header-border">

        </div>
      </>
    );
  };

  const daysOfWeek = () => {
    const dateFormat = "EEEEE";
    const days = [];
    let startDate = startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="days-class" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days-header">{days}</div>;
  };

  const cells = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;


    let formattedDate = "";
    let pastDates=moment().subtract(1, 'day');
      
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        var date = new Date();
        date.setDate(date.getDate()-1);
        days.push(
          <div
            className={`column-days ${!isSameMonth(day, monthStart)
              ? "disabled" : isSameDay(day, selectedDate)
                ? "selected" : ""}`}
            key={day}
            data-date={format(day, "yyyy-MM-dd")}
            data-bookingdate={bookings.includes(format(day, "yyyy-MM-dd"))}
            onClick={(e) => { onDateClick(e) }}
          >
            <span style={bookings.includes(format(day, "yyyy-MM-dd")) || props.selectedDate ? { cursor: "pointer" } : {}} className={!isSameMonth(day, monthStart)||day<date  ? "disabled" : selectedDate.indexOf(format(day, "yyyy-MM-dd")) > -1 ? "number selecteddate" : "number"}>{formattedDate}</span>
            <span className={bookings.includes(format(day, "yyyy-MM-dd")) ? "marker" : ""}></span>
            {/* <span style={{borderRadius:'50%',height:"5px",width:"5px",backgroundColor:"red",position:"relative",bottom:"2px"}}>{formattedDate}</span> */}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}> {days} </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  }
  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  }
  const onDateClick = day => {

    dispatch(getAppointmentSlot(day.currentTarget.dataset.date))
    if (props.selectedDate) {

      let selectday = day.currentTarget.dataset.date;
      let indexval = selectedDate.indexOf(selectday);
      if (indexval > -1) {
        selectedDate.splice(indexval, 1);
        setSelectedDate([...selectedDate])
        if (props.selectedDates)
          props.selectedDates([...selectedDate])

      }
      else {
        setSelectedDate([...selectedDate, selectday])
        if (props.selectedDates)
          props.selectedDates([...selectedDate, selectday])
      }
     
    }
    else {
      let bookingdate = day.currentTarget.dataset.bookingdate;
      if (bookingdate =="true") {
        setSeDate(day.currentTarget.dataset.date)
        setOpenCalender(true)
      }
    }

  }
  return (
    <div className="container-border">
      <div className="container">
        <div>{header()}</div>
        <div>{daysOfWeek()}</div>
        <div>{cells()}</div>
      </div>
      {openCalender && <div className="container overlay" onClick={()=>{handleClose()}}>
       
        <div className="popupoverlay" onClick={(e)=>e.preventDefault()}>
          <img src={PopClose} className="CalClose" crossorigin="anonymous" />
          <div className="popuptitle">
            <span className="popuptone">{moment(seDate).format("DD")}</span><span className="popupttwo">{moment(seDate).format("dddd")}</span>
          </div>
          <div className="popupcontainer" >
          
         
            {
              slotDate!=undefined&&slotDate!=""?
              slotDate.map((val)=>{
              return(
                <div className="popupcontent">
            <div className="popupdot"></div><span className="popupcone">Appointment with - </span><span className="popupctwo">{val.PatientName}</span>
            <div className="popupdate">{moment(val.start_time).format("hh:mm a")} -{moment(val.end_time).format("hh:mm a")} </div>
            </div>
              )
              
              }):""
            }
            </div>
        </div>
      </div>}
    </div>
  );
}

export default Calendar;
