import { makeStyles } from '@material-ui/core';
import backgroundImage from '../images/BackgroundImage.svg'

const useStyle = makeStyles(theme => ({
    main: {
        display: 'flex',
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    gridContainer: {
        height: '100%',
        width: '100%',
    },
    rightGrid: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid #00839B',
        borderRadius: '50px 0px 0px 50px',
        margin: '0 0 0 auto',
        background: '#FFFFFF',
        opacity: '0.95',
        [theme.breakpoints.down("sm")]: {
            borderRadius: '3px',
        },
    },
    sigin_register_container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5% 6%'
    },
    sign_in: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#013C44',
        fontWeight: 'bold',
        fontStyle: 'normal',
        width: '88px',
        height: '39px',
        background: 'rgba(102, 181, 195, 0.15)',
        boxShadow: '-7px 6px 7px rgba(6, 99, 116, 0.05)',
        borderRadius: '7px',

    },
    register: {
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        width: '78px',
        height: '12px',
        color: '#013C44',
        fontWeight: 'bold',
        letterSpacing: '1.71px',
        fontSize: '16px',
        fontStyle: 'normal',
        margin: 'auto',


    },
    stepper: {
        "& .MuiStepIcon-root.MuiStepIcon-active": {
            color: "#04A9C8",

        },
        "& .MuiStepIcon-root.MuiStepIcon-completed": {
            color: "#04A9C8"
        },
        "& .makeStyles-root-7 .MuiStepIcon-root": {
            fontSize: "36px"
        },
        "& .MuiStepIcon-root": {
            color: "#04A9C8"
        },
        "& .MuiStepLabel-label.MuiStepLabel-active": {
            color: "#04A9C8",
            fontFamily: "VisueltMedium",
        },
        "& .MuiStepLabel-label": {
            color: "#04A9C8",
            fontSize: '12px'
        },
        "& .MuiStepConnector-line": {
            border: '1px solid #04A9C8'
        },

    },
    primaryLabel: {
        fontFamily: "VisueltRegular",
        color: '#013C44',
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '23px',
        letterSpacing: '-0.291346px',
        marginBottom: '5px',

    },
    primaryInput2: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '10px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiOutlinedInput-input": {
            fontSize: '20px',
            fontFamily: "VisueltRegular",
            paddingLeft: "15px",
            backgroundColor: "#fff !important",
            borderRadius: "10px",
            color: "#013C44",
            padding:"12px 15px",
        }
    },
    primaryInput: {


        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '20px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '20px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiOutlinedInput-input": {
            fontSize: '20px',
            fontFamily: "VisueltRegular",
            height: "95px",
            padding: "0px",
            paddingLeft: "39px",
            backgroundColor: "#fff !important",
            borderRadius: "20px",
            color: "#00839B",
        }
    },
    primaryInputAddPatient: {


        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            // border: '1px solid #808080',
            // opacity: '0.95',
            border: "1.56614px solid #66B5C3",
            borderRadius: "10px",
        },
        "& .MuiTextField-root": {
            borderRadius: '5px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '5px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiOutlinedInput-input": {
            fontSize: '22px',
            fontFamily: "VisueltRegular",
            height: "50px",
            padding: "0px",
            paddingLeft: "15px",
            paddingRight: '10px',
            backgroundColor: "#fff !important",
            borderRadius: "1px",
            color: "#013C44",
            fontWeight:300,
        }
    },
    primaryInput3: {


        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '20px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '20px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiOutlinedInput-input": {
            fontSize: '20px',
            fontFamily: "VisueltRegular",
            height: "95px",
            padding: "0px",
            paddingLeft: "39px",
            backgroundColor: "#fff !important",
            borderRadius: "20px",
            color: "#00839B",
        }
    },
    DateInput: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #66B5C3',
            opacity: '0.95',
            textTransform: 'uppercase'
        },
        "& .MuiTextField-root": {
            borderRadius: '40px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            textTransform: 'uppercase',

        },
    },
    dropDownInput: {
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            color: '#00839B'
        },
        "& .MuiPaper-root": {
            // color: 'red !important',
        }
    },
    uploadLabel: {
        display: 'flex',
        fontFamily: "VisueltMedium",
        justifyContent: 'center',
        alignItems: 'center',
        width: '91px',
        height: '91px',
        cursor: 'pointer',
        border: '1px solid #00839B',
        background: '#DBE4F1',
        borderRadius: '50%',
    },
    uploadButton: {
        position: 'absolute',
        opacity: '0',
        zIndex: -1,
    },
    uploadContainer: {
        width: '288px',
        marginLeft: '60px',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    uploadHeading: {
        fontFamily: "VisueltRegular",
        color: '#013C44',
        fontSize: '20px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: '-0.291346px',
        marginBottom: '10px'
    },
    uploadInstruction: {
        textAlign: 'justify',
        fontStyle: 'normal',
        fontSize: '15px',
        lineHeight: '19px',
        fontWeight: 'normal',
        letterSpacing: '-0.291346px',
        color: 'rgba(4, 102, 115, 0.5)'
    },
    stepperButton: {
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems: 'center',
        background: '#04A9C8',
        color: '#FFFFFF',
        fontSize: '25px',
        lineHeight: '30px',
        fontWeight: 'bold',
        letterSpacing: '1.71px',
        fontStyle: 'normal',
        width: 'auto',
        height: '74px',
        borderRadius: '20px',
        padding: '0 10px',
        marginRight: 43,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#00839B',
        },
        [theme.breakpoints.down("sm")]: {
            padding: '0 20',
            fontSize: '13px',
            lineHeight: '20px',
            width: '10rem',
            height: '3.5rem',
        },
    },
    stepperButtonLogo: {
        marginLeft: '30px',
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        [theme.breakpoints.down("sm")]: {
            marginLeft: '40px',
            width: '10px'
        }

    },
    uploadButton1: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#FFFFFF',
        padding: '0 26px',
        fontSize: '18px',
        textTransform: 'capitalize',
        lineHeight: '30px',
        fontWeight: 'bold',
        letterSpacing: '1.71px',
        fontStyle: 'normal',
        margin: '10px 0 20px 0',
        width: 'auto',
        height: '4.625rem',
        backgroundColor: '#00839B',
        borderRadius: '15px',
        boxShadow: '-7px 6px 7px rgba(6, 99, 116, 0.05)',
        fontFamily: 'GreycliffCFBold',
        '&:hover': {
            backgroundColor: '#04A9C8',
        },
        [theme.breakpoints.down("sm")]: {
            padding: '0 20',
            fontSize: '13px',
            lineHeight: '20px',
            width: 'auto',
            height: '3.5rem',
        },
    },
    labelBold: {
        fontFamily: 'GreycliffCFBold',
        fontWeight: 'bold',
        color: '#013C44',
        lineHeight: '22px',
        fontSize: '18px'
    },
    disableDropDown: {
        color: '#b2bec3',
        fontSize: '20px',
    },

    dropDown: {
        fontFamily: 'VisueltRegular',
        color: '#00839B;',
        fontSize: '20px',
        cursor: 'pointer',
        '&:hover': {
            color: '#013C44'
        }
    },
    headersearchtxt: {
        background: "transparent",
        border: "1px solid #a0dfc4",
        borderRadius: "10px",
        width: "230px",
        height: "50px",
        paddingLeft: "10px",
        color: "#013c4480",
        fontSize: "18px",
        overflow: "hidden",
        whiteSpace: "pre",
        textOverflow: "ellipsis",
      },



}))


export { useStyle };










