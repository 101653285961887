const loginReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_LOGIN":
            return { payload: action.payload };
        case "GET_LOGIN_LOADING":
            return {};
        case "LOGOUT":
            return {};
        default:
            return state;
    }

}
export { loginReducer }