import React,{useState} from 'react'
import {
    Grid,
    Dialog,
    Button,
    DialogContentText,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    makeStyles,
    useTheme,
    Typography,
    Fab,
  } from "@material-ui/core";
  import style from "../style";
  import floatOpen from "../../../assets/images/floatOpen.svg";
import floatClose from "../../../assets/images/floatClose.svg";
import addpatientmenu from "../../../assets/images/addPatientmenu.svg";
import prescription from "../../../assets/images/prescription.svg";
import diagOrder from "../../../assets/images/diagOrder.svg";
import messagept from "../../../assets/images/messagept.svg";

import DialogLayout from "../components/DialogLayout";
import AddPatientMob from "../AddPatientMob";
import MessagePatient from "../MessagePatient";
import DiagnosticPres from "../DiagnosticPres";
  const useStyles = makeStyles((theme) => style(theme));

function FloatingIcon() {
    const classes = useStyles();
    const theme = useTheme();
    const [floatMenu, setFloatMenu] = useState(false);
    const [addPatient, setAddPatient] = useState(false);
    const [messagePatient, setMessagePatient] = useState(false);
    const [diagnostic, setDiagnostic] = useState(false);
  return (
 <>
      {/* floating icons start */}
      <Grid
        style={{
          position: "fixed",
          top: floatMenu ? "400px" : "600px",
          right: "23px",
        }}
      >
        {floatMenu ? (
          <Grid container direction="column" alignItems="flex-end">
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setAddPatient(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Find /Add a Patient
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#00839B" }}
                // onClick={() => setAddPatient(true)}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#66B5C3" }}
                >
                  <img src={addpatientmenu} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setDiagnostic(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Create a Prescription
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#37CC8C" }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#76DCB0" }}
                  // onClick={() => setDiagnostic(true)}
                >
                  <img src={prescription} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setDiagnostic(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Create a Diagnostic Order
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#013C44" }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#3D636A" }}
                  // onClick={() => setDiagnostic(true)}
                >
                  <img src={diagOrder} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ marginBottom: "9px" }}
              onClick={() => setMessagePatient(true)}
            >
              <Grid item className={classes.menuCont}>
                <Typography className={classes.menuText}>
                  Message Patient
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.menuIcon}
                style={{ background: "#FF5C3C" }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.menuIconInner}
                  style={{ background: "#FF8D77" }}
                  // onClick={() => setMessagePatient(true)}
                >
                  <img src={messagept} alt="close" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img
                src={floatClose}
                alt="close"
                onClick={() => setFloatMenu(false)}
              />
            </Grid>
          </Grid>
        ) : (
          <img
            src={floatOpen}
            alt="floatOpen"
            onClick={() => setFloatMenu(true)}
          />
        )}
      </Grid>


      {addPatient ? (
        <DialogLayout open={addPatient}>
          <AddPatientMob closePat={() => setAddPatient(false)} />
        </DialogLayout>
      ) : null}
      {messagePatient ? (
        <DialogLayout open={messagePatient} height="556px">
          <MessagePatient closeMessage={() => setMessagePatient(false)} />
        </DialogLayout>
      ) : null}
      {diagnostic ? (
        <DialogLayout open={diagnostic}>
          <DiagnosticPres closeDiag={() => setDiagnostic(false)} />
        </DialogLayout>
      ) : null}
 </>
  )
}

export default FloatingIcon