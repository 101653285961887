import React from "react";
import "./Referral.scss";
import drop from "../../assets/images/drop.svg";
import axios from "axios";
import { useSelector } from "react-redux";

function ReferralPost({ routedata, handlereferralSuccessPopup }) {
  const [show, setShow] = React.useState(false);
  const [refoption, setRefoption] = React.useState([]);
  const [option, setoption] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const userdata = useSelector((state) => state);

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/metadata/specialities")
      .then((res) => setRefoption(res.data)) //res.data
      .catch((err) => console.log(err.response));
  }, []);

  const handleRefSubmit = () => {
    var data = JSON.stringify({
      doctor_uuid: routedata.doctor_uuid,
      patient_uuid: routedata.patient_uuid,
      booking_uuid: routedata.uuid,
      referred_speciality: option,
      referral_notes: notes,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/epquery/createReferrals",
      headers: {
        "x-auth-token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MGQ5ZDI4ZjJmNThmZDAwMWFlN2Y5MDYiLCJ1dWlkIjoiQ04tRTZCQzM3RDgiLCJncm91cCI6ImNvbnN1bWVyIiwiZnVsbF9uYW1lIjoiU2l2YSBQIiwic3Vic2NyaXB0aW9uIjpmYWxzZSwiaWF0IjoxNjI4Njg0MjYyfQ.Ctkm-vJjQpi2AFECefnBg_VlNIpzN7gLV2P-GOVTzMs",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        handlereferralSuccessPopup();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="Referral">
      <div className="heading">Create Referral</div>
      <div className="dropdown" onClick={() => setShow(!show)}>
        {option ? option : "Select Speciality"} <img src={drop} alt="icon" />
      </div>
      {show ? (
        <div className="drop_option">
          {refoption.length !== 0 ? (
            refoption.map((v, i) => (
              <div
                key={i}
                className="options option_border"
                onClick={() => {
                  setoption(v.name);
                  setShow(!show);
                }}
              >
                {v.name}
              </div>
            ))
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "20px",
                color: "#013c44",
              }}
            >
              No Option to Select
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="secondary_heading">Referral Note:</div>
      <textarea
        placeholder="Please type heare"
        className="input_referral"
        onChange={(e) => setNotes(e.target.value)}
      ></textarea>
      <div className="reff_btn" onClick={handleRefSubmit}>
        Create Referral
      </div>
    </div>
  );
}

export default ReferralPost;
