
import React, {useState, useEffect} from 'react';
import backgroundImg from '../../images/BackgroundImage.svg';
import { Button, Grid, TextField, makeStyles } from '@material-ui/core';
// import { makeStyles, Grid } from '@material-ui/core';
import { useStyle } from '../../style/style'
import triangle from '../../images/icons/triangle.svg'
import MuiPhoneNumber from 'material-ui-phone-number';
import axios from 'axios'
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";

import MobLayout from "../../../Dashboard/mobLayout";

// import ForgotPassword from '../../../doctor-dashboard/mobileComp/ForgotPassword';
// import { useTheme, useMediaQuery } from "@material-ui/core";



// component
import Logo from '../../component/Logo/Logo'
import { Field, Form, Formik } from 'formik';
import { Dialog } from '@material-ui/core';
import ErrorScreen from '../../component/StepperComponent/ErrorScreen';
import { Helmet } from 'react-helmet';
import ForgotPassword from "../../../doctor-dashboard/mobileComp/authPages/ForgotPassword"
import {  useTheme, useMediaQuery } from "@material-ui/core";

const useStyle2 = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh',
        width: '100vw',
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    gridContainer: {
        height: '100%',
        width: '100%',
    },
    rightGrid: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid #00839B',
        borderRadius: '50px 0px 0px 50px',
        margin: '0 0 0 auto',
        background: '#FFFFFF',
        opacity: '0.95',
        [theme.breakpoints.down("sm")]: {
            borderRadius: '3px',
        },
    },
    container: {
        width: '70%',
        height: 'auto',
        marginTop: '20%'

    }
}))

function Screen1() {
    // const customSM = useMediaQuery("(max-width:800px)");
    const classes2 = useStyle2()
    const classes = useStyle()
    const customSM = useMediaQuery("(max-width:800px)");
    const [mobile, setMobile] = React.useState('')
    const [success, setsuccess] = React.useState('')
    const [error, setError] = React.useState('')
    const [errorDialog, seterrorDialog] = React.useState(false);
    const [handleDialogClose, setHandleDialogClose] = React.useState(false)
    const [errorMobile, SetErrorMobile] = React.useState(false);
    let history = useHistory();


    function handleOnChange(value) {
        var res = value.replace(/\D/g, "");
        setMobile(res);
        SetErrorMobile(false)
    }
  
    const handleSubmit = (e) => {
        // e.preventDefault();
      
        const { email } = e


        var data = JSON.stringify({ "email": email, "mobile": mobile });



        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/user/auth/reset-password',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        if (mobile.length === 0) {
            SetErrorMobile(true)
        } else {
            axios(config)
                .then(function (response) {
                    setsuccess(response.data.status)
                    history.push({ pathname: "/verificaton", state: { email, mobile } })
                })
                .catch(function (error) {
             
                    setError(error.response.data.message)
                    seterrorDialog(true)
                });
        }
    }


    const initialValues = {
        email: '',
        mobile: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required!'),
        // mobile: Yup.number().integer().required('Required!')

    })

    const handleClose = () => {
        seterrorDialog(false)
        setHandleDialogClose(true)
    }
  const goBack = () => {
    history.goBack()
  }
  const [heightS, setHeightS] = useState('')

  const checkSafari = () => {
    return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  }
  useEffect(() => {
    if (!checkSafari()){
        setHeightS("70px")
       }
       else {
        setHeightS("80px")
       }
  }, []);
    return customSM ? (
        <>
          <ForgotPassword />
          {/* <MobLayout>
            <ForgotPassword />
          </MobLayout> */}
        </>
      ) :(
        <div className={classes.main}>
            <Helmet>
                <title>doctor.doctall.com | forgot password
</title>
<meta 
            name='description' 
            content='Login to view your dashboard, see number of appointments, create case notes. Send SMS to patients, view earnings, store patient information in secure cloud storage'
            />
            </Helmet>
            <Grid container className={classes.gridContainer}>
                <Grid item md={6} xs={12} className={classes.rightGrid}>
                <div style={{marginTop:"10%",fontWeight:"bold", cursor:"pointer"}} onClick={goBack}> Back </div>
                    <div className={classes2.container}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {
                                ({ errors, touched }) => (
                              
                                    <Form>  
                                        <Logo />
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            name="email"
                                            variant="outlined"
                                            className={classes.primaryInput}
                                            placeholder="E-mail"
                                            required={true}
                                            error={touched.email && errors.email}
                                            helperText={touched.email && errors.email}
                                        />
                                        <div style={{ width: 'auto', height: '30px' }}></div>
                                        <Field
                                            as={MuiPhoneNumber}
                                            name="mobile"
                                            defaultCountry={'ng'}
                                            onChange={handleOnChange}
                                            fullWidth variant="outlined"
                                            required={true}
                                            className={classes.primaryInput}
                                            error={errorMobile}
                                            helperText={errorMobile ? "Required!" : ""}

                                        />

                                        <div style={{ width: 'auto', height: '50px' }}></div>
                                        <Button className={classes.stepperButton} type="submit" >Next <img src={triangle} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </Grid>
            </Grid>
            <Dialog handleDialogClose={handleDialogClose} open={Boolean(errorDialog)} onClose={handleClose}>
                <ErrorScreen err={error} />
            </Dialog>
        </div>
    )
}

export default Screen1

