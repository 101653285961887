import React, { useState } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import patients from "./components/Patients";
import dialogclose from "../../assets/images/DialogClose.svg";
import DialogLayout from "./components/DialogLayout";
import DiagnosticPres from "./DiagnosticPres";
import moment from 'moment';
import styles from "./style";
const useStyles = makeStyles((theme) => styles(theme));

const VisitDetail = ({ closeVisitDetail, visit }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [diagnostic, setDiagnostic] = useState(false);
  console.log(visit,"visits")
  return (
    <Grid
      container
      direction="column"
      style={{
        padding: "28px 20px 38px 16px",
      }}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{ marginBottom: "28px" }}
      >
        <img src={dialogclose} alt="close" onClick={() => closeVisitDetail()} />
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Name</Typography>
          <Typography className={classes.value}>{visit.name}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Date</Typography>
          <Typography className={classes.value}>{visit && visit.createdAt ? `${moment(visit.createdAt).format('DD/MM/YYYY')}` : "NA"}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Date of Birth</Typography>
          <Typography className={classes.value}>{visit && visit.age ? `${moment(visit.age).format('DD/MM/YYYY')}` : "NA"}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Phone No</Typography>
          <Typography className={classes.value}>{visit.mobile}</Typography>
        </Grid>
      </Grid>

      <button
        className={classes.addVisit}
        style={{ width: "300px", marginTop: "35px" }}
        onClick={() => {
          setDiagnostic(true);
        }}
      >
        Add Visit
      </button>
      {diagnostic ? (
        <DialogLayout open={diagnostic}>
          <DiagnosticPres closeDiag={() => setDiagnostic(false)}   patient={visit} 
            Vtype = "direct"/>
        </DialogLayout>
      ) : null}
    </Grid>
  );
};

export default VisitDetail;
