import React from 'react'
import { Button, Grid, TextField, } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import lock from '../images/icons/lock.svg'
import Logo from '../component/Logo/Logo'
import { useStyle } from '../style/style';
import triangle from '../images/icons/triangle.png'
import SuccessMessage from '../component/SuccessMessage/SuccessMessage';
import {
    useHistory
} from "react-router-dom";
import { getLoginData } from "../../redux/actions/loginAction"
import axios from 'axios'
import { useDispatch } from "react-redux";


function LoginScreenWithSuccessful() {
    let history = useHistory();
    const dispatch = useDispatch()

    const classes = useStyle();
    const { email, password } = history.location.state;
    const [showPassword, setShowPassword] = React.useState(true);
    const [loginError, setLoginError] = React.useState(true);
    const [errormsg, setErrorMSg] = React.useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const username = e.target.elements.email.value
        const password = e.target.password.value
        const data = {
            "username": username,
            "password": password
        }
        dispatch(getLoginData(data))

        axios.post(process.env.REACT_APP_API_URL + '/user/auth/Doctorlogin', data)
            .then(res => {
                // setLoginError(true)
                localStorage.setItem('auth', JSON.stringify(res.data.token))

                history.push("/");

            }
            )
            .catch(err => {
                setErrorMSg(err.response.data.message)
                setLoginError(false)

            })
    }
    const handleClickShowPassword = () => {
        setShowPassword(false)
    };
    return (
        <Grid container>
            <Grid item md={4} sm={12} style={{ display: 'block', margin: '0 auto', height: '100vh' }}>
                <div style={{ marginTop: '20%' }}>
                    <Logo />
                    <SuccessMessage message="Your password creation is successful" />
                    <form action="" onSubmit={handleSubmit}>
                        {loginError ? "" : <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ color: 'red', marginBottom: '40px' }}>{errormsg}</div>
                        </div>}
                        <TextField
                            fullWidth
                            name="email"
                            variant="outlined"
                            className={classes.primaryInput}
                            placeholder="E-mail"
                            value={email}

                        />
                        <div style={{ width: 'auto', height: '30px' }}></div>
                        <TextField
                            fullWidth
                            name="password"
                            variant="outlined"
                            type={showPassword ? 'password' : 'text'}
                            className={classes.primaryInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><img src={lock} alt="lock" style={{ cursor: 'pointer', marginRight: 22 }} onClick={handleClickShowPassword} crossorigin="anonymous" /></InputAdornment>,
                            }}
                            value={password}
                            placeholder="Password"
                        />

                        <div style={{ width: 'auto', height: '50px' }}></div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 60 }}>
                            <Button className={classes.stepperButton} type="submit" >Log in <img src={triangle} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                        </div>
                        <div style={{ width: 'auto', height: '50px' }}></div>

                    </form>
                </div>
            </Grid>
        </Grid>
    )
}

export default LoginScreenWithSuccessful
