import React, { useEffect } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import styles from "./style";
import { useState } from "react";
import ProfilePicture from "../../assets/images/ProfilePicture.svg";
import ibutton from "../../assets/images/ibutton.svg";
import icon11 from "../../assets/images/Icon11.svg";
import DialogLayout from "./components/DialogLayout";
import UpdateProfile from "./UpdateProfile";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  getBankDetails,
  getStatistics,
} from "../../redux/actions/ex-appointment-action";
import { getDoctorDetails } from "../../redux/actions/TestAction";
// import { getDoctorDetails } from "../../redux/actions/TestAction";
import { getPatientStats } from "../../redux/actions/TestAction";
import { getEprescription } from "../../redux/actions/TestAction";
import { getLabprescription } from "../../redux/actions/TestAction";
import { getNextAppointment } from "../../redux/actions/TestAction";
import { getAppointmentDates } from "../../redux/actions/TestAction";
import { getSaasDoctorProfile } from '../../redux/actions/ex-appointment-action';
import FloatingIcon from "./FloatingIcons/FloatingIcon";

import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles((theme) => styles(theme));

const ProfilePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const statisticsDetails = useSelector(
    (state) => state.PatientSnapshot.statistics
  );
  const authState = useSelector((state) => state.loginReducer.payload);
  const bankState = useSelector((state) => state.PatientSnapshot.bankDetails);
  console.log(bankState, "bankState");
  const [editProfile, setEditProfile] = useState(false);
  const [editCat, setEditcat] = useState("profile");

  const [allCount, setAllCount] = useState();

  console.log(allCount, "allcount");
  const doctorInfo = useSelector((state) => {
    if (
      state.getDoctorDetails != "" &&
      state.getDoctorDetails.payload != undefined &&
      state.getDoctorDetails.payload.length > 0 &&
      state.getDoctorDetails.payload[0] != undefined
    ) {
      console.log(state.getDoctorDetails.payload[0], "789789789");
      return {
        Location:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.address &&
          state.getDoctorDetails.payload[0].profile.address.city,
        Exp:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.practice_started_year,
        languages:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.languages &&
          state.getDoctorDetails.payload[0].profile.languages.toString(),
        firstName:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].firstName,
        full_name:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].full_name,
        profile_pic:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile_pic,
        experience:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile.practice_started_year,
        mobile:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].mobile,
        email:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].email,
        consultation_fee:
          state.getDoctorDetails.payload[0].consultation_fee &&
          state.getDoctorDetails.payload[0].consultation_fee,
        dob:
          state.getDoctorDetails.payload[0].profile.dob &&
          state.getDoctorDetails.payload[0].profile.dob,
        folioNumber: state.getDoctorDetails.payload[0].profile.folioNumber,
        gender:
          state.getDoctorDetails.payload[0].profile.gender &&
          state.getDoctorDetails.payload[0].profile.gender,
        speciality:
          state.getDoctorDetails.payload[0].profile.speciality &&
          state.getDoctorDetails.payload[0].profile.speciality,
        Certification: state.getDoctorDetails.payload[0].Certification,
        // qualification: state.getDoctorDetails.payload[0].profile.professional_detail.qualification,
        qualification:
          state &&
          state.getDoctorDetails &&
          state.getDoctorDetails.payload[0] &&
          state.getDoctorDetails.payload[0].profile &&
          state.getDoctorDetails.payload[0].profile.professional_detail &&
          state.getDoctorDetails.payload[0].profile.professional_detail
            .qualification,
      };
    }
  });

  console.log(doctorInfo, "doctorInfo");
  let currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var practice_started_year = doctorInfo && doctorInfo.Exp;
  let FullExperience = currentYear - practice_started_year;

  useEffect(() => {
    dispatch(getDoctorDetails());
    dispatch(getBankDetails());
    dispatch(getStatistics());
    dispatch(getPatientStats());
    dispatch(getEprescription());
    dispatch(getLabprescription());
    dispatch(getAppointmentDates());
    getCount();
    dispatch(getSaasDoctorProfile());
  }, [editProfile]);

  const getCount = () => {
    let endPoint = `/ep/dashboardcount/${authState.uuid}`;

    axios
      .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
        headers: {
          "x-auth-token": authState.token,
        },
      })
      .then((res) => {
        if (res.data) {
          setAllCount(res && res.data);
          console.log(res, "redsdf");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        //style={{ padding: "10px 10px" }}
      >
        <Typography
          style={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            color: "#66B5C3",
            marginTop: "17px",
            marginBottom: "18px",
            lineHeight: "23px",
            fontFamily: "Visuelt",
            width: "328px",
          }}
          align="left"
        >
          Hi, Dr.
          {doctorInfo && doctorInfo.firstName}
        </Typography>
        <Grid
          item
          container
          direction="column"
          style={{
            width: "328px",
            minHeight: "560px",
            maxHeight: "600px",
            background: "#FFFFFF",
            border: "1px solid #66B5C3",
            boxShadow: "-7px 6px 7px rgba(6, 99, 116, 0.05)",
            borderRadius: "10px",
          }}
        >
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{ padding: "21px 22px" }}
          >
            <button
              style={{
                width: "54px",
                height: "33px",
                background: "#00839B",
                borderRadius: "10px",
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#FFFFFF",
              }}
              onClick={() => {
                setEditProfile(true);
                setEditcat("profile");
              }}
            >
              Edit
            </button>
          </Grid>
          <Grid
            item
            container
            direction="column"
            style={{ paddingLeft: "20px" }}
          >
            <Grid item container>
              <Grid item>
                <img
                  src={doctorInfo && doctorInfo.profile_pic}
                  alt="profilepic"
                  style={{
                    borderRadius: "17px",
                    width: "121px",
                    height: "148px",
                  }}
                  crossorigin="anonymous"
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: "150px", marginLeft: "24px",overflow:"hidden", }}
              >
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "26px",
                    color: "#013C44",
                    
                  }}
                >
                  Dr.{" "}
                  {doctorInfo && doctorInfo.full_name && doctorInfo.full_name
                    ? doctorInfo.full_name
                    : "NA"}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "21px",
                    color: "#013C44",
                  }}
                >
                  {doctorInfo &&
                  doctorInfo.qualification &&
                  doctorInfo.qualification
                    ? doctorInfo.qualification
                    : "NA"}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "21px",
                    color: "#013C44",
                    marginTop: "6px",
                  }}
                >
                  {doctorInfo && doctorInfo.speciality && doctorInfo.speciality
                    ? doctorInfo.speciality
                    : "NA"}
                </Typography>
                <Typography
                  className={classes.profLabel}
                  style={{ marginTop: "19px" }}
                >
                  Gender
                </Typography>
                <Typography className={classes.profData}>
                  {doctorInfo && doctorInfo.gender && doctorInfo.gender
                    ? doctorInfo.gender
                    : "NA"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              //justifyContent="space-between"
              style={{ marginTop: "20px" }}
            >
              <Grid item style={{ width: "146px" }}>
                <Typography className={classes.profLabel}>Location</Typography>
                <Typography className={classes.profData}>
                  {doctorInfo && doctorInfo.Location && doctorInfo.Location
                    ? doctorInfo.Location
                    : "NA"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.profLabel}>
                  Date of Birth{" "}
                </Typography>
                <Typography className={classes.profData}>
                  {doctorInfo && doctorInfo.dob && doctorInfo.dob != null
                    ? doctorInfo.dob.substr(0, 10)
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              //justifyContent="space-between"
              style={{ marginTop: "13px" }}
            >
              <Grid item style={{ width: "146px" }}>
                <Typography className={classes.profLabel}>
                  Language spoken
                </Typography>
                <Typography className={classes.profData}>
                  {doctorInfo && doctorInfo.languages && doctorInfo.languages
                    ? doctorInfo.languages
                    : "NA"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.profLabel}>
                  Years of Experience
                </Typography>
                <Typography className={classes.profData}>
                  {" "}
                  {FullExperience ? FullExperience : 0} Years
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <hr
                style={{
                  marginTop: "30px",
                  width: "272px",
                  border: "0.25px solid #00839B",
                  marginLeft: "0px",
                }}
              />
            </Grid>
            <Grid item container direction="column">
              <Grid item container style={{ marginTop: "12px" }}>
                <Typography
                  className={classes.profLabel}
                  style={{ width: "29px", marginRight: "12px" }}
                >
                  Email
                </Typography>
                <Typography className={classes.profData}>
                  {doctorInfo && doctorInfo.email && doctorInfo.email
                    ? doctorInfo.email
                    : "NA"}
                </Typography>
              </Grid>
              <Grid item container style={{ marginTop: "16px" }}>
                <Typography
                  className={classes.profLabel}
                  style={{ width: "29px", marginRight: "12px" }}
                >
                  Phone
                </Typography>
                <Typography className={classes.profData}>
                  {doctorInfo && doctorInfo.mobile}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              className={classes.profData}
              style={{ marginTop: "46px" }}
            >
              If you wish to change any information on your profile, Please
              email Doctall Admin at{" "}
              <span style={{ color: "#00839B" }}>support@doctall.com</span>
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid
          item
          container
          direction="column"
          className={classes.earningsCard}
          style={{ marginTop: "16px" }}
        >
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ marginBottom: "11px" }}
          >
            <Typography
              style={{
                width: "86px",
                height: "24px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                color: "#101828",
                lineHeight: "24px",
                fontFamily: "Visuelt Pro",
              }}
            >
              Earnings
            </Typography>
            <img src={ibutton} alt="ibutton" />
          </Grid>
          <Typography
            style={{
              height: "24px",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "12px",
              color: "#667085",
              lineHeight: "16px",
              fontFamily: "Visuelt Pro",
            }}
          >
            Current Balance
          </Typography>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "30px",
              color: "#101828",
              lineHeight: "38px",
              fontFamily: "Visuelt Pro",
            }}
          >
            ₦{" "}
                            {allCount &&
                              allCount.earned &&
                              allCount.earned}
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              background: "#ECFDF3",
              color: "#027A48",
              width: "60px",
              height: "24px",
              borderRadius: "16px",
              padding: "2px 8px 2px 10px",
              marginTop: "10px",
            }}
          >
            <img src={icon11} alt="rise" />
            <Typography
              style={{
                marginLeft: "4.5px",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",

                lineHeight: "20px",
                fontFamily: "Visuelt Pro",
              }}
            >
              12%
            </Typography>
          </Grid>{" "}
        </Grid> */}
        <Grid
          item
          container
          justifyContent="center"
          // direction="column"
          // className={classes.earningsCard}
          style={{ marginTop: "16px" }}
        >
          <Carousel
            showArrows={true}
            showIndicators={false}
            showStatus={false}
            swipeable={true}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
          >
            <Grid
              item
              container
              direction="column"
              className={classes.earningsCard}
              style={{ marginRight: "24px" }}
            >
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-between"
                style={{ marginBottom: "11px" }}
              >
                <Typography
                  style={{
                    width: "86px",
                    height: "24px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#101828",
                    lineHeight: "24px",
                    fontFamily: "Visuelt Pro",
                  }}
                >
                  Earnings
                </Typography>
                <img
                  src={ibutton}
                  alt="ibutton"
                  style={{ width: "16px", height: "16px" }}
                />
              </Grid>
              <Typography
                style={{
                  height: "24px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "#667085",
                  lineHeight: "16px",
                  fontFamily: "Visuelt Pro",
                }}
              >
                Current Balance
              </Typography>
              <Typography
                style={{
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "30px",
                  color: "#101828",
                  lineHeight: "38px",
                  fontFamily: "Visuelt Pro",
                }}
              >
                ₦{allCount && allCount.earned}
              </Typography>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  background: "#ECFDF3",
                  color: "#027A48",
                  width: "60px",
                  height: "24px",
                  borderRadius: "16px",
                  padding: "2px 8px 2px 10px",
                  marginTop: "10px",
                }}
              >
                <img
                  src={icon11}
                  alt="rise"
                  style={{ width: "7px", height: "7px" }}
                />
                <Typography
                  style={{
                    marginLeft: "4.5px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",

                    lineHeight: "20px",
                    fontFamily: "Visuelt Pro",
                  }}
                >
                  12%
                </Typography>
              </Grid>{" "}
            </Grid>

            <Grid
              item
              container
              direction="column"
              className={classes.earningsCard}
              style={{ marginRight: "24px" }}
            >
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-between"
                style={{ marginBottom: "11px" }}
              >
                <Typography
                  style={{
                    width: "86px",
                    height: "24px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#101828",
                    lineHeight: "24px",
                    fontFamily: "Visuelt Pro",
                  }}
                >
                  Outstanding Payments
                </Typography>
                <img
                  src={ibutton}
                  alt="ibutton"
                  style={{ width: "16px", height: "16px" }}
                />
              </Grid>
              <Typography
                style={{
                  height: "24px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "#667085",
                  lineHeight: "16px",
                  fontFamily: "Visuelt Pro",
                  paddingTop: "10px",
                }}
              >
                Current Balance
              </Typography>
              <Typography
                style={{
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "30px",
                  color: "#101828",
                  lineHeight: "38px",
                  fontFamily: "Visuelt Pro",
                }}
              >
                ₦{allCount && allCount.outstanding}
              </Typography>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  background: "#ECFDF3",
                  color: "#027A48",
                  width: "60px",
                  height: "24px",
                  borderRadius: "16px",
                  padding: "2px 8px 2px 10px",
                  marginTop: "10px",
                }}
              >
                <img
                  src={icon11}
                  alt="rise"
                  style={{ width: "7px", height: "7px" }}
                />
                <Typography
                  style={{
                    marginLeft: "4.5px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",

                    lineHeight: "20px",
                    fontFamily: "Visuelt Pro",
                  }}
                >
                  12%
                </Typography>
              </Grid>{" "}
            </Grid>
          </Carousel>
        </Grid>

        <Grid
          container
          direction="column"
          style={{
            width: "328px",
            height: "234px",
            border: "1px solid #66B5C3",
            background: "#F3FAFC",
            borderRadius: "8px",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            style={{
              height: "58px",
              borderBottom: "0.5px solid #00839B",
              paddingTop: "16px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "35px",
                color: "#013C44",
                marginLeft: "21px",
              }}
            >
              Account
            </Typography>

            <button
              style={{
                width: "54px",
                height: "33px",
                background: "#00839B",
                borderRadius: "10px",
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#FFFFFF",
                marginRight: "21px",
              }}
              onClick={() => {
                setEditProfile(true);
                setEditcat("account");
              }}
            >
              Edit
            </button>
          </Grid>
          <Grid item container style={{ padding: "11px 21px" }}>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Bank Name
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {bankState &&
                bankState.bank_detail &&
                bankState.bank_detail.bank_name
                  ? bankState.bank_detail.bank_name
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Account Number
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {bankState &&
                bankState.bank_detail &&
                bankState.bank_detail.acc_number
                  ? bankState.bank_detail.acc_number
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Account Name
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {bankState &&
                bankState.bank_detail &&
                bankState.bank_detail.acc_name
                  ? bankState.bank_detail.acc_name
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Branch
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {bankState &&
                bankState.bank_detail &&
                bankState.bank_detail.branch
                  ? bankState.bank_detail.branch
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ width: "328px" }} alignSelf="flex-start">
          <Grid className={classes.sideHeading}>
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "15px",
                fontFamily: "Visuelt Pro",
                color: "#00839B",
              }}
            >
              This Week
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.statisticsCard}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              borderBottom: "0.5px solid #00839B",
              paddingLeft: "16px",
              paddingRight: "26px",
            }}
          >
            <Typography>Lab Test Advice</Typography>
            <Typography>
              {allCount && allCount.totalLabTestCurrentWeek}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              borderBottom: "0.5px solid #00839B",
              paddingLeft: "16px",
              paddingRight: "26px",
            }}
          >
            {" "}
            <Typography>Medicine Prescription</Typography>
            <Typography>
              {allCount && allCount.totalMedicieneCurrentWeek}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              paddingLeft: "16px",
              paddingRight: "26px",
            }}
          >
            <Typography>Medicine Ordered</Typography>
            <Typography>
              {allCount && allCount.totalMedicieneOrderCurrentWeek}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ width: "328px" }} alignSelf="flex-start">
          <Grid className={classes.sideHeading} style={{ width: "86px" }}>
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "15px",
                fontFamily: "Visuelt Pro",
                color: "#00839B",
              }}
            >
              This Month
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.statisticsCard}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              borderBottom: "0.5px solid #00839B",
              paddingLeft: "16px",
              paddingRight: "26px",
            }}
          >
            <Typography>Lab Test Advice</Typography>
            <Typography>
              {" "}
              {allCount && allCount.totalLabTestThisMonth}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              borderBottom: "0.5px solid #00839B",
              paddingLeft: "16px",
              paddingRight: "26px",
            }}
          >
            {" "}
            <Typography>Medicine Prescription</Typography>
            <Typography>
              {allCount && allCount.totalMedicieneThisMonth}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              paddingLeft: "16px",
              paddingRight: "26px",
            }}
          >
            <Typography>Medicine Ordered</Typography>
            <Typography>
              {allCount && allCount.totalMedicieneOrderThisMonth}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "35px", width: "329px" }}>
          <Grid
            item
            container
            direction="column"
            className={classes.weekIncomeCard}
            alignItems="center"
          >
            <Typography
              style={{
                color: "#FF6E4E",
              }}
              className={classes.incomeHeading}
            >
              Income This Week
            </Typography>
            <hr
              style={{
                borderTop: "0.3px solid #FFBEB2",
                height: "0px",
                width: "100%",
              }}
            />
            <Typography className={classes.incomeMain}>
              {" "}
              ₦ {allCount && allCount.incomeThisWeek}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.monthIncomeCard}
            alignItems="center"
          >
            <Typography
              style={{
                color: "#37CC8C",
              }}
              className={classes.incomeHeading}
            >
              Income This Month
            </Typography>
            <hr
              style={{
                borderTop: "0.3px solid #37CC8C",
                height: "0px",
                width: "100%",
              }}
            />
            <Typography
              className={classes.incomeMain}
              style={{ color: "#37CC8C" }}
            >
              ₦ {allCount && allCount.incomeThisMonth}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {editProfile ? (
        <DialogLayout open={editProfile}>
          <UpdateProfile
            closeEditProfile={() => setEditProfile(false)}
            category={editCat}
            bankDetails={bankState}
            drdata={doctorInfo}
          />
        </DialogLayout>
      ) : null}
      <FloatingIcon/>
    </>
  );
};

export default ProfilePage;
