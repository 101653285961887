import React, { useEffect, useState } from "react";
import style from "./PatientRiskNote.scss";
import opencaratol from "./icons/opencaratol.svg";
import { Checkbox } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import radio_off from "./icons/radio_off.svg";
import radio_on from "./icons/radio_on.svg";
import DGUpArrow from "../../assets/images/DGUpArrow.svg";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  getRiskarea,
  createRiskNote,
  getRisknote,
} from "../../redux/actions/caseNoteAction";
import { useDispatch, useSelector } from "react-redux";
import CreatecaseSuccess from "./CreatecaseSuccess";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #66B5C3",
      opacity: "0.95",
    },
    "& .MuiTextField-root": {
      borderRadius: "40px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      // opacity: '0.95'
    },
    "& .MuiInputBase-input": {
      color: "#00839B",
      // textTransform: 'uppercase',
    },
    "& .MuiCheckbox-root": {
      color: "#00839B",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#00839B",
    },
  },
}));

function PatientRiskNotesPopupListing({ readmore }) {
  const dispatch = useDispatch();
  let history = useHistory();

  const routedata = history.location.state;

  const classes = useStyles();
  const [radio, setRadio] = React.useState(false);
  const [riskArea, setRiskArea] = React.useState("");
  const [des, setDes] = React.useState("");
  const [dropdown, setDropdown] = React.useState(false);

  const riskareas = useSelector((state) => state.getRISKarearedus);
  const [othersbox, setothersbox] = useState(false);
  const [a, seta] = useState([]);
  const [othersvalue, setOthersvalue] = useState("");
  const [reasonConcern, setreasonConcernvalue] = useState("");
  const [actionBoolean, setactiontakenvalueboolean] = useState(false);
  const [actiontakenvalue, setactiontakenvalue] = useState("");
  const [show, setshow] = useState(false);
  const createRiskForpopup = useSelector((state) => state.createRiskreducer);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      patient_uuid: routedata.patient_uuid,
      doctor_uuid: routedata.doctor_uuid,
      booking_uuid: routedata.uuid,
      risk_area: a,
      others_note: othersvalue,
      reason_concern: reasonConcern,
      action_taken: actionBoolean,
      action_taken_notes: actiontakenvalue,
    };

    // dispatch(
    //     createRiskNoteRequest(data))
    dispatch(getRisknote(routedata.uuid));

    setshow(true);
  };
  return (
    <form onSubmit={handleSubmit}>
      <>
        {createRiskForpopup && createRiskForpopup.isLoading ? (
          setLoading(true)
        ) : createRiskForpopup != undefined &&
          createRiskForpopup.payload != undefined &&
          createRiskForpopup.payload.statusText != undefined &&
          createRiskForpopup.payload.statusText == "Created" &&
          show ? (
          setLoading(false) && <CreatecaseSuccess value="RiskNote" />
        ) : (
          <div
            className="PatientRiskNote"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              {/* left container */}
              <div style={{ display: "flex" }} className={classes.root}>
                <div>
                  <p className="patientriskheading">Patient Risk Note</p>

                  <div className="patientRiskNotesDropdown">
                    <p className="patientRiskNotesDropdownText">Risk Area</p>
                    {dropdown ? (
                      <img
                        src={opencaratol}
                        alt="DGUpArrow"
                        style={{ cursor: "pointer" }}
                        onClick={() => setDropdown(!dropdown)}
                        crossorigin="anonymous"
                      />
                    ) : (
                      <img
                        src={DGUpArrow}
                        alt="opencaratol"
                        style={{ cursor: "pointer" }}
                        onClick={() => setDropdown(!dropdown)}
                        crossorigin="anonymous"
                      />
                    )}
                  </div>

                  {dropdown ? (
                    ""
                  ) : (
                    <div className="patientRiskSelectField">
                      {readmore.risk_area.length == 0
                        ? "No Data"
                        : readmore.risk_area.map((item, index) => {
                            return (
                              <div className="checkboxContainer checkboxContainerBodder">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={item.RiskArea}
                                      icon={
                                        <RadioButtonUncheckedIcon
                                          style={{
                                            width: 30,
                                            height: 30,
                                            color: "#00839B",
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckCircleOutlineIcon
                                          style={{
                                            width: 30,
                                            height: 30,
                                            color: "#00839B",
                                          }}
                                        />
                                      }
                                      style={{ transform: "scale(0.8)" }}
                                      id="check"
                                      checked={true}
                                    />
                                  }
                                  label={item}
                                />

                                {/* <label className="radiobuttonlabel">{item.RiskArea}</label> */}
                              </div>
                            );
                          })}
                    </div>
                  )}

                  <textarea
                    className="patientRishNotsInputfield"
                    name="other_notes"
                    defaultValue={
                      readmore.others_note.length === 0
                        ? "No Data"
                        : readmore.others_note
                    }
                    placeholder="Please type here"
                  ></textarea>
                </div>
                {/* right container */}
                <div style={{ width: "50px", height: "auto" }}></div>
                <div>
                  <p className="patientriskheading">Reason for concern:</p>
                  <textarea
                    className="patientRishNotsInputfield"
                    defaultValue={
                      readmore.reason_concern.length == 0
                        ? "No Data"
                        : readmore.reason_concern
                    }
                    name="reason_concern"
                    placeholder="Please type here"
                    style={{ height: "205px", marginBottom: "10px" }}
                  ></textarea>
                  <p className="patientriskheading">Action Taken:</p>
                  <div
                    className="actiontakenButton"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <div className="radiocontainer">
                        {readmore.action_taken ? (
                          <img
                            src={radio_off}
                            alt="radio_on"
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        ) : (
                          <img
                            src={radio_on}
                            alt="radio_off"
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        )}
                        <label className="radiobuttonlabel">
                          No Action taken
                        </label>
                      </div>
                      <div className="radiocontainer">
                        {readmore.action_taken ? (
                          <img
                            src={radio_on}
                            alt="radio_on"
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        ) : (
                          <img
                            src={radio_off}
                            alt="radio_off"
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        )}
                        <label className="radiobuttonlabel">Action taken</label>
                      </div>
                    </div>
                  </div>
                  {readmore.action_taken ? (
                    <textarea
                      className="patientRishNotsInputfield"
                      defaultValue={
                        readmore.action_taken_notes.length == 0
                          ? "No Data"
                          : readmore.action_taken_notes
                      }
                      name="desc"
                      placeholder="Please describe action taken here"
                      style={{ height: "252px" }}
                    ></textarea>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button
                className="patientRiskNotesButton"
                type="submit"
                style={{ cursor: "pointer", backgroundColor: "#bdc3c7" }}
                disabled
              >
                <p
                  className="patientRiskNotesButtonTxt"
                  style={{ margin: "auto" }}
                >
                  Submit
                </p>
              </button>
            </div>
          </div>
        )}
      </>
    </form>
  );
}
export default PatientRiskNotesPopupListing;
