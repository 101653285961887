import React, { useState } from 'react';
import { Button, Grid, Select, TextField, Dialog, makeStyles } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useStyle } from '../../../Admin/style/style';
import { useHistory } from "react-router-dom";
import FileUpload from '../../../Admin/images/icons/fileUpload.svg'
import successTick from '../../../Admin/images/icons/successTick.svg'
import triangleLogo from '../../../Admin/images/icons/triangle.svg'
import axios from 'axios';
import Toast from '../../../components/Toast';
import LoaderComponent from '../../../components/Loading/LoaderComponent';
import { Helmet } from 'react-helmet';


const useStyle2 = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '40px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiPaper-root": {
            color: '#00839B',
            cursor: 'pointer',
            lineHeight: '20px'
        },
        "& .MuiIconButton-root": {
            padding: '0'
        }
    }
}))

export default function SignUpForm(props) {
    const classes = useStyle();
    const classes2 = useStyle2();
    let history = useHistory()

    const [txt, setTxt] = useState('')
    const [middle, setMiddle] = useState('')
    const [last, setLast] = useState('')
    const [folio, setFolio] = useState('')
    const [phoneErr, setPhoneerror] = useState('')





    const initialValues = {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        folioNumber: ''
    };


    const [active, setActive] = useState(false);

    const [profileImage, setProfileImage] = useState();
    const [profileUploaded, setProfileUploaded] = useState(false);
    const [regCertificate, setRegCertificate] = useState();
    const [certificateUploaded, setCertificateUploaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);
    const [country, setCountry] = React.useState("Nigeria");
    const [mobileError, SetMobileError] = React.useState(false);
    const [mobieleErrormsg, setMobileErrormsg] = React.useState('');
    const [mobExist, setmobExist] = React.useState('');
    const [mobile, setMobile] = React.useState('')



    function handleOnChange(value) {
        var res = value.replace(/\D/g, "");
        // const re = /^[0-9]+$/;
        // if (res === "" || re.test(res) === true) {
        //     setMobile(res);
        // }
        setMobile(res);
        SetMobileError(false)
        axios.get(`${process.env.REACT_APP_API_URL}/user/auth/mobileNumExist/${res}`)
            .then(res => {
                setmobExist(res.data.status)
            })
            .catch(err => console.log(err))
    }

    const handleToast = (visibility, messages, type) => {
        setToast(visibility)
        setToastMessage('' + messages)
        setToastType(type)
    }


    const uploadMedia = async (document, docType) => {
        try {
            const formData = new FormData()
            formData.append('media', document)
            // There is no auth header available it's hadcoded in old registration as well
            let hardCodedHeader = {
                'Content-Types': 'multipart/form-data',
                'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDZlOTE3MGQ1ZjVmYjAwMTQ0MmI2NjMiLCJ1dWlkIjoiQ04tQ0M1MjBDODUiLCJncm91cCI6ImNvbnN1bWVyIiwiZnVsbF9uYW1lIjoic3Mgc3NzIiwic3Vic2NyaXB0aW9uIjpmYWxzZSwiaWF0IjoxNjE3ODU4OTI4fQ.29JtWwv8V1Mv9M4nMfAYfr6C-DYKnNKV6Rvv24VLQ-4'
            }

            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/upload/avatar',
                headers: hardCodedHeader,
                data: formData,
            }

            let response = await axios(config)
            if (response.status === 200 || response.status === 201) {
                if (docType === "PIC") {
                    setProfileImage(response.data.url)
                    setProfileUploaded(true)
                } else {
                    setRegCertificate(response.data.url)
                    setCertificateUploaded(true)
                }
                setLoading(false)
            }

        } catch (error) {
            if (error && error.response) {
                handleToast(true, "" + error.response.data, 'error')
            }
            setLoading(false)
            if (docType === "PIC") {
                setProfileUploaded(false)
            } else {
                setCertificateUploaded(false)
            }
        }
    }


    const handleProfilePic = async ({ target: { files } }) => {
        const uploadedImage = files[0];
        setLoading(true)
        await uploadMedia(uploadedImage, "PIC")

    }

    const handleCertificate = async ({ target: { files } }) => {
        const certificates = files[0];
        setLoading(true)
        await uploadMedia(certificates, "CERT")
    }


    const handleSubmit = async (values) => {
        try {
            if (!mobile || mobile.length < 8) {
                handleToast(true, "Please Enter Valid Phone Number", "error")
            }
            // else if (!regCertificate) {
            //     handleToast(true, "Please Upload Registration Certificate", "error")
            // }
            else {
                setToast(false)

                const payLoad = {
                    // email: values.email,
                    // firstName: values.firstName,
                    // middleName: values.middleName,
                    // lastName: values.lastName,
                    email: values.email,
                    firstName: txt,
                    middleName: middle,
                    lastName: last,
                    group: "doctor",
                    profile: {
                        doctor_type: "advice",
                        speciality: ["life coach"],
                        bio: "i am a doctor",
                        doctorDocuments: [regCertificate],
                        folioNumber: folio,
                    },
                    profile_pic: profileImage,
                    mobile: mobile
                }
                setLoading(true)

                var config = {
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + '/user/auth/ext/Doctorregister',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: payLoad
                };
                let response = await axios(config)

                if (response.status === 200 || response.status === 201) {
                    setLoading(false)
                    handleToast(true, "Your Registration request has been sent successfully to doctall admin", "success")
                    setTimeout(() => {
                        history.push('/login')
                    }, 1500);
                }
            }
        } catch (error) {
            if (error && error.response) {
                handleToast(true, "" + error.response.data, "error")
                setLoading(false)
            }
        }
    }
    const FirstNameChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        const re = /^[A-Za-z]+$/;
        if (value === "" || re.test(value) === true) {
            setTxt(value);
        }
        // setTxt(value.replace(/[^a-zA-z]/ig,''));

    }
    const MiddleChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        const re = /^[A-Za-z]+$/;
        if (value === "" || re.test(value) === true) {
            setMiddle(value);
        }
        // setMiddle(value.replace(/[^a-zA-z]/ig,''));

    }
    const LastName = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        const re = /^[A-Za-z]+$/;
        if (value === "" || re.test(value) === true) {
            setLast(value);
        }
        // setLast(value.replace(/[^a-zA-z]/ig,''));

    }
    const FolioChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        const re = /^[0-9]+$/;
        if (value === "" || re.test(value) === true) {
            setFolio(value);
        }
        // setFolio(value.replace(/\D/g,''));

    }

    return (
        <>
        <Helmet>
            <title>doctor.doctall.com | Register
</title>
<meta 
            name='description' 
            content='Register to create an account with Doctall SAAS programme. See number of appointments, create case notes. Send SMS to patients, track earnings, store patient information in secure cloud storage'
            />
        </Helmet>
            <div style={{ width: '100%' }} className={classes2.root}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(value) => {
                        handleSubmit(value)
                    }}
                >
                    {
                        ({ errors, touched, isValid }) => (
                            <Form style={{ width: '70%', margin: 'auto' }} autoComplete="off">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <div className={classes.primaryLabel}>First name*</div>
                                        <Field
                                            fullWidth
                                            name="firstName"
                                            variant="outlined"
                                            required
                                            type="text"
                                            value={txt}
                                            onChange={FirstNameChange}
                                            className={classes.primaryInput2}
                                            as={TextField}
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.primaryLabel}>Middle name</div>
                                        <Field
                                            fullWidth
                                            name="middleName"
                                            variant="outlined"
                                            className={classes.primaryInput2}
                                            as={TextField}
                                            value={middle}
                                            onChange={MiddleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={classes.primaryLabel}>Last name*</div>
                                        <Field
                                            fullWidth
                                            name="lastName"
                                            variant="outlined"
                                            required
                                            className={classes.primaryInput2}
                                            as={TextField}
                                            value={last}
                                            onChange={LastName}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <div className={classes.primaryLabel}>Email Address *</div>
                                        <Field
                                            fullWidth
                                            name="email"
                                            type="email"
                                            variant="outlined"
                                            required={true}
                                            className={classes.primaryInput2}
                                            as={TextField}

                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={classes.primaryLabel}>Phone Number*</div>

                                        <Field
                                            isValid={(value) => {
                                                if (value.length < 12 || value.length > 15) {
                                                    // if (value.length >= 3 && value.length <= 11)
                                                    if (value.length > 3 && value.length <= 11)
                                                        setPhoneerror(true);
                                                    return false;
                                                } else {
                                                    setPhoneerror(false);
                                                    return true;
                                                }
                                            }}
                                            as={MuiPhoneNumber}
                                            name="phoneNumber"
                                            defaultCountry={'ng'}
                                            required
                                            onChange={handleOnChange}
                                            fullWidth variant="outlined"
                                            className={classes.primaryInput2}
                                        />
                                        {/* {phoneErr ? <label style={{ color: "red" }}>Please Enter a Valid Phone Number</label> : null} */}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={classes.primaryLabel}>Folio Number*</div>
                                        <Field
                                            type='text'
                                            min={0}
                                            fullWidth
                                            name="folioNumber"
                                            variant="outlined"
                                            required
                                            className={classes.primaryInput2}
                                            as={TextField}
                                            value={folio}
                                            onChange={FolioChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <label htmlFor="upload-photo2" className={classes.uploadLabel}>
                                                    {certificateUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                                </label>
                                                <input
                                                    type="file"
                                                    name="certificate"
                                                    as={TextField}
                                                    className={classes.uploadButton}
                                                    id="upload-photo2"
                                                    onChange={handleCertificate}
                                                    accept="image/*"
                                                // error={touched.profile && errors.profile}
                                                // required
                                                // helperText={touched.profile && errors.profile}
                                                />
                                            </div>
                                            <div className={classes.uploadContainer}>
                                                <div className={classes.uploadHeading}>
                                                    Upload registration certificate
                                                </div>
                                                <div className={classes.uploadInstruction}>
                                                    {/* Profile picture should be in the standard format png, jpeg & no more than 2mb. */}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>


                                    {/* first upload button  */}
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <label htmlFor="upload-photo" className={classes.uploadLabel}>
                                                    {profileUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                                </label>
                                                <input
                                                    type="file"
                                                    name="profilePic"
                                                    as={TextField}
                                                    className={classes.uploadButton}
                                                    id="upload-photo"
                                                    onChange={handleProfilePic}
                                                    accept="image/*"
                                                // error={touched.profile && errors.profile}
                                                // helperText={touched.profile && errors.profile}
                                                />

                                            </div>
                                            <div className={classes.uploadContainer}>
                                                <div className={classes.uploadHeading}>
                                                    Upload profile picture
                                                </div>
                                                <div className={classes.uploadInstruction}>
                                                    Profile picture should be in the standard format png, jpeg & no more than 2mb.
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={6} style={{ marginBottom: "62px", marginTop: 30 }}>
                                        <Button className={classes.stepperButton} type="submit" >Submit </Button>
                                    </Grid>
                                </Grid>


                            </Form>
                        )
                    }
                </Formik>


                <Toast
                    text={toastMessage}
                    type={toastType}
                    open={showToast}
                    setClose={() => {
                        setToast(false);
                    }}
                />
                {loading && <LoaderComponent />}

                <Dialog
                    disableBackdropClick
                    maxWidth={"sm"}
                    open={active}
                    scroll={"body"}
                    onClose={() => {
                        setActive(false);
                    }}
                >
                    <div className="loginActive">
                        <p>Registration Success</p>
                        <Button>Sign In</Button>
                    </div>
                </Dialog>

            </div >
        </>
    )
}