const initState = {
    payload: [],
    search:[]
};



const createCaseNotereducer = (state=initState,action) =>{
    switch(action.type){
        case "SET_CASE_NOTE_REQUEST":
            return {
                isLoading:true,
                isError:false
            };
            case "SET_CASE_NOTE_SUCCESS":
                return {
                    isLoading:false,
                    payload:action.payload,
                    isError:false
                };
                case "SET_CASE_NOTE_ERROR":
                    return {
                        isLoading:false,
                        isError:true
                    };
        case "CREATE_CASE":
            return {...state,payload:action.payload};
            case "SET_SEARCH_ICD":
                return {...state,search:action.payload,loading:false};
                case "SET_SEARCH_LOADING":
                    return {...state,search:false,loading:true};
                    default:
                        return state;
    }

}

const createRiskreducer = (state ={isLoading:false,isError:false},action) =>{
    switch(action.type){
        case "SET_PATIENT_RISK_NOTE_REQUEST":
            return {
                isLoading:true,
                isError:false
            };
            case "SET_PATIENT_RISK_NOTE_SUCCESS":
                return {
                    isLoading:false,
                    payload:action.payload,
                    isError:false
                };
                case "SET_PATIENT_RISK_NOTE_ERROR":
                    return {
                        isLoading:false,
                        isError:true
                    };
                    default:
                        return state;
    }

}



const createPhyCaseNotereducer = (state ={isLoading:false,isError:false},action) =>{
    switch(action.type){
        case "SET_PSHY_REQUEST":
            return {
                isLoading:true,
                isError:false
            };
            case "SET_PSHY_SUCCESS":
                return {
                    isLoading:false,
                    payload:action.payload,
                    isError:false
                };
                case "SET_PSHY_ERROR":
                    return {
                        isLoading:false,
                        isError:true
                    };
                    default:
                        return state;
    }

}


const getCaseNoteredu = (state="",action) =>{
    switch(action.type){
        case "GET_CASE_NOTE_REDUCER":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}


const getPhyNoteredus = (state="",action) =>{
    switch(action.type){
        case "GET_PHY_CASE_NOTE_REDUCER":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}



const getRiskNoteredus = (state="",action) =>{
    switch(action.type){
        case "GET_RISK_NOTE_REDUCER":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}



const getRISKarearedus = (state="",action) =>{
    switch(action.type){
        case "GET_RISKAREA_REDUCER":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}


const getHIsreducer = (state=[],action) =>{
    switch(action.type){
        case "GET_HIS_REDUCER":
            return {...state,payload:action.payload};
                    default:
                        return state;
    }

}




export {createCaseNotereducer,createPhyCaseNotereducer,getCaseNoteredu,getPhyNoteredus,getHIsreducer,getRISKarearedus,getRiskNoteredus,createRiskreducer}