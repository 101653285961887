export const getNotifyPopupScreen = (uuid) => {
    return {
        type: "GET_NOTIFY_POPUP_SCREEN",
        uuid
    }
}

export const getMedPrescription = (values) => {

    return {
        type: "GET_MED_PRESCRIPTION",
        values
    }
}

export const getCityLab = () => {
    return {
        type: "GET_CITY_LAB"
    }
}


export const getCityForPrescription = () => {
    return {
        type: "GET_CITY_FOR_PRESCRIPTION"
    }
}

export const getAreaLab = (uuid) => {

    return {
        type: "GET_AREA_LAB",
        uuid
    }
}

export const getAreaForPrescription = (city) => {

    return {
        type: "GET_AREA_FOR_PRESCRIPTION",
        city
    }
}

export const searchGenericNameForPrescription = (search) => {

    return {
        type: "SEARCH_GENERIC_NAME_FOR_PRESCRIPTION",
        search
    }
}

export const searchProductNameForPrescription = (search) => {

    return {
        type: "SEARCH_PRODUCT_NAME_FOR_PRESCRIPTION",
        search
    }
}

export const getSearchLab = (params, searchValue) => {
    return {
        type: "GET_SEARCH_LAB",
        params, searchValue
    }
}

export const getSearchLabFinal = (params, searchValue) => {
    return {
        type: "GET_SEARCH_LAB_FINAL",
        params, searchValue
    }
}

export const getSearchLabFinalRadiology = (params, searchValue) => {
    return {
        type: "GET_SEARCH_LAB_FINAL_RADIO",
        params, searchValue
    }
}




export const getHomeservice = (params) => {
    return {
        type: "GET_HOME_SERVICE_PATHOOGY",
        params
    }
}



export const extentAppointAction = (days, id, slot, uuid) => {
    return {
        type: "PUT_EXTENT_APPOINTMENT",
        days, id, slot, uuid
    }
}

export const changeStatusAction = (statusid, stype) => {
    return {
        type: "CHANGE_STATUS",
        statusid, stype
    }
}
export const changeStatusActionRequest = (statusid, stype) => {
    return {
        type: "CHANGE_STATUS_REQUEST",
        statusid, stype
    }
}
export const getSearchLabRa = (params, searchValue) => {
    return {
        type: "GET_SEARCH_LABRA",
        params, searchValue
    }
}



export const getDoc = (data) => {
    return {
        type: "GET_DOC",
        data
    }
}

export const followupAction = (followupdate, bookeddate, slotid, followuuid) => {
    return {
        type: "FOLLOW_UP",
        followupdate, bookeddate, slotid, followuuid
    }
}
export const getMedAttr = (pro, gen, eattr) => {
    return {
        type: "GET_MED_ATTR",
        pro, gen, eattr
    }
}
export const getQueryHistory = (id, stype) => {
    return {
        type: "GET_QUERY_HISTORY_LIST",
        id, stype
    }
}
export const getQueryHistoryRequest = (id, stype) => {
    return {
        type: "GET_QUERY_HISTORY_LIST_REQUEST",
        id, stype
    }
}
export const getNotifyDR = () => {
    return {
        type: "GET_NOTIYDR"
    }
}

export const getExtendRequest = (days, id, slot, uuid) => {
    return {
        type: "GET_EXTEND_REQUEST",
        days, id, slot, uuid
    }
}
export const getFollowRequest = (followupdate, bookeddate, slotid, followuuid) => {
    return {
        type: "GET_FOLLOW_UP_REQUEST",
        followupdate, bookeddate, slotid, followuuid
    }
}

export const getHistoryCaseNote =(caseId)=>{
    return{
        type: "GET_CASE_PDF",caseId
    }
}
export const getallprescription = (e) => {
    
    return {
        type: "GET_ALL_PRESCRIPTION_GENERIC",
        e
    }
}