import React from 'react'
import { Button, Checkbox, Grid, Select, TextField, Dialog, makeStyles, DialogContent } from '@material-ui/core';
import { useStyle } from "../../style/style";
import MuiPhoneNumber from 'material-ui-phone-number';
import Tick from '../../images/icons/Tick.svg'
import upload from '../../images/icons/upload.svg';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import checkoff from '../../images/icons/checkoff.svg';
import checkon from '../../images/icons/checkon.svg';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Close from '../../images/icons/close.svg';
import dropdown from '../../images/icons/dropdown.svg';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import MomentUtils from '@date-io/moment';
// upload documents components
import { DocumentsUpload } from './DocumentsUpload';
import { DatePicker } from '@material-ui/pickers';


const useStyle2 = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '40px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiCheckbox-root": {
            color: '#00839B',
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: '#00839B',
        }
    }
}))



function HrInfo({ handleStepperNext, hrinfo, setHrinfo, personalInfo }) {
    const classes = useStyle();
    const classes2 = useStyle2();
    const theme = useTheme();
    const [dateadded, setDateAdded] = React.useState(null);
    const [dateexpiry, setDateExpiry] = React.useState(null)
    const [dateissue, setDateIssue] = React.useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [uploadDocumentDetails, setUdsploadDocumentDetails] = React.useState({
        docName: '',

    })

    const handleDateAdded = (date) => {
        setDateAdded(moment(date).format("ddd, DD MMM, YYYY"))
    }

    const handleDateIssue = (date) => {
        setDateIssue(moment(date).format("ddd, DD MMM, YYYY"))
    }

    const handleDateExpiry = (date) => {
        setDateExpiry(moment(date).format("ddd, DD MMM, YYYY"))
    }




    // const [docDetails, setDocDetails] = React.useState([])
    const [professional_indemnity, setProInd] = React.useState(false)


    // const [edu, setEdu] = React.useState(false)
    // const [prof, setProf] = React.useState(false)
    // const [per, setPer] = React.useState(false)
    // const [lic, setLic] = React.useState(false)
    const [docStatus, setdocStatus] = React.useState([])
    // const docStatus = []
    const { firstName } = personalInfo;

    const documentsHandleFunction = async (data) => {
        const { _id, docType } = data
        var obj = {
            _id, docType
        }

        docStatus.push(obj)
        for (let x of docStatus) {
            if (x.docType == "EducationalCertifications") {
                setpdu(true)
            }
            else if (x.docType == "ProfessionalCertifications") {
                setproc(true)
            }
            else if (x.docType == "PersonalIdentification") {
                setproid(true)
            }
            else if (x.docType == "ProfessionalLicenses") {
                setprol(true)
            }
            else if (x.docType == "ProofofAddress") {
                setproa(true)
            }
            else if (x.docType == "CurriculumVitae") {
                setcv(true)
            }
        }
    }



    const [edu, setpdu] = React.useState(false);
    const [proc, setproc] = React.useState(false);
    const [proid, setproid] = React.useState(false);
    const [prol, setprol] = React.useState(false);
    const [proa, setproa] = React.useState(false);
    const [cv, setcv] = React.useState(false);







    // country
    const [country, setCountry] = React.useState('Nigeria');
    const [emgcountry, setEmgCountry] = React.useState('');

    // handle dialog open and close
    const [open, setOpen] = React.useState(false);

    const handleCountry = (event) => {
        setCountry(event.target.value)
    }

    const handleemgCountry = (event) => {
        setEmgCountry(event.target.value)
    }


    const [mobile, setMobile] = React.useState('')
    const [emgphone, setemgphone] = React.useState('')
    // country code
    function handleOnChange(value) {
        const res = value.replace(/\D/g, "");
        setMobile(parseInt(res));
    }

    function handleEmgPhone(value) {
        const res = value.replace(/\D/g, "");
        setemgphone(parseInt(res))
    }

    // DialogBox open
    const handleDialogOpen = () => {
        setOpen(true);
    };
    const handleDialogClose = () => {
        setOpen(false);
    };

    const hrInfoValidationSchema = Yup.object().shape({
        // full_name: Yup.string().required('Required'),
        // address: Yup.string().required('Required'),
        // houseNumber: Yup.string().required('Required'),
        // city: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email'),
        // mobile: Yup.string().required('Required'),
        // emgfull_name: Yup.string().required('Required'),
        // emgaddress: Yup.string().required('Required'),
        // emghouseNumber: Yup.string().required('Required'),
        // emgcity: Yup.string().required('Required'),
        // emgstate: Yup.string().required('Required'),
        // emgpostalcode: Yup.string().required('Required'),
        // emgcountry: Yup.string().required('Required'),
        emgemail: Yup.string().email('Invalid email'),
        // emgphone: Yup.string().required('Required'),
        // ProfessionalIndemnity: Yup.string().required('Required'),
        // dateAdded: Yup.string().required('Required'),
        // license: Yup.string().required('Required'),
        // li_authority: Yup.string().required('Required'),
        // dateIssue: Yup.string().required('Required'),
        // dateExpiry: Yup.string().required('Required')
    })


    return (
        <div style={{ width: '100%' }} className={classes2.root}>
            <Formik
                initialValues={hrinfo}
                onSubmit={value => {

                    const { full_name, houseNumber, city, state, postalcode, email, emgfull_name, emghouseNumber, emgcity, emgstate, emgpostalcode, emgemail, li_authority } = value;
                    const dateAdded = dateadded == null ? '' : dateadded;
                    const dateIssue = dateissue == null ? '' : dateissue;
                    const dateExpiry = dateexpiry == null ? '' : dateexpiry;
                    const obj = {
                        full_name, houseNumber, city, state, postalcode, country, email, mobile, emgfull_name, emghouseNumber, emgcity, emgstate, emgpostalcode, emgcountry, emgemail, emgphone, professional_indemnity, dateAdded, li_authority, dateIssue, dateExpiry, docStatus
                    }

                    setHrinfo(obj)
                    handleStepperNext()
                }}
                validationSchema={hrInfoValidationSchema}
            >
                {
                    ({ errors, touched }) => (
                        <Form style={{ width: '70%', margin: 'auto' }}>
                            <Grid spacing={3} container>
                                <Grid item xs={12}>
                                    <label className={classes.labelBold}>Next of Kin</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Full name</div>
                                    <Field
                                        fullWidth
                                        name="full_name"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}

                                        error={touched.full_name && errors.full_name}
                                        helperText={touched.full_name && errors.full_name}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Address</div>
                                    <Field
                                        fullWidth
                                        name="houseNumber"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}

                                        error={touched.houseNumber && errors.houseNumber}
                                        helperText={touched.houseNumber && errors.houseNumber}
                                        placeholder="House name/number" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        name="city"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}

                                        error={touched.city && errors.city}
                                        helperText={touched.city && errors.city}
                                        placeholder="City" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        name="state"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}

                                        error={touched.state && errors.state}
                                        helperText={touched.state && errors.state}
                                        placeholder="State" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        name="postalcode"
                                        className={classes.primaryInput2}
                                        variant="outlined"
                                        as={TextField}
                                        type="number"
                                        error={touched.postalcode && errors.postalcode}
                                        helperText={touched.postalcode && errors.postalcode}
                                        placeholder="Postal code" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select name="country" value={country} onChange={handleCountry} variant="outlined" fullWidth className={classes.primaryInput2} placeholder="Select Country">
                                        {/* <option value="" className={classes.dropDown}>Country</option> */}
                                        <option value="Nigeria" className={classes.dropDown}>Nigeria</option>
                                        <option value="United Kingdom" className={classes.dropDown}>United Kingdom</option>
                                        <option value="United States" className={classes.dropDown}>United States</option>
                                        <option value="India" className={classes.dropDown}>India</option>
                                        <option value="Other" className={classes.dropDown}>Other</option>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Email address</div>
                                    <Field
                                        name="email"
                                        fullWidth
                                        name="email"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}
                                        error={touched.email && errors.email}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel} >Mobile number</div>
                                    <MuiPhoneNumber defaultCountry={'ng'} onChange={handleOnChange} fullWidth variant="outlined" className={classes.primaryInput2} />
                                </Grid>
                                <Grid item xs={12}>
                                    <label className={classes.labelBold}>Emergency Contact</label>
                                    <div style={{ width: 'auto', height: '10px' }}></div>
                                    <div className={classes.primaryLabel} style={{ width: '70%' }}>
                                        Ignore these fields if the Next of Kin information is the same as the Emergency Contact
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Full name</div>
                                    <Field
                                        fullWidth
                                        name="emgfull_name"
                                        variant="outlined"
                                        className={classes.primaryInput2}

                                        as={TextField}
                                        error={touched.emgfull_name && errors.emgfull_name}
                                        helperText={touched.emgfull_name && errors.emgfull_name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Address</div>
                                    <Field
                                        fullWidth
                                        name="emghouseNumber"
                                        variant="outlined"
                                        className={classes.primaryInput2}

                                        as={TextField}
                                        error={touched.emghouseNumber && errors.emghouseNumber}
                                        helperText={touched.emghouseNumber && errors.emghouseNumber}
                                        placeholder="House name/number" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        name="emgcity"
                                        variant="outlined"
                                        className={classes.primaryInput2}

                                        as={TextField}
                                        error={touched.emgcity && errors.emgcity}
                                        helperText={touched.emgcity && errors.emgcity}
                                        placeholder="City" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        name="emgstate"
                                        variant="outlined"
                                        className={classes.primaryInput2}

                                        as={TextField}
                                        error={touched.emgstate && errors.emgstate}
                                        helperText={touched.emgstate && errors.emgstate}
                                        placeholder="State" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        name="emgpostalcode"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}

                                        error={touched.emgpostalcode && errors.emgpostalcode}
                                        helperText={touched.emgpostalcode && errors.emgpostalcode}
                                        placeholder="Postal code" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select name="country" value={emgcountry} onChange={handleemgCountry} variant="outlined" fullWidth className={classes.primaryInput2} >
                                        <option aria-label="None" value="" disabled selected hidden>Country</option>
                                        <option value="Nigeria" className={classes.dropDown}>Nigeria</option>
                                        <option value="United Kingdom" className={classes.dropDown}>United Kingdom</option>
                                        <option value="United States" className={classes.dropDown}>United States</option>
                                        <option value="India" className={classes.dropDown}>India</option>
                                        <option value="Other" className={classes.dropDown}>Other</option>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Email address</div>
                                    <Field
                                        fullWidth
                                        name="emgemail"
                                        variant="outlined"
                                        className={classes.primaryInput2}

                                        as={TextField}
                                        error={touched.emgemail && errors.emgemail}
                                        helperText={touched.emgemail && errors.emgemail}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Mobile number</div>
                                    <MuiPhoneNumber defaultCountry={'ng'} onChange={handleEmgPhone} fullWidth variant="outlined" className={classes.primaryInput2} />
                                </Grid>
                                {/* Changes */}
                                <Grid item xs={12}>
                                    <label className={classes.labelBold}>Professional Indemnity</label>
                                    <div style={{ width: 'auto', height: '10px' }}></div>
                                    <div style={{ display: 'flex', }}>
                                        {professional_indemnity ? <img src={checkon} alt="check" onClick={() => setProInd(!professional_indemnity)} style={{ marginRight: '10px', marginTop: '-15px' }} crossorigin="anonymous" /> : <img src={checkoff} alt="check" style={{ marginRight: '10px', marginTop: '-15px' }} onClick={() => setProInd(!professional_indemnity)} crossorigin="anonymous" />}
                                        <div className={classes.primaryLabel} style={{ width: '70%' }}>This confirms that Dr. {firstName} has been added to Doctall Group PI Policy</div>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div className={classes.primaryLabel}>Date added</div>
                                    {/* <Field
                                        fullWidth
                                        variant="outlined"
                                        name="dateAdded"
                                        type="date"

                                        as={TextField}
                                        className={classes.DateInput}
                                        error={touched.dateAdded && errors.dateAdded}
                                        helperText={touched.dateAdded && errors.dateAdded}

                                    /> */}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            inputVariant="outlined"
                                            name="dateAdded"
                                            format="DD/MM/yyyy"
                                            id="date-picker-inline"
                                            value={dateadded}
                                            className={classes.primaryInput2}
                                            onChange={handleDateAdded}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><img src={dropdown} alt="dateIcon" style={{ cursor: 'pointer', }} crossorigin="anonymous" /></InputAdornment>,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={12}>
                                    <label className={classes.labelBold}>Doctors License</label>
                                    <div style={{ width: 'auto', height: '10px' }}></div>
                                    <div className={classes.primaryLabel}>Licensing Authority</div>
                                    <Field
                                        fullWidth
                                        name="li_authority"
                                        variant="outlined"
                                        className={classes.primaryInput2}
                                        as={TextField}
                                        error={touched.license && errors.license}
                                        helperText={touched.license && errors.license}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <div className={classes.primaryLabel}>Date of Issue</div>
                                    {/* <Field
                                        fullWidth
                                        variant="outlined"
                                        name="dateIssue"
                                        type="date"
                                        as={TextField}
                                        className={classes.DateInput}

                                        error={touched.dob && errors.dob}
                                        helperText={touched.dob && errors.dob}

                                    /> */}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            inputVariant="outlined"
                                            name="dateIssue"
                                            format="DD/MM/yyyy"
                                            id="date-picker-inline"
                                            value={dateissue}
                                            onChange={handleDateIssue}

                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><img src={dropdown} alt="dateIcon" style={{ cursor: 'pointer', }} crossorigin="anonymous" /></InputAdornment>,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.primaryLabel}>Date of Expiry</div>
                                    {/* <Field
                                        fullWidth
                                        variant="outlined"
                                        name="dateExpiry"
                                        type="date"
                                        as={TextField}
                                        className={classes.DateInput}
                                        error={touched.dob && errors.dob}
                                        helperText={touched.dob && errors.dob}


                                    /> */}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            inputVariant="outlined"
                                            name="dateExpiry"
                                            format="DD/MM/yyyy"
                                            id="date-picker-inline"
                                            value={dateexpiry}
                                            onChange={handleDateExpiry}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><img src={dropdown} alt="dateIcon" style={{ cursor: 'pointer', }} crossorigin="anonymous" /></InputAdornment>,
                                            }}
                                            renderInput={props => <TextField {...props} />}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={12}>
                                    <label className={classes.labelBold}>Required documents</label>
                                    <div style={{ width: 'auto', height: '10px' }}></div>
                                    <div className={classes.primaryLabel} style={{ width: '70%' }}>
                                        Please ensure the documents listed below are all uploaded and check them when uploaded.</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className={classes.uploadButton1} onClick={handleDialogOpen} >
                                    <div>
                                        Upload Documents
                                        <img src={upload} alt="upload" style={{ marginLeft: '20px' }} crossorigin="anonymous" />
                                    </div>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                    {edu ? <img src={checkon} alt="check" onClick={() => setpdu(!edu)} style={{ marginRight: '10px', marginTop: '-15px' }} crossorigin="anonymous" /> : <img src={checkoff} alt="check" style={{ marginRight: '10px', marginTop: '-15px' }} onClick={() => setpdu(!edu)} crossorigin="anonymous" />}
                                    <div className={classes.primaryLabel} >Educational Certifications</div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                    {proc ? <img src={checkon} alt="check" onClick={() => setproc(!proc)} style={{ marginRight: '10px', marginTop: '-15px' }} crossorigin="anonymous" /> : <img src={checkoff} alt="check" style={{ marginRight: '10px', marginTop: '-15px' }} onClick={() => setproc(!proc)} crossorigin="anonymous" />}
                                    <div className={classes.primaryLabel} >Professional Certifications</div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                    {proid ? <img src={checkon} alt="check" onClick={() => setproid(!proid)} style={{ marginRight: '10px', marginTop: '-15px' }} crossorigin="anonymous" /> : <img src={checkoff} alt="check" style={{ marginRight: '10px', marginTop: '-15px' }} onClick={() => setproid(!proid)} crossorigin="anonymous" />}
                                    <div className={classes.primaryLabel} >Personal Identification (International Passport, National ID, Voters Card, etc.)</div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                    {prol ? <img src={checkon} alt="check" onClick={() => setprol(!prol)} style={{ marginRight: '10px', marginTop: '-15px' }} crossorigin="anonymous" /> : <img src={checkoff} alt="check" style={{ marginRight: '10px', marginTop: '-15px' }} onClick={() => setprol(!prol)} crossorigin="anonymous" />}
                                    <div className={classes.primaryLabel} >Professional Licenses</div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                    {proa ? <img src={checkon} alt="check" onClick={() => setproa(!proa)} style={{ marginRight: '10px', marginTop: '-15px' }} crossorigin="anonymous" /> : <img src={checkoff} alt="check" style={{ marginRight: '10px', marginTop: '-15px' }} onClick={() => setproa(!proa)} crossorigin="anonymous" />}
                                    <div className={classes.primaryLabel} >Proof of Address (Utility Bill, Phone Bill etc. not older than 3 months)</div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                    {cv ? <img src={checkon} alt="check" onClick={() => setcv(!cv)} style={{ marginRight: '10px', marginTop: '-15px' }} crossorigin="anonymous" /> : <img src={checkoff} alt="check" style={{ marginRight: '10px', marginTop: '-15px' }} onClick={() => setcv(!cv)} crossorigin="anonymous" />}
                                    <div className={classes.primaryLabel} >Curriculum Vitae</div>
                                </div>
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: "62px", marginTop: 30 }}>
                                <div style={{ width: 'auto', height: '10px' }}></div>
                                <Button className={classes.stepperButton} type="submit">Create Account <img src={Tick} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                            </Grid>


                        </Form>
                    )
                }
            </Formik>

            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="responsive-dialog-title"
                open={open}
                fullScreen={true}
                scroll={"body"}
            >
                <div className="UploadFile">

                    <div className="ContainerWrapperFull">
                        <img src={Close} alt="Close" className="Closepopup" onClick={() => setOpen(false)} crossorigin="anonymous" />


                        <DocumentsUpload uploadDocumentDetails={uploadDocumentDetails} documentsHandleFunction={documentsHandleFunction} />


                    </div>
                </div>
            </Dialog>


        </div>
    )
}

export default HrInfo
