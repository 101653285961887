import React from 'react';
import './SendSms.scss';
import send from '../../assets/images/Send.svg';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import Close from '../../assets/images/close.svg';
import succ from '../../assets/images/success.svg';
var axios = require('axios');



function SendSms({ mobile }) {
    const history = useHistory();
    const [text, setText] = React.useState('')
    const [errMsg, setErrMsg] = React.useState(false);
    const [msgSend, setMsgSend] = React.useState(false)
    const [error, setError] = React.useState('')
    const [msg, setMsg] = React.useState('')
    const token = useSelector(state => state.loginReducer.payload.token)
    const routedata = history.location.state;

    React.useEffect(() => {
        if (text.length >= 160) {
            setErrMsg(true)
            setMsg('Only 160 characters are allowed!')
        } else {
            setErrMsg(false)

        }
    }, [text])

    const handleClosePopup = () => {
        setMsgSend(false)
    }


    const handleSubmit = () => {


        if (text.length >= 160) {
            setErrMsg(true)
        } else if (mobile.length === 0) {
            setErrMsg(true)
        } else {
            var data = JSON.stringify({ "doctor_uuid": routedata.doctor_uuid, "patient_uuid": routedata.patient_uuid, "booking_uuid": routedata.uuid, "receiver_mobile_number": mobile, "message": text, "sender_id": routedata.doctor_uuid, "receiver_id": routedata.patient_uuid, });

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/sms/smsSendByDoctor',
                headers: {
                    'x-auth-token': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    setErrMsg(false)
                    setMsg('Message Sent!')
                    setMsgSend(true)
                })
                .catch(function (error) {
                    setErrMsg(true)
                    setMsg(error)
                });

            setErrMsg(false)
        }

    }

    return (
        <div className="sendSms">
            <div className="headerContainer">
                <div className="heading">SMS Text</div>
                <div className="mobile">{mobile}</div>
            </div>
            <textarea name="text" id="" className="smsInput" cols="30" rows="10" onChange={e => setText(e.target.value)}></textarea>
            {errMsg ? <div className="ErrorMsg">{msg}</div> : ''}
            <div className={errMsg ? "sendErrBtn" : "sendBtn"} onClick={handleSubmit}>Send SMS <img src={send} alt="icon" style={{ float: 'right' }} crossorigin="anonymous"/></div>
            <Dialog
                maxWidth={"lg"}
                open={msgSend}
                scroll={"body"}
                onClose={handleClosePopup}

            >
                <div className="ContainerWrapper">
                    <img src={Close} alt="Close" className="Closepopup" onClick={() => setMsgSend(false)} crossorigin="anonymous" />
                    <div className="MsgContainer" style={{
                        width: '423px',
                        height: '298px',
                        background: '#F0F5F5',
                        boxShadow: '0px 0px 50px rgba(38, 43, 82, 0.05)',
                        borderRadius: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div className="Container">
                            <img src={succ} alt="icon" style={{ marginBottom: '20px' }} crossorigin="anonymous"/>
                            <div style={{
                                fontFamily: 'GreycliffCFBold',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                lineHeight: '24px',
                                textAlign: 'center',
                                letterSpacing: '-0.3px',

                                color: '#013C44',
                            }}>
                                {msg}
                            </div>

                        </div>
                    </div>
                </div>

            </Dialog>
        </div>

    )
}

export default SendSms
