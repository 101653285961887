import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { useState,useEffect } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import redClose from "../../assets/images/redClose.svg";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Toast from '../../components/Toast/index';
import MuiPhoneNumber from 'material-ui-phone-number';

const useStyles = makeStyles((theme) => styles(theme));

const MessagePatient = ({ closeMessage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const userdata = useSelector((state) => state.loginReducer.payload);
  console.log(userdata,"userdata")
  const [SearchMobile, setSearchMobile]=useState("")
  const [message, setMessage]=useState("")
  const [isPatientFound, setPatientFound] = useState(false);
  const [PatientData, setPatientData]=useState("")
  console.log(PatientData.uuid,"isPatientFound")

  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const [showToast, setToast] = useState(false);

useEffect(()=>{
  handleSearchPatient()
},[SearchMobile])
  const handleSearchPatient = () => {
  
    axios.get(`${process.env.REACT_APP_API_URL}/patient/search/${SearchMobile}`, {
      headers: {
     
        "x-auth-token": userdata.token
      }
    })
      .then((res) => {
        console.log(res.data,"res234234322")
        setPatientData(res.data)
        if(res.data!=""){
          setPatientFound(true)
        }
        else{
          setPatientFound(false)
        }
  console.log(res,"res")

      })
      .catch((err) => {
        console.log(err,"err")
        setPatientFound(false)
      })
  }

    const handleSbmit = (e) => {
      e.preventDefault()
      if(isPatientFound==true){
      let payload={
        "doctor_uuid": userdata && userdata.uuid,
        "message": message,
        "mobile": SearchMobile,
        "patient_uuid": PatientData && PatientData.uuid,
     
    
      }
      axios.post(`${process.env.REACT_APP_API_URL}/sms/saas/send`,payload, {
        headers: {
       
          "x-auth-token": userdata.token
        }
      })
        .then((res) => {
          console.log(res.data, "dfdfdfdf")
          handleToast(true, "Message sent", "success")
          setTimeout(() => {
            closeMessage()
          }, 1500);
          
          // closeMessage()
            // .then(()=>{
            //   closePat();
            // })
         
         
        })
        .catch((error) => {
          console.log(error,"erzvxcr")
          handleToast(true, "Failed to send message", "error")
          // handleToast(true, error?error.response.data:null, "error")
        })
      }
    }

    const handleToast = (visibility, messages, type) => {
      setToast(visibility)
      setToastMessage('' + messages)
      setToastType(type)
    }
    const handleChangePhone = (e) => {
      console.log(e,"e")
      const  value = e
      const noSpecialCharacters = value.replace(/[^a-zA-Z0-9()]/g, '');
      console.log(noSpecialCharacters, "noSpecialCharacters");
  
      // const re = /[^a-zA-Z0-9 ]/;
      // if (value === "" || re.test(value) === true) {
        setSearchMobile(noSpecialCharacters);
      // }
      // setFolio(value.replace(/\D/g,''));
  
    }

  return (
    <Grid
      container
      direction="column"
      style={{ paddingTop: "16px", paddingLeft: "21px" }}
    >
      <Grid container justifyContent="space-between">
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "35px",
            color: "#013C44",
          }}
        >
          Message Patient
        </Typography>
        <img
          src={dialogclose}
          alt="close button"
          style={{ marginRight: "26px" }}
          onClick={() => closeMessage()}
        />
      </Grid>
      <hr
        style={{
          height: "0.5px",
          borderWidth: "0px",
          width: "338px",
          marginLeft: "-21px",
          background: "#00839B",
          borderColor: "#00839B",
        }}
      />
      <form onSubmit={handleSbmit}>
      <Typography
        style={{
          fontFamily: "Visuelt Pro",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "10px",
          lineHeight: "12px",
          letterSpacing: "0.015em",
          color: "#00839B",
          marginTop: "12px",
          marginBottom: "4px",
        }}
      >
        Mobile Number
      </Typography>
      {/* <input
        className={classes.inputField}
        style={{ border: "1px solid #A0DFC4" }}
        onChange={ e => setSearchMobile(e.target.value)}
      /> */}
      <MuiPhoneNumber 
defaultCountry={'ng'} 
onChange={handleChangePhone}
InputProps={{ disableUnderline: true }}
className={classes.inputField}
                placeholder="Enter Folio number"
                // value={phoneNewNumber}
/>
      <textarea className={classes.textArea} style={{ marginTop: "68px" }} 
        onChange={ e => setMessage(e.target.value)}
      />
      <button
        className={isPatientFound? classes.searchButton :classes.disabledButton}
        style={{ marginTop: "22px", marginBottom: "29px" }}
        type="submit"
      >
        Submit
      </button>
      </form>

      <Toast
                    text={toastMessage}
                    type={toastType}
                    open={showToast}
                    setClose={() => {
                        setToast(false);
                    }}
                /> 
    </Grid>
  );
};

export default MessagePatient;
