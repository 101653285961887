import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePagination from "./components/usePagination";
import { Pagination, PaginationItem } from "@material-ui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Previous from "../../assets/images/previous.svg";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import dots from "../../assets/images/dots.svg";
import DialogLayout from "./components/DialogLayout";
import AddPatientMob from "./AddPatientMob";
import DiagnosticPres from "./DiagnosticPres";
import patients from "./components/Patients";
import PatientDetail from "./PatientDetail";
import visits from "./components/Visits";
import VisitDetail from "./VisitDetail";
import { getVisits } from "../../redux/actions/ex-appointment-action";
import moment from 'moment';
import FloatingIcon from "./FloatingIcons/FloatingIcon";


import { useHistory } from "react-router-dom";
import styles from "./style";

const useStyles = makeStyles((theme) => styles(theme));

const VisitMob = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const visitState = useSelector((state) => state.PatientSnapshot);

console.log(visitState,"visitState");


  const [pageNumber, setPageNumber] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  let [page, setPage] = useState(1);



  const [addPatient, setAddPatient] = useState(false);

  const [visitDetail, setvisitDetail] = useState({
    status: false,
    data: {},
  });

  const PER_PAGE = 10;

  const count = Math.ceil(visitState && visitState.visitList && visitState.visitList.total/ PER_PAGE);

  const _DATA = usePagination(visits, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageNumber(p-1)
  };

  useEffect(() => {
    dispatch(getVisits(pageNumber, searchQuery));
  }, [pageNumber,searchQuery, "",addPatient]);
 



  const handleviewVisit = event => {
    console.log(event,"evesfdnt")
    history.push({
        pathname: '/visit-view',
        state: { 
          detail: event, 
         
         }
    });
 };
  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "20px",
          paddingBottom: "66px",
        }}
      >
        <input
          className={classes.searchField}
          placeholder="search"
          style={{ paddingLeft: "10%" }}
          onChange={(e)=>setSearchQuery(e.target.value)}
        />
        <Grid
          container
          style={{ marginTop: "23px" }}
          justifyContent="space-between"
        >
          <Grid item className={classes.category}>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "15px",
                color: "#00839B",
              }}
            >
              Appointments
            </Typography>
          </Grid>
          <Grid item>
            <button
              className={classes.addNewButton}
              onClick={() => setAddPatient(true)}
            >
              Add visit
            </button>
          </Grid>
        </Grid>
        <Grid container direction="column" style={{ marginTop: "13px" }}>
        {visitState &&
            visitState.visitList&&
            visitState.visitList.data ?
        <div>

          {visitState &&
            visitState.visitList&&
            visitState.visitList.data.map((visit, index) => (
              <Grid item container className={classes.patientCard}>
                <Grid
                  item
                  container
                  style={{
                    height: "70px",
                    borderBottom: "0.5px solid #00839B",
                    paddingLeft: "16px",
                    paddingTop: "22px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "30px",
                      color: "#046673",
                    }}
                  >
                    {visit.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  style={{
                    paddingTop: "12px",
                    paddingLeft: "16px",
                    paddingRight: "22px",
                    paddingBottom: "16px",
                  }}
                >
                  <Grid item container justifyContent="space-between">
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-start"
                      sm
                      xs
                    >
                      <Typography className={classes.label}>Time</Typography>
                      <Typography className={classes.value}>
                        {/* {visit.createdAt} */}
                        
                        {visit && visit.createdAt ? `${moment(visit.createdAt).format('LT')}` : "NA"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-end"
                      sm
                      xs
                    >
                      <Typography className={classes.label}>Date</Typography>
                      <Typography className={classes.value}>
                      {visit && visit.createdAt ? `${moment(visit.createdAt).format('DD/MM/YYYY')}` : "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    style={{ marginTop: "21px" }}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-start"
                      sm
                      xs
                    >
                      <Typography className={classes.label}>
                        Date of Birth
                      </Typography>
                      <Typography className={classes.value}>
                      
                        {visit && visit.age ? `${moment(visit.age).format('DD/MM/YYYY')}` : "NA"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-end"
                      sm
                      xs
                    >
                      <Typography className={classes.label}>
                        Phone No
                      </Typography>
                      <Typography className={classes.value}>
                        {visit.mobile}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container style={{ marginTop: "17px" }}>
                    <Grid item>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          width: "36px",
                          height: "50px",
                          background: "#00839B",
                          borderRadius: "4px",
                          marginRight: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setvisitDetail({ status: true, data: visit })
                        }
                      >
                        <img src={dots} alt="dots" />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <button
                        className={classes.addVisit}
                        // onClick={() => history.push("/visit-view")}
                        onClick={() => handleviewVisit(visit)}
                      >
                        View Visit
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
       </div>
       :null}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{
            borderTop: "0.7px solid #E4E7EC",
            marginTop: "32px",
            paddingTop: "15px",
          }}
        >
          <Pagination
            size="small"
            page={page}
            shape="rounded"
            onChange={handleChange}
            count={count}
            defaultPage={1}
            boundaryCount={1}
            className={classes.pagination}
            color="#FFF5F3"
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: ArrowForwardIcon,
                  previous: ArrowBackIcon,
                }}
                {...item}
              />
            )}
          />
        </Grid>
        {addPatient ? (
          <>
  {/* <DialogLayout open={addPatient}>
            <AddPatientMob closePat={() => setAddPatient(false)} />
          </DialogLayout> */}
          <DialogLayout open={addPatient}>
            <DiagnosticPres closeDiag={() => setAddPatient(false)}
            patient={addPatient.data} 
              
            />
          </DialogLayout>
          </>
        
        ) : null}

        {visitDetail.status ? (
          <DialogLayout open={visitDetail.status}>
            <VisitDetail
              closeVisitDetail={() =>
                setvisitDetail({ status: false, data: {} })
              }
              visit={visitDetail.data}
            />
          </DialogLayout>
        ) : null}
      </Grid>
      <FloatingIcon/>
    </>
  );
};

export default VisitMob;
