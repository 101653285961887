const Style = () => ({
  header: {
    width: "100%",
    height: "83px",
    borderBottom: "1px solid #D9D9D9",
    background: "white",
    zIndex: "1",
  },
  headerLogo: {
    width: "62px",
    height: "15px",
  },
});

export default Style;
