import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { useStyle } from "../style/style";
import Close from '../../assets/images/close.svg';
import { makeStyles } from "@material-ui/core";
import Logo from "../component/Logo/Logo";
import SecondaryButton from "../component/SecondaryButton/SecondaryButton";
import triangle from "../images/icons/triangle.png";
import axios from "axios";
import { Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import lock from "../images/icons/lock.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  useTheme, useMediaQuery } from "@material-ui/core";
import './login.scss'
import {
  getLoginData,
  getLoginLoading,
  Clear,
} from "../../redux/actions/loginAction";
import { Helmet } from "react-helmet";
import Login from "../../doctor-dashboard/mobileComp/authPages/Login";

const useStyle2 = makeStyles((theme) => ({
  container: {
    width: "70%",
    height: "auto",
    marginTop: "20%",
  },
}));

function DashbordLogin() {
  const customSM = useMediaQuery("(max-width:800px)");
  const userdata = useSelector((state) => state.loginReducer.payload);
  const userss = useSelector((state) => state.loginReducer);

  let history = useHistory();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const classes = useStyle();
  const classes2 = useStyle2();
  const [showPassword, setShowPassword] = React.useState(true);
  const [loginError, setLoginError] = React.useState(false);
  const [errormsg, setErrorMSg] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.elements.email.value;
    const password = e.target.password.value;
    const data = {
      username: username,
      password: password,
    };
    dispatch(getLoginData(data));
    console.log(data)
  };
  useEffect(() => {
    dispatch(getLoginLoading());
  }, []);

  useEffect(() => {
    if (userdata) {
      if (userdata.token) {
        let doctorType = userdata.profile && userdata.profile.doctor_type && userdata.profile.doctor_type ? userdata.profile.doctor_type : "N/A"
        if (doctorType === "advice") {
          history.push("/doctor-dashboard");
        } else {
          history.push("/");
        }
        setLoginError(false);
      }
      else {
        if (userdata === "Invalid username or password!") {
          setErrorMSg(userdata);
        } else if (userdata === "Your account have been suspended!") {
          setActive(true);
        } else {
          setErrorMSg(userdata.message);
        }
        setLoginError(true);
      }
    } else {
      setLoginError(true);
    }
  }, [userdata]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return  customSM ? (
    <>
      <Login />
    </>
  ) :(
    <div className={classes.main}>
<Helmet>
  <title>doctor.doctall.com | Login
</title>
<meta 
            name='description' 
            content='Login to view your account, see number of appointments, create case notes. Send SMS to patients, view earnings, store patient information in secure cloud storag'
            />
</Helmet>
      <Dialog
        disableBackdropClick
        maxWidth={"sm"}
        open={active}
        scroll={"body"}
        onClose={() => {
          setActive(false);
        }}
      >
        <div className="loginActive">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setActive(false)}
            crossorigin="anonymous"
          />
          Your account has been deactivated.<br />
          Please contact our support team.<br />
          Email: support@doctall.com <br />
          Phone Number: 09010996000<br />
        </div>
      </Dialog>
      <Grid container className={classes.gridContainer}>

        <Grid item xs={12} md={6} spacing={3} className={classes.rightGrid}>

          <div className={classes2.container}>
            {/* <Button style={{ display: 'flex', alignItems: 'flex-end' }} onClick={() => { history.push("/doctor-registration") }} >Register</Button> */}

            <form action="" onSubmit={handleSubmit}>
              <Logo />
              {loginError && (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ color: "red", marginBottom: "40px" }}>
                    {errormsg}
                  </div>
                </div>
              )}
              <TextField
                fullWidth
                name="email"
                variant="outlined"
                className={classes.primaryInput}
                placeholder="E-mail"
              />
              <div style={{ width: "auto", height: "30px" }}></div>
              <TextField
                fullWidth
                name="password"
                variant="outlined"
                type={showPassword ? "password" : "text"}
                className={classes.primaryInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={lock}
                        alt="lock"
                        style={{ cursor: "pointer", marginRight: 22 }}
                        onClick={handleClickShowPassword}
                        crossorigin="anonymous"
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder="Password"
              />

              <div style={{ width: "auto", height: "50px" }}></div>
              <div style={{ display: "flex", alignItems: "center",width:"117%" }}>
                <Button className={classes.stepperButton} type="submit">
                  Login{" "}
                  <img
                    src={triangle}
                    alt="logo"
                    className={classes.stepperButtonLogo}
                    crossorigin="anonymous"
                  />
                </Button>
                <SecondaryButton />
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashbordLogin;
