import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function LoaderComponent(props) {
    const classes = useStyles();

    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#BA084C");

    return (
        <>
            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h6" component="div" >
                    {/* {props && props.message ? props.message : ''} */}
                </Typography>
            </div>
            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress style={{ color: "#00839B" }} />
                </Backdrop>

            </div>

        </>
    )
}
