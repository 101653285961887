import React from 'react';
import { CockpitDesign } from './cockpitDesign';

const Cockpit = () => {

    return (
        <>
            <CockpitDesign />
        </>
    )
}

export default Cockpit;