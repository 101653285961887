import React from "react";
import { Grid, Typography } from "@mui/material";
import dialogclose from "../../assets/images/DialogClose.svg";
import NotesConfirm from "../../assets/images/NotesConfirm.svg";

const CaseNoteConf = ({ closeConfirm, text }) => {
  return (
    <Grid
      container
      direction="column"
      style={{
        height: "351px",
      }}
    >
      <Grid item container justifyContent="flex-end" style={{ height: "38px" }}>
        <img
          src={dialogclose}
          alt="close button"
          style={{
            marginRight: "28px",
            marginTop: "28px",
            width: "10px",
            cursor: "pointer",
          }}
          onClick={() => closeConfirm()}
        />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "89%" }}
      >
        <img src={NotesConfirm} alt="confirm" />
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "22px",
            color: "#013C44",
            marginTop: "12px",
          }}
          align="center"
        >
          {/* You Have Successfully
          <br /> Created Case Note */}
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CaseNoteConf;
